import { Component, OnInit } from '@angular/core';
import { AdminRoute, RouteLabel } from '@app/utils/routes';
import { BreadcrumbComponent, BreadcrumbItem, } from '@components/common/breadcrumb/breadcrumb.component';
import { PfTableComponent, TableColumn } from 'pf-ui';
import { ReactiveFormsModule, } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { NgForOf, NgIf, PercentPipe } from '@angular/common';
import { QuestionsStatsComponent } from '@components/statistics/questionsStats/questions-stats.component';
import { ButtonModule } from 'primeng/button';
import { TruncatePipeModule } from '@app/pipes/truncate.pipe';
import { StatisticsService } from '@services/statistics.service';

@Component({
  selector: 'app-statistics-categories',
  standalone: true,
  imports: [
    BreadcrumbComponent,
    ReactiveFormsModule,
    DropdownModule,
    NgIf,
    QuestionsStatsComponent,
    ButtonModule,
    NgForOf,
    PercentPipe,
    PfTableComponent,
    TruncatePipeModule

  ],
  templateUrl: './categories.component.html',
})
export class CategoriesComponent implements OnInit {
  protected readonly AdminRoute = AdminRoute;

  categories = this.statsService.signalCategoriesStats;

  columns: TableColumn[] = [
    { name: 'Nom' },
    { name: "Nombre d'examens passés" },
    { name: 'Réussite' },
  ];

  rowsLength = this.categories()?.length ?? 5

  breadcrumbs: BreadcrumbItem[] = [
    { label: RouteLabel.Home, route: AdminRoute.Home },
    { label: RouteLabel.Statistics, route: AdminRoute.Statistics },
    { label: RouteLabel.StatsCategories },
  ];

  constructor(public statsService: StatisticsService) {}

  ngOnInit(): void {
    this.statsService.getCategoryStats().subscribe();
  }
}
