export const ThemeLevel = {
  Theme: 'THEME',
  SubTheme: 'SUBTHEME',
  SubSubTheme: 'SUBSUBTHEME',
} as const;

export type ThemeLevel = (typeof ThemeLevel)[keyof typeof ThemeLevel];

export class Theme {
  id?: string;
  name?: string;
  level?: ThemeLevel;
  children?: Theme[];
  parent?: string;
  numberPerSeries?: number;
}

export class ThemeList {
  theme?: Theme[] = [];
}

export type QuestionCountForTheme = {
  questionCount: number;
  themeId: string;
};
