<div class="grid">
  <app-breadcrumb [items]="breadcrumbs"></app-breadcrumb>
</div>

<h1>Gestion des examens</h1>
<h5>Accédez aux paramétrages des examens et des questions</h5>

<div class="grid grid-col-2 gap-8 m-0">
  <div class="section flex-1">
    <h5>Les exams</h5>
    <pf-action-button
      type="previous"
      customIconClass=" "
      styleClass="p-button-tertiary border-none text-sm justify-content-start"
      label="Gestion des catégories"
      [routerLink]="'/' + AdminRoute.Categories"
    ></pf-action-button>
    <pf-action-button
      type="previous"
      customIconClass=" "
      styleClass="p-button-tertiary border-none text-sm justify-content-start text-left"
      label="Gestion des questions d'entraînement"
      [routerLink]="'/' + AdminRoute.TrainingQuestions"
    ></pf-action-button>
  </div>
  <div class="section flex-1">
    <h5>Les questions</h5>
    <pf-action-button
      type="previous"
      customIconClass=" "
      styleClass="p-button-tertiary border-none text-sm justify-content-start"
      label="Gestion des thèmes"
      [routerLink]="'/' + AdminRoute.Themes"
    ></pf-action-button>
    <pf-action-button
      type="previous"
      customIconClass=" "
      styleClass="p-button-tertiary border-none text-sm justify-content-start"
      label="Gestion des étiquettes"
      [routerLink]="'/' + AdminRoute.Tags"
    ></pf-action-button>
  </div>
</div>
