import { CommonModule } from '@angular/common';
import { Component, effect, model, signal } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { initCandidateForm } from '@app/models/candidate/candidate-form.model';
import { Candidate } from '@app/models/candidate/candidate.model';
import { UploadFile } from '@app/models/common/uploadFile.model';
import {
  ExamSessionForm,
  initCreateExamSessionForm,
} from '@app/models/exam/examSession-form.model';
import {
  defaultExamSession,
  ExamSession,
} from '@app/models/exam/examSession.model';
import { ExamSessionService } from '@app/services/examSession.service';
import { IslandService } from '@app/services/island.service';
import {
  DEFAULT_DATE_FORMAT,
  DEFAULT_MAX_FILE_SIZE,
} from '@app/utils/constants';
import { isError } from '@app/utils/error';
import { AdminRoute, RouteLabel } from '@app/utils/routes';
import { isBase64String, isString } from '@app/utils/stringHelper';
import { AbstractFormComponent } from '@components/common/abstract/form/abstract-form.component';
import {
  BreadcrumbComponent,
  BreadcrumbItem,
} from '@components/common/breadcrumb/breadcrumb.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DateTranslateService } from '@services/dateTranslate.service';
import {
  CSV_EXAM_SESSION_BASE64_ERROR,
  CSV_EXAM_SESSION_COLUMNS,
  CSV_EXAM_SESSION_COLUMNS_NUMBER_ERROR,
  CSV_EXAM_SESSION_GENERIC_ERROR_MESSAGE,
  CSV_SEPARATOR,
  readLineByLine,
} from '@utils/CSV';
import {
  PfActionButtonComponent,
  PfCardComponent,
  PfFormComponent,
  PfFormErrorComponent,
  PfFormFileUploadComponent,
  PfFormLabelWrapperComponent,
  PfTableComponent,
  TableColumn,
} from 'pf-ui';
import { MessageService } from 'primeng/api';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { TableModule } from 'primeng/table';

@Component({
  selector: 'app-create-session',
  standalone: true,
  templateUrl: './create-session.component.html',
  styleUrl: './create-session.component.scss',
  imports: [
    CommonModule,
    ReactiveFormsModule,
    PfFormComponent,
    PfActionButtonComponent,
    CalendarModule,
    DropdownModule,
    PfCardComponent,
    PfFormLabelWrapperComponent,
    PfTableComponent,
    PfFormErrorComponent,
    TableModule,
    PfFormFileUploadComponent,
    RouterModule,
    BreadcrumbComponent,
    TranslateModule,
  ],
})
export class CreateSessionComponent extends AbstractFormComponent {
  islands = this.islandService.signalList;
  candidates = model<Candidate[]>();
  currentCandidates = signal<Candidate[] | undefined>([]);
  isSubmitButtonDisabled = signal(true);

  // columns = CSV_EXAM_SESSION_COLUMNS

  columns: TableColumn[] = [
    { name: CSV_EXAM_SESSION_COLUMNS[0] },
    {
      name: CSV_EXAM_SESSION_COLUMNS[1],
    },
    { name: CSV_EXAM_SESSION_COLUMNS[2] },
    { name: CSV_EXAM_SESSION_COLUMNS[3] },
  ];
  DEFAULT_MAX_FILE_SIZE = DEFAULT_MAX_FILE_SIZE;

  minSessionExamDate = new Date(Date.now());
  form: FormGroup<ExamSessionForm> | undefined;
  breadcrumbs: BreadcrumbItem[] = [
    { label: RouteLabel.Home, route: AdminRoute.Home },
    { label: "Créer une session d'examen" },
  ];

  isBase64String = isBase64String;
  initCandidateForm = initCandidateForm;

  constructor(
    private fb: FormBuilder,
    private islandService: IslandService,
    private examSessionService: ExamSessionService,
    private router: Router,
    private messageService: MessageService,
    private translate: TranslateService,
    private dateTranslateService: DateTranslateService,
  ) {
    super();
    this.islandService.list().subscribe();

    effect(() => {
      this.form = initCreateExamSessionForm(
        this.fb,
        defaultExamSession,
        this.dateTranslateService.getMonthLabels(),
      );

      if (this.form === undefined) return;
      this.controls?.file?.valueChanges.subscribe((file: UploadFile | null) => {
        try {
          if (file == null || file.base64_file === null) return;
          const candidates = this.readCandidatesCsv(file.base64_file);
          this.updateCandidatesControl(candidates);
        } catch (error) {
          if (isError(error))
            this.showErrorToast(this.messageService, error.message);
        }
      });
    });

    effect(
      () => {
        this.loadCandidatesValue();
      },
      { allowSignalWrites: true },
    );
  }

  get currentForm(): FormGroup<ExamSessionForm> | undefined {
    return this.form;
  }

  loadCandidatesValue(isSubmittingForm = false): void {
    if (this.candidates() === undefined) return;
    if (!isSubmittingForm && this.candidates()?.length != null) {
      this.currentCandidates.set(this.candidates());
      this.isSubmitButtonDisabled.set(false);
    } else {
      this.currentCandidates.set(undefined);
      this.isSubmitButtonDisabled.set(true);
    }
  }

  get controls(): ExamSessionForm | undefined {
    return this.currentForm?.controls;
  }

  readCandidatesCsv(csv: string): Candidate[] {
    if (!this.isBase64String(csv))
      throw new Error(CSV_EXAM_SESSION_BASE64_ERROR);

    return readLineByLine(csv)
      .filter((line: string) => line.trim() !== '')
      .map((candidateLine: string) => {
        return this.createCandidate(candidateLine);
      });
  }

  createCandidate(candidateLine: string): Candidate {
    const candidate = candidateLine.split(CSV_SEPARATOR);

    if (candidate.length != CSV_EXAM_SESSION_COLUMNS.length)
      throw new Error(CSV_EXAM_SESSION_COLUMNS_NUMBER_ERROR);

    return {
      id: '',
      providerId: candidate?.[0],
      category: candidate?.[1],
      language: candidate?.[2],
      additionalTime: candidate?.[3],
    };
  }

  updateCandidatesControl(candidates: Candidate[]): void {
    const candidatesControl = this.controls?.candidates;
    const isControlAvailable = candidatesControl != undefined;

    if (!isControlAvailable)
      throw new Error(CSV_EXAM_SESSION_GENERIC_ERROR_MESSAGE);

    candidatesControl.clear();

    candidates.map((candidate) => {
      const newControl = this.initCandidateForm(this.fb, candidate);
      candidatesControl.push(newControl);
    });

    candidatesControl.updateValueAndValidity();
    this.candidates.update(() => candidates);
  }

  onValidate(): void {
    this.currentForm?.markAllAsTouched();
    if (!this.currentForm || !this.currentForm.dirty || !this.currentForm.valid)
      return;
    const examSession = this.currentForm.value as ExamSession;
    const file = this.currentForm.value.file;
    this.examSessionService
      .createExamSession(examSession, file, () =>
        this.loadCandidatesValue(true),
      )
      .subscribe({
        next: () => {
          this.returnToList();
        },
        complete: () => {
          this.loadCandidatesValue(false);
        },
      });
  }

  getCalendarDateformat(): string {
    const dateFormatValue = this.translate.instant('primeng.dateFormat');
    const isDateFormatString = isString(dateFormatValue);
    return isDateFormatString && dateFormatValue !== 'dateFormat'
      ? dateFormatValue
      : DEFAULT_DATE_FORMAT;
  }

  returnToList(): void {
    this.router.navigate([AdminRoute.Sessions]);
  }
}
