import { CommonModule } from '@angular/common';
import { Component, effect } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

import {
  SpecificClosableDialogComponent
} from '@app/components/dialogs/specific-closable-dialog/specific-closable-dialog.component';
import { MediaTypes } from '@app/models/media/media-types.models';
import { MediaTag } from '@app/models/media/mediaTag.model';
import { MediaTagService } from '@app/services/mediaTag.service';
import { PfActionButtonComponent, PfFormComponent, PfStepComponent } from 'pf-ui';
import { CheckboxModule } from 'primeng/checkbox';

@Component({
  selector: 'app-media',
  standalone: true,
  imports: [
    CommonModule,
    CheckboxModule,
    PfActionButtonComponent,
    PfFormComponent,
    PfStepComponent,
    ReactiveFormsModule,
    SpecificClosableDialogComponent
  ],
  templateUrl: './edit-media.component.html',
})
export class EditMediaComponent {
  form!: FormGroup;
  mediaTags = this.mediaTagsService.signalList();
  mediaTagsConfigured: MediaTag[] = [];
  mediaType?: MediaTypes;

  constructor(
    private formBuilder: FormBuilder,
    private mediaTagsService: MediaTagService,
    private config: DynamicDialogConfig<{
      mediaId: string;
      mediaTags: MediaTag[];
    }>,
    private dialog: DynamicDialogRef
  ) {
    this.mediaTagsService.getList();

    const { data } = this.config;
    if (data) {
      this.mediaTagsConfigured = data?.mediaTags;
    }

    effect(() => {
      this.mediaTags = this.mediaTagsService.signalList();

      this.form = this.formBuilder.group({
        tags: [this.mediaTagsConfigured],
      });
    });
  }

  public close(): void {
    if (this.form.get('tags')?.value !== undefined && this.form.touched) {
      this.dialog.close({
        mediaTags: this.form.get('tags')?.value,
        mediaType: this.mediaType,
      });
    } else {
      this.dialog.close({
        mediaTags: undefined,
        mediaType: this.mediaType,
      });
    }
  }
}
