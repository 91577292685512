import { stringInjector } from '@utils/stringHelper';

export const validatorErrorMessage = {
  required: "Le champ {{$1}} est obligatoire",
  loginFailed: "L'identifiant ou le code de session est incorrect",
  minlength: "Le champ {{$1}} doit avoir au minimum {{$2}} caractère(s)",
  maxlength: "Le champ {{$1}} doit avoir au maximum {{$2}} caractère(s)",
}

export const field = {
  providerId: "Identifiant utilisateur",
  sessionCode: "Code de la session",
}

export function getValidatorErrorMessage(key: keyof typeof validatorErrorMessage, config: {
  name?: keyof typeof field,
  bind?: string[]
} | null = null): string {
  let message = validatorErrorMessage[key]
  message = stringInjector(message,
    "$1",
    config?.name != null ? (field[config.name] ?? "") : ""
  );

  if(config?.bind){
    config.bind.forEach((value,index) => {
      message = stringInjector(message, `$${ index+2 }`,value ?? "")
    });
  }

  return message;
}
