import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators, } from '@angular/forms';
import { CandidateForm, initCandidateForm, } from '../candidate/candidate-form.model';
import { Candidate } from '../candidate/candidate.model';
import { UploadFile } from '../common/uploadFile.model';
import { ExamSession } from './examSession.model';
import { getCurrentDayBy10minutesStep } from '@utils/dateHelper';

export type ExamSessionForm = {
  [key in keyof ExamSession]: AbstractControl<ExamSession[key] | null>;
} & {
  candidates?: FormArray<FormGroup<CandidateForm>>;
  file?: AbstractControl<UploadFile | null>;
};

export const initCandidatesForm = (
  fb: FormBuilder,
  candidates: Candidate[] | undefined
): FormArray<FormGroup<CandidateForm>> | undefined =>
  fb.array(
    candidates?.map((candidate) => {
      return initCandidateForm(fb, candidate);
    }) || [],
    [Validators.required]
  );

export const initCreateExamSessionForm = (
  fb: FormBuilder,
  examSession: ExamSession,
  monthLabels: string[]
): FormGroup<ExamSessionForm> | undefined => {
  const customSessionDate = examSession.sessionDate ?? new Date(getCurrentDayBy10minutesStep(monthLabels));
  return fb.group<ExamSessionForm>({
    id: fb.control(examSession.id),
    island: fb.control(examSession.island, [Validators.required]),
    // WARNING : as unknown as string is here to bypass ExamSession Modele which should be : sessionDate?: Date;
    sessionDate: fb.control(customSessionDate as unknown as string, [Validators.required]),
    candidates: initCandidatesForm(fb, []), // Retrieve candidates from exam session
    file: fb.control({base64_file: '', name: ''}),
  });
};
