import { Component, OnInit } from '@angular/core';
import { AdminRoute, RouteLabel } from '@utils/routes';
import {
  BreadcrumbComponent,
  BreadcrumbItem,
} from '@components/common/breadcrumb/breadcrumb.component';
import { PfTableComponent } from 'pf-ui';
import { StatisticsService } from '@services/statistics.service';
import { TreeTableModule } from 'primeng/treetable';
import { TreeNode } from 'primeng/api';
import { UserExamQuestionsByTheme } from '@models/statistics/statistics.model';
import { PercentPipe } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';

interface Column {
  field: string;
  header: string;
}

@Component({
  selector: 'app-questions',
  standalone: true,
  templateUrl: './themes.component.html',
  imports: [
    BreadcrumbComponent,
    PfTableComponent,
    TreeTableModule,
    PercentPipe,
    ButtonModule,
    TableModule,
    TooltipModule,
  ],
})
export class ThemesComponent implements OnInit {
  breadcrumbs: BreadcrumbItem[] = [
    { label: RouteLabel.Home, route: AdminRoute.Home },
    { label: RouteLabel.Statistics, route: AdminRoute.Statistics },
    { label: RouteLabel.Themes },
  ];

  themes: string[] | undefined = undefined;
  treeNodes: TreeNode[] = [];
  cols!: Column[];

  constructor(private statisticService: StatisticsService) {}

  ngOnInit(): void {
    this.cols = [
      { field: 'themeName', header: 'Thèmes' },
      { field: 'questionsCount', header: 'Nombre de questions' },
      {
        field: 'userExamQuestionsCount',
        header: "Nombre de questions à l'examen",
      },
      { field: 'successRatioAnswer', header: 'Réussite' },
    ];
    this.statisticService
      .getUserExamQuestionsByThemes()
      .subscribe((userExamQuestions) => {
        this.treeNodes = this.transformToTreeNode(userExamQuestions);
      });
  }

  transformToTreeNode(
    userExamQuestions: UserExamQuestionsByTheme[],
  ): TreeNode[] {
    return userExamQuestions.map((ueq) => ({
      data: {
        [this.cols[0].field]: ueq.name,
        [this.cols[1].field]: ueq.questionsCount,
        [this.cols[2].field]: ueq.userExamQuestionDistinctCount,
        [this.cols[3].field]: ueq.successRatioAnswer,
      },
      children: ueq.children
        ? this.transformToTreeNode(ueq.children as UserExamQuestionsByTheme[])
        : [],
    }));
  }
}
