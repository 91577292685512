<p-breadcrumb class="max-w-full border-0" [model]="items()">
  <ng-template pTemplate="item" let-item>
    @if (item.route) {
      <ng-container>
        @if(disabled()) {
          <p-button [disabled]="disabled()" [label]="item.label" [link]="true" class="button-link"/>
        } @else {
          <p-button [label]="item.label" [link]="true" [routerLink]="'/'+item.route" class="button-link"/>
        }
      </ng-container>
    } @else if (item.action) {
      <ng-container>
        <p-button [disabled]="disabled()" [label]="item.label" [link]="true" (onClick)="callAction(item)" class="button-link"/>
      </ng-container>
    } @else {
      <ng-container>
        <p-button [label]="item.label" [link]="true" [disabled]="true" class="button-link active-item"/>
      </ng-container>
    }
  </ng-template>
</p-breadcrumb>
