import { Component, effect } from '@angular/core';
import { AgentService } from '@services/agent.service';
import { QuestionService } from '@services/question.service';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { ValidateQuestionComponent } from '@pages/questions/question/validate-question/validate-question.component';
import { QuestionStep } from '@models/question/question-steps.model';
import { NgIf } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import {
  initStepForm,
  QuestionForm,
} from '@models/question/question-form.model';
import { QuestionDetail } from '@models/question/question-detail.model';
import { MediaService } from '@services/media.service';
import {
  BreadcrumbComponent,
  BreadcrumbItem,
} from '@components/common/breadcrumb/breadcrumb.component';
import { AdminRoute, getQuestionIdRoute, RouteLabel } from '@utils/routes';
import { PfActionButtonComponent } from 'pf-ui';
import { QuestionStatus } from '@models/question/questionStatus.model';
import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { hasAuthority } from '@models/user/agent-detail.model';
import { TooltipModule } from 'primeng/tooltip';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import {
  SpecificConfirmDialogComponent,
  SpecificConfirmDialogData,
} from '@components/dialogs/specific-confirm-dialog/specific-confirm-dialog.component';
import { ViewQuestionComponent } from '@components/dialogs/view-question/view-question.component';

@Component({
  selector: 'app-home-analyzed-questions',
  standalone: true,
  imports: [
    ValidateQuestionComponent,
    NgIf,
    BreadcrumbComponent,
    PfActionButtonComponent,
    ButtonModule,
    RouterLink,
    TooltipModule,
  ],
  templateUrl: './analyzed-questions.component.html',
})
export class AnalyzedQuestionsComponent {
  question = this.questionService.signalCurrent;
  agent = this.agentService.signalCurrent;

  loading: boolean = false;
  form: FormGroup<QuestionForm> | undefined;
  questionId: string = '';

  testQuestionModalRef: DynamicDialogRef = new DynamicDialogRef();
  validateQuestionModalRef: DynamicDialogRef = new DynamicDialogRef();
  rejectQuestionModalRef: DynamicDialogRef = new DynamicDialogRef();

  breadcrumbs: BreadcrumbItem[] = [
    { label: RouteLabel.Home, route: AdminRoute.Home },
    { label: RouteLabel.AnalyzedQuestion },
  ];

  constructor(
    private fb: FormBuilder,
    private router: Router,
    public agentService: AgentService,
    public messageService: MessageService,
    public mediaService: MediaService,
    public questionService: QuestionService,
    public dialogService: DialogService,
    private route: ActivatedRoute,
  ) {
    this.questionId = this.route.snapshot.paramMap.get('questionId') ?? '';
    this.questionService.get(this.questionId).subscribe();

    effect(
      () => {
        const question = this.question() ?? new QuestionDetail();

        this.form = initStepForm(this.fb, question);
        this.form?.controls.difficulty?.disable();
      },
      { allowSignalWrites: true },
    );
  }

  openRejectQuestionModal(): void {
    if (!this.hasPermissionToValidate) return;
    this.rejectQuestionModalRef = this.dialogService.open(
      SpecificConfirmDialogComponent,
      {
        data: {
          label: 'Voulez-vous rejeter la question ?',
          title: 'Rejeter',
          confirmLabel: 'Confirmer',
          cancelLabel: 'Annuler',
        } satisfies SpecificConfirmDialogData,
        styleClass: 'pf-two-column-form-modal',
        showHeader: false,
      },
    );

    this.rejectQuestionModalRef.onClose.subscribe((confirm?: boolean) => {
      if (confirm === true) this.rejectQuestion();
    });
  }

  rejectQuestion(): void {
    if (this.question() === null || !this.hasPermissionToValidate) return;

    this.questionService.reject(this.question() as QuestionDetail).subscribe({
      error: () => {
        this.messageService.add({
          severity: 'error',
          summary: 'Erreur',
          detail: 'Impossible de rejeter la question',
        });
      },
      complete: () => {
        this.messageService.add({
          severity: 'success',
          detail: 'Question rejetée avec succès',
        });
        this.router.navigate([AdminRoute.Home]);
      },
    });
  }

  openValidateQuestionModal(): void {
    if (!this.hasPermissionToValidate) return;
    this.validateQuestionModalRef = this.dialogService.open(
      SpecificConfirmDialogComponent,
      {
        data: {
          label: 'Voulez-vous valider la question ?',
          title: 'Validation',
          confirmLabel: 'Confirmer',
          cancelLabel: 'Annuler',
        } satisfies SpecificConfirmDialogData,
        styleClass: 'pf-two-column-form-modal',
        showHeader: false,
      },
    );

    this.validateQuestionModalRef.onClose.subscribe((confirm?: boolean) => {
      if (confirm === true) this.validateQuestion();
    });
  }

  validateQuestion(): void {
    if (this.question() === null || !this.hasPermissionToValidate) return;

    this.questionService.validate(this.question() as QuestionDetail).subscribe({
      error: () => {
        this.messageService.add({
          severity: 'error',
          summary: 'Erreur',
          detail: 'Impossible de valider la question',
        });
      },
      complete: () => {
        this.messageService.add({
          severity: 'success',
          detail: 'Question Validée avec succès',
        });
        this.router.navigate([AdminRoute.Home]);
      },
    });
  }

  get canBeValidated(): boolean {
    return (
      this.question()?.questionStatus ===
        QuestionStatus.PENDING_VALIDATION_0_2 ||
      this.question()?.questionStatus === QuestionStatus.PENDING_VALIDATION_1_2
    );
  }

  get hasPermissionToValidate(): boolean {
    if (this.loading) return false;
    if (hasAuthority(this.agent(), 'question.validate')) {
      if (this.isLastQuestionUpdator) {
        return false;
      }

      if (
        this.question()?.questionStatus ===
        QuestionStatus.PENDING_VALIDATION_1_2
      ) {
        return !this.hasAlreadyValidateQuestion;
      }

      return true;
    }
    return false;
  }

  get hasAlreadyValidateQuestion(): boolean {
    return this.question()?.firstValidationBy === this.agent()?.sub;
  }

  get isLastQuestionUpdator(): boolean {
    return this.question()?.lastModifiedBy === this.agent()?.sub;
  }

  get validateTooltipMessage(): string {
    if (this.loading) return 'Chargement en cours...';

    if (this.hasAlreadyValidateQuestion) {
      return 'Vous ne pouvez pas valider à nouveau';
    }
    if (this.isLastQuestionUpdator) {
      return 'Vous ne pouvez pas valider votre question';
    }
    if (!this.hasPermissionToValidate) {
      return 'Vous ne pouvez pas valider la question';
    }
    return 'Valider';
  }

  get rejectTooltipMessage(): string {
    if (this.loading) return 'Chargement en cours...';

    if (this.hasAlreadyValidateQuestion) {
      return "Vous ne pouvez pas rejeter la question après l'avoir validée";
    }
    if (this.isLastQuestionUpdator) {
      return 'Vous ne pouvez pas rejeter votre question';
    }
    if (!this.hasPermissionToValidate) {
      return 'Vous ne pouvez pas rejeter la question';
    }
    return 'Rejeter';
  }

  onTestQuestionModal(): void {
    if (this.question() === null) return;

    this.testQuestionModalRef = this.dialogService.open(ViewQuestionComponent, {
      data: {
        question: this.question(),
        isTest: true,
      },
      styleClass: 'pf-two-column-form-modal',
      showHeader: false,
    });

    this.testQuestionModalRef.onClose.subscribe();
  }

  protected readonly QuestionStep = QuestionStep;
  protected readonly RouteLabel = RouteLabel;
  protected readonly QuestionStatus = QuestionStatus;
  protected readonly getQuestionIdRoute = getQuestionIdRoute;
}
