<app-specific-closable-dialog
  title="Sélectionner le ou les thème(s)"
  (closeClicked)="close()"
  [showCloseButton]="false"
>
  <div class="grid">
    <div class="col-12 form-container">
      <p-tree
        [value]="themes()"
        selectionMode="checkbox"
        class="w-full"
        [propagateSelectionUp]="false"
        [propagateSelectionDown]="false"
        (onNodeSelect)="onNodeSelect($event)"
        (onNodeUnselect)="onNodeUnselect($event)"
        [(selection)]="selectedThemes"
      ></p-tree>
    </div>
    <div class="col-12 flex gap-1 justify-content-end">
      <pf-action-button
        styleClass="p-button-tertiary"
        label="Annuler"
        (buttonClicked)="close()"
      ></pf-action-button>
      <pf-action-button
        label="Confirmer"
        (buttonClicked)="close(true)"
      ></pf-action-button>
    </div>
  </div>
</app-specific-closable-dialog>
