import { Observable } from 'rxjs';
import { BaseMessageService } from '@services/baseMessage.service';
import {
  SpecificConfirmDialogComponent,
  SpecificConfirmDialogData
} from '@components/dialogs/specific-confirm-dialog/specific-confirm-dialog.component';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { MessageService } from 'primeng/api';

export type EditableTableColumnType = {
  id?: string;
}

export abstract class AbstractEditableColumn<T extends EditableTableColumnType> extends BaseMessageService {

  protected constructor(props: MessageService, protected dialog: DialogService) {
    super(props);
  }

  abstract save(item: T): Observable<T>
  abstract delete(item: T): Observable<T>
  abstract reloadItems(): void
  abstract editableItems: T[] | undefined;

  abstract loading: boolean;

  public clonedItems: { [s: string]: T } = {};

  public errorEditMessage = "Une erreur est survenue lors de l'enregistrement";
  public successEditMessage = "Enregistrement réussie";
  public successDeleteMessage = "Suppression réussie";
  public errorDeleteMessage = "Erreur lors de la suppression de l'enregistrement";

  public deleteModalDialogRef: DynamicDialogRef = new DynamicDialogRef();
  public dialogConfig = {
    label: 'Souhaitez-vous confirmer la suppression ?',
    labelHtml: "",
    title: "Suppression",
    confirmLabel: 'Annuler',
    cancelLabel: 'Confirmer',
  } satisfies SpecificConfirmDialogData;

  public resetClonedItems(): void {
    this.clonedItems = {};
  }

  public onRowEditInit(item: T): void {
    this.resetClonedItems();
    if(item.id === undefined) return;
    this.clonedItems[item.id] = { ...item };
  }

  public onRowEditSave(item: T): void {
    if (this.loading) return;
    this.handleObservable(this.save(item), {
      success: this.successEditMessage,
      error: this.errorEditMessage
    });
  }

  public onRowEditCancel(item: T): void {
    const index = this.editableItems?.findIndex(i => i.id === item.id);
    if(item.id === undefined) return;
    if (index !== undefined && index >= 0 && this.editableItems !== undefined) {
      this.editableItems[index] = this.clonedItems[item.id];
      delete this.clonedItems[item.id];
    }
  }

  public isEditing(item: T): boolean {
    if(item.id === undefined) return false;
    return this.clonedItems[item.id] !== undefined;
  }

  public openModalConfirmDelete(item: T): void {
    if (this.loading) return;

    this.deleteModalDialogRef = this.dialog.open(SpecificConfirmDialogComponent, {
      data: this.dialogConfig,
      styleClass: 'pf-two-column-form-modal',
      showHeader: false,
    });

    this.deleteModalDialogRef.onClose.subscribe((cancel?: boolean) => {
      if (cancel === false) {
        this.handleObservable(this.delete(item), {
          success: this.successDeleteMessage,
          error: this.errorDeleteMessage
        });
      }
    });
  }

  public handleObservable(obs: Observable<T>, responses: {
    success: string;
    error: string;
  }): void {

    this.loading = true;

    obs.subscribe({
      next: () => {
        this.loading = false;
        this.resetClonedItems();
        this.reloadItems();
        this.showSuccessToast(responses.success);
      },
      error: () => {
        this.loading = false;
        this.resetClonedItems();
        this.showErrorToast(responses.error);
      },
      complete: () => {
        this.loading = false;
      }
    });
  }
}
