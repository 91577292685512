import { DEFAULT_DURATION, DEFAULT_QUESTION_DURATION } from './constants';

export const getQuestionDurationInMs = (
  duration: number | undefined,
  isCorrection: boolean,
  hasAdditionalTime = false,
): number => {
  if (isCorrection) return 3 * DEFAULT_DURATION;

  return duration !== undefined && duration > 0
    ? hasAdditionalTime
      ? getAdditionalTimeDuration(duration) * DEFAULT_DURATION
      : duration * DEFAULT_DURATION
    : hasAdditionalTime
      ? getAdditionalTimeDuration(DEFAULT_QUESTION_DURATION)
      : DEFAULT_QUESTION_DURATION;
};

export const getAdditionalTimeDuration = (duration: number): number => {
  return Math.round(duration * (1 + 1 / 3));
};
