import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { AnswerChoices } from './answerChoices.model';
import {
  AnswerChoicesForm,
  initAnswerChoiceControl,
} from './answerChoicesForm.model';
import { mapCategoryList } from './category.model';
import { Difficulty } from './difficulty.model';
import { hasIsCorrect } from './hasIsCorrect.directive';
import { QuestionDetail } from './question-detail.model';

export type QuestionForm = {
  [key in keyof Omit<
    QuestionDetail,
    'answerChoices' | 'secondAnswerChoices'
  >]: AbstractControl<QuestionDetail[key] | null>;
} & {
  answerChoices?: FormArray<FormGroup<AnswerChoicesForm>>;
  secondAnswerChoices?: FormArray<FormGroup<AnswerChoicesForm>>;
};

const initAnswerChoicesForm = (
  fb: FormBuilder,
  answerChoices: AnswerChoices[] | undefined,
): FormArray<FormGroup<AnswerChoicesForm>> | undefined => {
  return fb.array(
    answerChoices?.map((answer) => {
      return initAnswerChoiceControl(fb, answer.rank, answer);
    }) || [],
    [Validators.minLength(2), hasIsCorrect()],
  );
};

export const initStepForm = (
  fb: FormBuilder,
  question?: QuestionDetail,
): FormGroup<QuestionForm> | undefined => {
  if (!question) return;

  const questionDuration = question.duration ?? 30;
  const questionScore = question.score ?? 1;

  const form = fb.group<QuestionForm>({
    id: fb.control(question.id),
    questionStatus: fb.control(question.questionStatus),
    description: fb.control(question.description, Validators.required),
    difficulty: fb.control(question.difficulty ?? Difficulty.MEDIUM),
    secondDescription: fb.control(
      question.secondDescription,
      Validators.required,
    ),
    answerChoices: initAnswerChoicesForm(fb, question.answerChoices),
    secondAnswerChoices: initAnswerChoicesForm(
      fb,
      question.secondAnswerChoices,
    ),
    picture: fb.control(question.picture, Validators.required),
    themes: fb.control(question.themes, [Validators.required]),
    categories: fb.control(mapCategoryList(question.categories), [
      Validators.required,
    ]),
    duration: fb.control(questionDuration, [
      Validators.required,
      Validators.min(1),
    ]),
    score: fb.control(questionScore, [Validators.required, Validators.min(1)]),
    metropolitan: fb.control(question.metropolitan),
    voiceSound: fb.control(question.voiceSound),
    secondVoiceSound: fb.control(question.secondVoiceSound),
    firstValidationBy: fb.control(question.firstValidationBy),
    secondValidationBy: fb.control(question.secondValidationBy),
  });

  const secondAnswerChoicesLength =
    question?.secondAnswerChoices?.length === undefined
      ? 0
      : question?.secondAnswerChoices?.length;

  const isAnySecondAnswerChoices = secondAnswerChoicesLength > 0;

  if (question.secondDescription == null || !isAnySecondAnswerChoices) {
    form.controls.secondDescription?.disable();
    form.controls.secondAnswerChoices?.disable();
  } else {
    form.controls.secondDescription?.enable();
    form.controls.secondAnswerChoices?.enable();
  }

  return form;
};
