<div class="grid">
  <app-breadcrumb [items]="breadcrumbs"></app-breadcrumb>
</div>

<h1>Paramètres</h1>
<h5>
  Modifier, ajouter, supprimer les catégories d’examen de la Direction des
  Transports Terrestres.
</h5>

<pf-table
  [response]="categories()"
  [isLazyMode]="true"
  noResultLabel="Pas de catégories"
  (onParametersChanged)="onParametersChangedHandler($event)"
  [rows]="LIMIT_ROW_TABLE"
  [columns]="columns"
  [displaySkeleton]="true"
  title="Catégories"
  [showCaption]="true"
  [showSearch]="false"
  dataKey="id"
  styleClass="mb-5"
>
  <ng-template #content let-category>
    <tr>
      <td>
        @if (isEditing(category)) {
          <input
            class="w-full"
            pInputText
            type="text"
            [(ngModel)]="category.name"
          />
        } @else {
          <div class="flex w-full align-items-center gap-2">
            {{ category.name }}
          </div>
        }
      </td>
      <td class="icon-button">
        <div class="flex justify-content-end">
          <p-button
            *ngIf="isEditing(category)"
            ariaLabel="Annuler"
            pTooltip="Annuler"
            tooltipPosition="top"
            icon="pi pi-times"
            [rounded]="true"
            (onClick)="onRowEditCancel(category)"
          ></p-button>
          <p-button
            *ngIf="isEditing(category)"
            ariaLabel="Enregistrer"
            pTooltip="Enregistrer"
            tooltipPosition="top"
            icon="pi pi-check"
            [rounded]="true"
            (onClick)="onRowEditSave(category)"
          ></p-button>
          <p-button
            *ngIf="!isEditing(category)"
            ariaLabel="Modifier le nom"
            pTooltip="Modifier le nom"
            tooltipPosition="top"
            icon="pi pi-pencil"
            [rounded]="true"
            [disabled]="!canEditCategory"
            (onClick)="onRowEditInit(category)"
          ></p-button>
          <p-button
            ariaLabel="Voir les séries"
            pTooltip="Voir les séries"
            tooltipPosition="top"
            icon="pi pi-eye"
            [rounded]="true"
            [disabled]="!canShowSeries"
            [routerLink]="[getCategorySeriesIdRoute(category.id)]"
          ></p-button>
          <p-button
            ariaLabel="Modifier les paramètres"
            pTooltip="Modifier les paramètres"
            tooltipPosition="top"
            icon="pi pi-cog"
            [rounded]="true"
            [disabled]="!canEditCategory"
            [routerLink]="[getCategoryIdRoute(category.id)]"
          ></p-button>
          <p-button
            ariaLabel="Supprimer la catégorie"
            pTooltip="Supprimer la catégorie"
            tooltipPosition="top"
            icon="pi pi-trash"
            [disabled]="!canDeleteCategory"
            [rounded]="true"
            (onClick)="openModalConfirmDelete(category)"
          ></p-button>
        </div>
      </td>
    </tr>
  </ng-template>
</pf-table>
