import { CommonModule } from '@angular/common';
import { NgModule, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncate',
})
export class TruncatePipe implements PipeTransform {
  transform(value: string, limit: number = 75, trail: string = '...'): unknown {
    const shouldTruncate = value.length > limit;
    if (!shouldTruncate) {
      return value;
    }

    const truncatedValue = value.substring(0, limit);
    const lastSpaceIndex = truncatedValue.lastIndexOf(' ');

    if (lastSpaceIndex > -1) {
      return truncatedValue.substring(0, lastSpaceIndex) + trail;
    }

    return truncatedValue + trail;
  }
}

@NgModule({
  declarations: [TruncatePipe],
  imports: [CommonModule],
  exports: [TruncatePipe],
})
export class TruncatePipeModule {}
