<pf-form *ngIf="currentForm" [formGroup]="currentForm">
  <div class="grid">
    <pf-card class="col-12">
      <app-question-preview
        [isSoundDisabled]="true"
        imageContainerHeight="30vh"
        [question]="question()"
      >
      </app-question-preview>
    </pf-card>
    <!-- Theme(s) selection -->
    <pf-card class="col-12" title="Sélectionner le ou les thème(s)">
      <div class="grid grid-nogutter align-items-center">
        <div class="col-8">
          <p
            class="px-3 m-0"
            [class.hidden]="(controls?.themes?.value?.length ?? 0) <= 0"
          >
            {{ displaySelectedThemes() }}
          </p>
          <pf-form-label-wrapper
            [hidden]="(controls?.themes?.value?.length ?? 0) > 0"
            [showErrors]="showErrors(currentForm, 'themes')"
            class="px-5 m-0"
          >
            <ng-template #errors>
              <pf-form-error
                *ngIf="controls?.themes?.errors?.['required']"
                label="Le thème est un champ obligatoire. Veuillez en renseigner un au minimum."
              ></pf-form-error>
            </ng-template>
          </pf-form-label-wrapper>
        </div>
        <div class="col-4">
          <pf-action-button
            *ngIf="(controls?.themes?.value?.length ?? 0) > 0"
            styleClass="p-button-tertiary button-link"
            label="Supprimer le ou les thèmes"
            customIconClass="pi pi-trash"
            (buttonClicked)="patchThemes([])"
          >
          </pf-action-button>
        </div>
        <pf-action-button
          styleClass="p-button-tertiary button-link"
          label="Sélectionner un ou plusieurs thèmes"
          customIconClass="pi pi-plus-circle"
          (buttonClicked)="openAccordionSelect()"
        >
        </pf-action-button>
      </div>
    </pf-card>
    <!-- Category selection -->
    <pf-card class="col-12" title="Sélectionner la ou les catégorie(s)">
      <div class="grid grid-nogutter">
        <pf-form-label-wrapper
          [showErrors]="showErrors(currentForm, 'categories')"
          class="col-12"
        >
          <ng-template #errors>
            <pf-form-error
              *ngIf="controls?.categories?.errors?.['required']"
              label="La catégorie est un champ obligatoire. Veuillez en renseigner au minimum une."
            ></pf-form-error>
          </ng-template>
        </pf-form-label-wrapper>
        <div *ngFor="let category of categories; index as i" class="col-6 p-1">
          <p-checkbox
            labelStyleClass="white-space-nowrap text-overflow-ellipsis max-w-20rem overflow-hidden"
            [formControl]="$any(controls?.categories)"
            [pTooltip]="category.name"
            [inputId]="'categories' + i"
            [label]="category.name"
            [value]="category"
          ></p-checkbox>
        </div>
      </div>
    </pf-card>
    <pf-card class="col-6" title="Durée">
      <div class="grid grid-nogutter align-items-center">
        <pf-form-label-wrapper
          [showErrors]="showErrors(currentForm, 'duration')"
          class="col-12"
        >
          <ng-template #errors>
            <pf-form-error
              *ngIf="controls?.duration?.errors?.['min']"
              label="Le minimum de durée possible est de 1 seconde."
            ></pf-form-error>
          </ng-template>
        </pf-form-label-wrapper>
        <p class="col-5 white-space-nowrap">Durée en secondes :</p>
        <p-inputNumber
          class="col-4 h-3rem"
          inputStyleClass="w-4"
          inputId="duration"
          [formControl]="$any(controls?.duration)"
          [showButtons]="true"
        >
        </p-inputNumber>
      </div>
    </pf-card>
    <pf-card class="col-6" title="Notation">
      <div class="grid grid-nogutter align-items-center">
        <pf-form-label-wrapper
          [showErrors]="showErrors(currentForm, 'score')"
          class="col-12"
        >
          <ng-template #errors>
            <pf-form-error
              *ngIf="controls?.score?.errors?.['min']"
              label="Le minimum de point possible est de 1."
            ></pf-form-error>
          </ng-template>
        </pf-form-label-wrapper>
        <p class="col-2 white-space-nowrap">Points :</p>
        <p-inputNumber
          class="col-4 h-3rem"
          inputStyleClass="w-4"
          inputId="score"
          [formControl]="$any(controls?.score)"
          [showButtons]="true"
        >
        </p-inputNumber>
      </div>
    </pf-card>

    <div class="col-12 flex justify-content-end gap-3">
      <pf-action-button
        styleClass="p-button-tertiary"
        label="Valider"
        (buttonClicked)="validate()"
      ></pf-action-button>
    </div>
  </div>
</pf-form>
