<div class="grid">
  <app-breadcrumb [items]="breadcrumbs"></app-breadcrumb>
</div>

<h1>Série {{ this.serieId }}</h1>

<app-serie-informations [serie]="serie()"></app-serie-informations>

<pf-table
  [response]="serieQuestions()"
  [isLazyMode]="true"
  [columns]="columns"
  noResultLabel="Aucune question dans cette série"
  (onParametersChanged)="onParametersChangedHandler($event)"
  [rows]="10"
  [displaySkeleton]="true"
  [showCaption]="true"
  [showSearch]="false"
  title="Liste des questions de la série"
  dataKey="id"
  [rowsPerPageOptions]="[10, 20]"
  styleClass="mb-5"
>
  <ng-template #content let-question>
    <tr>
      <td>
        {{ question.description | truncate: MAX_QUESTION_DESCRIPTION_LENGTH }}
      </td>
      <td>
        <app-select-media-thumbnail [media]="question.picture">
        </app-select-media-thumbnail>
      </td>
      <td>{{ renderList(question.themesDTO) }}</td>
    </tr>
  </ng-template>
</pf-table>
