import { User } from '../user/user.model';
import { ExamSession } from './examSession.model';
import { Category } from '@models/question/category.model';

export enum UserExamSessionStatus {
  REGISTERED = 'REGISTERED',
  CONFIRMED = 'CONFIRMED',
  CANCELED = 'CANCELED',
  FINISHED = 'FINISHED',
}

export enum LanguageEnum {
  FRENCH = 'Francais',
  TAHITIEN = 'Tahitien',
}

export type UserExamSession = {
  examSession: ExamSession;
  user: User;

  category: Category;
  language: LanguageEnum;
  additionalTime: boolean;

  questions?: string[];
  answerChoices?: string[];
  hasSuccessfullyPassed?: boolean;
  status?: UserExamSessionStatus;
  present?: boolean;
  connected?: boolean;
  hasFinishedTraining?: boolean;
  hasFinishedExam?: boolean;
  hasFinishedExplanation?: boolean;
  hasFinishedTrainingExamples?: boolean;
  lastSavedQuestionId?: string;
};
