import { Component } from '@angular/core';
import { PageRequest, PfTableComponent, TableColumn } from 'pf-ui';
import { QuestionStats } from '@models/statistics/statistics.model';
import { MAX_QUESTION_DESCRIPTION_LENGTH } from '@pages/questions/questions.component';
import { NgForOf, PercentPipe } from '@angular/common';
import { TruncatePipeModule } from '@app/pipes/truncate.pipe';
import { ButtonModule } from 'primeng/button';
import { StatisticsService } from '@services/statistics.service';
import { BaseMessageService } from '@services/baseMessage.service';
import { QuestionService } from '@services/question.service';
import { TableModule } from 'primeng/table';

@Component({
  selector: 'app-statistics-flagged-questions-table',
  standalone: true,
  imports: [
    PfTableComponent,
    NgForOf,
    TruncatePipeModule,
    PercentPipe,
    ButtonModule,
    TableModule
  ],
  templateUrl: './flagged-questions-stats.component.html',
})
export class FlaggedQuestionsStatsComponent {
  flaggedQuestionsStats = this.statisticsService.signalFlaggedQuestionsStats;

  protected readonly MAX_QUESTION_DESCRIPTION_LENGTH = MAX_QUESTION_DESCRIPTION_LENGTH;

  flaggedQuestionStats: QuestionStats[] | undefined = undefined;
  loading: boolean = false;

  columns: TableColumn[] = [
    { name: "" },
    { name: "Questions" },
    { name: "Utilisation" },
    { name: "Réussite" },
    { name: "Thèmes" },
    { name: "Catégories" }
  ];

  rowsLength: number = this.flaggedQuestionStats?.length ?? 4;
  currentPageRequest: PageRequest = {
    global_filter_fields: 'categoryIds',
    global_filter: [],
  };

  constructor(
    public statisticsService: StatisticsService,
    public messageService: BaseMessageService,
    public questionService: QuestionService,
  ) {

  }

  onParametersChangedHandler(event: PageRequest): void {
    this.currentPageRequest = {
      ...event,
    };
    this.statisticsService.getFlaggedQuestionsStats(this.currentPageRequest).subscribe();
    this.statisticsService.getQuestionsStats({}).subscribe();
  }

  setUnFlagged(question: QuestionStats): void {
    this.questionService.setUnFlagged({ id: question.questionId }).subscribe({
      error: (err: string) => {
        this.messageService.showErrorToast(err);
      },
      complete: () => {
        this.onParametersChangedHandler(this.currentPageRequest);
        this.messageService.showSuccessToast("Flag retiré avec succès");
      }
    });
  }

  getThemesName(question: QuestionStats): string {
    return question.childrenThemes?.map((t) => `- ${t}`).join("<br/>") ?? "";
  }

  getCategoriesName(question: QuestionStats): string {
    return question.categories?.join("<br/>") ?? "";
  }
}
