import { Media } from '../media/media.model';
import { AnswerChoices } from './answerChoices.model';
import { Category } from './category.model';
import { Difficulty } from './difficulty.model';
import { QuestionStatus } from './questionStatus.model';
import { Theme } from './theme.model';
import { VoiceSound } from '@models/voice-sound/voice-sound.model';

export class QuestionDetail {
  id: string = '';
  description?: string;
  secondDescription?: string;
  difficulty?: Difficulty;
  duration?: number;
  score?: number;
  categories?: Category[] = [];
  themes?: Theme[] = [];
  picture?: Media;
  questionStatus?: QuestionStatus;
  answerChoices?: AnswerChoices[] = [];
  secondAnswerChoices?: AnswerChoices[] = [];
  voiceSound?: VoiceSound;
  secondVoiceSound?: VoiceSound;
  correction?: string;
  correctionVoiceSound?: VoiceSound;
  metropolitan?: boolean = false;

  createdBy?: string;
  lastModifiedBy?: string;
  firstValidationBy?: string | null = null;
  secondValidationBy?: string | null = null;
}

export class ProjectedQuestionDetails {
  question?: QuestionDetail[];
}

export const checkMissingSound = (
  question?: QuestionDetail | null,
): boolean => {
  if (!question) return true;

  if (
    question?.voiceSound?.id == null ||
    (question?.answerChoices?.some((a) => a.voiceSound?.id == null) ?? true)
  )
    return true;

  return (
    question?.secondDescription != null &&
    (question?.secondVoiceSound?.id == null ||
      (question?.secondAnswerChoices?.some((sa) => sa.voiceSound?.id == null) ??
        true))
  );
};
