import { CommonModule } from '@angular/common';
import { Component, input } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-personal-data-item',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './personal-data-item.component.html',
  styleUrl: './personal-data-item.component.scss',
})
export class PersonalDataItemComponent {
  label = input<string>();
  value = input<string>();

  constructor() {}
}
