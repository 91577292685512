import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AdminRoute } from '@app/utils/routes';
import { KeycloakService } from 'keycloak-angular';
import { MessageService } from 'primeng/api';
import { from, Observable, of, switchMap } from 'rxjs';
import { ErrorEnum } from '@utils/errorEnum';
import { TranslateService } from '@ngx-translate/core';

export type ExceptionError = {
  error?: string | { [k: string]: string };
  status: number;
};

@Injectable({
  providedIn: 'root',
})
export abstract class ErrorService {
  protected keycloak = inject(KeycloakService);
  protected router = inject(Router);
  protected translateService = inject(TranslateService);

  private messageErrorType =
    "Une erreur s'est produite lors du traitement de votre demande. Veuillez réessayer plus tard.";
  private titleErrorType = 'Oups...';

  protected constructor(protected messageService: MessageService) {}

  public handleErrors(
    exception: {
      error?: string | object;
      status: number;
    } = {
      status: 500,
    },
  ): Observable<never> {
    switch (exception.status) {
      case 406:
      case 400:
        if (exception.error != null && typeof exception.error === 'object') {
          const ex = exception as ExceptionError;
          return this.handleValidationError(ex);
        }
        return this.handleServerError();
      case 403:
        return this.handleUnauthorizedError();
      case 401:
        return this.handleUnAuthenticatedError();
      default:
        return this.handleServerError();
    }
  }

  handleValidationError(exception: ExceptionError): Observable<never> {
    if (exception.error != null && typeof exception.error === 'object') {
      const error = exception.error as { [k: string]: string };

      Object.entries(error).map(([key, value]) => {
        this.showErrorToast(
          `${key}: ${value}`,
          this.getErrorTitle(ErrorEnum.VALIDATION_FAIL),
        );
      });
    }
    return of();
  }

  handleUnauthorizedError(): Observable<never> {
    this.showErrorToast(
      this.getErrorMessage(ErrorEnum.UNAUTHORIZED),
      this.getErrorTitle(ErrorEnum.UNAUTHORIZED),
    );
    return this.goBackToHome();
  }

  handleServerError(): Observable<never> {
    this.showErrorToast(
      this.getErrorMessage(ErrorEnum.SERVER_ERROR),
      this.getErrorTitle(ErrorEnum.SERVER_ERROR),
    );
    return of();
  }

  handleUnAuthenticatedError(): Observable<never> {
    this.showErrorToast(
      this.getErrorMessage(ErrorEnum.UNAUTHENTICATED),
      this.getErrorTitle(ErrorEnum.UNAUTHENTICATED),
    );
    return this.goBackToKeycloakLogin();
  }

  protected goBackToKeycloakLogin(): Observable<never> {
    return from(this.keycloak.login()).pipe(switchMap(() => of()));
  }

  protected goBackToHome(): Observable<never> {
    return from(this.router.navigate([AdminRoute.Home])).pipe(
      switchMap(() => of()),
    );
  }

  private getErrorMessage(error: ErrorEnum): string {
    return (
      this.translateService.instant(`errors.${error}.message`) ??
      this.messageErrorType
    );
  }

  private getErrorTitle(error: ErrorEnum): string {
    return (
      this.translateService.instant(`errors.${error}.title`) ??
      this.titleErrorType
    );
  }

  protected showErrorToast(detail: string, summary: string = 'Erreur'): void {
    this.messageService.add({
      severity: 'error',
      summary,
      detail,
    });
  }

  protected showInfoToast(
    detail: string,
    summary: string = 'Information',
  ): void {
    this.messageService.add({
      severity: 'info',
      summary,
      detail,
    });
  }
}
