import { CommonModule } from '@angular/common';
import {
  Component,
  ContentChild,
  input,
  output,
  TemplateRef,
} from '@angular/core';
import { RouterLink } from '@angular/router';

import { SpinnerComponent } from '@app/components/common/spinner/spinner.component';
import { TruncatePipeModule } from '@app/pipes/truncate.pipe';
import { TranslateModule } from '@ngx-translate/core';
import {
  PageRequest,
  PageResponse,
  PfActionButtonComponent,
  PfTableComponent,
  TableColumn,
} from 'pf-ui';
import { ButtonModule } from 'primeng/button';
import { TableModule } from 'primeng/table';
import { BreadcrumbComponent } from '@components/common/breadcrumb/breadcrumb.component';
import { TooltipModule } from 'primeng/tooltip';
import { Question } from '@models/question/question.model';
import { MAX_QUESTION_DESCRIPTION_LENGTH } from '@pages/questions/questions.component';
import { ThumbnailComponent } from '@components/medias/thumbnail/thumbnail.component';
import { QuestionStatusComponent } from '@components/questions/question-status/question-status.component';
import { MultiSelectModule } from 'primeng/multiselect';
import { TagModule } from 'primeng/tag';
import { FormsModule } from '@angular/forms';
import { PluralizePipeModule } from '@app/pipes/pluralize.pipe';
import { Category } from '@models/question/category.model';
import { Theme } from '@models/question/theme.model';
import { joinArray } from '@utils/stringHelper';

@Component({
  selector: 'app-home-questions-list',
  standalone: true,
  templateUrl: './dashboard-questions-list.component.html',
  imports: [
    ButtonModule,
    CommonModule,
    PfActionButtonComponent,
    RouterLink,
    SpinnerComponent,
    TableModule,
    TranslateModule,
    TruncatePipeModule,
    BreadcrumbComponent,
    TooltipModule,
    ThumbnailComponent,
    PfTableComponent,
    QuestionStatusComponent,
    MultiSelectModule,
    TagModule,
    FormsModule,
    PluralizePipeModule,
  ],
})
export class DashboardQuestionsListComponent {
  questions = input<PageResponse<Question> | undefined>(undefined);
  noResultLabel = input<string>('Pas de question');
  columns = input<TableColumn[]>([]);

  parametersChangedHandler = output<PageRequest>();

  @ContentChild('customColumn', { static: true })
  customColumn: TemplateRef<{
    $implicit: Question;
  }> | null = null;

  constructor() {}

  onParametersChangedHandler(event: PageRequest): void {
    this.parametersChangedHandler.emit(event);
  }

  renderList(values: Category[] | Theme[]): string {
    return values.map((x) => x.name).join(', ');
  }

  getTooltipDescription(question: Question): string {
    return joinArray(
      [question.description, question.secondDescription],
      '\r\n',
    );
  }

  protected readonly MAX_QUESTION_DESCRIPTION_LENGTH =
    MAX_QUESTION_DESCRIPTION_LENGTH;
}
