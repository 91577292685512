import { CommonModule } from '@angular/common';
import { Component, effect, input, signal, untracked } from '@angular/core';
import { QuestionDetail } from '@app/models/question/question-detail.model';
import { EuropeanFlagComponent } from '@app/components/common/european-flag/european-flag.component';
import { MediaService } from '@app/services/media.service';
import { ImageModule } from 'primeng/image';
import { SkeletonModule } from 'primeng/skeleton';
import {
  getImageSize,
  IMAGE_RATIO,
  ImageDimensionStyle,
} from '@app/utils/image';
import { SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-question-preview-image',
  standalone: true,
  imports: [CommonModule, EuropeanFlagComponent, ImageModule, SkeletonModule],
  templateUrl: './question-preview-image.component.html',
  styleUrl: './question-preview-image.component.scss',
})
export class QuestionPreviewImageComponent {
  displayCorrection = input<boolean>(false);
  imageContainerHeight = input<string>('25vh');
  question = input<QuestionDetail | null>();
  currentImageUri = signal<SafeUrl | string | undefined>(undefined);

  imageRatio = IMAGE_RATIO;

  constructor(public mediaService: MediaService) {
    effect(
      () => {
        const picture = this.question()?.picture;

        untracked(() => {
          if (picture?.base64_file !== undefined) {
            this.currentImageUri.set(
              `data:image/jpg;base64,${picture.base64_file}`,
            );
          } else if (
            picture?.id !== undefined &&
            picture?.keyName !== undefined
          ) {
            this.mediaService
              .getImage(picture.keyName)
              .subscribe((currentImageUri: SafeUrl | undefined) => {
                this.currentImageUri.set(currentImageUri ?? undefined);
              });
          } else {
            this.currentImageUri.set(undefined);
          }
        });
      },
      { allowSignalWrites: true },
    );
  }

  get displayMetropolitanIcon(): boolean {
    const isMetropolitan = this.question()?.metropolitan;

    return isMetropolitan === true;
  }

  get imageSize(): ImageDimensionStyle {
    const primeNgImageComponent = document.getElementById('question-image');
    if (!primeNgImageComponent) return {};

    const image = primeNgImageComponent.getElementsByTagName('img')[0];
    if (image === undefined) return {};

    const height = image.naturalHeight;
    const width = image.naturalWidth;

    return getImageSize({ height, width });
  }

  isPictureReady(): boolean {
    return (
      this.question()?.picture?.keyName !== undefined ||
      this.question()?.picture?.base64_file !== undefined ||
      this.currentImageUri() !== undefined
    );
  }
}
