import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { CategoryService } from '@services/category.service';
import {
  AdminRoute,
  getCategoryIdRoute,
  getCategorySeriesIdRoute,
  RouteLabel,
} from '@utils/routes';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { TableModule } from 'primeng/table';
import {
  BreadcrumbComponent,
  BreadcrumbItem,
} from '@components/common/breadcrumb/breadcrumb.component';
import { PageRequest, PfTableComponent, TableColumn } from 'pf-ui';
import { Category } from '@models/question/category.model';
import { TooltipModule } from 'primeng/tooltip';
import { DialogService } from 'primeng/dynamicdialog';
import { MessageService } from 'primeng/api';
import { AgentDetail, hasAuthority } from '@models/user/agent-detail.model';
import { AgentService } from '@services/agent.service';
import { AbstractEditableColumn } from '@components/common/abstract/table/editable/abstractEditableColumn';
import { Observable } from 'rxjs';
import { FormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { LIMIT_ROW_TABLE } from '@app/utils/constants';

@Component({
  selector: 'app-settings-business-categories',
  standalone: true,
  imports: [
    ButtonModule,
    CommonModule,
    RouterLink,
    TableModule,
    TranslateModule,
    BreadcrumbComponent,
    PfTableComponent,
    TooltipModule,
    FormsModule,
    InputTextModule,
  ],
  templateUrl: './categories.component.html',
})
export class CategoriesComponent extends AbstractEditableColumn<Category> {
  categories = this.categoryService.signalCategoriesSettingList;
  agent = this.agentService.signalCurrent;

  getCategorySeriesIdRoute = getCategorySeriesIdRoute;
  getCategoryIdRoute = getCategoryIdRoute;

  breadcrumbs: BreadcrumbItem[] = [
    { label: RouteLabel.Home, route: AdminRoute.Home },
    { label: RouteLabel.Business, route: AdminRoute.Business },
    { label: RouteLabel.Categories },
  ];

  editableItems: Category[] | undefined;

  columns: TableColumn[] = [{ name: 'Nom', sortName: 'name' }, { name: '' }];

  currentRequest: PageRequest = {};

  LIMIT_ROW_TABLE = LIMIT_ROW_TABLE;

  constructor(
    public categoryService: CategoryService,
    public override messageService: MessageService,
    public override dialog: DialogService,
    public agentService: AgentService,
  ) {
    super(messageService, dialog);
  }

  onParametersChangedHandler(event: PageRequest): void {
    this.currentRequest = {
      ...this.currentRequest,
      ...event,
    };

    this.categoryService
      .listCategoriesSetting(this.currentRequest)
      .subscribe((val) => {
        this.editableItems = val.result as Category[];
      });
  }

  get currentAgent(): AgentDetail | null {
    return this.agent();
  }

  get canDeleteCategory(): boolean {
    return hasAuthority(this.currentAgent, 'business.manage');
  }

  get canEditCategory(): boolean {
    return hasAuthority(this.currentAgent, 'business.manage');
  }

  get canShowSeries(): boolean {
    return hasAuthority(this.currentAgent, 'business.manage');
  }

  delete(category: Category): Observable<Category> {
    return this.categoryService.delete(category);
  }

  loading: boolean = false;

  override save(category: Category): Observable<Category> {
    return this.categoryService.patch(category);
  }

  reloadItems(): void {
    this.onParametersChangedHandler({});
  }
}
