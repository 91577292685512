<div class="grid grid-nogutter">
  <pf-form-label-wrapper
    [showErrors]="showErrors(form(), controlName())"
    class="col-12"
  >
    <ng-template #errors>
      <pf-form-error
        *ngIf="control()?.errors?.['minlength']"
        label="Un minimum de 2 réponses est obligatoire. Veuillez compléter la liste des réponses."
      ></pf-form-error>
      <pf-form-error
        *ngIf="control()?.errors?.['hasIsCorrect']"
        label="Un minimum de 1 et un maximum de 3 bonnes réponses par question."
      ></pf-form-error>
    </ng-template>
  </pf-form-label-wrapper>
  <div class="col-12 grid grid-nogutter align-items-center">
    <div
      class="flex justify-content-start"
      [class.col-9]="!isDoubleQuestion()"
      [class.col-10]="isDoubleQuestion()"
    >
      <pf-action-button
        styleClass="p-button-tertiary border-none text-sm"
        customIconClass="pi pi-plus-circle"
        type="add"
        label="Ajouter une réponse"
        [disabled]="(control()?.controls?.length || 0) > 25"
        (buttonClicked)="addAnswer(control(), control()?.controls?.length)"
      ></pf-action-button>
    </div>
    <p
      class="text-center m-0 text-xs text-black-alpha-90"
      [class.col-3]="!isDoubleQuestion()"
      [class.col-2]="isDoubleQuestion()"
    >
      Sélectionner les bonnes réponses
    </p>
  </div>
  <div class="col-12">
    <div *ngFor="let answer of control()?.controls; index as i">
      <div [formGroup]="answer" class="grid p-2 align-items-center answer">
        <pf-form-label-wrapper
          [showErrors]="showErrors(answer, 'description')"
          [class.col-11]="!isDoubleQuestion()"
          [class.col-offset-1]="!isDoubleQuestion()"
          [class.col-10]="isDoubleQuestion()"
          [class.col-offset-2]="isDoubleQuestion()"
        >
          <ng-template #errors>
            <pf-form-error
              *ngIf="answer?.controls?.description?.errors?.['required']"
              label="La réponse {{
                numberToString(i)
              }} est un champs obligatoire. Veuillez la renseigner."
            ></pf-form-error>
          </ng-template>
        </pf-form-label-wrapper>
        <div class="col-2 grid grid-nogutter align-items-center">
          <div class="col-6">
            <pf-action-button
              styleClass="border-none p-button-tertiary"
              [showIconOnly]="true"
              customIconClass="pi pi-minus"
              [disabled]="(control()?.controls?.length || 0) <= 1"
              (buttonClicked)="deleteAnswer(control(), i)"
            ></pf-action-button>
          </div>
          <p class="col-6 m-0 text-3xl font-bold text-center">
            {{ numberToString(i) }}
          </p>
        </div>
        <div
          [class.col-7]="!isDoubleQuestion()"
          [class.col-8]="isDoubleQuestion()"
        >
          <input
            class="w-full"
            pInputText
            [id]="controlName() + i.toString()"
            formControlName="description"
            type="text"
            placeholder="Rédiger en langue française"
          />
        </div>
        <p-checkbox
          class="justify-content-center"
          [class.col-3]="!isDoubleQuestion()"
          [class.col-2]="isDoubleQuestion()"
          [binary]="true"
          formControlName="correct"
          [inputId]="'correct' + controlName() + i"
        ></p-checkbox>
      </div>
    </div>
  </div>
</div>
