import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root',
})

export abstract class BaseMessageService extends MessageService {

  protected constructor(protected messageService: MessageService) {
    super()
  }

  public showSuccessToast(message: string): void {
    this.showToast({
      severity: 'success',
      summary: 'Success',
      detail: message,
    })
  }

  public showErrorToast(message: string): void {
    this.showToast({
      severity: 'error',
      summary: 'Erreur',
      detail: message,
    })
  }

  protected showToast(config: {
    severity: 'error' | 'success' | 'info' | 'warn' | 'contrast' | 'secondary',
    summary: 'Erreur' | 'Success' | 'Info' | 'Warn' | 'Error' | 'Secondary',
    detail: string,
  }): void {
    this.messageService.add(config)
  }
}
