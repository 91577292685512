import { CommonModule } from '@angular/common';
import { Component, effect } from '@angular/core';
import { RouterLink } from '@angular/router';
import { AdminRoute, getCategoryIdRoute, RouteLabel } from '@utils/routes';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { TableModule } from 'primeng/table';
import {
  BreadcrumbComponent,
  BreadcrumbItem,
} from '@components/common/breadcrumb/breadcrumb.component';
import {
  PageRequest,
  PfActionButtonComponent,
  PfTableComponent,
  TableColumn,
} from 'pf-ui';
import { MediaTag } from '@models/media/mediaTag.model';
import { MediaTagService } from '@services/mediaTag.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { TagModule } from 'primeng/tag';
import { MessageService } from 'primeng/api';
import { SpinnerComponent } from '@components/common/spinner/spinner.component';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CreateMediaTagsComponent } from '@components/mediaTags/create/create-media-tags.component';
import { Observable } from 'rxjs';
import { TooltipModule } from 'primeng/tooltip';
import { AbstractEditableColumn } from '@components/common/abstract/table/editable/abstractEditableColumn';
import { hasAuthority } from '@models/user/agent-detail.model';
import { AgentService } from '@services/agent.service';
import { LIMIT_ROW_TABLE } from '@app/utils/constants';

@Component({
  selector: 'app-settings-business-tags',
  standalone: true,
  imports: [
    ButtonModule,
    CommonModule,
    RouterLink,
    TableModule,
    TranslateModule,
    BreadcrumbComponent,
    PfTableComponent,
    FormsModule,
    InputTextModule,
    ReactiveFormsModule,
    TagModule,
    PfActionButtonComponent,
    SpinnerComponent,
    TooltipModule,
  ],
  templateUrl: './tags.component.html',
})
export class TagsComponent extends AbstractEditableColumn<MediaTag> {
  protected readonly getCategoryIdRoute = getCategoryIdRoute;
  mediaTags = this.mediaTagsService.signalListPaginated;

  createTagDialogRef: DynamicDialogRef = new DynamicDialogRef();

  loading: boolean = false;
  editableItems: MediaTag[] | undefined;

  override dialogConfig = {
    label: 'Les médias associés à cette étiquette ne le seront plus.',
    labelHtml: '',
    title: "Supprimer l'étiquette ?",
    confirmLabel: 'Annuler',
    cancelLabel: 'Confirmer',
  };

  breadcrumbs: BreadcrumbItem[] = [
    { label: RouteLabel.Home, route: AdminRoute.Home },
    { label: RouteLabel.Business, route: AdminRoute.Business },
    { label: RouteLabel.Tags },
  ];

  currentPageRequest: PageRequest = {
    sort: 'name',
  };

  columns: TableColumn[] = [{ name: 'Nom', sortName: 'name' }, { name: '' }];
  agent = this.agentService.signalCurrent;

  LIMIT_ROW_TABLE = LIMIT_ROW_TABLE;

  constructor(
    public mediaTagsService: MediaTagService,
    public override messageService: MessageService,
    public override dialog: DialogService,
    public agentService: AgentService,
  ) {
    super(messageService, dialog);

    effect(() => {
      this.editableItems = this.mediaTags()?.result as MediaTag[];
    });
  }

  onParametersChangedHandler(event: PageRequest): void {
    this.currentPageRequest = {
      ...this.currentPageRequest,
      ...event,
    };

    this.mediaTagsService.getListPaginated(this.currentPageRequest).subscribe();
  }

  get canCreate(): boolean {
    return hasAuthority(this.agent(), 'mediatag.create');
  }

  get canEdit(): boolean {
    return hasAuthority(this.agent(), 'mediatag.edit');
  }

  get canDelete(): boolean {
    return hasAuthority(this.agent(), 'mediatag.delete');
  }

  openModalCreateMediaTag(): void {
    if (this.loading) return;

    this.createTagDialogRef = this.dialog.open(CreateMediaTagsComponent, {
      styleClass: 'pf-two-column-form-modal',
      showHeader: false,
    });

    this.createTagDialogRef.onClose.subscribe((name?: string) => {
      if (name != null) {
        this.createTags(name);
      }
    });
  }

  createTags(name: string): void {
    this.loading = true;
    this.mediaTagsService.create(name).subscribe({
      error: () => {
        this.loading = false;
      },
      complete: () => {
        this.onParametersChangedHandler(this.currentPageRequest);
        this.loading = false;
        this.showSuccessToast('Création réalisée avec succès');
      },
    });
  }

  save(mediaTag: MediaTag): Observable<MediaTag> {
    return this.mediaTagsService.put(mediaTag);
  }

  delete(mediaTag: MediaTag): Observable<MediaTag> {
    return this.mediaTagsService.delete(mediaTag).pipe();
  }

  reloadItems(): void {
    this.onParametersChangedHandler(this.currentPageRequest);
  }
}
