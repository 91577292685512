import { Component, input } from '@angular/core';
import { Serie } from '@models/serie/serie.model';
import { DatePipe } from '@angular/common';
import { PfFormSummaryEditionWrapperComponent } from 'pf-ui';
import { PluralizePipeModule } from '@app/pipes/pluralize.pipe';
import { Agent, getFullName } from '@models/user/agent.model';

@Component({
  selector: 'app-serie-informations',
  standalone: true,
  templateUrl: './serie-informations.component.html',
  imports: [
    DatePipe,
    PfFormSummaryEditionWrapperComponent,
    PluralizePipeModule,
  ],
})
export class SerieInformationsComponent {
  serie = input<Serie | undefined>(undefined);

  constructor() {}

  get creator(): string {
    const agent: Agent | undefined = this.serie()?.creator;
    return agent !== undefined ? getFullName(agent) : '';
  }
}
