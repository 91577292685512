import { CommonModule } from '@angular/common';
import {
  Component,
  computed,
  ContentChild,
  input,
  output,
  TemplateRef,
} from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { QuestionPreviewComponent } from '@app/components/questions/question-preview/question-preview.component';
import { Difficulty } from '@app/models/question/difficulty.model';
import { QuestionDetail } from '@app/models/question/question-detail.model';
import { QuestionForm } from '@app/models/question/question-form.model';
import { MediaService } from '@app/services/media.service';

import { TranslateModule } from '@ngx-translate/core';
import { QuestionStatusComponent } from '@components/questions/question-status/question-status.component';
import {
  PfActionButtonComponent,
  PfCardComponent,
  PfFormComponent,
  PfInlineLinkComponent,
} from 'pf-ui';
import { CardModule } from 'primeng/card';
import { CheckboxModule } from 'primeng/checkbox';
import { DialogService } from 'primeng/dynamicdialog';
import { SkeletonModule } from 'primeng/skeleton';
import { TooltipModule } from 'primeng/tooltip';

@Component({
  selector: 'app-validate-question',
  standalone: true,
  imports: [
    TranslateModule,
    PfActionButtonComponent,
    PfCardComponent,
    CommonModule,
    ReactiveFormsModule,
    QuestionPreviewComponent,
    CheckboxModule,
    PfFormComponent,
    PfInlineLinkComponent,
    SkeletonModule,
    QuestionStatusComponent,
    CardModule,
    TooltipModule,
  ],
  templateUrl: './validate-question.component.html',
  styleUrl: './validate-question.component.scss',
})
export class ValidateQuestionComponent {
  generateSoundForQuestionEvent = output();

  disabled = input<boolean>(false);
  form = input<FormGroup<QuestionForm> | undefined>();
  loading = input<boolean>(false);

  question = computed(() => this.currentForm?.value as QuestionDetail);

  @ContentChild('customActions', { static: true })
  customActions: TemplateRef<{
    $implicit: FormGroup<QuestionForm>;
  }> | null = null;

  constructor(
    public dialog: DialogService,
    public mediaService: MediaService,
  ) {}

  get currentForm(): FormGroup<QuestionForm> | undefined {
    return this.form();
  }

  hasSound(): boolean {
    return this.question().voiceSound?.id !== undefined;
  }

  handleGenerateSound(): void {
    this.generateSoundForQuestionEvent.emit();
  }

  getScore(score: number | undefined): string {
    if (score === undefined) return '0 point';
    if (score <= 1) return `${score} point`;
    return `${score} points`;
  }

  trueValue(): string {
    return Difficulty.HARD;
  }

  falseValue(): string {
    return Difficulty.MEDIUM;
  }
}
