<div class="grid">
  <app-breadcrumb [items]="breadcrumbs"></app-breadcrumb>
</div>

<h1>{{ title }}</h1>
<h5>Configurer le tirage de série d'éxamen</h5>

<pf-form *ngIf="form" [formGroup]="form">
  <pf-card *ngIf="loading" class="text-center">
    <app-spinner />
  </pf-card>
  <pf-card *ngIf="!loading">
    <div class="grid header">
      <div class="header-input">
        <p class="label">Nombre de questions total de l'examen</p>
        <input
          class="w-full"
          pInputText
          type="text"
          [formControl]="$any(controls.numberOfQuestions)"
          placeholder="20"
        />
      </div>
      <div class="header-input">
        <p class="label">
          Nombre de minimal de questions correctes pour réussir l'examen
        </p>
        <input
          class="w-full"
          pInputText
          type="text"
          [formControl]="$any(controls.requiredCorrectQuestions)"
          placeholder="10"
        />
      </div>
    </div>
    <div *ngFor="let theme of themes?.controls; index as i">
      <div [formGroup]="theme" class="grid mt-2 p-3 align-items-center">
        <p class="col-5 m-0 text-xl font-medium text-right">
          {{ theme.controls.name.value }}
        </p>
        <input
          class="col-3"
          pInputText
          [id]="theme?.controls?.id?.value"
          formControlName="numberPerSeries"
          type="number"
          placeholder="Nombre de questions pour le thème"
        />
        <p *ngIf="theme.value.id" class="col-3 m-0 text-l text-center">
          ({{ getQuestionCount(theme.value.id) }})
        </p>
      </div>
    </div>
    <div class="grid mt-2 p-3 align-items-center">
      <p class="col-5 m-0 label">Nombre de questions par thème :</p>
      <p class="col-3 m-0 label">
        {{ themesForExamSum }} / {{ controls.numberOfQuestions.value }}
      </p>
    </div>
    <div class="flex flex-column align-items-end">
      <pf-action-button
        [disabled]="showError()"
        styleClass="p-button-primary w-auto"
        label="Valider"
        (buttonClicked)="validate()"
      ></pf-action-button>
      <pf-form-label-wrapper
        [showErrors]="showError() || showCorrectAnswersError()"
      >
        <ng-template #errors>
          <div class="flex flex-column gap-1">
            <pf-form-error
              *ngIf="showError()"
              label="Le nombre total de questions doit être égal à la somme du nombre de questions par thème."
            ></pf-form-error>
            <pf-form-error
              *ngIf="showCorrectAnswersError()"
              label="Le nombre de questions correctes pour réussir l'examen doit être inférieur au nombre total de questions."
            ></pf-form-error>
          </div>
        </ng-template>
      </pf-form-label-wrapper>
    </div>
  </pf-card>
</pf-form>
