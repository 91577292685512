import { CommonModule } from '@angular/common';
import { Component, effect, model } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { SpecificClosableDialogComponent } from '@app/components/dialogs/specific-closable-dialog/specific-closable-dialog.component';
import { Media } from '@app/models/media/media.model';
import { MediaTag } from '@app/models/media/mediaTag.model';
import { MediaService } from '@app/services/media.service';
import {
  PfActionButtonComponent,
  PfCardComponent,
  PfFormComponent,
} from 'pf-ui';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ImageModule } from 'primeng/image';
import { SafeUrl } from '@angular/platform-browser';
import { getImageSafeUrl } from '@utils/media';

@Component({
  selector: 'app-select-media',
  standalone: true,
  imports: [
    CommonModule,
    PfFormComponent,
    ReactiveFormsModule,
    PfActionButtonComponent,
    ImageModule,
    PfCardComponent,
    SpecificClosableDialogComponent,
  ],
  templateUrl: './select-media.component.html',
  styleUrl: './select-media.component.scss',
})
export class SelectMediaComponent {
  tagList = model<MediaTag[] | undefined>();
  media = model<Media | undefined>({});
  medias = model<Media[] | undefined>([]);
  mediaUrls = model<Record<string, SafeUrl> | undefined>(undefined);

  form!: FormGroup;
  tagFilter: string[] = [];

  constructor(
    private fb: FormBuilder,
    private config: DynamicDialogConfig<{
      media: Media;
      tagList: MediaTag[];
      medias: Media[];
      mediasUrl: Record<string, SafeUrl> | undefined;
    }>,
    private dialog: DynamicDialogRef,
    public mediaService: MediaService,
  ) {
    this.media.update(() => this.config.data?.media);
    this.medias.update(() => this.config.data?.medias);
    this.mediaUrls.update(() => this.config.data?.mediasUrl);
    this.tagList.update(() => this.config.data?.tagList);

    effect(() => {
      this.form = this.fb.group({
        tags: [this.tagFilter],
        media: [this.media()],
      });
    });
  }

  isPictureReady(): boolean {
    return this.mediaUrls() !== undefined;
  }

  onSelectMedia(media: Media): void {
    this.form.get('media')?.setValue(media);
  }

  onTagClick(id?: string): void {
    const index = this.form.get('tags')?.value?.indexOf(id);
    if (index > -1) {
      this.form.get('tags')?.value?.splice(index, 1);
    } else this.form.get('tags')?.value?.push(id);

    this.tagFilter = this.form.get('tags')?.value;
    if (this.tagFilter.length > 0)
      this.mediaService
        .findByTags({
          global_filter_fields: 'tagIds',
          global_filter: this.tagFilter,
        })
        .subscribe();
    else this.mediaService.list().subscribe();
  }

  mediaStyle(media?: Media): string {
    const isSelected = this.form.get('media')?.value?.id === media?.id;
    return isSelected ? 'media-selected' : '';
  }

  tagStyle(id?: string): string {
    const isSelected = this.form
      .get('tags')
      ?.value?.some((current: string) => current === id);
    return isSelected === true ? 'p-button-primary' : 'p-button-tertiary';
  }

  public close(save?: boolean): void {
    const selectedMedia = this.form.get('media')?.value as Media;
    if (selectedMedia != null && save === true) {
      const safeUrl = getImageSafeUrl(selectedMedia, this.mediaUrls());
      this.dialog.close({
        ...selectedMedia,
        safeUrl,
      } as Media);
    } else {
      this.dialog.close();
    }
  }

  protected readonly getImageSafeUrl = getImageSafeUrl;
}
