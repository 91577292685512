<app-specific-closable-dialog title="Banque de médias" (closeClicked)="close()">
  <pf-form *ngIf="form" [formGroup]="form">
    <div class="grid">
      <p class="col-12 m-0 pt-0 pb-6 text-xl">Sélectionner une photo</p>
      <pf-card class="col-12 pb-6" title="Étiquettes">
        <p class="text-xs my-3">
          Sélectionner une ou plusieurs étiquettes pour filtrer les médias selon
          leurs attributs.
        </p>
        <div class="flex align-items-center gap-3 flex-wrap">
          <div *ngFor="let tag of tagList(); index as i">
            <pf-action-button
              styleClass="media-tags text-xs {{ tagStyle(tag.id) }}"
              [label]="tag.name || ''"
              (click)="onTagClick(tag.id)"
            >
            </pf-action-button>
          </div>
        </div>
      </pf-card>
      <div class="col-12">
        <div class="grid">
          @if (medias()?.length || -1 > 0) {
            <div *ngFor="let media of medias(); index as i" class="col-3">
              <p-image
                *ngIf="isPictureReady()"
                imageClass="media w-full h-9rem {{ mediaStyle(media) }}"
                [src]="getImageSafeUrl(media, mediaUrls())"
                alt="Image"
                (click)="onSelectMedia(media)"
              >
              </p-image>
            </div>
          } @else {
            <p class="col-12 flex justify-content-center">
              Pas de médias trouvés
            </p>
          }
        </div>
      </div>

      <div class="col-12 flex justify-content-end">
        <pf-action-button
          label="Valider"
          (buttonClicked)="close(true)"
        ></pf-action-button>
      </div>
    </div>
  </pf-form>
</app-specific-closable-dialog>
