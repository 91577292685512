import { Component, effect, input } from '@angular/core';
import { PageRequest, PfTableComponent, TableColumn } from 'pf-ui';
import { Category } from '@models/question/category.model';
import { QuestionStats } from '@models/statistics/statistics.model';
import { MAX_QUESTION_DESCRIPTION_LENGTH } from '@pages/questions/questions.component';
import { NgForOf, PercentPipe } from '@angular/common';
import { TruncatePipeModule } from '@app/pipes/truncate.pipe';
import { ButtonModule } from 'primeng/button';
import { StatisticsService } from '@services/statistics.service';
import { BaseMessageService } from '@services/baseMessage.service';
import { QuestionService } from '@services/question.service';
import { LIMIT_ROW_TABLE } from '@app/utils/constants';

@Component({
  selector: 'app-statistics-questions-table',
  standalone: true,
  imports: [
    PfTableComponent,
    NgForOf,
    TruncatePipeModule,
    PercentPipe,
    ButtonModule,
  ],
  templateUrl: './questions-stats.component.html',
})
export class QuestionsStatsComponent {
  questionsStats = this.statisticsService.signalQuestionsStats;

  protected readonly MAX_QUESTION_DESCRIPTION_LENGTH =
    MAX_QUESTION_DESCRIPTION_LENGTH;
  MAX_ROW = input<number>(1);
  ONLY_FLAGGED = input<boolean>(false);
  title = input<string | null>(null);
  category = input<Category | null>();

  columns: TableColumn[] = [
    { name: '' },
    { name: 'Questions' },
    { name: 'Thèmes' },
    { name: 'Utilisation' },
    { name: 'Réussite' },
  ];

  rowsLength: number = this.questionsStats()?.page_size ?? 10;

  currentPageRequest: PageRequest = {
    page_size: this.MAX_ROW(),
    global_filter_fields: 'categoryIds',
    global_filter: [],
  };

  LIMIT_ROW_TABLE = LIMIT_ROW_TABLE;

  constructor(
    public statisticsService: StatisticsService,
    public messageService: BaseMessageService,
    public questionService: QuestionService,
  ) {
    effect(() => {
      this.onParametersChangedHandler(this.currentPageRequest);
    });
  }

  onParametersChangedHandler(event: PageRequest): void {
    this.currentPageRequest = {
      ...this.currentPageRequest,
      ...event,
      global_filter: [this.category()?.id as string],
    };
    this.statisticsService
      .getQuestionsStats(this.currentPageRequest)
      .subscribe();
  }

  get tableTitle(): string {
    return this.title() ?? this.category()?.name ?? 'Toutes les catégories';
  }

  setFlag(question: QuestionStats): void {
    this.questionService.setFlagged({ id: question.questionId }).subscribe({
      error: (err: string) => {
        this.messageService.showErrorToast(err);
      },
      complete: () => {
        this.onParametersChangedHandler(this.currentPageRequest);
        this.statisticsService.getFlaggedQuestionsStats({}).subscribe();
        this.messageService.showSuccessToast('Question flaggué avec succès');
      },
    });
  }

  setUnFlagged(question: QuestionStats): void {
    this.questionService.setUnFlagged({ id: question.questionId }).subscribe({
      error: (err: string) => {
        this.messageService.showErrorToast(err);
      },
      complete: () => {
        this.onParametersChangedHandler(this.currentPageRequest);
        this.statisticsService.getFlaggedQuestionsStats({}).subscribe();
        this.messageService.showSuccessToast('Flag retiré avec succès');
      },
    });
  }

  getThemesName(question: QuestionStats): string {
    return question.childrenThemes?.map((t) => `- ${t}`).join('<br/>') ?? '';
  }

  toggleFlag(question: QuestionStats): void {
    question.flagged ? this.setUnFlagged(question) : this.setFlag(question);
  }
}
