import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import {
  BreadcrumbComponent,
  BreadcrumbItem,
} from '@components/common/breadcrumb/breadcrumb.component';
import { getAnalyzedQuestionIdRoute, getQuestionIdRoute, RouteLabel } from '@utils/routes';
import {
  PageRequest,
  PageResponse,
  PfAlertComponent,
  TableColumn,
} from 'pf-ui';
import { DatePipe, NgIf } from '@angular/common';
import { AgentService } from '@services/agent.service';
import { TabViewModule } from 'primeng/tabview';
import { PluralizePipe } from '@app/pipes/pluralize.pipe';
import { DashboardQuestionsListComponent } from '@pages/home/dashboard-questions-list/dashboard-questions-list.component';
import { QuestionService } from '@services/question.service';
import { QuestionStatus } from '@models/question/questionStatus.model';
import { Question } from '@models/question/question.model';
import { ButtonModule } from 'primeng/button';
import { TooltipModule } from 'primeng/tooltip';
import { hasAuthority } from '@models/user/agent-detail.model';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    TranslateModule,
    RouterLink,
    BreadcrumbComponent,
    PfAlertComponent,
    NgIf,
    TabViewModule,
    DashboardQuestionsListComponent,
    DatePipe,
    ButtonModule,
    TooltipModule,
  ],
  templateUrl: './home.component.html',
})
export class HomeComponent {
  pipe: PluralizePipe = new PluralizePipe();
  breadcrumbs: BreadcrumbItem[] = [{ label: RouteLabel.Home }];
  waitingValidationQuestions?: PageResponse<Question>;
  draftQuestions?: PageResponse<Question>;
  rejectedQuestions?: PageResponse<Question>;

  agent = this.agentService.signalCurrent;

  waitingValidationQuestionsColumns: TableColumn[] = [
    { name: 'Description' },
    { name: 'Média' },
    { name: 'Thème(s)' },
    { name: 'Catégorie(s)' },
    { name: 'Etat' },
    { name: 'Date de mise en attente de validation' },
    { name: '' },
  ];

  draftQuestionsColumns: TableColumn[] = [
    { name: 'Description' },
    { name: 'Média' },
    { name: 'Thème(s)' },
    { name: 'Catégorie(s)' },
    { name: 'Etat' },
    { name: 'Date de dernière modification' },
    { name: '' },
  ];

  rejectedQuestionsColumns: TableColumn[] = [
    { name: 'Description' },
    { name: 'Média' },
    { name: 'Thème(s)' },
    { name: 'Catégorie(s)' },
    { name: 'Etat' },
    { name: 'Date de rejet' },
    { name: '' },
  ];

  constructor(
    public agentService: AgentService,
    public questionService: QuestionService,
  ) {}

  get hasPermissionToQuestionActions(): boolean {
    return hasAuthority(this.agent(), 'question.view')
  }

  waitingValidationQuestionParameterChangedHandler(event: PageRequest): void {
    this.questionService
      .listPaginated({
        ...event,
        global_filter_fields: 'questionStatus',
        global_filter: [
          QuestionStatus.PENDING_VALIDATION_0_2,
          QuestionStatus.PENDING_VALIDATION_1_2,
        ],
      })
      .subscribe(
        (waitingValidationQuestions) =>
          (this.waitingValidationQuestions = waitingValidationQuestions),
      );
  }

  draftQuestionParameterChangedHandler(event: PageRequest): void {
    this.questionService
      .listPaginated({
        ...event,
        global_filter_fields: 'questionStatus',
        global_filter: [QuestionStatus.DRAFT],
        filters: `lastModifiedBy=${this.agent()?.sub}`,
      })
      .subscribe((draftQuestions) => (this.draftQuestions = draftQuestions));
  }

  rejectedQuestionParameterChangedHandler(event: PageRequest): void {
    this.questionService
      .listPaginated({
        ...event,
        global_filter_fields: 'questionStatus',
        global_filter: [QuestionStatus.REJECTED],
      })
      .subscribe(
        (rejectedQuestions) => (this.rejectedQuestions = rejectedQuestions),
      );
  }

  get hasRoles(): boolean {
    const roles = this.agent()?.agentRoles ?? [];
    return roles.length > 0;
  }

  get waitingValidationQuestionsTitle(): string {
    return this.pipe.transform(
      this.waitingValidationQuestions?.total_count ?? 0,
      'question en attente de validation',
      'questions en attente de validation',
    );
  }

  get draftQuestionsTitle(): string {
    return this.pipe.transform(
      this.draftQuestions?.total_count ?? 0,
      'question en brouillon',
      'questions en brouillon',
    );
  }

  get rejectedQuestionsTitle(): string {
    return this.pipe.transform(
      this.rejectedQuestions?.total_count ?? 0,
      'question rejetée',
      'questions rejetées',
    );
  }

  protected readonly RouteLabel = RouteLabel;
  protected readonly getAnalyzedQuestionIdRoute = getAnalyzedQuestionIdRoute;
  protected readonly getQuestionIdRoute = getQuestionIdRoute;
}
