@if (displayDetails() && questionTotal() !== undefined) {
  <pf-licence-bar
    [current]="currentQuestionIndex()"
    [total]="questionsTotal"
    [candidateName]="providerId ?? '-'"
    [countdownDuration]="
      getQuestionDurationInMs(
        question()?.duration,
        displayCorrection(),
        userExamSession()?.additionalTime
      )
    "
    [countdownColors]="['#073ca5', '#5986e0', '#f78c01', '#cc0000']"
    [countdownColorTime]="countdownColorTime()"
    [start]="startTimer && question() !== null"
    (onCountDownCompleted)="onTimeOver()"
    [displaySound]="false"
    class="col-6"
  >
    @if (startTimer && !this.isSoundReplayed() && !this.isSoundMissing()) {
      <ng-template #additionalContent>
        <i
          role="button"
          tabindex="0"
          class="pi pi-replay sound-replay-icon"
          (keyup)="replaySound()"
          (click)="replaySound()"
        ></i>
      </ng-template>
    } @else {
      <ng-template #additionalContent>
        <i class="pi pi-replay sound-replay-icon-disabled"></i>
      </ng-template>
    }
  </pf-licence-bar>
}

@if (!displayDetails() && isSoundMissing()) {
  <pf-alert
    label="Attention, la question n'a pas de son. Veuillez générer un son avant de valider la question."
    severity="warn"
  ></pf-alert>
}
<app-question-preview-image
  [displayCorrection]="displayCorrection()"
  [imageContainerHeight]="imageContainerHeight()"
  [question]="question()"
/>

<div class="question-container text-xl">
  <p class="description">
    {{ question()?.description }}
  </p>
  <app-question-preview-answers
    *ngIf="!displayCorrection()"
    [answerSelection]="answersSelected()"
    [questionAnswers]="question()?.answerChoices"
    [displayDetails]="displayDetails()"
    [toggleAnswerSelection]="toggleAnswerSelection"
    [trainingQuestions]="trainingQuestions()"
  >
  </app-question-preview-answers>
  <app-question-correction-answers
    *ngIf="displayCorrection() && question() !== null"
    [questionAnswers]="question()?.answerChoices"
  >
  </app-question-correction-answers>

  <p *ngIf="question()?.secondDescription" class="description">
    {{ question()?.secondDescription }}
  </p>

  <app-question-preview-answers
    *ngIf="isSecondQuestionAnswers() && !displayCorrection()"
    [answerSelection]="secondAnswersSelected()"
    [questionAnswers]="question()?.secondAnswerChoices"
    [displayDetails]="displayDetails()"
    [toggleAnswerSelection]="toggleAnswerSelection"
    [trainingQuestions]="trainingQuestions()"
  >
  </app-question-preview-answers>
  <app-question-correction-answers
    *ngIf="
      isSecondQuestionAnswers() && displayCorrection() && question() !== null
    "
    [questionAnswers]="question()?.secondAnswerChoices"
  >
  </app-question-correction-answers>
  <pf-alert
    *ngIf="displayCorrection() && question() !== null"
    [label]="question()?.correction ?? ''"
    [severity]="severity"
    [title]="correctionTitle"
  ></pf-alert>
</div>
