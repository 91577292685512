<div class="grid">
  <app-breadcrumb [items]="breadcrumbs"></app-breadcrumb>
</div>

<h1>Question d'examen</h1>

<div class="grid justify-content-end py-4">
  <div *ngIf="form" [formGroup]="form">
    <p-dropdown
      formControlName="selectedCategory"
      [options]="categoriesList"
      optionLabel="name"
      (onChange)="handleChangeCategory()"
      [showClear]="true"
      placeholder="Choisir une catégorie"
    >
    </p-dropdown>
  </div>
</div>

<app-statistics-questions-table [category]="selectedCategory">
</app-statistics-questions-table>
