<app-specific-closable-dialog
  title="Choisir les question d'entraînement"
  (closeClicked)="close()"
  [showCloseButton]="true"
>

  <pf-alert label="Si une question est sélectionnée, alors elle ne peut pas être utilisée dans les examens " severity="warn"></pf-alert>

  <div *ngIf="formGroup" [formGroup]="formGroup">
    <app-question-list>
      <ng-template #templateActions let-question>
        <div class="flex">
          <p-button
            icon="pi pi-eye"
            [rounded]="true"
            size="large"
            ariaLabel="Voir la question"
            pTooltip="Voir la question"
            tooltipPosition="top"
            (click)="openViewQuestionModal(question)"
          ></p-button>
          <p-checkbox
            labelStyleClass="white-space-nowrap text-overflow-ellipsis max-w-20rem overflow-hidden ml-3"
            [formControl]="$any(formGroup).controls['selectedQuestions']"
            [pTooltip]="question.description"
            tooltipPosition="left"
            [inputId]="question.id"
            [value]="question.id"
          ></p-checkbox>
        </div>
      </ng-template>
    </app-question-list>
  </div>

  <div class="flex align-items-stretch flex-column">
    <pf-form-action-bar class="mt-5" styleClass="gap-5 justify-content-center">
      <pf-action-button
        (buttonClicked)="close()"
        [disabled]="isConfirmButtonDisabled"
        styleClass="text-xl"
        [pTooltip]="isConfirmButtonDisabled ? 'Limiter à 3 questions' : undefined"
        label="Valider"
        type="custom"
      >
      </pf-action-button>
      <pf-action-button
        (buttonClicked)="cancel()"
        styleClass="text-xl p-button-tertiary"
        [label]="'PF.GLOBAL.CANCEL' | translate"
      >
      </pf-action-button>
    </pf-form-action-bar>
  </div>
</app-specific-closable-dialog>
