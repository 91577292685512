<pf-form *ngIf="currentForm" [formGroup]="currentForm">
  <div class="grid">
    <pf-card class="col-7">
      <app-question-preview
        [isSoundDisabled]="true"
        imageContainerHeight="30vh"
        [question]="question()"
      >
      </app-question-preview>
    </pf-card>
    <div class="col-5 flex flex-grow-1 flex-column gap-2 right-panel">
      <p-card header="Caractéristiques" styleClass="flex-grow-1 w-full">
        <app-question-status
          [status]="question().questionStatus"
        ></app-question-status>
        <div class="pt-2">
          <h6>Thèmes :</h6>
          <p *ngFor="let theme of question().themes">{{ theme.name }}</p>
          <h6>Catégorie :</h6>
          <p *ngFor="let category of question().categories">
            {{ category.name }}
          </p>
          <h6>Durée :</h6>
          <p>{{ question().duration }} secondes</p>
          <h6>Notation :</h6>
          <p>{{ getScore(question().score) }}</p>
        </div>
        <div class="pt-4 flex">
          <pf-action-button
            type="previous"
            [customIconClass]="loading() ? 'pi pi-spinner' : 'pi pi-refresh'"
            styleClass="p-button-tertiary border-none text-sm justify-content-start"
            [label]="hasSound() ? 'Regénérer le son' : 'Générer le son'"
            (buttonClicked)="handleGenerateSound()"
            [disabled]="loading() || disabled()"
          ></pf-action-button>
        </div>
      </p-card>
      <pf-card class="flex">
        @if (loading()) {
          <div class="flex">
            <p-skeleton size="2rem" styleClass="mr-2" />
            <p-skeleton height="2rem" class="mb-2 flex-grow-1 w-full" />
          </div>
        } @else {
          <p-checkbox
            labelStyleClass="white-space-nowrap text-overflow-ellipsis max-w-20rem overflow-hidden"
            pTooltip="Question difficile"
            label="Question difficile"
            inputId="difficulty"
            formControlName="difficulty"
            [trueValue]="trueValue()"
            [falseValue]="falseValue()"
            [binary]="true"
          ></p-checkbox>
        }
      </pf-card>
    </div>

    <ng-container
      *ngTemplateOutlet="customActions; context: { $implicit: form() }"
    >
    </ng-container>
  </div>
</pf-form>
