<div class="grid">
  <app-breadcrumb [items]="breadcrumbs"></app-breadcrumb>
</div>

<h1>Gestion des étiquettes</h1>
<h5>
  Lorsque vous modifiez une étiquette, les médias associés seront
  automatiquement mis à jour
</h5>

<div class="flex mb-4 justify-content-end">
  <pf-action-button
    *ngIf="canCreate"
    label="Créer une nouvevelle étiquette"
    [disabled]="loading"
    [customIconClass]="loading ? 'pi pi-spinner spin-animation' : 'pi pi-plus'"
    (click)="openModalCreateMediaTag()"
  ></pf-action-button>
</div>

<pf-table
  [response]="mediaTags()"
  [isLazyMode]="true"
  noResultLabel="Pas d'étiquette"
  (onParametersChanged)="onParametersChangedHandler($event)"
  [rows]="LIMIT_ROW_TABLE"
  [columns]="columns"
  [displaySkeleton]="true"
  [showCaption]="true"
  [showSearch]="false"
  title="Etiquette"
  dataKey="id"
>
  <ng-template #content let-tag let-index>
    <tr>
      <td>
        @if (isEditing(tag)) {
          <input class="w-full" pInputText type="text" [(ngModel)]="tag.name" />
        } @else {
          <p-tag severity="info" [value]="tag.name" [rounded]="true"> </p-tag>
        }
      </td>
      <td class="icon-button">
        <div class="flex align-items-center justify-content-center gap-2">
          <p-button
            *ngIf="!isEditing(tag)"
            [disabled]="!canEdit || loading"
            icon="pi pi-pencil"
            [rounded]="true"
            size="small"
            tooltipPosition="top"
            pTooltip="Modifier l'étiquette"
            ariaLabel="Modifier l'étiquette"
            (click)="onRowEditInit(tag)"
          ></p-button>
          <p-button
            *ngIf="!isEditing(tag)"
            [disabled]="!canDelete || loading"
            icon="pi pi-trash"
            [rounded]="true"
            size="small"
            tooltipPosition="top"
            pTooltip="Supprimer l'étiquette"
            ariaLabel="Supprimer l'étiquette"
            (click)="openModalConfirmDelete(tag)"
          ></p-button>

          <p-button
            *ngIf="isEditing(tag)"
            [disabled]="loading"
            [rounded]="true"
            pTooltip="Annuler la modification"
            tooltipPosition="top"
            ariaLabel="Annuler la modification"
            icon="pi pi-times"
            (click)="onRowEditCancel(tag)"
            class="p-button-rounded p-button-text p-button-danger"
          >
          </p-button>
          <p-button
            *ngIf="isEditing(tag)"
            [disabled]="loading"
            [rounded]="true"
            pTooltip="Enregistrer l'étiquette"
            tooltipPosition="top"
            ariaLabel="Enregistrer l'étiquette"
            icon="pi pi-check"
            (click)="onRowEditSave(tag)"
            class="p-button-rounded p-button-text p-button-success mr-2"
          >
          </p-button>
        </div>
      </td>
    </tr>
  </ng-template>
</pf-table>
