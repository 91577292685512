import { Component, output } from '@angular/core';
import {
  PfActionButtonComponent,
  PfFormActionBarComponent,
  PfFormComponent,
  PfFormErrorComponent,
  PfFormLabelWrapperComponent
} from 'pf-ui';
import { DynamicDialogRef } from 'primeng/dynamicdialog';

import { CommonModule } from '@angular/common';
import { QuestionPreviewComponent } from '@components/questions/question-preview/question-preview.component';
import {
  SpecificClosableDialogComponent
} from '@components/dialogs/specific-closable-dialog/specific-closable-dialog.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { getValidatorErrorMessage } from '@utils/validations';
import { AbstractFormComponent } from '@components/common/abstract/form/abstract-form.component';

@Component({
  selector: 'app-settings-business-themes-create',
  standalone: true,
  imports: [
    CommonModule,
    PfActionButtonComponent,
    QuestionPreviewComponent,
    SpecificClosableDialogComponent,
    PfFormActionBarComponent,
    TranslateModule,
    FormsModule,
    InputTextModule,
    PfFormErrorComponent,
    PfFormLabelWrapperComponent,
    ReactiveFormsModule,
    PfFormComponent
  ],
  templateUrl: './create-theme.component.html',
})
export class CreateThemeComponent extends AbstractFormComponent {
  handleSaveClick = output<string>();
  form: FormGroup;

  constructor(
    private dialog: DynamicDialogRef,
    private fb: FormBuilder,
  ) {
    super();
    this.form = this.fb.group({
      name: ['', Validators.required],
    });
  }

  save(): void {
    this.form.markAllAsTouched();
    if(this.form.valid) this.dialog.close(this.form.controls['name'].value);
  }

  public close(): void {
    this.dialog.close();
  }

  protected readonly getValidatorErrorMessage = getValidatorErrorMessage;
}
