<div class="grid">
  <app-breadcrumb [disabled]="loading" [items]="breadcrumbs"></app-breadcrumb>
</div>
<div class="grid">
  <pf-action-button
    type="previous"
    styleClass="col-12 mb-4 p-button-tertiary border-none justify-content-start text-sm"
    [label]="formManager.currentStep === 0 ? 'Retour' : 'Étape précédente'"
    (buttonClicked)="goBack()"
    [disabled]="loading"
  ></pf-action-button>
  <pf-form-wrapper [classList]="'col-12'">
    <ng-template #steps>
      <pf-steps
        [currentStep]="formManager.currentStep + 1"
        [steps]="stepperSteps"
      ></pf-steps>
    </ng-template>
    <ng-container>
      <div class="grid">
        <div class="col-12 grid gap-3 justify-content-end">
          <pf-action-button
            styleClass="p-button-tertiary"
            [disabled]="loading || !form?.controls?.description?.valid || false"
            (buttonClicked)="onDraftQuestion()"
            label="Enregistrer comme brouillon"
          ></pf-action-button>
          <pf-action-button
            styleClass="p-button-tertiary"
            label="Abandonner"
            [disabled]="loading"
            (buttonClicked)="onCancel()"
          ></pf-action-button>
        </div>

        <!-- CREATE QUESTION -->
        <app-create-question
          class="col-12"
          *ngIf="formManager.isStepSelected(QuestionStep.CREATE_QUESTION)"
          [form]="form"
          [mediaTags]="mediaTags()"
          (validateClicked)="onValidateFirstStepClicked($event)"
        >
        </app-create-question>

        <!-- CHARACTERIZE QUESTION -->
        <app-characterize-question
          class="col-12"
          *ngIf="formManager.isStepSelected(QuestionStep.CHARACTERIZE_QUESTION)"
          [form]="form"
          (validateClicked)="onValidateSecondStepClicked()"
        >
        </app-characterize-question>

        <!-- VALIDATE QUESTION -->
        <app-validate-question
          class="col-12"
          *ngIf="formManager.isStepSelected(QuestionStep.SUMMARY_QUESTION)"
          [form]="form"
          (generateSoundForQuestionEvent)="onGenerateSoundForQuestionEvent()"
          [loading]="loading"
        >
          <ng-template #customActions let-form>
            <div class="col-12 flex justify-content-end gap-3">
              <pf-action-button
                label="Tester"
                [disabled]="loading"
                (buttonClicked)="onTestQuestionModal()"
              ></pf-action-button>
              <pf-action-button
                [disabled]="!isQuestionTested"
                styleClass="p-button-tertiary"
                label="Valider"
                (buttonClicked)="onValidateLastStep()"
              ></pf-action-button>
            </div>
          </ng-template>
        </app-validate-question>
      </div>
    </ng-container>
  </pf-form-wrapper>
</div>
