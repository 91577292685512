<div
  [fitContent]="true"
  tooltipStyleClass="bg-white max-w-21rem custom-session-tooltip p-0"
  [tooltipPosition]=tooltipPosition()
  [pTooltip]="tooltipContent"
  class="custom-session-tooltip-helper absolute top-0 right-0 flex flex-column gap-1 align-items-center justify-content-end p-2">
  <ng-template #tooltipContent>
    <div class="flex align-items-left flex-column gap-2 w-auto bg-white text-color p-3 border-round-sm">
      <span class="text-xs"><b>{{ tooltipHeaderLabel() }}</b></span>
      <span class="text-xs">{{ tooltipContentLabel() }}</span>
    </div>
  </ng-template>
  <i id="sessionHelper" [class]=buildHelperIcon()></i>
  <label class="text-xs custom-session-helper" for="sessionHelper">{{ buttonLabel() }}</label>
</div>
