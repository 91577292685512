import { Component, signal } from '@angular/core';
import {
  BreadcrumbComponent,
  BreadcrumbItem,
} from '@components/common/breadcrumb/breadcrumb.component';
import { AdminRoute, RouteLabel } from '@utils/routes';
import { DatePipe, NgIf } from '@angular/common';
import { PageRequest, PfTableComponent, TableColumn } from 'pf-ui';
import { ThumbnailComponent } from '@components/medias/thumbnail/thumbnail.component';
import { ButtonModule } from 'primeng/button';
import { TooltipModule } from 'primeng/tooltip';
import { RouterLink } from '@angular/router';
import { getFullName } from '@models/user/agent.model';
import { AuditService } from '@services/audit.service';
import {
  Audit,
  AuditableType,
  AuditableTypeInfo,
  AuditableTypeInfoAction,
  AuditableTypeInfos,
  AuditAction,
} from '@models/user/audit.model';
import { ActionBadgeComponent } from '@pages/series/archives/logs/action-badge/action-badge.component';
import { LIMIT_ROW_TABLE } from '@app/utils/constants';

@Component({
  selector: 'app-archives-logs-users',
  standalone: true,
  imports: [
    BreadcrumbComponent,
    NgIf,
    PfTableComponent,
    ThumbnailComponent,
    DatePipe,
    ButtonModule,
    TooltipModule,
    RouterLink,
    ActionBadgeComponent,
  ],
  templateUrl: './logs-users.component.html',
})
export class LogsUsersComponent {
  audits = this.auditService.signalListPaginated;
  loading = signal<boolean>(false);

  breadcrumbs: BreadcrumbItem[] = [
    { label: RouteLabel.Home, route: AdminRoute.Home },
    { label: RouteLabel.SeriesArchives, route: AdminRoute.SeriesArchives },
    { label: RouteLabel.SeriesArchivesLogsUsers },
  ];

  columns: TableColumn[] = [
    { name: 'Agent', sortName: 'agent.firstName' },
    { name: "Date de l'action", sortName: 'createdDate' },
    { name: "Type de l'action" },
    { name: 'Module', sortName: 'auditableType' },
    { name: '' },
  ];

  currentPageRequest: PageRequest = {
    sort: 'createdDate',
    order: 'DESC',
  };

  LIMIT_ROW_TABLE = LIMIT_ROW_TABLE;

  constructor(private auditService: AuditService) {}

  onParametersChangedHandler(event: PageRequest): void {
    this.currentPageRequest = {
      ...this.currentPageRequest,
      ...event,
    };
    this.auditService.listPaginated(this.currentPageRequest).subscribe();
  }

  agentName(audit: Audit): string {
    return audit.agent ? getFullName(audit.agent) : '';
  }

  displayAuditableType(type: keyof typeof AuditableType): string {
    return this.getAuditableTypeInfo(type)?.label ?? '';
  }

  displayAuditableAction(
    type: keyof typeof AuditableType,
    action: keyof typeof AuditAction,
  ): AuditableTypeInfoAction | undefined {
    return this.getAuditableTypeInfo(type)?.actions.find(
      (auditLogTypeAction: AuditableTypeInfoAction) =>
        auditLogTypeAction.type === AuditAction[action],
    );
  }

  getAuditableTypeInfo(
    type: keyof typeof AuditableType,
  ): AuditableTypeInfo | undefined {
    return AuditableTypeInfos.find(
      (auditLogTypeAction: AuditableTypeInfo) =>
        auditLogTypeAction.type === AuditableType[type],
    );
  }

  protected readonly AdminRoute = AdminRoute;
}
