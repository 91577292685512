<app-question-preview
  class="questions"
  imageContainerHeight="54vh"
  [displayDetails]="true"
  [question]="currentQuestion()"
  [currentQuestionIndex]="currentQuestionIndex + 1"
  [questionTotal]="questionList?.length"
  [trainingQuestions]="trainingQuestions()"
  (goToNextQuestion)="setNextQuestion()"
>
</app-question-preview>
