import { CommonModule } from '@angular/common';
import { Component, ContentChild, TemplateRef } from '@angular/core';
import { RouterLink } from '@angular/router';

import { SpinnerComponent } from '@app/components/common/spinner/spinner.component';
import { Category } from '@app/models/question/category.model';
import { Theme } from '@app/models/question/theme.model';
import { TruncatePipeModule } from '@app/pipes/truncate.pipe';
import { AdminRoute, RouteLabel } from '@app/utils/routes';
import { TranslateModule } from '@ngx-translate/core';
import {
  PageRequest,
  PfActionButtonComponent,
  PfTableComponent,
  TableColumn,
} from 'pf-ui';
import { ButtonModule } from 'primeng/button';
import { TableModule } from 'primeng/table';
import {
  BreadcrumbComponent,
  BreadcrumbItem,
} from '@components/common/breadcrumb/breadcrumb.component';
import { TooltipModule } from 'primeng/tooltip';
import { Question } from '@models/question/question.model';
import { MAX_QUESTION_DESCRIPTION_LENGTH } from '@pages/questions/questions.component';
import { ThumbnailComponent } from '@components/medias/thumbnail/thumbnail.component';
import { QuestionStatusComponent } from '@components/questions/question-status/question-status.component';
import { MultiSelectModule } from 'primeng/multiselect';
import { TagModule } from 'primeng/tag';
import { FormsModule } from '@angular/forms';
import {
  QuestionStatus,
  QuestionStatusInfos,
} from '@models/question/questionStatus.model';
import { QuestionService } from '@services/question.service';
import { PluralizePipeModule } from '@app/pipes/pluralize.pipe';
import { joinArray } from '@utils/stringHelper';
import { CategoryService } from '@services/category.service';
import { ThemeService } from '@services/theme.service';
import { forkJoin, of } from 'rxjs';
import { LIMIT_ROW_TABLE } from '@app/utils/constants';

@Component({
  selector: 'app-question-list',
  standalone: true,
  templateUrl: './question-list.component.html',
  imports: [
    ButtonModule,
    CommonModule,
    PfActionButtonComponent,
    RouterLink,
    SpinnerComponent,
    TableModule,
    TranslateModule,
    TruncatePipeModule,
    BreadcrumbComponent,
    TooltipModule,
    ThumbnailComponent,
    PfTableComponent,
    QuestionStatusComponent,
    MultiSelectModule,
    TagModule,
    FormsModule,
    PluralizePipeModule,
  ],
})
export class QuestionListComponent {
  questions = this.questionService.signalListPaginated;
  choices: { name: string; id: string }[] = [];
  LIMIT_ROW_TABLE = LIMIT_ROW_TABLE;
  globalFilterFields = ['description'];

  // initialisation du tableau de colonnes
  columns: TableColumn[] = [
    { name: 'Enoncé' },
    {
      name: 'Média',
    },
    {
      name: 'Thème(s)',
    },
    {
      name: 'Catégorie(s)',
    },
    {
      name: 'Statut(s)',
    },
    { name: '' },
  ];
  breadcrumbs: BreadcrumbItem[] = [
    { label: RouteLabel.Home, route: AdminRoute.Home },
    { label: RouteLabel.Questions },
  ];

  @ContentChild('templateActions', { static: true })
  templateActions: TemplateRef<{
    $implicit: Question;
  }> | null = null;

  constructor(
    private categoryService: CategoryService,
    private themeService: ThemeService,
    public questionService: QuestionService,
  ) {
    const categories$ = this.categoryService.listAllCategories();
    const themes$ = this.themeService.listAndReturnObservable();
    const choices$ = of(
      Object.entries(QuestionStatusInfos).map(([id, value]) => {
        return { name: value.label, id };
      }),
    );
    forkJoin([categories$, themes$, choices$]).subscribe(
      ([categories, themes, choices]) => {
        const categoryFilters = categories.map((cat: Category) => ({
          name: cat.name,
          id: cat.id,
        }));

        const themesFilters = themes.theme?.map((th: Theme) => ({
          name: th.name,
          id: th.id,
        }));

        if (themesFilters != null && categoryFilters != null) {
          this.columns = [
            { name: 'Enoncé' },
            {
              name: 'Média',
            },
            {
              name: 'Thème(s)',
              filter: {
                field: 'themes',
                mode: 'equals',
                type: 'text',
                placeholder: 'Choisir le(les) thèmes',
                data: themesFilters,
              },
            },
            {
              name: 'Catégorie(s)',
              filter: {
                field: 'categories',
                mode: 'equals',
                type: 'text',
                placeholder: 'Choisir la(les) catégories',
                data: categoryFilters,
              },
            },
            {
              name: 'Statut(s)',
              filter: {
                field: 'questionStatus',
                mode: 'equals',
                type: 'text',
                placeholder: 'Choisir le(s) statut(s)',
                data: choices,
              },
            },
            { name: '' },
          ];
        }
      },
    );
  }

  onParametersChangedHandler(event: PageRequest): void {
    // adding skeleton loading
    // this.questions.set(undefined);
    this.questionService.listPaginated({ ...event }).subscribe();
  }

  renderList(values: Category[] | Theme[]): string {
    return values.map((x) => x.name).join(', ');
  }

  getTooltipDescription(question: Question): string {
    return joinArray(
      [question.description, question.secondDescription],
      '\r\n',
    );
  }

  protected readonly MAX_QUESTION_DESCRIPTION_LENGTH =
    MAX_QUESTION_DESCRIPTION_LENGTH;
  protected readonly QuestionStatus = QuestionStatus;
}
