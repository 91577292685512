import { HttpClient } from '@angular/common/http';
import { Injectable, signal } from '@angular/core';

import { environement } from '@environments/environment';
import { PageRequest, PageResponse } from 'pf-ui';
import { MessageService } from 'primeng/api';
import { Observable, tap } from 'rxjs';
import { BaseService } from './base.service';
import { Audit } from '@models/user/audit.model';
import { PageableResponse } from '@models/common/pageable-response.model';

@Injectable({
  providedIn: 'root',
})
export class AuditService extends BaseService {
  signalListPaginated = signal<PageResponse<Audit> | undefined>(undefined);

  constructor(
    protected override http: HttpClient,
    protected override messageService: MessageService,
  ) {
    super(http, messageService);
  }

  listPaginated(event?: PageRequest): Observable<PageResponse<Audit>> {
    return this.executeRequest(
      this.http.get<PageableResponse<Audit>>(
        `${environement.BACKEND_URL}/audits`,
        {
          params: this.getHttpParams({ ...event }),
        },
      ),
    ).pipe(
      this.mapPageableResponseToPageResponse<Audit>(event),
      tap((pageResponse) => {
        this.signalListPaginated.set(pageResponse);
      }),
    );
  }
}
