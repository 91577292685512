<div class="grid">
  <app-breadcrumb [items]="breadcrumbs"></app-breadcrumb>
</div>

<h1>Banque de médias</h1>
<div
  class="flex mb-4"
  [ngClass]="loading() ? 'justify-content-between' : 'justify-content-end'"
>
  <div *ngIf="loading()" class="text-center align-items-center">
    <app-spinner />
  </div>
  <pf-action-button
    *ngIf="canCreateMedia"
    customIconClass="pi pi-plus"
    label="Ajouter un nouveau média"
    (click)="openAddNewMediaModal()"
  ></pf-action-button>
</div>

<pf-table
  [response]="medias()"
  [isLazyMode]="true"
  noResultLabel="Pas de média"
  (onParametersChanged)="onParametersChangedHandler($event)"
  [rows]="LIMIT_ROW_TABLE"
  [displaySkeleton]="true"
  [showCaption]="true"
  [showSearch]="false"
  title="Liste des médias"
  dataKey="id"
  [rowsPerPageOptions]="[10, 20]"
  styleClass="mb-5"
>
  <ng-template #header>
    <tr>
      <th class="w-10rem" scope="col" pSortableColumn="name">
        Nom <i class="pi {{ icon }}"></i>
      </th>
      <th class="w-7rem" scope="col">Media</th>
      <th class="column-header" scope="col">
        Etiquettes
        <p-columnFilter
          field="mediaTags.name"
          matchMode="equals"
          display="menu"
          [showAddButton]="false"
          [showMatchModes]="false"
          [showOperator]="false"
        >
          <ng-template pTemplate="filter">
            <p-multiSelect
              display="chip"
              [(ngModel)]="selectedTags"
              (onChange)="filterByMediaTag($event.value)"
              (onClear)="clearMediaTagFilter()"
              optionLabel="name"
              [options]="mediaTags()"
              placeholder="Choisir des étiquettes"
              [showClear]="true"
            >
              <ng-template let-option pTemplate="item">
                <p-tag [value]="option.name" severity="info" />
              </ng-template>
            </p-multiSelect>
          </ng-template>
        </p-columnFilter>
      </th>
      <th scope="col"></th>
    </tr>
  </ng-template>

  <ng-template #content let-media>
    <tr>
      <td class="w-10rem">{{ media.name }}</td>
      <td>
        <app-select-media-thumbnail
          [media]="media"
        ></app-select-media-thumbnail>
      </td>
      <td class="media-tags">
        <div *ngFor="let mediaTag of media.mediaTags">
          <p-tag
            severity="info"
            [value]="mediaTag.name"
            [rounded]="true"
          ></p-tag>
        </div>
      </td>
      <td class="icon-button">
        <div class="flex">
          <p-button
            icon="pi pi-file-edit"
            [rounded]="true"
            size="large"
            ariaLabel="Modifier le media"
            pTooltip="Modifier le media"
            tooltipPosition="top"
            [disabled]="!canEdit"
            (click)="openEditMediaModal(media)"
          ></p-button>
          <p-button
            icon="pi pi-file-plus"
            [rounded]="true"
            size="large"
            ariaLabel="Créer une question à partir de ce media"
            pTooltip="Créer une question à partir de ce media"
            tooltipPosition="top"
            [disabled]="!canCreateQuestionFromMedia"
            (click)="createQuestionFromMediaModel(media)"
          ></p-button>
        </div>
      </td>
    </tr>
  </ng-template>
</pf-table>
