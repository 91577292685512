<div class="grid">
  <app-breadcrumb [items]="breadcrumbs"></app-breadcrumb>
</div>

<h1>Liste des utilisateurs</h1>

<pf-table
  [response]="agents()"
  [isLazyMode]="true"
  noResultLabel="Pas d'utilisateur"
  (onParametersChanged)="onParametersChangedHandler($event)"
  [rows]="LIMIT_ROW_TABLE"
  [columns]="columns"
  [displaySkeleton]="true"
  [showCaption]="true"
  [showSearch]="false"
  dataKey="id"
  [rowsPerPageOptions]="[10, 20]"
  styleClass="mb-5"
>
  <ng-template #content let-agent let-index>
    <tr [pEditableRow]="agent">
      <td>{{ agent.sub }}</td>
      <td>{{ agent.lastName }}</td>
      <td>{{ agent.firstName }}</td>
      <td>
        @if (isEditing(agent)) {
          <p-dropdown
            [(ngModel)]="agent.island"
            dataKey="id"
            [options]="islands()"
            optionLabel="name"
            placeholder="Lieu"
            appendTo="body"
          >
          </p-dropdown>
        } @else {
          {{ agent.island?.name ?? "" }}
        }
      </td>
      <td>
        @if (isEditing(agent)) {
          <p-multiSelect
            [(ngModel)]="agent.agentRoles"
            dataKey="id"
            [options]="roles()"
            optionLabel="displayName"
            [maxSelectedLabels]="1"
            optionDisabled="notEditable"
            appendTo="body"
          >
          </p-multiSelect>
        } @else {
          {{ renderRoles(agent.agentRoles) }}
        }
      </td>

      <td class="icon-button">
        <div class="flex align-items-center justify-content-center gap-2">
          <p-button
            *ngIf="!isEditing(agent)"
            icon="pi pi-pencil"
            [rounded]="true"
            size="small"
            pTooltip="Modifier les rôles"
            tooltipPosition="top"
            ariaLabel="Modifier les rôles"
            [disabled]="!canEditAgent(agent)"
            (click)="onRowEditInit(agent)"
          ></p-button>
          <p-button
            *ngIf="isEditing(agent)"
            icon="pi pi-times"
            [rounded]="true"
            size="small"
            ariaLabel="Annuler"
            pTooltip="Annuler"
            tooltipPosition="top"
            (click)="onRowEditCancel(agent)"
          ></p-button>
          <p-button
            *ngIf="isEditing(agent)"
            icon="pi pi-check"
            [rounded]="true"
            (click)="onRowEditSave(agent)"
            pTooltip="Enregistrer"
            tooltipPosition="top"
            [disabled]="!canEditAgent(agent)"
            class="p-button-rounded p-button-text p-button-success mr-2"
          ></p-button>
        </div>
      </td>
    </tr>
  </ng-template>
</pf-table>
