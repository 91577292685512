import { Component, computed, input, Signal } from '@angular/core';
import {
  AuditAction,
  AuditLogInfos,
  AuditLogInfosType,
} from '@models/user/audit.model';

@Component({
  selector: 'app-archives-logs-users-action-badge',
  standalone: true,
  imports: [],
  templateUrl: './action-badge.component.html',
})
export class ActionBadgeComponent {
  action = input<AuditAction | undefined>(undefined);
  actionInfos: Signal<AuditLogInfosType | undefined> | undefined = computed(
    () => {
      return this.action() != undefined
        ? this.auditLogInfos(
            this.action() as unknown as keyof typeof AuditAction,
          )
        : undefined;
    },
  );

  constructor() {}

  auditLogInfos(
    action: keyof typeof AuditAction,
  ): AuditLogInfosType | undefined {
    return AuditLogInfos[action];
  }
}
