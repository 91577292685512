<div class="grid">
  <app-breadcrumb [items]="breadcrumbs"></app-breadcrumb>
</div>

<h1>Catégories</h1>

<pf-table
  [data]="categories() ?? undefined"
  [response]="undefined"
  [isLazyMode]="true"
  [noResultLabel]="'Pas de statistiques sur les questions'"
  [rows]="rowsLength"
  [columns]="columns"
  [displaySkeleton]="true"
  [showCaption]="true"
  title="Catégories"
  styleClass="mb-5"
>
  <ng-template #header>
    <tr>
      <th *ngFor="let column of columns" [id]="'id_'+column" class="font-normal">
        {{ column }}
      </th>
    </tr>
  </ng-template>

  <ng-template #content let-category>
    <tr>
      <td>{{ category.categoryName }}</td>
      <td>{{ category.userExamSessionCount }}</td>
      <td>{{ category.successRate | percent }}</td>
    </tr>
  </ng-template>
</pf-table>
