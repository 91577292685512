<div class="grid">
  <app-breadcrumb [items]="breadcrumbs"></app-breadcrumb>
</div>

<h1>Les sessions d'examen</h1>
<p>
  Sélectionnez la session que vous souhaitez consulter ou lancer.
</p>

<div *ngIf="sessionsByDate() === undefined" class="m-5 text-center">
  <app-spinner></app-spinner>
</div>

<div class="section" *ngIf="sessionsByDate() !== undefined">
  <div class="flex justify-content-end align-items-center gap-6">
    @if (isInternalSupervisor()) {
      <p-dropdown
        styleClass="w-full"
        [(ngModel)]="islandFilter"
        [options]="islands()"
        optionLabel="name"
        placeholder="Filtrer par lieu"
        [showClear]="true"
        (onChange)="getFilteredSessions()"
      >
      </p-dropdown>
    }
    <p-calendar
      [(ngModel)]="dateFilter"
      [showIcon]="true"
      [showOnFocus]="false"
      [showClear]="true"
      [readonlyInput]="true"
      (onSelect)="getFilteredSessions()"
      (onClear)="getFilteredSessions()"
      inputId="buttondisplay"
      placeholder="Filtrer par date"
    />
  </div>
  <div *ngFor="let entry of sessionsByDate(); index as entryIndex">
    <h2>{{ getFrenchSessionDay(entry[0]) }}</h2>
    <div
      *ngFor="let session of entry[1]; index as sessionIndex"
      class="flex flex-vertical w-full"
    >
      <!-- Temporary fix until pf-ui fix primeNg version (currently, disabled not working on routerLink) -->
      @if (canManageSession) {
        <pf-action-button
          [label]="getExamSessionLabel(session)"
          [routerLink]="getSessionIdRoute(session.id)"
          iconPosition="right"
          type="next"
          class="my-3 w-full sessions-buttons"
        ></pf-action-button>
      } @else {
        <pf-action-button
          [label]="getExamSessionLabel(session)"
          iconPosition="right"
          type="next"
          class="my-3 w-full sessions-buttons"
          [disabled]="true"
        ></pf-action-button>
      }
    </div>
  </div>
</div>
