import { CommonModule } from '@angular/common';
import {
  Component,
  effect,
  input,
  output,
  signal,
  WritableSignal,
} from '@angular/core';

import { HttpClientModule } from '@angular/common/http';
import { SpinnerComponent } from '@app/components/common/spinner/spinner.component';
import { AnswerChoices } from '@app/models/question/answerChoices.model';
import { MediaService } from '@app/services/media.service';
import { QuestionService } from '@app/services/question.service';
import { numberToString } from '@app/utils/stringHelper';
import { ButtonModule } from 'primeng/button';

@Component({
  selector: 'app-question-preview-answers',
  standalone: true,
  imports: [ButtonModule, CommonModule, HttpClientModule, SpinnerComponent],
  templateUrl: './question-preview-answers.component.html',
  styleUrl: './question-preview-answers.component.scss',
})
export class QuestionPreviewAnswersComponent {
  answerSelection = input<{ [key: number]: string }>({});
  displayDetails = input<boolean>();
  questionAnswers = input<AnswerChoices[] | undefined>([]);
  toggleAnswerSelection = input<
    (
      answerSelection: Record<number, string>,
      answerIndex: number,
      answerId: string,
    ) => void
  >(() => {
    return;
  });
  trainingQuestions = input<boolean>(false);

  randomChoices = signal<AnswerChoices[]>([]);

  selectAnswers = output<string[]>();

  numberToString = numberToString;
  loading = false;
  currentRoute: string | undefined;

  constructor(
    public mediaService: MediaService,
    private questionService: QuestionService,
  ) {
    effect(() => {
      const answers = this.questionAnswers();
      this.getAnswerChoices(this.randomChoices, answers);
    });
  }

  getAnswerChoices(
    resultRandomChoices: WritableSignal<AnswerChoices[]>,
    questionAnswers?: AnswerChoices[],
  ): void {
    const answerNumber = questionAnswers?.length ?? 0;

    if (questionAnswers && answerNumber > 0) {
      this.loading = true;
      this.questionService
        .getRandomChoicesByAnswers(questionAnswers)
        .subscribe((answers) => {
          resultRandomChoices.set(answers);
          this.loading = false;
        });
    }
  }

  getSelectedAnswerStyle(answerIndex: number): string | undefined {
    if (this.displayDetails() === true) {
      const myAnswers = this.answerSelection();
      const isSelected = myAnswers?.[answerIndex] !== undefined;
      return isSelected ? 'p-button-primary' : 'p-button-tertiary not-selected';
    } else {
      const questionAnswers = this.randomChoices();
      if (!questionAnswers.length) return;
      return (questionAnswers[answerIndex]?.correct ?? false)
        ? 'p-button-primary'
        : 'p-button-tertiary not-selected';
    }
  }
}
