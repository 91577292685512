import { AsyncPipe, CommonModule } from '@angular/common';
import { Component, effect, OnDestroy, untracked } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { SpinnerComponent } from '@app/components/common/spinner/spinner.component';
import {
  SpecificConfirmDialogComponent,
  SpecificConfirmDialogData,
} from '@app/components/dialogs/specific-confirm-dialog/specific-confirm-dialog.component';
import { MediaTypes } from '@app/models/media/media-types.models';
import { Media } from '@app/models/media/media.model';
import {
  checkMissingSound,
  QuestionDetail,
} from '@app/models/question/question-detail.model';
import {
  initStepForm,
  QuestionForm,
} from '@app/models/question/question-form.model';
import {
  QuestionStep,
  QuestionSteps,
  questionStepTranslation,
} from '@app/models/question/question-steps.model';
import { Question } from '@app/models/question/question.model';
import { QuestionStatus } from '@app/models/question/questionStatus.model';
import { MediaService } from '@app/services/media.service';
import { MediaTagService } from '@app/services/mediaTag.service';
import { QuestionService } from '@app/services/question.service';
import { objectEqual } from '@app/utils/objectHelper';
import { AdminRoute, RouteLabel } from '@app/utils/routes';
import {
  BreadcrumbComponent,
  BreadcrumbItem,
} from '@components/common/breadcrumb/breadcrumb.component';
import { ViewQuestionComponent } from '@components/dialogs/view-question/view-question.component';
import { TranslateModule } from '@ngx-translate/core';
import {
  FormManager,
  PfActionButtonComponent,
  PfFormComponent,
  PfFormWrapperComponent,
  PfStepsComponent,
  Step,
} from 'pf-ui';
import { MessageService, SharedModule } from 'primeng/api';
import { MenuItem } from 'primeng/api/menuitem';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { finalize, Observable, of, switchMap, tap } from 'rxjs';
import { CharacterizeQuestionComponent } from './characterize-question/characterize-question.component';
import { CreateQuestionComponent } from './create-question/create-question.component';
import { ValidateQuestionComponent } from './validate-question/validate-question.component';

@Component({
  selector: 'app-question',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    PfFormWrapperComponent,
    PfStepsComponent,
    CreateQuestionComponent,
    CharacterizeQuestionComponent,
    RouterModule,
    PfActionButtonComponent,
    ValidateQuestionComponent,
    SpinnerComponent,
    BreadcrumbModule,
    PfFormComponent,
    SharedModule,
    BreadcrumbComponent,
    AsyncPipe,
  ],
  templateUrl: './question.component.html',
  styleUrl: './question.component.scss',
})
export class QuestionComponent implements OnDestroy {
  form: FormGroup<QuestionForm> | undefined;

  formManager: FormManager<QuestionSteps>;
  stepperSteps: MenuItem[] = [];

  currentStatus?: QuestionStatus;

  QuestionStep = QuestionStep;
  question = this.questionService.signalCurrent;
  mediaTags = this.mediaTagsService.signalList;

  id?: string;

  loading = false;
  breadcrumbs: BreadcrumbItem[] = [];

  objectEqual = objectEqual;

  cancelModalRef: DynamicDialogRef = new DynamicDialogRef();
  draftModalRef: DynamicDialogRef = new DynamicDialogRef();
  generateSoundModalRef: DynamicDialogRef = new DynamicDialogRef();
  testQuestionModalRef: DynamicDialogRef = new DynamicDialogRef();

  isQuestionTested = false;

  private breadcrumbItemHome: BreadcrumbItem = {
    label: RouteLabel.Home,
    route: AdminRoute.Home,
  };

  private breadcrumbItemQuestions: BreadcrumbItem = {
    label: RouteLabel.Questions,
    route: AdminRoute.Questions,
  };

  constructor(
    private fb: FormBuilder,
    public questionService: QuestionService,
    public mediaService: MediaService,
    private mediaTagsService: MediaTagService,
    public messageService: MessageService,
    public router: Router,
    private route: ActivatedRoute,
    public dialog: DialogService,
  ) {
    this.formManager = new FormManager();
    this.formManager.setSteps(this.steps);
    this.stepperSteps = this.formManager.formatStepForStepper();

    this.loadQuestion();

    this.mediaTagsService.getList().subscribe();
    this.setBreadcrumbs();

    effect(
      () => {
        const question = this.question() ?? new QuestionDetail();

        const currentMedia: Media | null = this.mediaService.signalCurrent();
        if (currentMedia !== null) {
          this.updateQuestionWithMedia(question);
        }
        this.form = initStepForm(this.fb, question);
      },
      { allowSignalWrites: true },
    );
  }

  updateQuestionWithMedia(question: QuestionDetail): void {
    const currentMedia: Media | null = this.mediaService.signalCurrent();
    if (currentMedia !== null && currentMedia !== undefined) {
      question.picture = currentMedia;
      this.mediaService.resetCurrent();
    }
  }

  loadQuestion(): void {
    const routeId = this.route.snapshot.paramMap.get('id');

    if (routeId !== null && routeId !== '') {
      this.id = routeId;
      this.loading = true;
      this.questionService.get(routeId).subscribe(() => (this.loading = false));
    }
  }

  goToStep(questionStep: QuestionSteps): void {
    this.formManager.goToStep(questionStep);
    this.setBreadcrumbs();
  }

  setBreadcrumbs(): void {
    const breadcrumb: BreadcrumbItem[] = [
      this.breadcrumbItemHome,
      this.breadcrumbItemQuestions,
    ];

    if (this.formManager.isStepSelected(QuestionStep.CREATE_QUESTION)) {
      breadcrumb.push({ label: questionStepTranslation.CREATE_QUESTION });
    }

    if (this.formManager.isStepSelected(QuestionStep.CHARACTERIZE_QUESTION)) {
      breadcrumb.push({
        label: questionStepTranslation.CREATE_QUESTION,
        action: () => this.goToStep(QuestionStep.CREATE_QUESTION),
      });
      breadcrumb.push({ label: questionStepTranslation.CHARACTERIZE_QUESTION });
    }

    if (this.formManager.isStepSelected(QuestionStep.SUMMARY_QUESTION)) {
      breadcrumb.push({
        label: questionStepTranslation.CREATE_QUESTION,
        action: () => this.goToStep(QuestionStep.CREATE_QUESTION),
      });
      breadcrumb.push({
        label: questionStepTranslation.CHARACTERIZE_QUESTION,
        action: () => this.goToStep(QuestionStep.CHARACTERIZE_QUESTION),
      });
      breadcrumb.push({ label: questionStepTranslation.SUMMARY_QUESTION });
    }

    this.breadcrumbs = breadcrumb;
  }

  get steps(): Step<QuestionSteps>[] {
    return Object.values(QuestionStep).map((step) => ({
      name: step,
      title: questionStepTranslation[step],
      labelButton: '',
    }));
  }

  get currentQuestion(): QuestionDetail {
    return this.form?.value as QuestionDetail;
  }

  mapQuestionDetail(value: QuestionDetail): Question {
    return {
      ...value,
      answerChoices: value.answerChoices ?? [],
      secondAnswerChoices: value.secondAnswerChoices ?? [],
      secondDescription: value.secondDescription ?? null,
      categories: value.categories?.map((x) => x),
      themes: value.themes?.map((x) => x),
      picture: value.picture?.id != null ? { ...value.picture } : undefined,
    };
  }

  updatePicture(): Observable<Media | undefined> {
    return of(undefined).pipe(
      switchMap(() => {
        const picture = this.form?.controls.picture?.value;
        if (picture?.base64_file === undefined) return of(undefined);
        return picture?.base64_file && picture?.id === undefined
          ? // an upload has been made, the pf-form-file-upload component
            // adds a base64_file property inside picture
            this.mediaService.post(picture, MediaTypes.PICTURE)
          : // TODO: put when API is enabled
            this.mediaService.post(picture, MediaTypes.PICTURE);
      }),
    );
  }

  patchPictureForm(media: Media | undefined): void {
    const pictureControl = this.form?.controls.picture;
    if (media && pictureControl) pictureControl?.patchValue(media);
  }

  updateQuestionObservable(): Observable<QuestionDetail | undefined> {
    return of(undefined).pipe(
      switchMap(() => this.updatePicture()),
      tap((media?: Media) => this.patchPictureForm(media)),
      switchMap(() => this.save()),
    );
  }

  updateQuestion(leavePage: boolean = false): void {
    this.form?.controls.firstValidationBy?.setValue(null);
    this.form?.controls.secondValidationBy?.setValue(null);
    this.form?.controls.questionStatus?.setValue(
      this.currentStatus ?? QuestionStatus.PENDING_VALIDATION_0_2,
    );

    this.updateQuestionObservable().subscribe(() => {
      if (leavePage) this.returnToList();
    });
  }

  deleteDraft(): void {
    this.returnToList();
  }

  onCancel(): void {
    this.cancelModalRef = this.dialog.open(SpecificConfirmDialogComponent, {
      data: {
        label:
          'Abandonner la modification de la question et revenir à la banque ?',
        title: 'Abandonner',
        confirmLabel: 'Confirmer',
        cancelLabel: 'Annuler',
      } satisfies SpecificConfirmDialogData,
      styleClass: 'pf-two-column-form-modal',
      showHeader: false,
    });

    this.cancelModalRef.onClose.subscribe((confirm?: boolean) => {
      if (confirm === true) this.deleteDraft();
    });
  }

  onDraftQuestion(): void {
    this.draftModalRef = this.dialog.open(SpecificConfirmDialogComponent, {
      data: {
        label: 'Mettre la question en brouillon ?',
        title: 'Enregistrer comme brouillon',
        cancelLabel: 'Confirmer et quitter',
        confirmLabel: 'Confirmer et continuer',
      } satisfies SpecificConfirmDialogData,
      styleClass: 'pf-two-column-form-modal',
      showHeader: false,
    });

    this.draftModalRef.onClose.subscribe((confirm?: boolean) => {
      if (confirm !== undefined) {
        this.currentStatus = QuestionStatus.DRAFT;
      }
      this.updateQuestionAndStatus(confirm);
    });
  }

  updateQuestionAndStatus(confirm?: boolean): void {
    if (confirm !== undefined) {
      this.updateQuestion(!confirm);
    }
  }

  updateQuestionStatus(newValue: QuestionStatus): void {
    if (!this.form) return;

    const questionStatus = this.form.controls.questionStatus;
    if (questionStatus === undefined) return;

    this.patchQuestionStatusWith(questionStatus, newValue);
  }

  patchQuestionStatusWith(
    questionStatus: AbstractControl<QuestionStatus | null | undefined>,
    newValue: QuestionStatus,
  ): void {
    questionStatus.patchValue(newValue);
  }

  save(): Observable<QuestionDetail | undefined> {
    if (!this.form) return of(undefined);

    const toMap = this.form.value as QuestionDetail;

    if (!this.objectEqual(this.question(), toMap)) this.form.markAsDirty();

    if (!this.form.dirty) return of(undefined);
    const questionToSave = this.mapQuestionDetail({
      ...toMap,
    });
    const isExistingQuestion = questionToSave.id != '';

    return isExistingQuestion
      ? this.questionService.put(questionToSave)
      : this.questionService.post(questionToSave);
  }

  returnToList(): void {
    this.router.navigate([AdminRoute.Questions]);
  }

  goToDashboard(): void {
    this.router.navigate([AdminRoute.Home]);
  }

  onValidateFirstStepClicked(goToNextStep: boolean): void {
    if (goToNextStep) this.formManager.goToNextStep();
    this.setBreadcrumbs();
  }

  onValidateSecondStepClicked(): void {
    this.formManager.goToNextStep();
    this.setBreadcrumbs();
  }

  onValidateLastStep(): void {
    this.updateQuestion(true);
    this.setBreadcrumbs();
  }

  onGenerateSoundForQuestionEvent(): void {
    this.generateSoundModalRef = this.dialog.open(
      SpecificConfirmDialogComponent,
      {
        data: {
          label:
            'Les modifications sur la question seront enregistrées en regénérant le son.',
          title: 'Regénérer le son',
          cancelLabel: 'Annuler',
          confirmLabel: 'Continuer',
        } satisfies SpecificConfirmDialogData,
        styleClass: 'pf-two-column-form-modal',
        showHeader: false,
      },
    );

    this.generateSoundModalRef.onClose.subscribe((confirm?: boolean) => {
      if (confirm === true) this.generateSoundForQuestion().subscribe();
    });
  }

  generateSoundForQuestion(): Observable<QuestionDetail | undefined> {
    this.loading = true;
    return this.updateQuestionObservable().pipe(
      switchMap((question) => {
        if (question) {
          return this.questionService
            .generateSound(question.id)
            .pipe(switchMap(() => of(question)));
        }
        return of(undefined);
      }),
      switchMap((question) => {
        if (question) {
          return this.questionService.get(question.id);
        }
        return of(undefined);
      }),
      tap({
        next: () =>
          this.messageService.add({
            severity: 'success',
            summary: 'Succès',
            detail: 'Le son à été généré avec succès !',
          }),
      }),
      finalize(() => {
        this.loading = false;
      }),
    );
  }

  goBack(): void {
    if (this.formManager.currentStep === 0) this.returnToList();
    else this.formManager.goToPreviousStep();
  }

  onTestQuestionModal(): void {
    if (checkMissingSound(this.currentQuestion) || this.form?.dirty === true) {
      this.generateSoundForQuestion().subscribe((question) => {
        this.testQuestionModal(question);
      });
    } else {
      this.testQuestionModal(this.question());
    }
  }

  testQuestionModal(question: QuestionDetail | undefined | null): void {
    this.testQuestionModalRef = this.dialog.open(ViewQuestionComponent, {
      data: {
        question,
        isTest: true,
      },
      styleClass: 'pf-two-column-form-modal',
      showHeader: false,
    });

    this.testQuestionModalRef.onClose.subscribe(async (isTest: boolean) => {
      if (isTest) {
        this.isQuestionTested = true;
      }
    });
  }

  ngOnDestroy(): void {
    untracked(() => {
      this.questionService.signalCurrent.set(new QuestionDetail());
      this.mediaService.signalCurrent.set(null);
    });
  }
}
