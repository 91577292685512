import { HttpClient } from '@angular/common/http';
import { Injectable, signal } from '@angular/core';
import { AnswerChoices } from '@app/models/question/answerChoices.model';
import { environement } from '@environments/environment';
import { ApiResponse } from '@models/common/api-response.model';
import { PageableResponse } from '@models/common/pageable-response.model';
import {
  ProjectedQuestionDetails,
  QuestionDetail,
} from '@models/question/question-detail.model';
import { Question } from '@models/question/question.model';
import { BaseService } from '@services/base.service';
import { PageRequest, PageResponse } from 'pf-ui';
import { MessageService } from 'primeng/api';
import { EMPTY, Observable, catchError, tap, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class QuestionService extends BaseService {
  signalListPaginated = signal<PageResponse<Question> | undefined>(undefined);
  signalCurrent = signal<QuestionDetail | null>(null);
  signalTrainingQuestions = signal<QuestionDetail[] | null>(null);

  constructor(
    protected override http: HttpClient,
    protected override messageService: MessageService,
  ) {
    super(http, messageService);
  }

  listPaginated(event?: PageRequest): Observable<PageResponse<Question>> {
    return this.executeRequest(
      this.http.get<PageableResponse<Question>>(
        `${environement.BACKEND_URL}/question/list`,
        {
          params: this.getHttpParams({ ...event }),
        },
      ),
    ).pipe(
      this.mapPageableResponseToPageResponse<Question>(event),
      tap((questionsStats) => {
        this.signalListPaginated.set(questionsStats);
      }),
    );
  }

  get(id: string): Observable<QuestionDetail> {
    return this.executeRequest(
      this.http.get<QuestionDetail>(
        `${environement.BACKEND_URL}/question/${id}`,
      ),
    ).pipe(
      tap((val) => {
        this.signalCurrent.set(val);
      }),
    );
  }

  getAllTrainingQuestions(): Observable<ApiResponse<ProjectedQuestionDetails>> {
    return this.executeRequest(
      this.http.get<ApiResponse<ProjectedQuestionDetails>>(
        `${environement.BACKEND_URL}/question/search/findAllByCorrectionNotNull`,
      ),
    ).pipe(
      tap((questionDetails) => {
        const questionsDetails = questionDetails._embedded;
        const filteredQuestionDetails = questionsDetails?.question?.filter(
          (qd) => qd.correction,
        );
        if (filteredQuestionDetails)
          this.signalTrainingQuestions.set(filteredQuestionDetails);
      }),
    );
  }

  post(body: Question): Observable<QuestionDetail> {
    return this.executeRequest(
      this.http.post<QuestionDetail>(`${environement.BACKEND_URL}/question`, {
        ...body,
        id: undefined,
      }),
    ).pipe(tap((val) => this.signalCurrent.set(val)));
  }

  put(body: Question | QuestionDetail): Observable<QuestionDetail> {
    return this.executeRequest(
      this.http.patch<QuestionDetail>(
        `${environement.BACKEND_URL}/question/${body.id}/edit`,
        body,
      ),
    ).pipe(tap((val) => this.signalCurrent.set(val)));
  }

  patch(question: QuestionDetail): Observable<QuestionDetail> {
    return this.executeRequest(
      this.http.patch<QuestionDetail>(
        `${environement.BACKEND_URL}/question/${question.id}/edit`,
        question,
      ),
    ).pipe(tap((val) => this.signalCurrent.set(val)));
  }

  suspend(question: QuestionDetail): Observable<QuestionDetail> {
    return this.executeRequest(
      this.http.patch<QuestionDetail>(
        `${environement.BACKEND_URL}/question/${question.id}/suspend`,
        question,
      ),
    );
  }

  reject(question: QuestionDetail): Observable<QuestionDetail> {
    return this.executeRequest(
      this.http.patch<QuestionDetail>(
        `${environement.BACKEND_URL}/question/${question.id}/reject`,
        question,
      ),
    ).pipe(tap((val) => this.signalCurrent.set(val)));
  }

  validate(question: QuestionDetail): Observable<QuestionDetail> {
    return this.executeRequest(
      this.http.patch<QuestionDetail>(
        `${environement.BACKEND_URL}/question/${question.id}/validate`,
        question,
      ),
    ).pipe(
      tap((val) => {
        this.signalCurrent.set(val);
      }),
    );
  }

  delete(id?: string): Observable<object> {
    if (id === undefined) return EMPTY;

    return this.executeRequest(
      this.http.delete(`${environement.BACKEND_URL}/question/${id}`),
    );
  }

  generateSound(id: string): Observable<string> {
    return this.executeRequest(
      this.http.get<string>(
        `${environement.BACKEND_URL}/question/generate-sound/${id}`,
      ),
    ).pipe(
      catchError((err) => {
        return throwError(() => err);
      }),
    );
  }

  getRandomChoicesByAnswers(
    answerChoices: AnswerChoices[],
  ): Observable<AnswerChoices[]> {
    return this.executeRequest(
      this.http.post<AnswerChoices[]>(
        `${environement.BACKEND_URL}/question/random-choices`,
        answerChoices,
      ),
    );
  }

  getTrainingQuestions(categoryId?: string): Observable<QuestionDetail[]> {
    return this.executeRequest(
      this.http.post<QuestionDetail[]>(
        `${environement.BACKEND_URL}/question/training-questions`,
        { categoryId },
      ),
    ).pipe(
      tap((trainingQuestions) => {
        this.signalTrainingQuestions.set(trainingQuestions);
      }),
    );
  }

  setFlagged(question: Question): Observable<string> {
    return this.executeRequest(
      this.http.patch<string>(
        `${environement.BACKEND_URL}/question/flag`,
        question,
      ),
    ).pipe();
  }

  setUnFlagged(question: Question): Observable<string> {
    return this.executeRequest(
      this.http.patch<string>(
        `${environement.BACKEND_URL}/question/unFlag`,
        question,
      ),
    ).pipe();
  }
}
