import { CommonModule } from '@angular/common';
import { Component, effect, model } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { RouterLink } from '@angular/router';
import { SpecificClosableDialogComponent } from '@app/components/dialogs/specific-closable-dialog/specific-closable-dialog.component';
import { Media } from '@app/models/media/media.model';
import { MediaTag } from '@app/models/media/mediaTag.model';
import { DEFAULT_MAX_FILE_SIZE } from '@app/utils/constants';
import { TranslateModule } from '@ngx-translate/core';
import {
  PfActionButtonComponent,
  PfFormComponent,
  PfFormFileUploadComponent,
  PfStepComponent,
} from 'pf-ui';
import { CheckboxModule } from 'primeng/checkbox';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ImageModule } from 'primeng/image';
import { SkeletonModule } from 'primeng/skeleton';
import { TooltipModule } from 'primeng/tooltip';
import { SafeUrl } from '@angular/platform-browser';
import { getImageSafeUrl } from '@utils/media';

@Component({
  selector: 'app-upload-media',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    RouterLink,
    PfFormFileUploadComponent,
    PfFormComponent,
    ImageModule,
    SkeletonModule,
    ReactiveFormsModule,
    PfActionButtonComponent,
    PfStepComponent,
    CheckboxModule,
    TooltipModule,
    SpecificClosableDialogComponent,
  ],
  templateUrl: './upload-media.component.html',
  styleUrl: './upload-media.component.scss',
})
export class UploadMediaComponent {
  media = model<Media | undefined>({});
  tagList = model<MediaTag[] | undefined>();
  mediaUrl = model<Record<string, SafeUrl> | undefined>(undefined);
  form!: FormGroup;
  imagePath?: string | SafeUrl = undefined;

  DEFAULT_MAX_FILE_SIZE = DEFAULT_MAX_FILE_SIZE;

  constructor(
    private fb: FormBuilder,
    private config: DynamicDialogConfig<{
      media?: Media;
      tagList?: MediaTag[];
      mediasUrl?: Record<string, SafeUrl>;
    }>,
    private dialog: DynamicDialogRef,
  ) {
    this.media.update(() => this.config.data?.media);
    this.tagList.update(() => this.config.data?.tagList);
    this.mediaUrl.update(() => this.config.data?.mediasUrl);

    effect(() => {
      this.form = this.fb.group({
        media: [this.media()],
        tags: [this.media()?.mediaTags],
      });

      this.form
        .get('media')
        ?.valueChanges.subscribe(
          (file: { base64_file: string; name: string }) => {
            this.imagePath = `data:image/jpg;base64,${file.base64_file}`;
          },
        );

      if (this.media()?.base64_file !== undefined) {
        this.imagePath = `data:image/jpg;base64,${this.media()?.base64_file}`;
      } else if (this.media()?.id !== undefined && this.mediaUrl()) {
        this.imagePath = getImageSafeUrl(this.media(), this.mediaUrl());
      }
    });
  }

  close(save?: boolean): void {
    if (
      this.media() ||
      (this.form.get('media')?.value != null && save === true)
    ) {
      const mediaToReturn = {
        ...this.form.get('media')?.value,
        mediaTags: this.form.get('tags')?.value,
      } satisfies Media;

      this.dialog.close(mediaToReturn);
      return;
    }
    this.dialog.close();
  }
}
