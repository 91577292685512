import { CommonModule } from '@angular/common';
import { Component, effect, input, signal } from '@angular/core';
import { ImageModule } from 'primeng/image';
import { Media } from '@models/media/media.model';
import { MediaService } from '@services/media.service';
import { SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-select-media-thumbnail',
  standalone: true,
  imports: [CommonModule, ImageModule],
  templateUrl: './thumbnail.component.html',
  styleUrl: './thumbnail.component.scss',
})
export class ThumbnailComponent {
  media = input<Media>();
  currentImageUri = signal<SafeUrl | string | undefined>(undefined);

  constructor(public mediaService: MediaService) {
    effect(() => {
      const keyName = this.media()?.keyName;
      if (keyName === undefined) return;
      this.mediaService
        .getImageThumbnail(keyName)
        .subscribe((currentImageUri: SafeUrl | undefined) => {
          this.currentImageUri.set(currentImageUri ?? undefined);
        });
    });
  }

  get name(): string {
    return this.media()?.name ?? '';
  }
}
