import { HttpClient } from '@angular/common/http';
import { Injectable, signal } from '@angular/core';
import { Island } from '@app/models/island/island.model';
import { MessageService } from 'primeng/api';
import { Observable, tap } from 'rxjs';
import { environement } from '@environments/environment';
import { ApiResponse } from '@models/common/api-response.model';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root',
})
export class IslandService extends BaseService {
  signalList = signal<Island[]>([]);

  constructor(
    protected override http: HttpClient,
    protected override messageService: MessageService,
  ) {
    super(http, messageService);
  }

  list(): Observable<Island[]> {
    return this.executeNotPaginatedRequest(
      this.http.get<ApiResponse<{ [key: string]: Island[] }>>(
        `${environement.BACKEND_URL}/island`,
      ),
    ).pipe(
      tap((response) => {
        this.signalList.set(response);
      }),
    );
  }
}
