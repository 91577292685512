<pf-table
  [response]="questions()"
  [globalFilterFields]="globalFilterFields"
  [isLazyMode]="true"
  noResultLabel="Pas de question"
  (onParametersChanged)="onParametersChangedHandler($event)"
  searchPlaceholder="Rechercher par énoncé"
  [rows]="LIMIT_ROW_TABLE"
  [displaySkeleton]="true"
  [showCaption]="true"
  [showSearch]="true"
  title="Liste des questions"
  dataKey="id"
  [columns]="columns"
  [rowsPerPageOptions]="[10, 20]"
  styleClass="mb-5"
>
  <ng-template #content let-question>
    <tr>
      <td tooltipPosition="top" [pTooltip]="getTooltipDescription(question)">
        {{ question.description | truncate: MAX_QUESTION_DESCRIPTION_LENGTH }}
      </td>
      <td>
        <app-select-media-thumbnail [media]="question.picture">
        </app-select-media-thumbnail>
      </td>
      <td>{{ renderList(question.themes) }}</td>
      <td>{{ renderList(question.categories) }}</td>
      <td>
        <app-question-status
          [status]="question.questionStatus"
        ></app-question-status>
      </td>
      <td class="icon-button">
        <ng-container
          *ngTemplateOutlet="templateActions; context: { $implicit: question }"
        >
        </ng-container>
      </td>
    </tr>
  </ng-template>
</pf-table>
