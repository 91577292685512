import { CommonModule } from '@angular/common';
import { Component, effect, input } from '@angular/core';
import { PfCardComponent, PfFormSummaryEditionWrapperComponent } from 'pf-ui';
import { StatisticsService } from '@services/statistics.service';
import { TableModule } from 'primeng/table';
import { PluralizePipeModule } from '@app/pipes/pluralize.pipe';
import { RouterLink } from '@angular/router';
import { AdminRoute, RouteLabel } from '@utils/routes';
import { SkeletonModule } from 'primeng/skeleton';
import { ExamByCategory } from '@models/statistics/statistics.model';
import { Category } from '@models/question/category.model';

@Component({
  selector: 'app-exam-by-category',
  standalone: true,
  imports: [
    CommonModule,
    PfCardComponent,
    TableModule,
    PluralizePipeModule,
    PfFormSummaryEditionWrapperComponent,
    RouterLink,
    SkeletonModule,
  ],
  templateUrl: './exam-by-category.component.html',
})

export class ExamByCategoryComponent {
  protected readonly AdminRoute = AdminRoute;
  protected readonly RouteLabel = RouteLabel;

  category = input<Category | null | undefined>(null);

  loading: boolean = false;
  exams: ExamByCategory = {
    totalSuccessfullyPassed: 0,
    total: 0,
  };

  constructor(
    private statisticsService: StatisticsService,
  ) {
    effect(() => {
      this.load();
    });
  }

  load(): void {
    this.loading = true;
    let categoryId: string[] = [];
    if(this.category()?.id !== undefined && this.category()?.id !== null){
      categoryId = [this.category()?.id as string];
    }

    this.statisticsService.getExamsByCategories(categoryId)
      .subscribe((exams) => {
        this.exams = exams;
        this.loading = false;
      });
  }
}
