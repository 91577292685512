<app-question-preview
  class="questions training"
  [displayCorrection]="true"
  imageContainerHeight="33vh"
  [question]="currentQuestion()"
  [currentQuestionIndex]="currentQuestionIndex + 1"
  [questionTotal]="trainingQuestions()?.length"
  [displayDetails]="true"
  (goToNextQuestion)="setNextQuestion()"
>
</app-question-preview>
