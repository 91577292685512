import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { PfActionButtonComponent, PfCardComponent, PfFormSummaryEditionWrapperComponent } from 'pf-ui'
import { StatisticsService } from '@services/statistics.service'
import { TableModule } from 'primeng/table'
import { PluralizePipeModule } from '@app/pipes/pluralize.pipe'
import { RouterLink } from '@angular/router'
import { AdminRoute, RouteLabel } from '@utils/routes'
import { SkeletonModule } from 'primeng/skeleton'
import { QuestionsByThemes } from '@models/statistics/statistics.model'
import { ButtonModule } from 'primeng/button'

@Component({
  selector: 'app-questions-by-themes',
  standalone: true,
  imports: [
    CommonModule,
    PfCardComponent,
    TableModule,
    PluralizePipeModule,
    PfFormSummaryEditionWrapperComponent,
    RouterLink,
    SkeletonModule,
    ButtonModule,
    PfActionButtonComponent,
  ],
  templateUrl: './questions-by-themes.component.html',
})

export class QuestionsByThemesComponent {
  protected readonly AdminRoute = AdminRoute
  protected readonly RouteLabel = RouteLabel

  loading: boolean;
  questionsByThemes: QuestionsByThemes[] = Array.from({length: 3}).map(() => {
    return {
      themeName: '',
      questionCount: 0
    }
  });

  constructor(private statisticsService: StatisticsService) {
    this.loading = true;
    this.statisticsService.getQuestionsByThemes().subscribe((questionsByThemes) => {
      this.questionsByThemes = questionsByThemes
      this.loading = false
    });
  }
}
