<app-specific-closable-dialog
  title="Créer une étiquette"
  (closeClicked)="close()"
  [showCloseButton]="true"
>

  <div class="grid">
    <pf-form [formGroup]="form" class="col-12">
      <div class="field flex flex-column">
        <pf-form-label-wrapper
          class="m-0"
          [showErrors]="showErrors(form, 'name')"
        >
          <ng-template #label>
            <label class="font-normal" for="name">
              Nom de l'étiquette
            </label>
          </ng-template>
          <ng-template #errors>
            <pf-form-error [label]="getError(form, 'name')"></pf-form-error>
          </ng-template>
        </pf-form-label-wrapper>
        <input
          class="w-full"
          pInputText
          id="name"
          formControlName="name"
          type="text"
          placeholder="Nom"
        />
      </div>
      <div class="flex align-items-stretch flex-column">
        <pf-form-action-bar class="mt-5" styleClass="gap-5 justify-content-center">
          <pf-action-button
            (buttonClicked)="save()"
            styleClass="text-xl"
            label="Enregistrer"
            type="custom"
          >
          </pf-action-button>
          <pf-action-button
            (buttonClicked)="close()"
            styleClass="text-xl p-button-tertiary"
            label="Annuler"
          >
          </pf-action-button>
        </pf-form-action-bar>
      </div>
    </pf-form>
  </div>
</app-specific-closable-dialog>
