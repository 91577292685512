import { CommonModule } from '@angular/common';
import { Component, effect, output, signal } from '@angular/core';
import { generateDecreasingPercentages } from '@utils/circleCountdownHelper';
import { PfCircleCountdownComponent } from 'pf-ui';
import { AppService } from '@services/app.service';
import { DEFAULT_EXPLANATION } from '@utils/constants'

@Component({
  selector: 'app-explanation',
  standalone: true,
  imports: [CommonModule, PfCircleCountdownComponent],
  templateUrl: './explanation.component.html',
  styleUrl: './explanation.component.scss',
})
export class ExplanationComponent {
  countdownColorTimes = signal<number[]>([]);
  enableTrainingStep = output<void>();
  startTimer: boolean = false;

  constructor(private appService: AppService) {
    this.appService.updateHeaderDisplay(false);
    this.startTimer = true;
    effect(
      () => {
        this.countdownColorTimes.set(
          generateDecreasingPercentages(DEFAULT_EXPLANATION / 1000, 4),
        );
      },
      { allowSignalWrites: true },
    );
  }

  loadTrainingStep(): void {
    this.startTimer = false;
    this.enableTrainingStep.emit();
  }

  protected readonly DEFAULT_EXPLANATION = DEFAULT_EXPLANATION
}
