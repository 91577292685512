<pf-table
  [response]="flaggedQuestionsStats()"
  [isLazyMode]="true"
  [columns]="columns"
  noResultLabel="Pas de question suivie"
  (onParametersChanged)="onParametersChangedHandler($event)"
  [rows]="4"
  [displaySkeleton]="true"
  [showCaption]="true"
  [showSearch]="false"
  title="Liste des questions suivies"
  dataKey="id"
  [rowsPerPageOptions]="[4]"
  styleClass="mb-5"
>
  <ng-template #content let-question>
    <tr>
      <td class="icon-button">
        <p-button
          icon="pi pi-flag-fill"
          [rounded]="true"
          size="small"
          ariaLabel="Arrêter de suivre la question"
          (click)="setUnFlagged(question)"
        ></p-button>
      </td>
      <td class="">{{ question.questionDescription |  truncate : MAX_QUESTION_DESCRIPTION_LENGTH }}</td>
      <td>{{ question.usageRate | percent }}</td>
      <td>{{ question.successRate | percent }}</td>
      <td [innerHTML]="getThemesName(question)"></td>
      <td [innerHTML]="getCategoriesName(question)"></td>
    </tr>
  </ng-template>

  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="6" class="text-center">Pas de questions suivies</td>
    </tr>
  </ng-template>
</pf-table>
