import { Component, input } from '@angular/core';
import { TooltipModule } from 'primeng/tooltip';

@Component({
  selector: 'app-session-tooltip-helper',
  standalone: true,
  imports: [
    TooltipModule
  ],
  templateUrl: './session-tooltip-helper.component.html',
})
export class SessionTooltipHelperComponent {
  public tooltipHeaderLabel = input<string>('Informations incorrectes');

  public tooltipContentLabel = input<string>('Si certaines informations sont incorrectes, signalez le au surveillant présent dans la salle.');

  public buttonLabel = input<string>('Aide');

  public helperIcon = input<string>('pi-question-circle');

  public tooltipPosition = input<string>('left');

  public buildHelperIcon(): string {
    return `pi ${this.helperIcon()}`;
  }

}
