import { HttpClient } from '@angular/common/http';
import { Injectable, signal } from '@angular/core';
import { environement } from '@environments/environment';
import { Question } from '@models/question/question.model';
import { Observable, tap } from 'rxjs';
import { BaseService } from '@services/base.service';
import { MessageService } from 'primeng/api';
import { Serie } from '@models/serie/serie.model';
import { PageRequest, PageResponse } from 'pf-ui';
import { PageableResponse } from '@models/common/pageable-response.model';

@Injectable({
  providedIn: 'root',
})
export class SeriesService extends BaseService {
  signalSeries = signal<Question[] | undefined>(undefined);
  signalSeriesPaginated = signal<PageResponse<Question> | undefined>(undefined);
  signalCurrent = signal<Serie | undefined>(undefined);
  signalListPaginated = signal<PageResponse<Serie> | undefined>(undefined);

  constructor(
    protected override http: HttpClient,
    protected override messageService: MessageService,
  ) {
    super(http, messageService);
  }

  listPaginated(event?: PageRequest): Observable<PageResponse<Serie>> {
    return this.executeRequest(
      this.http.get<PageableResponse<Serie>>(
        `${environement.BACKEND_URL}/series`,
        {
          params: this.getHttpParams({ ...event }),
        },
      ),
    ).pipe(
      this.mapPageableResponseToPageResponse<Serie>(event),
      tap((serie) => {
        this.signalListPaginated.set(serie);
      }),
    );
  }

  getSerieQuestions(
    id: string,
    event: PageRequest,
  ): Observable<PageResponse<Question>> {
    return this.executeRequest(
      this.http.get<PageableResponse<Question>>(
        `${environement.BACKEND_URL}/question/series/${id}`,
        {
          params: this.getHttpParams({ ...event }),
        },
      ),
    ).pipe(
      this.mapPageableResponseToPageResponse<Question>(event),
      tap((questionsStats) => {
        this.signalSeriesPaginated.set(questionsStats);
      }),
    );
  }

  getSerie(id: string): Observable<Serie> {
    return this.executeRequest(
      this.http
        .get<Serie>(`${environement.BACKEND_URL}/series/${id}`)
        .pipe(tap((s) => this.signalCurrent.set(s))),
    );
  }

  downloadSeries(id: string): Observable<Blob> {
    return this.executeRequest(
      this.http.get<Blob>(
        `${environement.BACKEND_URL}/setting/category/${id}/series/download-from-category`,
        { responseType: 'blob' as 'json' },
      ),
    );
  }

  getSeries(id: string): Observable<Question[]> {
    return this.http
      .get<
        Question[]
      >(`${environement.BACKEND_URL}/setting/category/${id}/series/series-from-category`)
      .pipe(
        tap((val) => {
          this.signalSeries.set(val);
        }),
      );
  }
}
