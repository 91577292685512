<div class="image-section">
  <div
    class="image-container flex relative"
    [ngStyle]="{
      'aspect-ratio': imageRatio,
      height: imageContainerHeight(),
    }"
  >
    <p-image
      *ngIf="isPictureReady()"
      [src]="currentImageUri()"
      alt="image {{ question()?.picture?.name }}"
      [ngClass]="{ 'p-image': imageSize }"
      id="question-image"
    >
    </p-image>
    <div *ngIf="displayMetropolitanIcon" class="absolute european-flag">
      <app-european-flag></app-european-flag>
    </div>
    <p-skeleton *ngIf="!isPictureReady()" animation="none"></p-skeleton>
  </div>
</div>
