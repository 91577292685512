import { HttpClient } from '@angular/common/http';
import { Injectable, signal } from '@angular/core';
import { ThemeSetting } from '@app/models/question/themeSetting.model';
import { Observable, tap } from 'rxjs';
import { environement } from '@environments/environment';
import {
  QuestionCountForTheme,
  Theme,
  ThemeList,
} from '@models/question/theme.model';
import { BaseService } from '@services/base.service';
import { MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root',
})
export class ThemeService extends BaseService {
  signalList = signal<Theme[] | undefined>(undefined);
  signalRootThemesInCategory = signal<Theme[] | null>(null);

  constructor(
    public override http: HttpClient,
    public override messageService: MessageService,
  ) {
    super(http, messageService);
  }

  list(): void {
    this.executeRequest(
      this.http.get<ThemeList>(`${environement.BACKEND_URL}/nested-themes`),
    ).subscribe((themeList) => {
      this.signalList.set(
        themeList?.theme?.map((theme) => {
          return {
            id: theme.id,
            name: theme.name,
            level: theme.level,
            children: theme.children,
            numberPerSeries: theme.numberPerSeries,
          };
        }) || [],
      );
    });
  }

  listAndReturnObservable(): Observable<ThemeList> {
    return this.executeRequest(
      this.http.get<ThemeList>(`${environement.BACKEND_URL}/nested-themes`),
    ).pipe(
      tap((themeList) =>
        this.signalList.set(
          themeList?.theme?.map((theme) => {
            return {
              id: theme.id,
              name: theme.name,
              level: theme.level,
              children: theme.children,
              numberPerSeries: theme.numberPerSeries,
            };
          }) || [],
        ),
      ),
    );
  }

  listRootThemesInCategory(categoryId: string): void {
    this.executeRequest(
      this.http.get<Theme[]>(
        `${environement.BACKEND_URL}/root-themes-from-category/${categoryId}`,
      ),
    ).subscribe((rootThemes) => {
      this.signalRootThemesInCategory.set(rootThemes);
    });
  }

  saveThemesSettings(
    categoryId: string,
    themes: ThemeSetting[],
  ): Observable<string> {
    return this.executeRequest(
      this.http.post(
        `${environement.BACKEND_URL}/save-themes-settings/${categoryId}`,
        themes,
        { responseType: 'text' },
      ),
    );
  }

  getQuestionCountPerThemesInCategory(
    categoryId: string,
  ): Observable<QuestionCountForTheme[]> {
    return this.executeRequest(
      this.http.get<QuestionCountForTheme[]>(
        `${environement.BACKEND_URL}/question-count/${categoryId}`,
      ),
    );
  }

  update(theme: Theme): Observable<Theme> {
    return this.executeRequest(
      this.http.patch<Theme>(
        `${environement.BACKEND_URL}/themes/${theme.id}/edit`,
        { ...theme, children: theme.children?.map((c) => c.id) },
      ),
    ).pipe();
  }

  delete(theme: Theme): Observable<Theme> {
    return this.executeRequest(
      this.http.delete<Theme>(`${environement.BACKEND_URL}/themes/${theme.id}`),
    ).pipe();
  }

  create(theme: Theme): Observable<Theme> {
    return this.executeRequest(
      this.http.post(`${environement.BACKEND_URL}/themes`, theme),
    ).pipe();
  }
}
