import { Agent } from '@models/user/agent.model';
import {
  AdminRoute,
  getCategoryIdRoute,
  getQuestionIdRoute,
  getSeriesArchivesDownloadedSeries,
  getSessionIdRoute,
} from '@utils/routes';

export type Audit = {
  action?: AuditAction;
  agent?: Agent;
  auditableType?: string;
  auditableId?: string;
  createdDate?: string;
};

export enum AuditAction {
  SHOW = 'Consulter',
  CREATE = 'Création',
  UPDATE = 'Mise à jour',
  DELETE = 'Supprimer',
  DOWNLOAD = 'Télécharger',
  REJECT = 'Rejeter',
  VALIDATE = 'Valider',
  MONITOR = 'Surveiller',
  CANCEL = 'Annuler',
}

export enum AuditableType {
  AGENTS,
  QUESTIONS,
  SERIES,
  STATISTICS,
  EXAMSESIONS,
  CATEGORIES,
  MEDIATAGS,
  THEMES,
}

export type AuditableTypeInfo = {
  type: AuditableType;
  label: string;
  actions: AuditableTypeInfoAction[];
};

export type AuditableTypeInfoAction = {
  type: AuditAction;
  url?: (id: string) => string;
  label?: string;
};

export const AuditableTypeInfos: AuditableTypeInfo[] = [
  {
    type: AuditableType.QUESTIONS,
    label: 'Questions',
    actions: [
      {
        type: AuditAction.SHOW,
        url: (id: string): string => getQuestionIdRoute(id),
      },
      {
        type: AuditAction.UPDATE,
        url: (id: string): string => getQuestionIdRoute(id),
      },
      {
        type: AuditAction.REJECT,
        url: (id: string): string => getQuestionIdRoute(id),
      },
      {
        type: AuditAction.VALIDATE,
        url: (id: string): string => getQuestionIdRoute(id),
      },
    ],
  },
  {
    type: AuditableType.CATEGORIES,
    label: 'Paramètres - Catégories',
    actions: [
      {
        type: AuditAction.SHOW,
        label: 'Voir les catégorie',
        url: (): string => `/${AdminRoute.Statistics}`,
      },
      {
        type: AuditAction.UPDATE,
        url: (id): string => `/${getCategoryIdRoute(id)}`,
      },
    ],
  },
  {
    type: AuditableType.STATISTICS,
    label: 'Statistiques',
    actions: [
      {
        type: AuditAction.SHOW,
        label: 'Voir les statistiques',
        url: (): string => `/${AdminRoute.Statistics}`,
      },
    ],
  },
  {
    type: AuditableType.SERIES,
    label: 'Série',
    actions: [
      {
        type: AuditAction.DOWNLOAD,
        url: (id: string): string => getSeriesArchivesDownloadedSeries(id),
      },
    ],
  },
  {
    type: AuditableType.EXAMSESIONS,
    label: 'Examens',
    actions: [
      {
        type: AuditAction.DOWNLOAD,
        url: (id: string): string => getSessionIdRoute(id),
      },
      {
        type: AuditAction.MONITOR,
        url: (id: string): string => getSessionIdRoute(id),
      },
      {
        type: AuditAction.CANCEL,
        url: (id: string): string => getSessionIdRoute(id),
      },
    ],
  },
  {
    type: AuditableType.THEMES,
    label: 'Thèmes',
    actions: [
      {
        type: AuditAction.UPDATE,
        url: (): string => `/${AdminRoute.Themes}`,
      },
      {
        type: AuditAction.CREATE,
        url: (): string => `/${AdminRoute.Themes}`,
      },
      {
        type: AuditAction.DELETE,
        url: (): string => `/${AdminRoute.Themes}`,
      },
    ],
  },
  {
    type: AuditableType.MEDIATAGS,
    label: 'Etiquettes',
    actions: [
      {
        type: AuditAction.UPDATE,
        url: (): string => `/${AdminRoute.Tags}`,
      },
      {
        type: AuditAction.DELETE,
        url: (): string => `/${AdminRoute.Tags}`,
      },
      {
        type: AuditAction.CREATE,
        url: (): string => `/${AdminRoute.Tags}`,
      },
    ],
  },
  {
    type: AuditableType.AGENTS,
    label: 'Agents',
    actions: [
      {
        type: AuditAction.UPDATE,
        label: "Modification d'un agent",
        url: (): string => `/${AdminRoute.Users}`,
      },
    ],
  },
];

export type AuditLogInfosType = {
  label: string;
  icon: string;
  severityClass: string;
};

export const AuditLogInfos: {
  [key in keyof typeof AuditAction]: AuditLogInfosType;
} = {
  SHOW: {
    label: AuditAction.SHOW,
    icon: 'pi pi-eye',
    severityClass: 'bg-primary',
  },
  CREATE: {
    label: AuditAction.CREATE,
    icon: 'pi pi-plus-circle',
    severityClass: 'bg-primary',
  },
  UPDATE: {
    label: AuditAction.UPDATE,
    icon: 'pi pi-pencil',
    severityClass: 'bg-warning text-white ',
  },
  DELETE: {
    label: AuditAction.DELETE,
    icon: 'pi pi-trash',
    severityClass: 'bg-danger text-white ',
  },
  DOWNLOAD: {
    label: AuditAction.DOWNLOAD,
    icon: 'pi pi-pause-circle',
    severityClass: 'bg-primary',
  },
  REJECT: {
    label: AuditAction.REJECT,
    icon: 'pi pi-minus-circle',
    severityClass: 'bg-danger text-white ',
  },
  VALIDATE: {
    label: AuditAction.VALIDATE,
    icon: 'pi pi-check-circle',
    severityClass: 'bg-success text-white',
  },
  CANCEL: {
    label: AuditAction.CANCEL,
    icon: 'pi pi-times-circle',
    severityClass: 'bg-danger text-white',
  },
  MONITOR: {
    label: AuditAction.MONITOR,
    icon: 'pi pi-minus-circle',
    severityClass: 'bg-gray-800 text-gray-200',
  },
};
