import { CommonModule } from '@angular/common';
import { Component, computed, input, output } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { AccordionSelectComponent } from '@app/components/dialogs/accordion-select/accordion-select.component';
import { QuestionPreviewComponent } from '@app/components/questions/question-preview/question-preview.component';
import { Category, mapCategoryList } from '@app/models/question/category.model';
import { QuestionDetail } from '@app/models/question/question-detail.model';
import { QuestionForm } from '@app/models/question/question-form.model';
import { Theme } from '@app/models/question/theme.model';
import { CategoryService } from '@app/services/category.service';
import { ThemeService } from '@app/services/theme.service';
import { objectEqual } from '@app/utils/objectHelper';
import { AbstractFormComponent } from '@components/common/abstract/form/abstract-form.component';
import { TranslateModule } from '@ngx-translate/core';
import {
  PfActionButtonComponent,
  PfCardComponent,
  PfFormComponent,
  PfFormErrorComponent,
  PfFormLabelWrapperComponent,
} from 'pf-ui';
import { CheckboxModule } from 'primeng/checkbox';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { InputNumberModule } from 'primeng/inputnumber';
import { TooltipModule } from 'primeng/tooltip';

@Component({
  selector: 'app-characterize-question',
  standalone: true,
  imports: [
    TranslateModule,
    PfFormComponent,
    PfActionButtonComponent,
    PfCardComponent,
    CommonModule,
    ReactiveFormsModule,
    CheckboxModule,
    InputNumberModule,
    TooltipModule,
    PfFormErrorComponent,
    PfFormLabelWrapperComponent,
    QuestionPreviewComponent,
  ],
  templateUrl: './characterize-question.component.html',
})
export class CharacterizeQuestionComponent extends AbstractFormComponent {
  validateClicked = output();
  form = input<FormGroup<QuestionForm> | undefined>();
  categories: Category[] = [];
  question = computed(() => this.currentForm?.value as QuestionDetail);

  accordionSelectModalRef: DynamicDialogRef = new DynamicDialogRef();

  constructor(
    public themeService: ThemeService,
    public categoryService: CategoryService,
    public dialog: DialogService,
  ) {
    super();
    this.load();
  }

  load(): void {
    this.themeService.list();
    this.categoryService.list().subscribe((categories) => {
      this.categories = mapCategoryList(categories._embedded?.category);
    });
  }

  get currentForm(): FormGroup<QuestionForm> | undefined {
    return this.form();
  }

  get controls(): QuestionForm | undefined {
    return this.currentForm?.controls;
  }

  openAccordionSelect(): void {
    this.accordionSelectModalRef = this.dialog.open(AccordionSelectComponent, {
      data: {
        themes: this.themeService.signalList(),
        selectedThemes: this.currentForm?.controls.themes?.value,
      },
      styleClass: 'pf-two-column-form-modal',
      showHeader: false,
    });

    this.accordionSelectModalRef.onClose.subscribe(
      (selectedThemes?: Theme[]) => {
        const themes = this.controls?.themes;
        if (
          selectedThemes !== undefined &&
          !objectEqual(themes?.value, selectedThemes)
        ) {
          this.patchThemes(selectedThemes);
        }
      },
    );
  }

  markAsTouched(): void {
    this.controls?.themes?.markAsTouched();
    this.controls?.categories?.markAsTouched();
    this.controls?.duration?.markAsTouched();
    this.controls?.score?.markAsTouched();
  }

  isValid(): boolean | undefined {
    return (
      this.controls?.themes?.valid === true &&
      this.controls.categories?.valid === true &&
      this.controls.duration?.valid === true &&
      this.controls.score?.valid
    );
  }

  validate(): void {
    this.markAsTouched();
    if (this.isValid() === false) return;
    this.validateClicked.emit();
  }

  displaySelectedThemes(): string | undefined {
    return this.controls?.themes?.value
      ?.map((theme: Theme) => theme.name)
      .join(' - ');
  }

  patchThemes(newThemes: Theme[]): void {
    const themes = this.controls?.themes;
    themes?.patchValue(newThemes);
    themes?.markAsDirty();
  }
}
