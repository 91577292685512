import {
  AbstractControl,
  FormArray,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import { AnswerChoicesForm } from './answerChoicesForm.model';

export function hasIsCorrect(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const answerChoicesControl = isCorrectAnswerChoiceForm(control);

    const hasIsCorrect = answerChoicesControl.value.reduce((acc, current) => {
      if (current.correct === true) return acc + 1;

      return acc;
    }, 0);

    return hasIsCorrect <= 0
      ? { hasIsCorrect: { value: control.value } }
      : null;
  };
}

function isCorrectAnswerChoiceForm(
  control: AbstractControl,
): FormArray<FormGroup<AnswerChoicesForm>> {
  if (!(control instanceof FormArray))
    throw new Error(
      'hasIsCorrect requires a FormArray<FormGroup<AnswerChoicesForm>> control',
    );

  if (control.controls.some((c) => !(c instanceof FormGroup)))
    throw new Error(
      'FormArray contains invalid controls. It should only contain FormGroups.',
    );

  const currentControl = control as FormArray<FormGroup>;

  currentControl.value.forEach((current) => {
    if (!isValidAnswerChoicesForm(current))
      throw new Error(
        'hasIsCorrect requires a FormArray<FormGroup<AnswerChoicesForm>> control',
      );
  });

  return control as FormArray<FormGroup<AnswerChoicesForm>>;
}

function isValidAnswerChoicesForm(value: unknown): value is AnswerChoicesForm {
  if (typeof value !== 'object' || value === null) return false;
  for (const key of Object.keys(value)) if (!(key in value)) return false;
  return true;
}
