@if(control()) {
  <div class="grid grid-nogutter">
    <pf-form-label-wrapper [showErrors]="showErrors(form(), controlName())" class="col-12">
      <ng-template #errors>
        <pf-form-error
          *ngIf="control()?.errors?.['required']"
          label="L'énoncé est un champs obligatoire. Veuillez le renseigner."
        ></pf-form-error>
      </ng-template>
    </pf-form-label-wrapper>
    <div class="col-12">
      <input
        class="w-full"
        pInputText
        [id]="controlName()"
        type="text"
        [formControl]="$any(control())"
        placeholder="Rédiger en langue française"
      />
    </div>
  </div>
}
