<div class="grid">
  <app-breadcrumb [items]="breadcrumbs"></app-breadcrumb>
</div>

<h1>Thèmes</h1>
<p-treeTable
  [value]="treeNodes"
  [tableStyle]="{ 'min-width': '50rem' }"
  [columns]="cols"
  [resizableColumns]="true"
>
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th scope="col">Thèmes et questions</th>
      <th scope="col"></th>
      <th scope="col"></th>
      <th scope="col"></th>
    </tr>
    <tr>
      @for (col of columns; track col.field) {
        <th [ttSortableColumn]="col.field">
          {{ col.header }}
          <p-treeTableSortIcon [field]="col.field" />
        </th>
      }
    </tr>
  </ng-template>
  <ng-template
    pTemplate="body"
    let-rowNode
    let-rowData="rowData"
    let-columns="columns"
  >
    <tr [ttRow]="rowNode">
      @for (col of columns; track col.field; let idx = $index) {
        <td>
          <div class="flex align-items-baseline gap-2">
            @if (idx === 0) {
              <p-treeTableToggler [rowNode]="rowNode" />
            }
            @if (idx === 3) {
              <span>
                {{ rowData[col.field] | percent: "1.0-2" }}
              </span>
            } @else {
              <span>
                {{ rowData[col.field] }}
              </span>
            }
          </div>
        </td>
      }
    </tr>
  </ng-template>
</p-treeTable>
