<div class="section section-stats h-full flex flex-column">

  <div class="flex align-items-center gap-2 mb-4">
    <div class="flex border-round-xl w-2rem h-2rem bg-blue-gray-200 text-primary p-1 justify-content-center align-items-center">
      <i class="pi pi-check-circle"></i>
    </div>
    <h3 class="my-0 text-xl font-medium">
      Nombre d'examens
    </h3>
  </div>
  <span class="my-0 font-light text-gray-500">
    {{ this.category()?.name ?? "Toutes les catégories" }}
  </span>

  <div class="text-blue-gray-800">
    <h4 class="my-4 font-medium text-6xl">
      @if(loading){ <p-skeleton width="4rem" /> }
      @else { {{ exams.total }} }
    </h4>

    <div>
      @if(loading){ <p-skeleton /> }
      @else {
        {{ exams.totalSuccessfullyPassed | pluralize:"examen obtenu" : "examens obtenus"}}
      }

    </div>
  </div>
</div>
