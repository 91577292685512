import { HttpClient } from '@angular/common/http';
import { Injectable, signal } from '@angular/core';
import { Router } from '@angular/router';
import { UserExamSessionStatusDTO } from '@app/models/exam/examSession.model';
import { UserExamResults } from '@app/models/exam/userExamResults.model';
import { UserExamSession } from '@app/models/exam/userExamSession.model';
import { AnswerChoices } from '@app/models/question/answerChoices.model';
import { QuestionDetail } from '@app/models/question/question-detail.model';
import { CandidateRoute } from '@app/utils/routes';
import { environement } from '@environments/environment';
import { Category } from '@models/question/category.model';
import { BaseService } from '@services/base.service';
import { MessageService } from 'primeng/api';
import { BehaviorSubject, Observable, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserExamSessionService extends BaseService {
  signalCategory = signal<Category | null>(null);
  signalQuestions = signal<QuestionDetail[] | null>(null);
  signalResults = signal<UserExamResults | null>(null);
  signalCurrentAnswers = signal<AnswerChoices[]>([]);
  signalUserExamSession = signal<UserExamSessionStatusDTO | undefined>(
    undefined,
  );
  signalCurrentQuestion = signal<QuestionDetail | null>(null);

  private isAuthenticatedSubject = new BehaviorSubject<boolean>(false);
  public isAuthenticated$ = this.isAuthenticatedSubject.asObservable();

  constructor(
    protected override http: HttpClient,
    protected override messageService: MessageService,
    public override router: Router,
  ) {
    super(http, messageService);
  }

  login(): void {
    this.isAuthenticatedSubject.next(true);
    this.router.navigate([CandidateRoute.Home]);
  }

  checkAuthentication(): Observable<boolean> {
    return this.http.get<boolean>(
      `${environement.BACKEND_URL}/custom-user-exam-session/check-authentication`,
    );
  }

  public getUserExamSessionByPinAndProviderId(
    pin: string,
    providerId: string,
  ): Observable<UserExamSessionStatusDTO> {
    return this.executeRequest(
      this.http.get<UserExamSessionStatusDTO>(
        `${environement.BACKEND_URL}/custom-user-exam-session/${pin}_${providerId}`,
      ),
    ).pipe(
      tap((userExamSession) => this.signalUserExamSession.set(userExamSession)),
    );
  }

  getExamCategory(examSessionId: string): Observable<Category> {
    return this.executeRequest(
      this.http.get<Category>(
        `${environement.BACKEND_URL}/exam_session/${examSessionId}/category`,
      ),
    ).pipe(tap((category) => this.signalCategory.set(category)));
  }

  getQuestions(
    examSessionId: string,
    providerId: string,
  ): Observable<QuestionDetail[]> {
    return this.executeRequest(
      this.http.get<QuestionDetail[]>(
        `${environement.BACKEND_URL}/custom-user-exam-session/questions/${examSessionId}_${providerId}`,
      ),
    ).pipe(tap((questions) => this.signalQuestions.set(questions)));
  }

  getTrainingQuestions(
    examSessionId: string,
    providerId: string,
  ): Observable<QuestionDetail[]> {
    return this.executeRequest(
      this.http.get<QuestionDetail[]>(
        `${environement.BACKEND_URL}/custom-user-exam-session/training-questions/${examSessionId}_${providerId}`,
      ),
    ).pipe(tap((questions) => this.signalQuestions.set(questions)));
  }

  getTrainingAnswers(
    examSessionId: string,
    providerId: string,
  ): Observable<AnswerChoices[]> {
    return this.executeRequest(
      this.http.get<AnswerChoices[]>(
        `${environement.BACKEND_URL}/custom-user-exam-session/training-answer-choices/${examSessionId}_${providerId}`,
      ),
    ).pipe(tap((answers) => this.signalCurrentAnswers.set(answers)));
  }

  getCorrection(
    examSessionId: string,
    providerId: string,
  ): Observable<UserExamResults> {
    return this.executeRequest(
      this.http.get<UserExamResults>(
        `${environement.BACKEND_URL}/custom-user-exam-session/correction/${examSessionId}_${providerId}`,
      ),
    ).pipe(tap((correction) => this.signalResults.set(correction)));
  }

  saveAnswers(
    examSessionId: string,
    providerId: string,
    questionId: string,
    body: string[],
  ): Observable<AnswerChoices[]> {
    return this.executeRequest(
      this.http.post<AnswerChoices[]>(
        `${environement.BACKEND_URL}/custom-user-exam-session/new-answer/${examSessionId}_${providerId}_${questionId}`,
        body,
      ),
    );
  }

  updateStatusConfirmed(examSessionId: string, providerId: string): void {
    this.executeRequest(
      this.http.get<UserExamSession>(
        `${environement.BACKEND_URL}/custom-user-exam-session/update-status-confirmed/${examSessionId}_${providerId}`,
      ),
    ).subscribe();
  }

  updateStatusFinishedTraining(
    examSessionId: string,
    providerId: string,
  ): void {
    this.executeRequest(
      this.http.get<UserExamSession>(
        `${environement.BACKEND_URL}/custom-user-exam-session/update-status-finished-training/${examSessionId}_${providerId}`,
      ),
    ).subscribe();
  }

  updateStatusFinishedExam(
    examSessionId: string,
    providerId: string,
  ): Observable<UserExamSession> {
    return this.executeRequest(
      this.http.get<UserExamSession>(
        `${environement.BACKEND_URL}/custom-user-exam-session/update-status-finished-exam/${examSessionId}_${providerId}`,
      ),
    );
  }

  updateStatusFinishedExplanation(
    examSessionId: string,
    providerId: string,
  ): Observable<UserExamSession> {
    return this.executeRequest(
      this.http.get<UserExamSession>(
        `${environement.BACKEND_URL}/custom-user-exam-session/update-status-finished-explanation/${examSessionId}_${providerId}`,
      ),
    );
  }

  updateStatusFinishedTrainingExamples(
    examSessionId: string,
    providerId: string,
  ): Observable<UserExamSession> {
    return this.executeRequest(
      this.http.get<UserExamSession>(
        `${environement.BACKEND_URL}/custom-user-exam-session/update-status-finished-training-examples/${examSessionId}_${providerId}`,
      ),
    );
  }

  updateLastSavedQuestionId(
    examSessionId: string,
    providerId: string,
    questionId: string,
  ): Observable<UserExamSession> {
    return this.executeRequest(
      this.http.get<UserExamSession>(
        `${environement.BACKEND_URL}/custom-user-exam-session/update-last-saved-question-id/${examSessionId}_${providerId}/${encodeURIComponent(questionId)}`,
      ),
    );
  }

  getQuestion(id: string): Observable<QuestionDetail> {
    return this.executeRequest(
      this.http.get<QuestionDetail>(
        `${environement.BACKEND_URL}/custom-user-exam-session/question/${id}`,
      ),
    ).pipe(
      tap((val) => {
        this.signalCurrentQuestion.set(val);
      }),
    );
  }

  setCurrentQuestion(question: QuestionDetail | null): void {
    this.signalCurrentQuestion.set(question);
  }
}
