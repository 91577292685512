import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { AnswerChoices } from './answerChoices.model';

export type AnswerChoicesForm = {
  [key in keyof AnswerChoices]: AbstractControl<AnswerChoices[key] | null>;
};

export const defaultAnswerChoice = (specificRank: number): AnswerChoices => {
  return {
    id: '',
    description: '',
    correct: false,
    rank: specificRank,
  };
};

export const initAnswerChoiceControl = (
  fb: FormBuilder,
  specificRank: number = 0,
  answer: AnswerChoices = defaultAnswerChoice(specificRank),
): FormGroup<AnswerChoicesForm> => {
  return fb.group<AnswerChoicesForm>({
    id: fb.control(answer.id !== '' ? answer.id : null),
    description: fb.control(answer.description, Validators.required),
    correct: fb.control(answer.correct),
    type: fb.control(answer.type),
    rank: fb.control(answer.rank),
    voiceSound: fb.control(answer.voiceSound),
  });
};
