<div class="grid">
  <app-breadcrumb [items]="breadcrumbs"></app-breadcrumb>
</div>

<h1>Statistiques</h1>

<app-statistics-flagged-questions-table />

<div class="grid justify-content-end py-4">
  <div *ngIf="form" [formGroup]="form">
    <p-dropdown
      formControlName="selectedCategory"
      [options]="categoriesList"
      optionLabel="name"
      [showClear]="true"
      placeholder="Choisir une catégorie"
    >
    </p-dropdown>
  </div>
</div>

<div class="grid">
  <app-user-exam-by-category class="col-4" />
  <app-exam-by-category [category]="controls?.selectedCategory?.value" class="col-4" />
  <app-questions-by-themes class="col-4" />
</div>

<div class="flex justify-content-end my-4">
  <pf-action-button
    type="previous"
    customIconClass=" "
    styleClass="p-button-tertiary border-none text-sm justify-content-start"
    label="Voir toutes les questions"
    [routerLink]="'/' + AdminRoute.StatsQuestions"
  ></pf-action-button>
</div>

<app-statistics-questions-table [MAX_ROW]="4" [category]="controls?.selectedCategory?.value" />
