<div class="grid">
  <app-breadcrumb [items]="breadcrumbs"></app-breadcrumb>
</div>

<h1>Log Utilisateurs</h1>

<pf-table
  [response]="audits()"
  [isLazyMode]="true"
  [columns]="columns"
  noResultLabel="Aucune action n'a été réalisée"
  (onParametersChanged)="onParametersChangedHandler($event)"
  [rows]="LIMIT_ROW_TABLE"
  [displaySkeleton]="true"
  [showCaption]="true"
  [showSearch]="false"
  dataKey="id"
  title="Liste des actions"
  [rowsPerPageOptions]="[10, 20]"
  styleClass="mb-5"
>
  <ng-template #content let-audit>
    <tr>
      <td>{{ agentName(audit) }}</td>
      <td>Le {{ audit?.createdDate | date: "dd/MM/yyyy à H:mm" }}</td>
      <td>
        <div class="flex">
          <app-archives-logs-users-action-badge
            [action]="audit.action"
          ></app-archives-logs-users-action-badge>
        </div>
      </td>
      <td>{{ displayAuditableType(audit?.auditableType) }}</td>
      <td>
        <span
          class="underline cursor-pointer"
          [routerLink]="
            displayAuditableAction(audit?.auditableType, audit?.action)?.url?.(
              audit?.auditableId
            )
          "
        >
          {{
            displayAuditableAction(audit?.auditableType, audit?.action)
              ?.label ?? audit?.auditableId
          }}
        </span>
      </td>
    </tr>
  </ng-template>
</pf-table>
