import { Component } from '@angular/core';
import {
  BreadcrumbComponent,
  BreadcrumbItem,
} from '@components/common/breadcrumb/breadcrumb.component';
import { AdminRoute, RouteLabel } from '@utils/routes';
import { PfActionButtonComponent } from 'pf-ui';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-archives',
  standalone: true,
  imports: [BreadcrumbComponent, PfActionButtonComponent, RouterLink],
  templateUrl: './archives.component.html',
})
export class ArchivesComponent {
  breadcrumbs: BreadcrumbItem[] = [
    { label: RouteLabel.Home, route: AdminRoute.Home },
    { label: RouteLabel.Archives },
  ];

  constructor() {}

  get seriesArchivesDownloadedSeries(): string {
    return `/${AdminRoute.SeriesArchivesDownloadedSeries}`;
  }

  getLogUserRoute(): string {
    return `/${AdminRoute.SeriesArchivesLogsUsers}`;
  }

  protected readonly AdminRoute = AdminRoute;
}
