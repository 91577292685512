import { HttpClient } from '@angular/common/http';
import { Injectable, signal } from '@angular/core';

import { Role } from '@app/models/user/role.model';
import { environement } from '@environments/environment';
import { MessageService } from 'primeng/api';
import { Observable, tap } from 'rxjs';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root',
})
export class RoleService extends BaseService {
  signalList = signal<Role[]>([]);

  constructor(
    protected override http: HttpClient,
    protected override messageService: MessageService,
  ) {
    super(http, messageService);
  }

  list(): Observable<Role[]> {
    return this.executeRequest(
      this.http.get<Role[]>(`${environement.BACKEND_URL}/role`),
    ).pipe(
      tap((response) => {
        this.signalList.set(response);
      }),
    );
  }
}
