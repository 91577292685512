import { CommonModule } from '@angular/common';
import {
  Component,
  computed,
  effect,
  input,
  OnDestroy,
  OnInit,
  output,
  signal,
  untracked,
} from '@angular/core';
import {
  checkMissingSound,
  QuestionDetail,
} from '@app/models/question/question-detail.model';

import { SpinnerComponent } from '@app/components/common/spinner/spinner.component';
import { UserExamSession } from '@app/models/exam/userExamSession.model';
import { AuthService } from '@app/services/auth.service';
import { ExamSessionService } from '@app/services/examSession.service';
import { MediaService } from '@app/services/media.service';
import { UserExamSessionService } from '@app/services/userExamSession.service';
import { correctQuestion } from '@app/utils/questions/correction';
import { numberToString } from '@app/utils/stringHelper';
import { getQuestionDurationInMs } from '@app/utils/timer';
import {
  SpecificConfirmDialogComponent,
  SpecificConfirmDialogData,
} from '@components/dialogs/specific-confirm-dialog/specific-confirm-dialog.component';
import { generateDecreasingPercentages } from '@utils/circleCountdownHelper';
import {
  AlertSeverity,
  PfActionButtonComponent,
  PfAlertComponent,
  PfLicenceBarComponent,
} from 'pf-ui';
import { ButtonModule } from 'primeng/button';
import { DialogService } from 'primeng/dynamicdialog';
import { SkeletonModule } from 'primeng/skeleton';
import { EMPTY, forkJoin, Observable, of, Subscription, switchMap } from 'rxjs';
import { QuestionCorrectionAnswersComponent } from '../question-correction-answers/question-correction-answers.component';
import { QuestionPreviewAnswersComponent } from '../question-preview-answers/question-preview-answers.component';
import { QuestionPreviewImageComponent } from './question-preview-image/question-preview-image.component';

@Component({
  selector: 'app-question-preview',
  standalone: true,
  imports: [
    ButtonModule,
    CommonModule,
    PfActionButtonComponent,
    PfLicenceBarComponent,
    SkeletonModule,
    QuestionPreviewAnswersComponent,
    QuestionPreviewImageComponent,
    QuestionCorrectionAnswersComponent,
    SpinnerComponent,
    PfAlertComponent,
  ],
  templateUrl: './question-preview.component.html',
  styleUrl: './question-preview.component.scss',
})
export class QuestionPreviewComponent implements OnDestroy, OnInit {
  isSoundDisabled = input<boolean>(false);
  displayCorrection = input<boolean>(false);
  displayDetails = input<boolean>();
  imageContainerHeight = input<string>('25vh');
  trainingQuestions = input<boolean>(false);
  currentQuestionIndex = input<number>(5);
  question = input<QuestionDetail | null>();
  questionTotal = input<number>();

  selectAnswers = output<string[]>();
  goToNextQuestion = output();

  answersSelected = signal<{ [key: number]: string }>({});
  secondAnswersSelected = signal<{ [key: number]: string }>({});
  examSession = this.examSessionService.signalExamSession;
  userExamSession = this.userExamSessionService.signalUserExamSession;

  staticVoiceSound = this.mediaService.signalStaticVoices;
  loading = signal<boolean>(false);
  isSoundReplayed = signal<boolean>(false);
  isSoundMissing = signal<boolean>(false);

  userAnswerIds = computed(() =>
    this.userExamSessionService.signalCurrentAnswers()?.map((ac) => ac?.id),
  );

  getQuestionDurationInMs = getQuestionDurationInMs;
  numberToString = numberToString;

  audio?: HTMLAudioElement;
  currentAudioIndex = 0;
  mediaUrls: (string | undefined)[] = [];
  startTimer = false;
  allSelectedAnswers: string[] = [];
  providerId?: string;
  isQuestionCorrect: boolean = true;
  updateLastSavedQuestionIdSub: Subscription | undefined;

  constructor(
    private authService: AuthService,
    private examSessionService: ExamSessionService,
    public mediaService: MediaService,
    private userExamSessionService: UserExamSessionService,
    private dialog: DialogService,
  ) {
    if (this.authService.checkCandidateToken()) {
      this.providerId =
        this.authService.getProviderIdAndSessionCode().providerId;
    }
    effect(() => {
      if (this.displayDetails() !== true || this.loading()) return;
      const question = this.question();
      const answerIds = this.userAnswerIds();
      if (question == null) return;

      this.allSelectedAnswers = [];

      untracked(() => {
        if (!this.displayCorrection()) return;
        this.isQuestionCorrect = correctQuestion(answerIds, question);
      });
    });

    effect(
      () => {
        const isSoundDisabled = this.isSoundDisabled();
        if (isSoundDisabled) return;

        const question = this.question();
        if (question == null) return;
        const voices = this.staticVoiceSound();
        if (!voices.length) return;
        untracked(() => {
          this.playSound(question);
        });
      },
      { allowSignalWrites: true },
    );
  }

  ngOnInit(): void {
    if (
      this.staticVoiceSound() == null ||
      this.staticVoiceSound().length === 0
    ) {
      this.loading.set(true);
      this.mediaService
        .fetchStaticVoices()
        .subscribe(() => this.loading.set(false));
    }
  }

  /** SOUND */
  addSoundToMediaUrls(
    voiceSoundId: string | undefined | null,
  ): Observable<string> {
    if (voiceSoundId === undefined || voiceSoundId === null) {
      return EMPTY;
    }
    return this.mediaService.getAudio(voiceSoundId);
  }

  setMediaUrls(question: QuestionDetail): Observable<string>[] {
    const audioObservables$: Observable<string>[] = [];

    this.mediaUrls = [];
    // be careful with signals, if this method is
    // used in an effect, the signal will also respond here
    // we need to omit currentQuestionIndex changes there
    // to preserve precharging and good unfolding of the correction
    const currentQuestionIndex = untracked(() => this.currentQuestionIndex());
    if (this.displayCorrection()) {
      const sound =
        this.mediaService.getCorrectionIntroStaticVoices(currentQuestionIndex);
      if (sound !== undefined) {
        audioObservables$.push(this.addSoundToMediaUrls(sound.id));
      } else {
        console.error(
          'No sound found for question number',
          currentQuestionIndex,
        );
      }

      if (question.correctionVoiceSound?.id == null) {
        return [];
      }

      audioObservables$.push(
        this.addSoundToMediaUrls(question.correctionVoiceSound.id),
      );

      return audioObservables$;
    }

    let atLeastOneSoundMissing = checkMissingSound(question);

    if (atLeastOneSoundMissing) {
      return [];
    }

    audioObservables$.push(this.addSoundToMediaUrls(question.voiceSound?.id));

    if (this.staticVoiceSound().length) {
      question.answerChoices?.forEach((answer, index) => {
        const voiceSound = this.staticVoiceSound()[index];
        atLeastOneSoundMissing =
          atLeastOneSoundMissing ||
          voiceSound === undefined ||
          voiceSound === null;
        audioObservables$.push(this.addSoundToMediaUrls(voiceSound?.id));
        audioObservables$.push(this.addSoundToMediaUrls(answer.voiceSound?.id));
      });

      if (question.secondVoiceSound != null) {
        audioObservables$.push(
          this.addSoundToMediaUrls(question.secondVoiceSound.id),
        );
      }

      question.secondAnswerChoices?.forEach((answer, index) => {
        const voiceSound = this.staticVoiceSound()[index];
        atLeastOneSoundMissing =
          atLeastOneSoundMissing ||
          voiceSound === undefined ||
          voiceSound === null;
        audioObservables$.push(this.addSoundToMediaUrls(voiceSound.id));
        audioObservables$.push(this.addSoundToMediaUrls(answer.voiceSound?.id));
      });
    } else {
      atLeastOneSoundMissing = true;
    }

    if (atLeastOneSoundMissing) {
      return [];
    }
    return audioObservables$;
  }

  playAudio(index: number): void {
    if (this.audio) {
      // On pause volontairement l'audio en cours
      this.audio?.pause();
      this.audio.removeEventListener('ended', this.onAudioEnded);
      this.audio.remove();
    }

    this.currentAudioIndex = index;
    this.audio = new Audio(this.mediaUrls[this.currentAudioIndex]);
    this.audio.muted = false;
    this.audio.addEventListener('ended', this.onAudioEnded.bind(this));
    this.audio.play().catch((error) => {
      console.error('Error playing the sound:', error);
      // SI l'erreur correspond au code 20 (PAUSE volontaire de l'audio), on n'ouvre pas la modale car l'erreur a été provoquée pour pallier à un double rendu non souhaité du composant
      // SI l'erreur correspond au code 9 (coupure internet) on ne veut pas non plus afficher la modale de reprise
      if (![20, 9].includes(error.code)) {
        this.openConfirmReconnection();
      }
    });
  }

  onAudioEnded(): void {
    const newIndex = this.currentAudioIndex + 1;
    if (newIndex >= this.mediaUrls.length) {
      // En mode replay on ne déclenche pas le timer à la fin de l'audio
      if (!this.isSoundReplayed()) {
        this.startTimer = true;
      }
      if (this.audio === undefined) return;
      //We need to remove the last event listener
      this.audio.removeEventListener('ended', this.onAudioEnded);
      this.audio.remove();
      // On libère la référence en mémoire
      this.audio = undefined;
      return;
    }
    this.playAudio(newIndex);
  }

  playSound(question?: QuestionDetail | null): void {
    if (question === null || question === undefined) return;

    const mediaUrls$ = this.setMediaUrls(question);

    if (!mediaUrls$.length) {
      this.startTimerWithMissingSound();
    }

    forkJoin(mediaUrls$).subscribe(([...urls]) => {
      this.mediaUrls = [...urls];
      this.playAudio(0);
    });
  }

  startTimerWithMissingSound(): undefined {
    this.startTimer = true;
    console.info('No voice sound id, start timer');
    this.isSoundMissing.set(true);
    return;
  }

  replaySound(): void {
    // On initialise le Mode replay (flag pour disabled le button de soundReplay et ne pas redéclencher startTimer dans onAudioEnded())
    this.isSoundReplayed.set(true);
    // On rejoue le son apres s'être assure que la question est tjrs disponible
    const question = this.question();
    if (question == null) return;
    this.playAudio(0);

    // On disabled (change css=== pas de cursor) ou on change l'icône du bouton de replaySound
  }

  /** QUESTIONS / ANSWERS */
  get questionsTotal(): number {
    return this.questionTotal() ?? 0;
  }

  toggleAnswerSelection(
    answers: Record<number, string>,
    answerIndex: number,
    answerId: string,
  ): { [key: number]: string } {
    const isAnswerSelected = answers[answerIndex] === answerId;

    if (isAnswerSelected) {
      delete answers[answerIndex];
    } else {
      answers[answerIndex] = answerId;
    }

    return answers;
  }

  isSecondQuestionAnswers(): boolean {
    return (this.question()?.secondAnswerChoices?.length ?? 0) > 0;
  }

  get firstSelectedAnswers(): string[] {
    return Object.values(this.answersSelected());
  }

  get secondSelectedAnswers(): string[] {
    return Object.values(this.secondAnswersSelected());
  }

  saveAnswers(): Observable<UserExamSession | undefined> {
    const selectedAnswerIds = [
      ...this.firstSelectedAnswers,
      ...this.secondSelectedAnswers,
    ];
    const examSessionId = this.examSession()?.id;
    const currentQuestion = this.question();

    if (examSessionId == null || this.providerId == null || !currentQuestion)
      return of(undefined);

    return this.userExamSessionService
      .saveAnswers(
        examSessionId,
        this.providerId,
        currentQuestion.id,
        selectedAnswerIds,
      )
      .pipe(
        switchMap(() => {
          if (this.providerId != null) {
            return this.userExamSessionService.updateLastSavedQuestionId(
              examSessionId,
              this.providerId,
              currentQuestion.id,
            );
          }
          return of(undefined);
        }),
      );
  }

  get severity(): AlertSeverity {
    return this.isQuestionCorrect ? 'success' : 'error';
  }

  get correctionTitle(): string {
    return this.isQuestionCorrect ? 'Réponse correcte' : 'Réponse fausse';
  }

  countdownColorTime = computed(() =>
    generateDecreasingPercentages(
      this.question()?.duration ?? 30_000 / 1000,
      4,
    ),
  );

  removeCurrentAudioInfo(): void {
    this.audio?.pause();
    this.audio?.removeEventListener('ended', this.onAudioEnded);
    this.audio?.remove();
    this.audio = undefined;
    this.currentAudioIndex = 0;
    this.mediaUrls.forEach((url) => URL.revokeObjectURL(url ?? ''));
    this.isSoundReplayed.set(false);
  }

  /** onCountDownCompleted event */
  onTimeOver(): void {
    if (this.question() == null) return;

    let obs: Observable<UserExamSession | undefined> = of(undefined);

    if (!this.displayCorrection()) {
      // we don't need to save the answers if we are in correction mode
      obs = obs.pipe(switchMap(() => this.saveAnswers()));
    }

    obs.subscribe(() => {
      // reset answer choices
      this.answersSelected.set({});
      this.secondAnswersSelected.set({});

      this.startTimer = false;

      this.removeCurrentAudioInfo();

      this.goToNextQuestion.emit();
    });
  }

  openConfirmReconnection(): void {
    const dialog = this.dialog.open(SpecificConfirmDialogComponent, {
      data: {
        labelHtml:
          "Ta session a rencontré un problème de connexion, clique sur le bouton ci-dessous pour reprendre l'examen",
        title: "Reprendre l'examen",
        confirmLabel: 'Confirmer',
        cancelLabel: 'Annuler',
        displayCancelButton: false,
      } satisfies SpecificConfirmDialogData,
      styleClass: 'pf-two-column-form-modal',
      showHeader: false,
    });

    dialog?.onClose?.subscribe(() => {
      this.playAudio(0);
    });
  }

  ngOnDestroy(): void {
    this.updateLastSavedQuestionIdSub?.unsubscribe();
    this.removeCurrentAudioInfo();
  }
}
