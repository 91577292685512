import { Component, effect, signal } from '@angular/core';
import {
  BreadcrumbComponent,
  BreadcrumbItem,
} from '@components/common/breadcrumb/breadcrumb.component';
import { AdminRoute, RouteLabel } from '@utils/routes';
import { NgForOf, NgIf } from '@angular/common';
import { DEFAULT_MAX_FILE_SIZE } from '@utils/constants';
import { CSV_EXAM_SESSION_COLUMNS } from '@utils/CSV';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { PaginatorModule } from 'primeng/paginator';
import {
  PfActionButtonComponent,
  PfCardComponent,
  PfFormComponent,
  PfFormErrorComponent,
  PfFormFileUploadComponent,
  PfFormLabelWrapperComponent,
} from 'pf-ui';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'primeng/api';
import { TableModule } from 'primeng/table';
import { CategoryService } from '@services/category.service';
import { AbstractFormComponent } from '@components/common/abstract/form/abstract-form.component';
import { Category, CategoryForm } from '@models/question/category.model';
import { SeriesService } from '@services/series.service';
import { downloadFile } from '@utils/files';
import { toLocaleDateString } from '@utils/dateHelper';

@Component({
  selector: 'app-series',
  standalone: true,
  imports: [
    BreadcrumbComponent,
    NgIf,
    CalendarModule,
    DropdownModule,
    NgForOf,
    PaginatorModule,
    PfActionButtonComponent,
    PfCardComponent,
    PfFormErrorComponent,
    PfFormFileUploadComponent,
    PfFormLabelWrapperComponent,
    ReactiveFormsModule,
    SharedModule,
    TableModule,
    PfFormComponent,
  ],
  templateUrl: './series.component.html',
  styleUrl: './series.component.scss',
})
export class SeriesComponent extends AbstractFormComponent {
  categories = this.categoryService.signalList;
  loading = signal<boolean>(false);

  breadcrumbs: BreadcrumbItem[] = [
    { label: RouteLabel.Home, route: AdminRoute.Home },
    { label: RouteLabel.SeriesGenerate },
  ];

  public downloadFile = downloadFile;

  form: FormGroup<CategoryForm> | undefined;
  selectedCategory: Category | null = null;

  protected readonly DEFAULT_MAX_FILE_SIZE = DEFAULT_MAX_FILE_SIZE;
  protected readonly columns = CSV_EXAM_SESSION_COLUMNS;

  constructor(
    private categoryService: CategoryService,
    private seriesService: SeriesService,
    private fb: FormBuilder,
  ) {
    super();
    this.categoryService.list().subscribe();

    effect(() => {
      this.form = this.initForm(this.fb);
    });
  }

  initForm(fb?: FormBuilder): FormGroup<CategoryForm> | undefined {
    if (!fb) return;

    return fb.group<CategoryForm>({
      selectedCategory: fb.control(this.selectedCategory),
    });
  }

  get isCategorySelected(): boolean {
    return this.category !== null && this.category !== undefined;
  }

  public download(): void {
    const category = this.category;

    if (category && !this.loading()) {
      this.loading.set(true);
      this.seriesService.downloadSeries(category.id).subscribe({
        next: (val) => {
          this.downloadFile(val, this.filename);
          this.loading.set(false);
        },
        error: () => this.loading.set(false),
        complete: () => this.loading.set(false),
      });
    }
  }

  get filename(): string {
    return (
      toLocaleDateString(new Date()) +
      '_' +
      (this.category?.name ?? '') +
      '.zip'
    ).replaceAll(' ', '_');
  }

  get category(): Category | null | undefined {
    return this.form?.controls.selectedCategory.value;
  }
}
