import { AbstractControl } from '@angular/forms';

export type Category = {
  id: string;
  name: string;
  numberOfQuestions: number;
  requiredCorrectQuestions: number;
};

export type CategoryList = {
  category: Category[];
};

export interface CategoryForm {
  selectedCategory: AbstractControl<Category | null>;
}

export function mapCategoryList(categoryList?: Category[]): Category[] {
  return (
    categoryList?.map((category) => {
      return {
        id: category.id,
        name: category.name,
        numberOfQuestions: category.numberOfQuestions,
        requiredCorrectQuestions: category.requiredCorrectQuestions,
      } satisfies Category;
    }) ?? []
  );
}
