import { Component, effect } from '@angular/core';
import { AdminRoute, RouteLabel } from '@utils/routes';
import {
  BreadcrumbComponent,
  BreadcrumbItem,
} from '@components/common/breadcrumb/breadcrumb.component';
import {
  PfActionButtonComponent,
  PfAlertComponent,
  PfTableComponent,
  TableColumn,
} from 'pf-ui';
import { QuestionService } from '@services/question.service';
import { NgForOf, NgIf, PercentPipe } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { PaginatorModule } from 'primeng/paginator';
import { TableModule } from 'primeng/table';
import { TagModule } from 'primeng/tag';
import { QuestionDetail } from '@models/question/question-detail.model';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { SpinnerComponent } from '@components/common/spinner/spinner.component';
import {
  QuestionListDialogComponent,
  QuestionListDialogData,
} from '@components/dialogs/question-list-dialog/question-list-dialog.component';
import { TruncatePipeModule } from '@app/pipes/truncate.pipe';
import { TooltipModule } from 'primeng/tooltip';
import { Observable, of } from 'rxjs';
import { MessageService } from 'primeng/api';
import { AbstractEditableColumn } from '@components/common/abstract/table/editable/abstractEditableColumn';
import { MAX_QUESTION_DESCRIPTION_LENGTH } from '@pages/questions/questions.component';
import { ViewQuestionComponent } from '@components/dialogs/view-question/view-question.component';
import { ThumbnailComponent } from '@components/medias/thumbnail/thumbnail.component';
import { AgentService } from '@services/agent.service';
import { hasAuthority } from '@models/user/agent-detail.model';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { Question } from '@models/question/question.model';
import { joinArray } from '@utils/stringHelper';

@Component({
  selector: 'app-questions',
  standalone: true,
  templateUrl: './training-questions.component.html',
  imports: [
    BreadcrumbComponent,
    PfTableComponent,
    NgForOf,
    ButtonModule,
    InputTextModule,
    NgIf,
    PaginatorModule,
    TableModule,
    TagModule,
    PfActionButtonComponent,
    SpinnerComponent,
    PfAlertComponent,
    PercentPipe,
    TruncatePipeModule,
    TooltipModule,
    ThumbnailComponent,
    InputTextareaModule,
  ],
})
export class TrainingQuestionsComponent extends AbstractEditableColumn<QuestionDetail> {
  loading: boolean = false;

  AddNewModalDialogRef: DynamicDialogRef = new DynamicDialogRef();

  trainingQuestions = this.questionService.signalTrainingQuestions;

  editableItems: QuestionDetail[] | undefined;

  columns: TableColumn[] = [
    { name: 'Ennoncé' },
    { name: 'Média' },
    { name: 'Correction' },
    { name: '' },
  ];

  breadcrumbs: BreadcrumbItem[] = [
    { label: RouteLabel.Home, route: AdminRoute.Home },
    { label: RouteLabel.Business, route: AdminRoute.Business },
    { label: RouteLabel.TrainingQuestions },
  ];

  agent = this.agentService.signalCurrent;

  questions: string[] | undefined = undefined;
  rowsLength: number = this.questions?.length ?? 3;

  constructor(
    public questionService: QuestionService,
    public override dialog: DialogService,
    public override messageService: MessageService,
    public agentService: AgentService,
  ) {
    super(messageService, dialog);
    effect(() => {
      this.loadTrainingQuestions();
    });
  }

  get canShow(): boolean {
    return hasAuthority(this.agent(), 'question.view');
  }

  get canEdit(): boolean {
    return hasAuthority(this.agent(), 'question.edit');
  }

  loadTrainingQuestions(): void {
    this.questionService.getAllTrainingQuestions().subscribe(() => {
      this.editableItems = this.trainingQuestions() ?? [];
    });
  }

  addTrainingQuestion(): void {
    if (this.loading) return;

    this.AddNewModalDialogRef = this.dialog.open(QuestionListDialogComponent, {
      data: {
        trainingQuestions: this.editableItems,
      } satisfies QuestionListDialogData,
      styleClass: 'pf-two-column-form-modal',
      showHeader: false,
    });

    this.AddNewModalDialogRef.onClose.subscribe((questions?: string[]) => {
      if (questions != undefined) {
        questions.forEach((questionId: string) => {
          this.editableItems = [];
          this.questionService.get(questionId).subscribe((val) => {
            this.editableItems?.push(val);
          });
        });
      }
    });
  }

  public openViewQuestionModal(question: QuestionDetail): void {
    this.dialog.open(ViewQuestionComponent, {
      data: {
        question,
      },
      styleClass: 'pf-two-column-form-modal',
      showHeader: false,
    });
  }

  delete(question: QuestionDetail): Observable<QuestionDetail> {
    return this.questionService.patch({ ...question, correction: undefined });
  }

  reloadItems(): void {
    this.loadTrainingQuestions();
  }

  save(question: QuestionDetail): Observable<QuestionDetail> {
    return question.correction != null && question.correction != ''
      ? this.questionService.patch(question)
      : of();
  }

  getTooltipDescription(question: Question): string {
    return joinArray(
      [question.description, question.secondDescription],
      '\r\n',
    );
  }

  protected readonly MAX_QUESTION_DESCRIPTION_LENGTH =
    MAX_QUESTION_DESCRIPTION_LENGTH;
}
