import { Buffer } from 'buffer';
import { areArraysStringEqual } from '@utils/stringHelper';

export const CSV_SEPARATOR = ',';
export const CSV_EXAM_SESSION_COLUMNS = ['ID', 'Categorie', 'Langue', 'Tiers-temps'];
export const CSV_EXAM_SESSION_GENERIC_ERROR_MESSAGE = "Une erreur est survenue lors de l'ajout d'un candidat";
export const CSV_EXAM_SESSION_COLUMNS_NUMBER_ERROR = `${CSV_EXAM_SESSION_GENERIC_ERROR_MESSAGE} : nombre de colonnes incorrect`
export const CSV_EXAM_SESSION_EMPTY_COLUMNS_ERROR = `${CSV_EXAM_SESSION_GENERIC_ERROR_MESSAGE} : fichier vide`
export const CSV_EXAM_SESSION_COLUMNS_ERROR = `${CSV_EXAM_SESSION_GENERIC_ERROR_MESSAGE} : les colonnes ne sont pas correctes`
export const CSV_EXAM_SESSION_BASE64_ERROR = `${CSV_EXAM_SESSION_GENERIC_ERROR_MESSAGE} : fichier corrompu`

export const readLineByLine = (base64: string): string[] => {
  const csv = Buffer.from(base64, 'base64').toString('utf-8');
  const lineByLine = csv.split(/[\r\n|\r|\n]+/);

  if (lineByLine.length <= 0 || lineByLine[0] === '')
    throw new Error(CSV_EXAM_SESSION_EMPTY_COLUMNS_ERROR);

  const columns = lineByLine[0].split(CSV_SEPARATOR);

  if (!areArraysStringEqual(columns, CSV_EXAM_SESSION_COLUMNS)) throw new Error(CSV_EXAM_SESSION_COLUMNS_ERROR);

  return lineByLine.slice(1);
}
