<app-dtt-logo class="fixed"></app-dtt-logo>
<div class="h-screen align-content-center">
  <div *ngIf="correctionLoading" class="section text-center">
    <app-spinner />
  </div>
  <div *ngIf="!correctionLoading" class="flex flex-column section text-xl">
    <i
      class="pi result-icon"
      [ngClass]="hasSuccessfullyPassed ? 'pi-check-circle' : 'pi-times-circle '"
    ></i>
    <h1 class="result-title">
      {{ hasSuccessfullyPassed ? "Félicitations" : "Dommage" }}
    </h1>
    <p class="result-text font-bold">
      {{
        hasSuccessfullyPassed
          ? "Vous avez réussi l'examen"
          : "Vous avez échoué l'examen"
      }}
    </p>
    <p class="result-text font-bold">Identifiant : {{ providerId }}</p>
    <br />
    <p class="result-text font-normal" [innerHTML]="errorLine"></p>
    <br />
    <p class="result-text font-normal">
      {{
        hasSuccessfullyPassed
          ? "Vous recevrez bientôt le détail de vos résultats et votre attestation de réussite par e-mail."
          : "Vous recevrez bientôt le détail de vos résultats par e-mail."
      }}
    </p>
    <br />
    <p class="result-text font-normal">À bientôt.</p>
  </div>
</div>
