<div class="section h-full section-stats flex flex-column justify-content-between">

  <div>
    <div class="flex align-items-center gap-2 mb-4">
      <div class="flex border-round-xl w-2rem h-2rem bg-blue-gray-200 text-primary p-1 justify-content-center align-items-center">
        <i class="pi pi-list"></i>
      </div>
      <h3 class="my-0 text-xl font-medium">
        Questions par thèmes</h3>
    </div>

    <span class="font-light text-gray-500" *ngIf="questionsByThemes.length === 0">
      Aucunne question trouvée
   </span>
    <div class="grid" *ngFor="let questionsByTheme of questionsByThemes">
      <div class="col-8 text-gray-500">
        @if(loading){ <p-skeleton /> } @else { {{ questionsByTheme.themeName }} }
      </div>
      <div class="col text-xs text-right blue-gray-800">
        @if(loading){ <p-skeleton /> } @else { {{ questionsByTheme.questionCount | pluralize: 'question' }} }
      </div>
    </div>
  </div>
  <div class="flex justify-content-end mt-4">
    <pf-action-button
      type="previous"
      customIconClass=" "
      styleClass="p-button-tertiary border-none text-sm justify-content-start"
      label="Voir les thèmes"
      [routerLink]="'/' + AdminRoute.StatsThemes"
    ></pf-action-button>
  </div>
</div>
