<div class="grid">
  <app-breadcrumb [items]="breadcrumbs"></app-breadcrumb>
</div>

<h1>Archives</h1>
<h5>Accéder aux archives métiers et actions des utilisateurs</h5>

<div class="grid grid-col-2 gap-8 m-0">
  <div class="section flex-1">
    <h5>Métiers</h5>
    <pf-action-button
      type="previous"
      customIconClass=" "
      styleClass="p-button-tertiary border-none text-sm justify-content-start"
      label="Liste des série téléchargées"
      [routerLink]="seriesArchivesDownloadedSeries"
    ></pf-action-button>
  </div>
  <div class="section flex-1">
    <h5>Les actions utilisateurs</h5>
    <pf-action-button
      type="previous"
      customIconClass=" "
      styleClass="p-button-tertiary border-none text-sm justify-content-start"
      label="Les logs"
      [routerLink]="getLogUserRoute()"
    ></pf-action-button>
  </div>
</div>
