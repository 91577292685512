<div class="grid">
  <app-breadcrumb [items]="breadcrumbs"></app-breadcrumb>
</div>

<pf-form *ngIf="currentForm" [formGroup]="currentForm">
  <div class="grid form-container">
    <pf-action-button
      type="previous"
      styleClass="col-12 mb-4 p-button-tertiary border-none justify-content-start text-sm"
      label="Retour"
    ></pf-action-button>
    <div class="page-title col-12">Créer une session</div>
    <div class="col-12 grid gap-3 justify-content-end">
      <pf-form-file-upload
        customLabel="Importer les données"
        [displayFileName]="false"
        [form]="currentForm"
        fieldName="file"
        [maxFileSize]="DEFAULT_MAX_FILE_SIZE"
        [fileTypeAuthorized]="['text/csv']"
        [fileTypeAuthorizedLabels]="['csv']"
      >
      </pf-form-file-upload>
    </div>
    <pf-card class="col-12" title="Programmer ma session">
      <div class="grid">
        <pf-form-label-wrapper
          [showErrors]="showErrors(currentForm, 'island')"
          class="col-12"
        >
          <ng-template #errors>
            <pf-form-error
              *ngIf="controls?.island?.errors?.['required']"
              label="Lieu obligatoire."
            ></pf-form-error>
          </ng-template>
        </pf-form-label-wrapper>
        <p-dropdown
          class="col-4"
          styleClass="w-full"
          formControlName="island"
          [options]="islands()"
          optionLabel="name"
          placeholder="Lieu"
        >
        </p-dropdown>

        <pf-form-label-wrapper
          [showErrors]="showErrors(currentForm, 'sessionDate')"
          class="col-4"
        >
          <ng-template #errors>
            <pf-form-error
              *ngIf="controls?.sessionDate?.errors?.['required']"
              label="Choisir une date"
            ></pf-form-error>
          </ng-template>
        </pf-form-label-wrapper>
        <p-calendar
          [readonlyInput]="true"
          class="col-4"
          styleClass="w-full"
          iconDisplay="input"
          [minDate]="minSessionExamDate"
          [showIcon]="true"
          formControlName="sessionDate"
          [showTime]="true"
          [stepMinute]="10"
          hourFormat="24"
          [dateFormat]="getCalendarDateformat()"
        />

        <pf-form-label-wrapper
          [showErrors]="showErrors(currentForm, 'candidates')"
          class="col-12"
        >
          <ng-template #errors>
            <pf-form-error
              *ngIf="controls?.candidates?.errors?.['required']"
              label="Minimum de 1 candidat."
            ></pf-form-error>
          </ng-template>
        </pf-form-label-wrapper>
        <pf-table
          class="col-12"
          [data]="currentCandidates()"
          [columns]="columns"
          [displaySkeleton]="true"
          [rows]="candidates()?.length ?? 10"
          noResultLabel="Pas de candidats trouvés"
        >
          <ng-template #content let-candidate>
            <tr>
              <td>{{ candidate.providerId }}</td>
              <td>{{ candidate.category }}</td>
              <td>{{ candidate.language }}</td>
              <td>{{ candidate.additionalTime }}</td>
            </tr>
          </ng-template>
        </pf-table>
      </div>
    </pf-card>
    <div class="col-12 flex justify-content-end">
      <pf-action-button
        [disabled]="isSubmitButtonDisabled()"
        type="custom"
        label="Valider"
        (buttonClicked)="onValidate()"
      ></pf-action-button>
    </div>
  </div>
</pf-form>
