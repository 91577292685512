import { CommonModule } from '@angular/common';
import { Component, input } from '@angular/core';
import { ExamSteps } from '@app/models/exam/examSteps.model';
import { SafePipeModule } from '@app/pipes/safe.pipe';
import { UserExamSessionService } from '@app/services/userExamSession.service';

import { TranslateModule } from '@ngx-translate/core';
import { AppService } from '@services/app.service';

@Component({
  selector: 'app-waiting-page',
  standalone: true,
  imports: [CommonModule, TranslateModule, SafePipeModule],
  templateUrl: './waiting-page.component.html',
})
export class WaitingPageComponent {
  categoryId = input<string>();
  examId = input<string>();
  examStep = input<ExamSteps>();
  providerId = input<string>();
  waitingMessage = input<string>();

  constructor(
    public userExamSessionService: UserExamSessionService,
    private appService: AppService,
  ) {
    this.appService.updateHeaderDisplay(true);
  }
}
