import { CommonModule } from '@angular/common';
import { Component, model } from '@angular/core';
import { Theme } from '@app/models/question/theme.model';
import { PfActionButtonComponent } from 'pf-ui';
import { TreeNode } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import {
  TreeModule,
  TreeNodeSelectEvent,
  TreeNodeUnSelectEvent,
} from 'primeng/tree';
import { SpecificClosableDialogComponent } from '../specific-closable-dialog/specific-closable-dialog.component';

@Component({
  selector: 'app-accordion-select',
  standalone: true,
  imports: [
    CommonModule,
    PfActionButtonComponent,
    SpecificClosableDialogComponent,
    TreeModule,
  ],
  templateUrl: './accordion-select.component.html',
  styleUrl: './accordion-select.component.scss',
})
export class AccordionSelectComponent {
  themes = model<TreeNode[]>();
  selectedThemes: TreeNode[] = [];

  constructor(
    private config: DynamicDialogConfig<{
      themes?: Theme[];
      selectedThemes?: Theme[];
    }>,
    public dialog: DynamicDialogRef
  ) {
    this.themes.update(() =>
      this.initTree(
        this.config.data?.themes,
        this.config.data?.selectedThemes,
        true
      )
    );
  }

  initTree(
    themes?: Theme[],
    selection?: Theme[],
    isFirstLevel: boolean = false
  ): TreeNode[] {
    return (
      themes?.map((t) => this.initNode(t, selection ?? [], isFirstLevel)) || []
    );
  }

  initNode(
    theme: Theme,
    selection?: Theme[],
    isFirstLevel: boolean = false
  ): TreeNode {
    const subNodes = this.initTree(theme.children, selection);

    const hasChildrenSelected = subNodes.some(
      (node) => node.partialSelected === false
    );

    const hasAllChildrenSelected = subNodes.every(
      (node) => node.partialSelected === false
    );

    const node = this.mapTheme(theme);
    node.children = subNodes;

    if (subNodes.length > 0 && isFirstLevel) {
      node.styleClass = 'specific-treenode';
    }

    const isSelected = selection?.some((t) => t.id === node.key);

    if (hasChildrenSelected) node.partialSelected = true;

    if (isSelected === true && hasAllChildrenSelected)
      node.partialSelected = false;

    if (isSelected === true) this.selectedThemes.push(node);

    return node;
  }

  mapTheme(theme: Theme): TreeNode {
    return {
      label: theme.name,
      data: theme.id,
      key: theme.id,
      leaf: theme.children != null && theme.children.length === 0,
      children: this.mapThemes(theme.children || []),
    };
  }

  mapThemes(themes: Theme[]): TreeNode[] {
    return themes.map((theme) => this.mapTheme(theme));
  }

  mapTreeNodes(themes: TreeNode[]): Theme[] {
    return themes.map((theme) => this.mapTreeNode(theme));
  }

  mapTreeNode(theme: TreeNode): Theme {
    return {
      id: theme.data,
      name: theme.label,
      children: this.mapTreeNodes(theme.children || []),
    };
  }

  public close(save?: boolean): void {
    if (save === true) {
      const selectedNodes = this.selectedThemes.filter(
        (node) => node.leaf === true
      );
      const selectedThemes = this.mapTreeNodes(selectedNodes);

      this.dialog.close(selectedThemes);
      return;
    }
    this.dialog.close();
  }

  onNodeSelect($event: TreeNodeSelectEvent): void {
    $event.node.expanded = true;
  }

  onNodeUnselect($event: TreeNodeUnSelectEvent): void {
    $event.node.expanded = false;
  }
}
