import { CommonModule } from '@angular/common';
import {
  Component,
  effect,
  input,
  OnInit,
  output,
  signal,
  untracked,
} from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
} from '@angular/forms';
import { ViewQuestionComponent } from '@app/components/dialogs/view-question/view-question.component';
import { SelectMediaComponent } from '@app/components/medias/select-media/select-media.component';
import { UploadMediaComponent } from '@app/components/medias/upload-media/upload-media.component';
import { AbstractFormComponent } from '@components/common/abstract/form/abstract-form.component';

import { SafeUrl } from '@angular/platform-browser';
import { Media } from '@app/models/media/media.model';
import { MediaTag } from '@app/models/media/mediaTag.model';
import { AnswerChoicesForm } from '@app/models/question/answerChoicesForm.model';
import { QuestionDetail } from '@app/models/question/question-detail.model';
import { QuestionForm } from '@app/models/question/question-form.model';
import { objectEqual } from '@app/utils/objectHelper';
import { numberToString } from '@app/utils/stringHelper';
import { MediaService } from '@services/media.service';
import {
  PfActionButtonComponent,
  PfCardComponent,
  PfFormComponent,
  PfFormErrorComponent,
  PfFormLabelWrapperComponent,
} from 'pf-ui';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ImageModule } from 'primeng/image';
import { SkeletonModule } from 'primeng/skeleton';
import { QuestionAnswerFormComponent } from '../question-answer-form/question-answer-form.component';
import { QuestionDescriptionFormComponent } from '../question-description-form/question-description-form.component';

@Component({
  selector: 'app-create-question',
  standalone: true,
  imports: [
    PfActionButtonComponent,
    PfCardComponent,
    PfFormComponent,
    CommonModule,
    ReactiveFormsModule,
    PfFormErrorComponent,
    PfFormLabelWrapperComponent,
    ImageModule,
    SkeletonModule,
    QuestionDescriptionFormComponent,
    QuestionAnswerFormComponent,
  ],
  templateUrl: './create-question.component.html',
  styleUrl: './create-question.component.scss',
})
export class CreateQuestionComponent
  extends AbstractFormComponent
  implements OnInit
{
  validateClicked = output<boolean>();
  form = input<FormGroup<QuestionForm> | undefined>();
  mediaTags = input<MediaTag[]>([]);
  openViewQuestionRequested = false;

  medias = this.mediaService.signalList;
  mediaUrls = signal<Record<string, SafeUrl | undefined> | undefined>(
    undefined,
  );

  numberToString = numberToString;
  imagePath: string | SafeUrl | undefined = '';

  descriptionTitle = 'Rédiger un énoncé';
  answerChoicesTitle = 'Constituer les réponses';

  viewQuestionModalRef: DynamicDialogRef = new DynamicDialogRef();
  uploadMediaModalRef: DynamicDialogRef = new DynamicDialogRef();
  selectMediaModalRef: DynamicDialogRef = new DynamicDialogRef();

  constructor(
    protected fb: FormBuilder,
    public dialog: DialogService,
    protected mediaService: MediaService,
  ) {
    super();
    effect(() => {
      const question = this.currentForm?.value as QuestionDetail;
      if (this.openViewQuestionRequested && question !== undefined) {
        this.openViewQuestionModal(question);
        this.openViewQuestionRequested = false;
      }
    });

    effect(
      () => {
        const medias = this.medias();
        this.loadMedias(medias);
      },
      { allowSignalWrites: true },
    );

    effect(() => {
      const mediaUrls = this.mediaUrls();
      if (mediaUrls === undefined) return;
      this.updateImagePath();
    });
  }

  ngOnInit(): void {
    // FIXME : Prévoir une liste paginée dans le template!!
    this.mediaService.list().subscribe();
  }

  get currentForm(): FormGroup<QuestionForm> | undefined {
    return this.form();
  }

  get controls(): QuestionForm | undefined {
    return this.currentForm?.controls;
  }

  get answerChoices(): FormArray<FormGroup<AnswerChoicesForm>> | undefined {
    return this.controls?.answerChoices;
  }

  get secondAnswerChoices():
    | FormArray<FormGroup<AnswerChoicesForm>>
    | undefined {
    return this.controls?.secondAnswerChoices;
  }

  get picture(): AbstractControl<Media | undefined | null> | undefined {
    return this.controls?.picture;
  }

  loadMedias(medias: Media[] | undefined): void {
    if (medias == null || medias.length <= 0) return;
    medias?.forEach((media) => {
      this.mediaService.getImage(media.keyName).subscribe((medialUrl) => {
        this.updateCurrentMedia(media, medialUrl);
      });
    });
  }

  updateCurrentMedia(media: Media, medialUrl: SafeUrl | undefined): void {
    untracked(() => {
      this.mediaUrls.update((currentRecord) => ({
        ...currentRecord,
        [media.keyName ?? '']: medialUrl,
      }));
    });
  }

  addDoubleQuestion(): void {
    if (this.isDoubleQuestion()) {
      this.controls?.secondDescription?.disable();
      this.controls?.secondAnswerChoices?.disable();
    } else {
      this.controls?.secondDescription?.enable();
      this.controls?.secondAnswerChoices?.enable();
    }
  }

  isDoubleQuestion(): boolean {
    return (
      this.controls?.secondDescription?.enabled === true &&
      this.controls?.secondAnswerChoices?.enabled === true
    );
  }

  openUploadMediaModal(): void {
    this.uploadMediaModalRef = this.dialog.open(UploadMediaComponent, {
      data: {
        media: this.picture?.value,
        mediasUrl: this.mediaUrls(),
        tagList: this.mediaTags(),
      },
      styleClass: 'pf-two-column-form-modal',
      showHeader: false,
    });

    this.uploadMediaModalRef.onClose.subscribe((media?: Media) => {
      if (!(media !== undefined && !objectEqual(this.picture?.value, media))) {
        return;
      }
      this.updatePicture(media);
    });
  }

  openSelectMediaModal(): void {
    this.selectMediaModalRef = this.dialog.open(SelectMediaComponent, {
      data: {
        tagList: this.mediaTags(),
        medias: this.medias(),
        mediasUrl: this.mediaUrls(),
        media: this.picture?.value,
      },
      styleClass: 'pf-two-column-form-modal',
      showHeader: false,
    });

    this.selectMediaModalRef.onClose.subscribe((media?: Media) => {
      if (media == undefined) return;
      this.updatePicture(media);
    });
  }

  emptyPicture(): void {
    this.updatePicture();
  }

  updatePicture(media?: Media): void {
    this.picture?.patchValue(media);
    this.picture?.markAsDirty();
    this.updateImagePath(media);
  }

  updateImagePath(media?: Media): void {
    const picture = this.picture?.value;
    const mediaUrls = this.mediaUrls();
    if (picture?.base64_file != undefined) {
      this.imagePath = `data:image/jpg;base64,${picture.base64_file}`;
    } else if (picture?.id != undefined && picture?.keyName != undefined) {
      if (media) {
        this.imagePath = media?.safeUrl ?? '';
      } else if (mediaUrls !== undefined) {
        this.imagePath = mediaUrls[picture.keyName];
      }
    } else this.imagePath = '';
  }

  validate(goToNextStep: boolean): void {
    this.markAsTouched();

    if (!this.isValid()) return;

    this.validateClicked.emit(goToNextStep);

    if (goToNextStep) return;

    this.openViewQuestionModal(this.currentForm?.value as QuestionDetail);
    this.openViewQuestionRequested = false;
  }

  markAsTouched(): void {
    this.answerChoices?.markAllAsTouched();
    this.picture?.markAllAsTouched();
    this.controls?.description?.markAsTouched();

    if (this.isDoubleQuestion()) {
      this.secondAnswerChoices?.markAllAsTouched();
      this.controls?.secondDescription?.markAsTouched();
    }
  }

  isValid(): boolean {
    let normalQuestionValid =
      this.answerChoices?.valid === true &&
      this.picture?.valid === true &&
      this.controls?.description?.valid === true;

    if (this.isDoubleQuestion())
      normalQuestionValid &&=
        this.secondAnswerChoices?.valid === true &&
        this.controls?.secondDescription?.valid === true;

    return normalQuestionValid;
  }

  openViewQuestionModal(question: QuestionDetail): void {
    this.viewQuestionModalRef = this.dialog.open(ViewQuestionComponent, {
      data: {
        question,
      },
      styleClass: 'pf-two-column-form-modal',
      showHeader: false,
    });
  }
}
