import { Component } from '@angular/core';
import {
  PfActionButtonComponent,
  PfFormActionBarComponent,
  PfSelectButtonComponent,
  PfUiTranslateModule,
} from 'pf-ui';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TenantService } from '@services/tenant.service';
import { SelectButtonModule } from 'primeng/selectbutton';
import {
  SpecificClosableDialogComponent
} from '@components/dialogs/specific-closable-dialog/specific-closable-dialog.component';
import { FormsModule } from '@angular/forms';

export type ChangeTenantDialogData = {
  tenants: string[];
};

@Component({
  selector: 'app-specific-confirm-dialog',
  templateUrl: './change-tenant-dialog.component.html',
  styleUrl: './change-tenant-dialog.component.scss',
  standalone: true,
  imports: [
    PfSelectButtonComponent,
    SpecificClosableDialogComponent,
    SelectButtonModule,
    PfFormActionBarComponent,
    PfActionButtonComponent,
    PfUiTranslateModule,
    FormsModule
  ],
})
export class ChangeTenantDialogComponent {
  public tenants: string[] = [];

  public tenantSelected: string | null

  constructor(
    private dialog: DynamicDialogRef,
    private config: DynamicDialogConfig<ChangeTenantDialogData>,
    private tenantService: TenantService
  ) {
    this.initValues(this.config.data);
    this.tenantSelected = tenantService.getActiveTenant()
  }

  initValues(data: ChangeTenantDialogData | undefined): void {
    if (data === undefined) {
      return;
    }

    this.tenants = data.tenants;
  }

  changeTenant(tenant: string): void {
    this.tenantService.setActiveTenant(tenant);
  }

  validate(): void {
    window.location.reload();
  }

  public close(save?: boolean): void {
    this.dialog.close(save);
  }
}
