import { CommonModule } from '@angular/common';
import { Component, OnInit, signal } from '@angular/core';
import { RouterLink } from '@angular/router';
import { SpinnerComponent } from '@app/components/common/spinner/spinner.component';
import {
  ExamSession,
  ExamSessionByDate,
} from '@app/models/exam/examSession.model';
import { ExamSessionService } from '@app/services/examSession.service';
import {
  formatFrenchLocaleSessionDate,
  formatFrenchSessionDay,
} from '@app/utils/examSessions';
import { AdminRoute, getSessionIdRoute, RouteLabel } from '@app/utils/routes';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  hasAnyAuthorities,
  hasAuthority,
} from '@app/models/user/agent-detail.model';
import { AgentService } from '@app/services/agent.service';
import {
  BreadcrumbComponent,
  BreadcrumbItem,
} from '@components/common/breadcrumb/breadcrumb.component';
import { Island } from '@models/island/island.model';
import { TranslateModule } from '@ngx-translate/core';
import { DateTranslateService } from '@services/dateTranslate.service';
import { IslandService } from '@services/island.service';
import { getDateAtStartOfTheDay } from '@utils/dateHelper';
import { PfActionButtonComponent } from 'pf-ui';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { SelectButtonModule } from 'primeng/selectbutton';
import { PluralizePipe } from '@app/pipes/pluralize.pipe';

@Component({
  selector: 'app-sessions',
  standalone: true,
  templateUrl: './sessions.component.html',
  styleUrl: './sessions.component.scss',
  imports: [
    ButtonModule,
    CommonModule,
    PfActionButtonComponent,
    TranslateModule,
    RouterLink,
    SpinnerComponent,
    BreadcrumbComponent,
    CalendarModule,
    FormsModule,
    DropdownModule,
    ReactiveFormsModule,
    SelectButtonModule,
  ],
})
export class SessionsComponent implements OnInit {
  // Sessions affichées
  sessionsByDate = signal<[string, ExamSession[]][] | undefined>(undefined);
  islands = this.islandService.signalList;
  agent = this.agentService.signalCurrent;

  formatFrenchSessionDate = formatFrenchLocaleSessionDate;
  formatFrenchSessionDay = formatFrenchSessionDay;
  getSessionIdRoute = getSessionIdRoute;

  breadcrumbs: BreadcrumbItem[] = [
    { label: RouteLabel.Home, route: AdminRoute.Home },
    { label: RouteLabel.Sessions },
  ];
  dateFilter?: Date;
  islandFilter?: Island;
  pluralizePipe: PluralizePipe = new PluralizePipe();

  constructor(
    protected examSessionService: ExamSessionService,
    protected dateTranslateService: DateTranslateService,
    private islandService: IslandService,
    private agentService: AgentService,
  ) {}

  get canManageSession(): boolean {
    return hasAnyAuthorities(this.agent(), [
      'session.manage.all',
      'session.manage.external',
    ]);
  }

  ngOnInit(): void {
    this.islandService.list().subscribe();
    this.dateFilter = getDateAtStartOfTheDay();
    this.getFilteredSessions();
  }

  getFrenchSessionDay(sessionDate: string): string {
    return formatFrenchSessionDay(
      sessionDate,
      this.dateTranslateService.getMonthLabels(),
    );
  }

  getExamSessionLabel(session: ExamSession): string {
    const data = [this.getFrenchSessionDate(session.sessionDate)];

    const islandName = this.getIslandName(session);
    if (islandName != undefined) {
      data.push(islandName);
    }

    const userExamSessionCount = this.getUserExamSessionCount(session);
    if (userExamSessionCount != undefined) {
      data.push(userExamSessionCount);
    }

    return data.join(' - ');
  }

  getIslandName(session: ExamSession): string | undefined {
    return session.island?.name;
  }

  getUserExamSessionCount(session: ExamSession): string | undefined {
    if (!session.userExamSessionDTO) return undefined;

    return this.pluralizePipe.transform(
      session.userExamSessionDTO.length,
      'candidat',
    );
  }

  getFrenchSessionDate(sessionDate?: string): string {
    return this.formatFrenchSessionDate(
      this.dateTranslateService.getMonthLabels(),
      sessionDate,
    );
  }

  getFilteredSessions(): void {
    this.examSessionService
      .getExamSessionsByDate(this.dateFilter, this.islandFilter?.id)
      .subscribe((result: ExamSessionByDate | undefined) => {
        this.sessionsByDate.set(
          result === undefined ? [] : Object.entries(result),
        );
      });
  }

  isInternalSupervisor(): boolean {
    return hasAuthority(this.agent(), 'session.view.all');
  }
}
