import { Component, effect, input, output } from '@angular/core';
import { BreadcrumbComponent } from '@components/common/breadcrumb/breadcrumb.component';
import { PfTableComponent, TableColumn } from 'pf-ui';
import { TreeTableModule } from 'primeng/treetable';
import { Theme } from '@models/question/theme.model';
import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { NgIf } from '@angular/common';
import { getQuestionIdRoute } from '@utils/routes';
import { TooltipModule } from 'primeng/tooltip';
import { Observable } from 'rxjs';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { FormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { ThemeEventType } from '@pages/settings/business/themes/themes.component';
import { ThemeService } from '@services/theme.service';
import { AgentDetail, hasAuthority } from '@models/user/agent-detail.model';
import { AbstractEditableColumn } from '@components/common/abstract/table/editable/abstractEditableColumn';

@Component({
  selector: 'app-settings-business-themes-tree-node',
  standalone: true,
  templateUrl: './themesTree.component.html',
  imports: [
    BreadcrumbComponent,
    PfTableComponent,
    TreeTableModule,
    TableModule,
    ButtonModule,
    NgIf,
    TooltipModule,
    FormsModule,
    InputTextModule,
  ],
})
export class ThemesTreeComponent extends AbstractEditableColumn<Theme> {
  columns: TableColumn[] = [{ name: 'Thèmes' }, { name: '' }];
  level = input<number>(0);
  themes = input<Theme[] | undefined>(undefined);
  agent = input<AgentDetail | null>(null);

  onAddNewThemeAtPosition = output<ThemeEventType>();
  onThemeChange = output();

  editableItems: Theme[] = [];
  rowsLength: number = this.editableItems?.length ?? 4;
  loading: boolean = false;

  override dialogConfig = {
    labelHtml:
      'Si vous supprimez le thème, les sous-thèmes associés seront également supprimés.<br/> Vous ne pourrez plus faire passer ce thème, les sous-thème associés et les questions qui y sont liées dans un examen.',
    label: '',
    title: 'Supprimer le thème ?',
    confirmLabel: 'Annuler',
    cancelLabel: 'Confirmer',
  };

  constructor(
    public override messageService: MessageService,
    public override dialog: DialogService,
    private themeService: ThemeService,
  ) {
    super(messageService, dialog);

    effect(() => {
      this.editableItems = this.themes() ?? [];
    });
  }

  getMarginSize(theme: Theme): string {
    if (this.level() === 0) return '0rem';

    const size = this.level() * 2;

    return (this.hasChildren(theme) ? size : size * 1.5) + 'rem';
  }

  hasChildren(theme: Theme): boolean {
    return (theme.children && theme.children.length > 0) ?? false;
  }

  addNewThemeTo(themes: ThemeEventType): void {
    this.onAddNewThemeAtPosition.emit(themes);
  }

  assertingTo: { [s: string]: Theme } = {};
  newTheme: Theme = new Theme();

  addNew(parent: Theme): void {
    if (parent.id === undefined) return;
    this.assertingTo = {};
    this.assertingTo[parent.id] = parent;
  }

  isAsserting(theme: Theme): boolean {
    if (theme.id === undefined) return false;
    return this.assertingTo[theme.id] !== undefined;
  }

  cancelAssertion(theme: Theme): void {
    if (theme.id === undefined) return;
    this.newTheme = new Theme();
    delete this.assertingTo[theme.id];
  }

  onAssertion(theme: Theme): void {
    this.addNewTheme({
      theme: this.newTheme,
      parent: {
        level: this.level(),
        theme: theme,
      },
    });
  }


  addNewTheme(themes: ThemeEventType): void {
    this.themeService
      .create({ ...themes.theme, parent: themes.parent.theme.id })
      .subscribe({
        error: () => {
          this.showErrorToast('Erreur lors de la création du thème');
        },
        complete: () => {
          this.cancelAssertion(themes.parent.theme);
          this.showSuccessToast('Thème créé avec succès');
          this.onThemeChange.emit();
        },
      });
  }

  handleAddNewThemeAtPositionClick(event: ThemeEventType, parent: Theme): void {
    event.parent = { level: this.level(), theme: parent };
    this.addNewThemeTo(event);
  }

  protected readonly getQuestionIdRoute = getQuestionIdRoute;

  delete(theme: Theme): Observable<Theme> {
    return this.themeService.delete(theme);
  }

  reloadItems(): void {
    this.onThemeChange.emit();
  }

  save(theme: Theme): Observable<Theme> {
    return this.themeService.update(theme);
  }

  get currentAgent(): AgentDetail | null {
    return this.agent() ?? null;
  }

  get canDelete(): boolean {
    return hasAuthority(this.currentAgent, 'theme.delete');
  }

  get canEdit(): boolean {
    return hasAuthority(this.currentAgent, 'theme.edit');
  }

  get canCreate(): boolean {
    return hasAuthority(this.currentAgent, 'theme.create');
  }
}
