<app-specific-closable-dialog
  title="Télécharger un média"
  (closeClicked)="close()"
>
  <pf-form *ngIf="form" [formGroup]="form">
    <div class="grid">
      <pf-step
        [classList]="'col-12 w-24rem'"
        summary="Sélectionner le media"
        [number]="1"
      ></pf-step>
      <div class="col-12 grid ml-2 pl-7">
        <pf-form-file-upload
          [classList]="'col-12'"
          customLabel="Télécharger"
          [displayFileName]="false"
          [form]="form"
          fieldName="media"
          [maxFileSize]="DEFAULT_MAX_FILE_SIZE"
          [fileTypeAuthorized]="['image/png', 'image/jpeg', 'image/jpg']"
          [fileTypeAuthorizedLabels]="['png', 'jpeg', 'jpg']"
        >
        </pf-form-file-upload>
        <div class="col-12">
          <p-image
            *ngIf="imagePath"
            [src]="imagePath"
            alt="Image"
            [preview]="true"
          ></p-image>
          <p-skeleton
            *ngIf="!imagePath"
            animation="none"
          ></p-skeleton>
        </div>
      </div>
      <pf-step
        [classList]="'col-12 w-24rem mb-2'"
        summary="Sélectionner les étiquettes associées"
        [number]="2"
      ></pf-step>
      <div class="col-12 grid ml-2 pl-7">
        <div *ngFor="let tag of tagList(); index as i" class="col-4">
          <p-checkbox
            labelStyleClass="white-space-nowrap text-overflow-ellipsis max-w-20rem overflow-hidden ml-3"
            [formControl]="$any(form).controls['tags']"
            [pTooltip]="tag.name"
            [inputId]="'tags' + i"
            [label]="tag.name"
            [value]="tag"
          ></p-checkbox>
        </div>
      </div>

      <div class="col-12 flex justify-content-end">
        <pf-action-button
          label="Valider"
          [disabled]="!form.valid"
          (buttonClicked)="close(true)"
        ></pf-action-button>
      </div>
    </div>
  </pf-form>
</app-specific-closable-dialog>
