import { CommonModule } from '@angular/common';
import { Component, computed, effect, OnDestroy } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import {
  SpecificConfirmDialogComponent,
  SpecificConfirmDialogData,
} from '@app/components/dialogs/specific-confirm-dialog/specific-confirm-dialog.component';
import {
  LanguageEnum,
  UserExamSessionStatus,
} from '@app/models/exam/userExamSession.model';

import {
  ExamStep,
  SessionExamStep,
  SessionExamSteps,
  sessionExamStepTranslation,
} from '@app/models/exam/examSteps.model';
import { User } from '@app/models/user/user.model';

import { ExamSessionService } from '@app/services/examSession.service';
import { UserExamSessionService } from '@app/services/userExamSession.service';
import { formatFrenchLocaleSessionDate } from '@app/utils/examSessions';

import { downloadFile } from '@app/utils/files';
import { SpecificClosableDialogComponent } from '@components/dialogs/specific-closable-dialog/specific-closable-dialog.component';
import {
  ExamSessionStatusDTO,
  UserExamSessionStatusDTO,
} from '@models/exam/examSession.model';
import { Category } from '@models/question/category.model';
import { TranslateModule } from '@ngx-translate/core';
import { DateTranslateService } from '@services/dateTranslate.service';
import { AdminRoute } from '@utils/routes';
import {
  FormManager,
  PfActionButtonComponent,
  PfAlertComponent,
  PfDownloadButtonComponent,
  PfFormComponent,
  PfTableComponent,
  Step,
} from 'pf-ui';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { DialogService } from 'primeng/dynamicdialog';
import { RadioButtonModule } from 'primeng/radiobutton';
import { TooltipModule } from 'primeng/tooltip';
import { Subscription, switchMap, timer } from 'rxjs';

type SessionInformationForm = {
  candidates: FormArray<FormGroup<Candidate>>;
  informationValidated: FormControl<boolean | null>;
};

type Candidate = {
  user: FormControl<User | null>;
  present: FormControl<boolean | null>;
  language: FormControl<LanguageEnum | null>;
  additionalTime: FormControl<boolean | null>;
  status: FormControl<UserExamSessionStatus | null>;
  connected: FormControl<boolean | null>;
  category: FormControl<Category | null>;
  hasFinishedTraining: FormControl<boolean | null>;
  hasFinishedExam: FormControl<boolean | null>;
  hasSawResult: FormControl<boolean | null>;
};

@Component({
  selector: 'app-session',
  standalone: true,
  templateUrl: './session.component.html',
  styleUrl: './session.component.scss',
  imports: [
    ButtonModule,
    CheckboxModule,
    CommonModule,
    FormsModule,
    PfActionButtonComponent,
    RadioButtonModule,
    ReactiveFormsModule,
    RouterLink,
    PfDownloadButtonComponent,
    PfTableComponent,
    TranslateModule,
    SpecificClosableDialogComponent,
    PfFormComponent,
    PfAlertComponent,
    TooltipModule,
  ],
})
export class SessionComponent implements OnDestroy {
  session = this.examSessionService.signalExamSession;
  sessionStatus = this.examSessionService.signalExamSessionStatus;

  form!: FormGroup;
  sessionId: string;

  // alert messages
  alertTrainingMessage = computed(() => this.alertComputedSignal(true));
  alertExamMessage = computed(() => this.alertComputedSignal(false));
  isStartExamButtonDisabledSignal = computed(() =>
    this.isStartExamButtonDisabled(),
  );
  isDisplayResultsButtonDisabledSignal = computed(() =>
    this.isDisplayResultsButtonDisabled(),
  );
  isEndedSessionButtonDisabledSignal = computed(() =>
    this.isEndedSessionButtonDisabled(),
  );

  formatFrenchSessionDate = formatFrenchLocaleSessionDate;
  columns = [
    'Présence',
    'Identifiant',
    'Examens',
    'Langue',
    'Spécificité',
    'Etat',
  ];
  centeredColumns = ['Présence', 'Confirmation des données candidats'];

  SessionExamStep = SessionExamStep;
  formManager: FormManager<SessionExamSteps>;

  subscriptionInterval: Subscription;
  status: SessionExamSteps = SessionExamStep.PERSONAL_INFORMATION;

  constructor(
    private dialog: DialogService,
    protected examSessionService: ExamSessionService,
    protected formBuilder: FormBuilder,
    private route: ActivatedRoute,
    public router: Router,
    protected userExamSessionService: UserExamSessionService,
    private dateTranslateService: DateTranslateService,
  ) {
    this.formManager = new FormManager();
    this.formManager.setSteps(this.steps);

    const routeId = this.route.snapshot.paramMap.get('id');
    this.sessionId = routeId === null ? '' : routeId;

    this.subscriptionInterval = timer(0, 6_000)
      .pipe(
        switchMap(() => {
          return this.examSessionService.getExamSessionStatus(this.sessionId);
        }),
      )
      .subscribe();

    this.form = this.initSessionForm({
      userExamSessionStatusDTOS: [] as UserExamSessionStatusDTO[],
    } as ExamSessionStatusDTO);

    effect(() => {
      this.form = this.initSessionForm(this.sessionStatus() ?? []);
    });
  }

  /**
   * Manage to display message during exam or training step to get candidates advancement
   * @param {boolean} isTrainingStep is training step
   */
  alertComputedSignal(isTrainingStep: boolean): string {
    // filter candidates who are not present in total
    const presentCandidates = this.getPresentCandidates();
    const candidatesNumber = presentCandidates?.length ?? 0;

    const title = isTrainingStep ? 'Entraînement en cours' : 'Examen en cours';
    const step = isTrainingStep ? 'l’entraînement' : 'l’examen';

    const achievementsNumber = this.getAchievementsNumber(
      presentCandidates,
      isTrainingStep,
    );

    const endOfMessage =
      achievementsNumber <= 1
        ? `${achievementsNumber} candidat sur ${candidatesNumber} a terminé.`
        : `${achievementsNumber} candidats sur ${candidatesNumber} ont terminé.`;

    return `<strong>${title}</strong>. Les candidats sont en train de passer ${step}. ${endOfMessage}`;
  }

  /**
   * Get Number of candidates who achieve their exams or training step
   * @param presentCandidates candidates presents in exam or training step
   * @param isTrainingStep is training step
   */
  getAchievementsNumber(
    presentCandidates: UserExamSessionStatusDTO[],
    isTrainingStep = false,
  ): number {
    return presentCandidates.reduce((totalAchieved, examStatus) => {
      const hasFinished = isTrainingStep
        ? examStatus.hasFinishedTraining
        : examStatus.hasFinishedExam;
      if (hasFinished) {
        totalAchieved += 1;
      }
      return totalAchieved;
    }, 0);
  }

  /**
   * Display alert message on training step
   */
  shouldDisplayTrainingAlert(): boolean {
    return (
      this.sessionStatus().hasClickedOnStartTraining &&
      !this.sessionStatus().hasClickedOnStartExam
    );
  }

  /**
   * Display alert message on exam step
   */
  shouldDisplayExamAlert(): boolean {
    return (
      this.sessionStatus().hasClickedOnStartExam &&
      !this.sessionStatus().hasClickedOnSessionEnd
    );
  }

  /**
   * get candidates filtered by their presence
   */
  getPresentCandidates(): UserExamSessionStatusDTO[] {
    return this.sessionStatus()?.userExamSessionStatusDTOS.filter(
      (userExam) => userExam.present,
    );
  }

  hasAtLeastOneCandidatesPresent(): boolean {
    const presentCandidate = this.controls.candidates.controls.filter(
      (user: FormGroup<Candidate>) => user.controls.present.value,
    );

    return presentCandidate.length > 0;
  }

  areAllPresentCandidatesConnected(): boolean {
    if (!this.hasAtLeastOneCandidatesPresent()) return false;

    const candidatesNotConnected = this.controls.candidates.controls.filter(
      (userControl: FormGroup<Candidate>) => {
        const { present, connected } = userControl.controls;
        return present.value === true && connected.value === false;
      },
    );

    return candidatesNotConnected.length === 0;
  }

  areAllConnectedCandidatesArePresent(): boolean {
    if (!this.hasAtLeastOneCandidatesPresent()) return false;

    const candidatesNotPresent = this.controls.candidates.controls.filter(
      (userControl: FormGroup<Candidate>) => {
        const { present, connected } = userControl.controls;
        return present.value === false && connected.value === true;
      },
    );

    return candidatesNotPresent.length === 0;
  }

  get openConfirmCandidatesInformationValidatedProps(): {
    disabled: boolean;
    message: string;
  } {
    if (!this.hasAtLeastOneCandidatesPresent()) {
      return {
        message: `Aucun candidat n'a été confirmé. Il doit y avoir au moins 1 candidat présent pour démarrer l'examen`,
        disabled: true,
      };
    } else if (!this.areAllPresentCandidatesConnected()) {
      return {
        message: `Au moins un des candidats présent n'a pas confirmé ses informations.`,
        disabled: true,
      };
    } else if (!this.areAllConnectedCandidatesArePresent()) {
      return {
        message: `Il y a actuellement ${this.numberOfPresentCandidatesMessage} et ${this.numberOfAbsentCandidatesMessage}.
          <br />Au moins un candidat ayant confirmé ses informations n'est pas marqué présent.
          <br /><br />Confirmez-vous les informations renseignées ?`,
        disabled: false,
      };
    } else {
      return {
        message: `Il y a actuellement
      ${this.numberOfPresentCandidatesMessage} et
      ${this.numberOfAbsentCandidatesMessage}.
      \n\r<br>Confirmez-vous les informations renseignées ?`,
        disabled: false,
      };
    }
  }

  openConfirmCandidatesInformationValidated(): void {
    const { message, disabled } =
      this.openConfirmCandidatesInformationValidatedProps;

    const dialog = this.dialog.open(SpecificConfirmDialogComponent, {
      data: {
        labelHtml: message,
        title: 'Status des candidats',
        confirmLabel: 'Confirmer',
        cancelLabel: 'Annuler',
        disabledConfirm: disabled,
      } satisfies SpecificConfirmDialogData,
      styleClass: 'pf-two-column-form-modal',
      showHeader: false,
    });

    dialog.onClose.subscribe((confirm?: boolean) => {
      this.controls.informationValidated.setValue(confirm ?? false);
      if (confirm === true) {
        this.examSessionService.setValidatedCandidatesInformation(
          this.sessionId,
        );
        this.controls.informationValidated.disable();
        this.disableAllPresentCheckbox(this.form);
      }
    });
  }

  ngOnDestroy(): void {
    this.subscriptionInterval.unsubscribe();
  }

  initSessionForm(
    examSessions: ExamSessionStatusDTO,
  ): FormGroup<SessionInformationForm> {
    const fb = this.formBuilder;
    const form = fb.group<SessionInformationForm>({
      candidates: fb.array(
        examSessions.userExamSessionStatusDTOS.map(
          (
            userExamSessionStatusDTO: UserExamSessionStatusDTO,
          ): FormGroup<Candidate> => {
            const providerId = userExamSessionStatusDTO.providerId;

            // TODO: refacto this part
            const user = {
              id: '',
              providerId: providerId ?? '',
              email: '',
              firstName: '',
              lastName: '',
              phoneNumber: '',
              role: '',
            } as unknown as User;

            return fb.group<Candidate>({
              user: fb.control(user),
              category: fb.control(userExamSessionStatusDTO.category),
              language: fb.control(userExamSessionStatusDTO.language),
              hasFinishedExam: fb.control(
                userExamSessionStatusDTO.hasFinishedExam,
              ),
              hasFinishedTraining: fb.control(
                userExamSessionStatusDTO.hasFinishedTraining,
              ),
              hasSawResult: fb.control(userExamSessionStatusDTO.hasSawResult),
              additionalTime: fb.control(
                userExamSessionStatusDTO.additionalTime,
              ),
              present: fb.control(userExamSessionStatusDTO.present ?? false),
              status: fb.control(
                UserExamSessionStatus.REGISTERED, //TODO: TO DELETE
              ),
              // TODO: verify if user disconnected
              connected: fb.control(
                userExamSessionStatusDTO.connected ?? false,
              ),
            });
          },
        ),
      ),
      informationValidated: fb.control(
        examSessions.hasValidatedCandidatesInformation ??
          this.controls?.informationValidated?.value ??
          false,
      ),
    });

    if (
      this.sessionPin === undefined ||
      examSessions.hasValidatedCandidatesInformation
    ) {
      form.controls.informationValidated.disable();
      this.disableAllPresentCheckbox(form);
    }

    return form;
  }

  disableAllPresentCheckbox(form: FormGroup<SessionInformationForm>): void {
    const candidatesArray = form.get('candidates') as FormArray;

    candidatesArray.controls.forEach((candidateControl) => {
      const presentControl = candidateControl.get('present') as FormControl;
      presentControl.disable();
    });
  }

  get sessionPin(): string {
    const pin = this.sessionStatus()?.pin;
    return pin ?? '';
  }

  get steps(): Step<SessionExamSteps>[] {
    return Object.values(SessionExamStep).map((step) => ({
      name: step,
      title: sessionExamStepTranslation[step].stepTitle,
      labelButton: '',
    }));
  }

  get sessionDate(): string | undefined {
    return this.sessionStatus()?.sessionDate;
  }

  get numberOfRegisteredCandidates(): string {
    const length = this.sessionStatus()?.userExamSessionStatusDTOS.length ?? 0;
    return length > 1
      ? `${length} candidats inscrits`
      : `${length} candidat inscrit`;
  }

  get numberOfPresentCandidatesMessage(): string {
    const presentCandidates = this.numberOfPresentCandidates;

    return presentCandidates > 1
      ? `${presentCandidates} candidats présents`
      : `${presentCandidates} candidat présent`;
  }

  get numberOfPresentCandidates(): number {
    return this.controls.candidates.value.filter(
      (candidate) => candidate.present,
    )?.length;
  }

  get numberOfAbsentCandidatesMessage(): string {
    const absentCandidates = this.numberOfAbsentCandidates;

    return absentCandidates > 1
      ? `${absentCandidates} candidats absents`
      : `${absentCandidates} candidat absent`;
  }

  get numberOfAbsentCandidates(): number {
    return this.controls.candidates.value.filter(
      (candidate) => candidate.present === false,
    )?.length;
  }

  get controls(): SessionInformationForm {
    const form: FormGroup<SessionInformationForm> = this.form;

    return form?.controls;
  }

  updateCandidatePresence(userId: string): void {
    this.examSessionService.updatePresence(this.sessionId, userId);
  }

  updateExamSessionStep(step: SessionExamSteps): void {
    this.formManager.goToStep(step);
    this.examSessionService.patch({
      id: this.sessionId,
      step,
    });
  }

  onStartStep(step: SessionExamSteps): void {
    let data = {};
    switch (step) {
      case SessionExamStep.TRAINING_QUESTIONS:
        data = {
          label: "Démarrer les 3 questions d'entraînement pour les candidats.",
          title: "Démarrer l'entraînement",
        };
        break;
      case SessionExamStep.OFFICIAL_TEST:
        data = {
          label: "Démarrer l'examen théorique général du code de la route.",
          title: "Démarrer l'examen",
        };
        break;
      case SessionExamStep.DISPLAY_RESULTS:
        data = {
          label: 'Les résultats vont être affichés aux candidats.',
          title: 'Afficher les résultats',
        };
        break;
      case SessionExamStep.SESSION_END:
        data = {
          label: 'La session va être clôturée pour les candidats.',
          title: 'Clôturer la session',
        };
        break;
      default:
        this.updateExamSessionStep(step);
        return;
    }

    const dialog = this.dialog.open(SpecificConfirmDialogComponent, {
      data: data satisfies SpecificConfirmDialogData,
      styleClass: 'pf-two-column-form-modal',
      showHeader: false,
    });

    dialog.onClose.subscribe((confirm?: boolean) => {
      if (confirm === true) {
        this.updateExamSessionStep(step);
      }
    });
  }

  isPinButtonDisabled(): boolean {
    return this.sessionStatus().pin != null;
  }

  isStartTrainingButtonDisabled(): boolean {
    return (
      this.sessionStatus().pin === null ||
      !this.sessionStatus().allAreConnected ||
      this.sessionStatus().hasClickedOnStartTraining ||
      this.controls.informationValidated.value === false
    );
  }

  /**
   * Detect first person to achieve training
   * @param {boolean} isTrainingStep is training step
   */
  getFirstAchievement(isTrainingStep: boolean): boolean {
    // filter candidates who are not present in total
    const presentCandidates = this.getPresentCandidates();
    const achievementsNumber = this.getAchievementsNumber(
      presentCandidates,
      isTrainingStep,
    );
    return achievementsNumber >= 1;
  }

  isStartExamButtonDisabled(): boolean {
    return (
      this.sessionStatus().pin == null ||
      !this.getFirstAchievement(true) ||
      this.sessionStatus().hasClickedOnStartExam
    );
  }

  isDisplayResultsButtonDisabled(): boolean {
    return (
      !this.sessionStatus().hasClickedOnStartExam ||
      !this.getFirstAchievement(false) ||
      this.sessionStatus()?.hasClickedOnShowResult === true
    );
  }

  isEndedSessionButtonDisabled(): boolean {
    return (
      !this.sessionStatus().hasClickedOnShowResult ||
      this.sessionStatus().hasClickedOnSessionEnd
    );
  }

  generatePin(): void {
    this.examSessionService.generatePinAndGetStatus(this.sessionId);
  }

  downloadResultsCsv(): void {
    const filename = `resultats_session_${this.sessionId}.csv`;
    this.examSessionService
      .getResultsCsv(this.sessionId)
      .subscribe((resultFile) => downloadFile(resultFile, filename));
  }

  async goBack(): Promise<boolean> {
    return this.router
      .navigate([AdminRoute.Sessions])
      .then(() => true)
      .catch(() => false);
  }

  getFrenchSessionDate(): string {
    return this.formatFrenchSessionDate(
      this.dateTranslateService.getMonthLabels(),
      this.sessionDate,
    );
  }

  getLanguage(lang: keyof typeof LanguageEnum | null): string {
    return lang ? LanguageEnum[lang] : '';
  }

  getSpec(hasSpec: boolean): string {
    return hasSpec ? 'Tiers temps' : 'Aucune';
  }

  candidatePassedStepClass(hasPassed: boolean): string {
    return hasPassed
      ? 'text-primary font-bold pi-check-circle '
      : 'text-gray pi-minus-circle ';
  }

  isCandidateConnectedMessage(connected: boolean): string {
    return `Le candidat ${connected ? 'a' : "n'a pas encore"} validé ses informations`;
  }

  isCandidateFinishTrainingMessage(hasFinishedTraining: boolean): string {
    return `Le candidat ${hasFinishedTraining ? 'a' : "n'a pas encore"} terminé la session d'entraînement`;
  }

  isCandidateFinishedExamMessage(hasFinishedExam: boolean): string {
    return `Le candidat ${hasFinishedExam ? 'a' : "n'a pas encore"} terminé la session d'examen`;
  }

  isCandidateSawResultMessage(hasSawResult: boolean): string {
    return `Le candidat ${hasSawResult ? 'a' : "n'a pas encore"} vu ses résultats`;
  }

  protected readonly ExamStep = ExamStep;
}
