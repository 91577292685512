<div class="grid">
  <app-breadcrumb [items]="breadcrumbs"></app-breadcrumb>
</div>

<h1>Thèmes</h1>
<h5>Les questions sont associées à des thèmes, ces derniers permettent d'affiner le tirage des séries de chaque catégorie d'examen</h5>


<div class="flex mb-4 justify-content-end">
  <pf-action-button
    label="Créer un nouveau thème"
    customIconClass="pi pi-plus"
    (click)="openModalCreateTheme()"
    [disabled]="!canCreate"
  ></pf-action-button>
</div>

<div class="tree-node-theme-table">
  <app-settings-business-themes-tree-node
    [themes]="clonedThemes"
    (onThemeChange)="load()"
    [agent]="agent()"
  >

  </app-settings-business-themes-tree-node>
</div>
