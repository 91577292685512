import { Component, signal } from '@angular/core';
import {
  BreadcrumbComponent,
  BreadcrumbItem,
} from '@components/common/breadcrumb/breadcrumb.component';
import {
  AdminRoute,
  getSeriesArchivesDownloadedSeries,
  RouteLabel,
} from '@utils/routes';
import { DatePipe, NgIf } from '@angular/common';
import { SeriesService } from '@services/series.service';
import { PageRequest, PfTableComponent, TableColumn } from 'pf-ui';
import { ThumbnailComponent } from '@components/medias/thumbnail/thumbnail.component';
import { Serie } from '@models/serie/serie.model';
import { ButtonModule } from 'primeng/button';
import { TooltipModule } from 'primeng/tooltip';
import { RouterLink } from '@angular/router';
import { Agent, getFullName } from '@models/user/agent.model';
import { LIMIT_ROW_TABLE } from '@app/utils/constants';

@Component({
  selector: 'app-archives-series',
  standalone: true,
  imports: [
    BreadcrumbComponent,
    NgIf,
    PfTableComponent,
    ThumbnailComponent,
    DatePipe,
    ButtonModule,
    TooltipModule,
    RouterLink,
  ],
  templateUrl: './archives-series.component.html',
})
export class ArchivesSeriesComponent {
  series = this.seriesService.signalListPaginated;
  loading = signal<boolean>(false);

  breadcrumbs: BreadcrumbItem[] = [
    { label: RouteLabel.Home, route: AdminRoute.Home },
    { label: RouteLabel.SeriesArchives, route: AdminRoute.SeriesArchives },
    { label: RouteLabel.SeriesArchivesDownloadedSeries },
  ];

  columns: TableColumn[] = [
    { name: 'ID Série' },
    { name: 'Catégorie' },
    { name: 'Agent' },
    { name: 'Date de téléchargement' },
    { name: '' },
  ];

  currentPageRequest: PageRequest = {
    global_filter_fields: 'tagIds',
    global_filter: [],
    sort: 'createdDate',
    order: 'DESC',
  };

  LIMIT_ROW_TABLE = LIMIT_ROW_TABLE;

  constructor(private seriesService: SeriesService) {}

  onParametersChangedHandler(event: PageRequest): void {
    this.currentPageRequest = {
      ...this.currentPageRequest,
      ...event,
    };
    this.seriesService.listPaginated(this.currentPageRequest).subscribe();
  }

  displayCreatorName(serie: Serie): string {
    const agent: Agent | undefined = serie?.creator;
    return agent !== undefined ? getFullName(agent) : '';
  }

  protected readonly getSeriesArchivesDownloadedSeries =
    getSeriesArchivesDownloadedSeries;
}
