import { CommonModule } from '@angular/common';
import { Component, effect, input } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { AbstractFormComponent } from '@components/common/abstract/form/abstract-form.component';
import { AnswerChoicesForm, initAnswerChoiceControl, } from '@app/models/question/answerChoicesForm.model';

import { QuestionForm } from '@app/models/question/question-form.model';
import { numberToString } from '@app/utils/stringHelper';
import { PfActionButtonComponent, PfFormErrorComponent, PfFormLabelWrapperComponent, } from 'pf-ui';
import { CheckboxModule } from 'primeng/checkbox';
import { InputTextModule } from 'primeng/inputtext';

@Component({
  selector: 'app-question-answer-form',
  standalone: true,
  imports: [
    PfActionButtonComponent,
    CheckboxModule,
    InputTextModule,
    CommonModule,
    ReactiveFormsModule,
    PfFormErrorComponent,
    PfFormLabelWrapperComponent,
  ],
  templateUrl: './question-answer-form.component.html',
  styleUrl: './question-answer-form.component.scss',
})
export class QuestionAnswerFormComponent extends AbstractFormComponent {
  form = input<FormGroup<QuestionForm> | undefined>();
  control = input<FormArray<FormGroup<AnswerChoicesForm>> | undefined>();
  controlName = input<string>();
  isInit = input<boolean>(true);
  isDoubleQuestion = input<boolean>(false);

  numberToString = numberToString;

  constructor(protected fb: FormBuilder) {
    super();

    effect(() => {
      const initialAnswerChoices = this.control()?.value || [];

      if (this.isInit() && initialAnswerChoices.length === 0) {
        this.addAnswer(this.control(), 0);
        this.addAnswer(this.control(), 1);
      }
    });
  }

  addAnswer(
    answerChoicesControl: FormArray<FormGroup<AnswerChoicesForm>> | undefined,
    rank: number = 0
  ): void {
    if (!answerChoicesControl) return;

    const answer = initAnswerChoiceControl(this.fb, rank);

    answerChoicesControl.push(answer);
  }

  deleteAnswer(
    answerChoicesControl: FormArray<FormGroup<AnswerChoicesForm>> | undefined,
    index: number
  ): void {
    if (!answerChoicesControl) return;

    answerChoicesControl?.controls?.splice(index, 1);
    this.updateControl(answerChoicesControl);
  }

  updateControl(
    answerChoicesControl: FormArray<FormGroup<AnswerChoicesForm>> | undefined
  ): void {
    answerChoicesControl?.updateValueAndValidity();
    this.control()?.markAllAsTouched();
  }
}
