import { Component } from '@angular/core';
import { QuestionDetail } from '@app/models/question/question-detail.model';
import {
  PfActionButtonComponent,
  PfAlertComponent,
  PfFormActionBarComponent,
  PfFormComponent,
} from 'pf-ui';
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from 'primeng/dynamicdialog';

import { CommonModule } from '@angular/common';
import { QuestionPreviewComponent } from '@app/components/questions/question-preview/question-preview.component';
import { SpecificClosableDialogComponent } from '../specific-closable-dialog/specific-closable-dialog.component';
import { QuestionListComponent } from '@components/questions/question-list/question-list.component';
import { CheckboxModule } from 'primeng/checkbox';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { TooltipModule } from 'primeng/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { Question } from '@models/question/question.model';
import { ViewQuestionComponent } from '@components/dialogs/view-question/view-question.component';
import { ButtonModule } from 'primeng/button';
import { QuestionService } from '@services/question.service';

export type QuestionListDialogData = {
  trainingQuestions?: QuestionDetail[];
};

@Component({
  selector: 'app-question-list-dialog',
  standalone: true,
  imports: [
    CommonModule,
    PfActionButtonComponent,
    QuestionPreviewComponent,
    SpecificClosableDialogComponent,
    QuestionListComponent,
    CheckboxModule,
    PfFormComponent,
    ReactiveFormsModule,
    TooltipModule,
    PfFormActionBarComponent,
    TranslateModule,
    PfAlertComponent,
    ButtonModule,
  ],
  templateUrl: './question-list-dialog.component.html',
})
export class QuestionListDialogComponent {
  formGroup: FormGroup | undefined;

  constructor(
    private config: DynamicDialogConfig<{
      trainingQuestions?: Question[];
    }>,
    private fb: FormBuilder,
    private dialog: DynamicDialogRef,
    private subDialog: DialogService,
    private questionService: QuestionService,
  ) {
    this.initializeValue(this.config.data?.trainingQuestions ?? []);
  }

  public initializeValue(questions: Question[]): void {
    this.formGroup = this.fb.group({
      selectedQuestions: [
        questions?.map((q) => q.id) ?? [],
        Validators.required,
      ],
    });
  }

  public close(): void {
    this.dialog.close(this.selectedQuestionsControl);
  }

  public cancel(): void {
    this.dialog.close();
  }

  get selectedQuestionsControl(): Question[] {
    const questions = this.formGroup?.get('selectedQuestions');
    return questions ? questions.value : [];
  }

  get isConfirmButtonDisabled(): boolean {
    return this.selectedQuestionsControl.length > 3;
  }

  public openViewQuestionModal(question: Question): void {
    this.questionService.get(question.id).subscribe((q: QuestionDetail) => {
      this.subDialog.open(ViewQuestionComponent, {
        data: {
          question: q,
        },
        styleClass: 'pf-two-column-form-modal',
        showHeader: false,
      });
    });
  }
}
