import { CommonModule } from '@angular/common';
import { Component, effect } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
} from '@angular/forms';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import {
  BreadcrumbComponent,
  BreadcrumbItem,
} from '@components/common/breadcrumb/breadcrumb.component';
import { SpinnerComponent } from '@components/common/spinner/spinner.component';
import { QuestionCountForTheme, Theme } from '@models/question/theme.model';
import { ThemeSetting } from '@models/question/themeSetting.model';
import { TranslateModule } from '@ngx-translate/core';
import { CategoryService } from '@services/category.service';
import { ThemeService } from '@services/theme.service';
import { AdminRoute, RouteLabel } from '@utils/routes';
import {
  PfActionButtonComponent,
  PfCardComponent,
  PfFormComponent,
  PfFormErrorComponent,
  PfFormLabelWrapperComponent,
} from 'pf-ui';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { TableModule } from 'primeng/table';
import { switchMap } from 'rxjs';
import { PluralizePipe } from '@app/pipes/pluralize.pipe';
import { MessageService } from 'primeng/api';

export type ThemeForCategorySettingsForm = {
  id: AbstractControl<string | null>;
  name: AbstractControl<string | null>;
  numberPerSeries: AbstractControl<number | null>;
};

export type CategorySettingsForm = {
  categoryId: AbstractControl<string | null>;
  numberOfQuestions: AbstractControl<number | null>;
  requiredCorrectQuestions: AbstractControl<number | null>;
  themes: FormArray<FormGroup<ThemeForCategorySettingsForm>>;
};

@Component({
  selector: 'app-settings-business-categories-category',
  standalone: true,
  imports: [
    ButtonModule,
    CommonModule,
    InputTextModule,
    PfActionButtonComponent,
    PfCardComponent,
    PfFormComponent,
    PfFormErrorComponent,
    PfFormLabelWrapperComponent,
    ReactiveFormsModule,
    RouterLink,
    SpinnerComponent,
    TableModule,
    TranslateModule,
    BreadcrumbComponent,
  ],
  templateUrl: './category-settings.component.html',
  styleUrl: './category-settings.component.scss',
})
export class CategorySettingsComponent {
  category = this.categoryService.signalCurrent;
  questionCountPerThemes: Record<string, number> = {};
  rootThemes = this.themeService.signalRootThemesInCategory;
  id?: string;

  breadcrumbs: BreadcrumbItem[] = [
    { label: RouteLabel.Home, route: AdminRoute.Home },
    { label: RouteLabel.Business, route: AdminRoute.Business },
    { label: RouteLabel.Categories, route: AdminRoute.Categories },
    { label: RouteLabel.Settings },
  ];

  form: FormGroup<CategorySettingsForm>;
  loading = true;
  pluralizePipe: PluralizePipe = new PluralizePipe();

  constructor(
    public categoryService: CategoryService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    public themeService: ThemeService,
    public router: Router,
    public messageService: MessageService,
  ) {
    const routeId = this.route.snapshot.paramMap.get('id');
    this.id = routeId ?? '';

    if (this.id) {
      this.categoryService.get(this.id).subscribe();
      this.themeService.listRootThemesInCategory(this.id);
    }

    this.themeService
      .getQuestionCountPerThemesInCategory(this.id)
      .subscribe((value) => {
        value.forEach((questionCountForTheme: QuestionCountForTheme) => {
          this.questionCountPerThemes[questionCountForTheme.themeId] =
            questionCountForTheme.questionCount;
        });
      });

    this.form = this.formBuilder.group<CategorySettingsForm>({
      categoryId: formBuilder.control(this.id),
      numberOfQuestions: formBuilder.control(
        this.category()?.numberOfQuestions ?? 0,
      ),
      requiredCorrectQuestions: formBuilder.control(
        this.category()?.requiredCorrectQuestions ?? 0,
      ),
      themes: this.initThemesForm(this.rootThemes() ?? []),
    });

    effect(() => {
      const category = this.category();

      if (category === null) return;

      this.form.setControl(
        'numberOfQuestions',
        this.formBuilder.control(category.numberOfQuestions),
      );
      this.form.setControl(
        'requiredCorrectQuestions',
        this.formBuilder.control(category.requiredCorrectQuestions),
      );
    });

    effect(() => {
      const themes = this.rootThemes();
      if (themes === null) return;
      this.form.setControl('themes', this.initThemesForm(themes));
    });

    effect(() => {
      this.loading = !this.category() || !this.rootThemes();
    });
  }

  initThemesForm(
    themes: Theme[],
  ): FormArray<FormGroup<ThemeForCategorySettingsForm>> {
    return this.formBuilder.array(
      themes.map((theme) =>
        this.formBuilder.group<ThemeForCategorySettingsForm>({
          id: this.formBuilder.control(theme.id ?? ''),
          name: this.formBuilder.control(theme.name ?? ''),
          numberPerSeries: this.formBuilder.control(theme.numberPerSeries ?? 0),
        }),
      ),
    );
  }

  showError(): boolean {
    const totalQuestions = this.controls.numberOfQuestions?.value;
    if (totalQuestions === null) return true;

    const totalQuestionsInThemes = this.themesForExamSum;
    if (totalQuestionsInThemes > totalQuestions) return true;

    return totalQuestionsInThemes < totalQuestions;
  }

  showCorrectAnswersError(): boolean {
    const numberOfQuestions = this.controls.numberOfQuestions?.value;
    const requiredCorrectQuestions =
      this.controls.requiredCorrectQuestions?.value;

    if (numberOfQuestions === null || requiredCorrectQuestions === null)
      return true;

    return requiredCorrectQuestions > numberOfQuestions;
  }

  get controls(): CategorySettingsForm {
    return this.form.controls;
  }

  get themes(): FormArray<FormGroup<ThemeForCategorySettingsForm>> {
    return this.controls.themes;
  }

  get themesForExamSum(): number {
    return this.themes.controls.reduce(
      (acc, theme) => acc + (theme.value.numberPerSeries ?? 0),
      0,
    );
  }

  get title(): string {
    return this.category() !== null
      ? `Paramètres de ${this.category()?.name}`
      : 'Paramètres';
  }

  mapFormValuesToThemes(
    themes: typeof this.form.value.themes,
  ): ThemeSetting[] | undefined {
    if (themes === undefined) return undefined;

    return themes
      .map(
        (theme) =>
          ({
            themeId: theme.id ?? undefined,
            numberPerSeries: theme.numberPerSeries ?? 0,
          }) satisfies ThemeSetting,
      )
      .filter((themeSetting) => themeSetting.themeId !== undefined);
  }

  validate(): void {
    const category = this.category();
    // TODO: manage error case
    if (category == null) return;

    const formValues = this.form.value;

    const numberOfQuestions = formValues.numberOfQuestions ?? undefined;
    const requiredCorrectQuestions =
      formValues.requiredCorrectQuestions ?? undefined;
    const themes = this.mapFormValuesToThemes(formValues.themes);

    if (
      numberOfQuestions === undefined ||
      requiredCorrectQuestions === undefined ||
      themes === undefined
    )
      return;

    this.categoryService
      .patch({
        ...category,
        numberOfQuestions,
        requiredCorrectQuestions,
      })
      .pipe(
        switchMap(() =>
          this.themeService.saveThemesSettings(category.id, themes),
        ),
      )
      .subscribe(() => {
        this.messageService.add({
          severity: 'success',
          summary: 'Succès',
          detail: 'Modification enregistrée avec succès !',
        });
        this.router.navigate([AdminRoute.Categories]);
      });
  }

  getQuestionCount(themeId: string): string {
    return this.pluralizePipe.transform(
      this.questionCountPerThemes[themeId],
      'question disponible',
      'questions disponibles',
    );
  }
}
