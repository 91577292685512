import { Component } from '@angular/core';
import { AdminRoute, RouteLabel } from '@app/utils/routes';
import {
  BreadcrumbComponent,
  BreadcrumbItem,
} from '@components/common/breadcrumb/breadcrumb.component';
import { UserExamByCategoryComponent } from '@components/statistics/overview/user-exam-by-category/user-exam-by-category.component';
import { PfActionButtonComponent, PfTableComponent, TableColumn } from 'pf-ui';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { QuestionsByThemesComponent } from '@components/statistics/overview/questions-by-themes/questions-by-themes.component';
import { ExamByCategoryComponent } from '@components/statistics/overview/exam-by-category/exam-by-category.component';
import { DropdownModule } from 'primeng/dropdown';
import { AbstractFormComponent } from '@components/common/abstract/form/abstract-form.component';
import { Category, CategoryForm } from '@models/question/category.model';
import { PluralizePipe } from '@app/pipes/pluralize.pipe';
import { CategoryService } from '@services/category.service';
import { NgIf } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { RouterLink } from '@angular/router';
import { QuestionsStatsComponent } from '@components/statistics/questionsStats/questions-stats.component';
import { FlaggedQuestionsStatsComponent } from '@components/statistics/flaggedQuestionsStats/flagged-questions-stats.component';

@Component({
  selector: 'app-statistics',
  standalone: true,
  imports: [
    PfTableComponent,
    BreadcrumbComponent,
    DropdownModule,
    ReactiveFormsModule,
    UserExamByCategoryComponent,
    ExamByCategoryComponent,
    QuestionsByThemesComponent,
    NgIf,
    PfActionButtonComponent,
    ButtonModule,
    RouterLink,
    QuestionsStatsComponent,
    FlaggedQuestionsStatsComponent,
  ],
  templateUrl: './statistics.component.html',
  styleUrl: './statistics.component.scss',
})
export class StatisticsComponent extends AbstractFormComponent {
  protected readonly AdminRoute = AdminRoute;

  category = this.categoryService.signalCurrent;
  form: FormGroup<CategoryForm> | undefined;

  categoriesList: Category[] = [];

  columns: TableColumn[] = [
    { name: 'Questions' },
    { name: 'Utilisation' },
    { name: 'Réussite' },
    { name: 'Réponse' },
    { name: 'Thèmes' },
    { name: 'Catégories' },
    { name: '' },
  ];

  pipe: PluralizePipe = new PluralizePipe();
  selectedCategory: Category | null = null;

  breadcrumbs: BreadcrumbItem[] = [
    { label: RouteLabel.Home, route: AdminRoute.Home },
    { label: RouteLabel.Statistics },
  ];

  constructor(
    public categoryService: CategoryService,
    public fb: FormBuilder,
  ) {
    super();
    this.categoryService
      .list()
      .subscribe(
        (val) => (this.categoriesList = val._embedded?.category ?? []),
      );

    this.form = this.initForm(this.fb);
  }

  initForm(fb?: FormBuilder): FormGroup<CategoryForm> | undefined {
    if (!fb) return;

    return fb.group<CategoryForm>({
      selectedCategory: fb.control(this.selectedCategory),
    });
  }

  get controls(): CategoryForm | undefined {
    return this.form?.controls;
  }
}
