import { CommonModule } from '@angular/common';
import { Component, effect, OnDestroy, untracked } from '@angular/core';
import { RouterModule } from '@angular/router';
import {
  CandidateStep,
  DISPLAYED_EXAM_STEPS,
  ExamStep,
  ExamSteps,
  examStepTranslation,
  ExamStepType,
  examStepWaitingMessage,
} from '@app/models/exam/examSteps.model';
import { AuthService } from '@app/services/auth.service';
import { ExamSessionService } from '@app/services/examSession.service';
import { UserExamSessionService } from '@app/services/userExamSession.service';
import { FormManager, PfFormWrapperComponent, PfStepsComponent } from 'pf-ui';
import { MenuItem } from 'primeng/api/menuitem';
import { WaitingPageComponent } from '@components/common/waiting-page/waiting-page.component';
import { PersonalInformationComponent } from './personal-information/personal-information.component';
import { ResultsComponent } from '@pages/candidate/results/results.component';
import { stringInjector } from '@utils/stringHelper';
import { ExplanationComponent } from '@pages/candidate/explanation/explanation.component';
import { CandidateExamSessionService } from '@services/candidateExamSession.service';
import { QuestionsComponent } from '@pages/candidate/questions/questions.component';
import { TrainingCorrectionComponent } from '@pages/candidate/training-correction/training-correction.component';
import { SpinnerComponent } from '@components/common/spinner/spinner.component';
import { UserService } from '@services/user.service';

@Component({
  selector: 'app-candidate',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    PersonalInformationComponent,
    PfFormWrapperComponent,
    PfStepsComponent,
    ResultsComponent,
    WaitingPageComponent,
    ExplanationComponent,
    QuestionsComponent,
    TrainingCorrectionComponent,
    SpinnerComponent,
  ],
  templateUrl: './candidate.component.html',
  styleUrl: './candidate.component.scss',
})
export class CandidateComponent implements OnDestroy {
  examSession = this.examSessionService.signalExamSession;
  currentStep = this.candidateExamSessionService.getCurrentStep();
  isExamScreenDisplayed =
    this.candidateExamSessionService.isExamScreenDisplayed();
  userExamSession = this.userExamSessionService.signalUserExamSession;

  formManager: FormManager<ExamSteps>;
  stepperSteps: MenuItem[] = [];
  ExamStep = ExamStep;
  providerId: string;

  get steps(): CandidateStep[] {
    return Object.values(ExamStep).map((step: ExamStepType) => {
      const waitingMessage = examStepWaitingMessage?.[step];

      return {
        name: step,
        title: examStepTranslation[step]?.stepTitle || '',
        labelButton: '',
        waitingMessage:
          waitingMessage !== undefined
            ? stringInjector(
                waitingMessage,
                'exam_session_name',
                this.userExamSession()?.category?.name ?? '',
              )
            : undefined,
      };
    });
  }

  get currentStepPageTitle(): string {
    const currentStep = this.steps[this.formManager.currentStep];
    return examStepTranslation[currentStep.name]?.pageTitle;
  }

  constructor(
    private examSessionService: ExamSessionService,
    private userService: UserService,
    private userExamSessionService: UserExamSessionService,
    private candidateExamSessionService: CandidateExamSessionService,
    private authService: AuthService,
  ) {
    this.formManager = new FormManager();
    this.formManager.setSteps(this.steps);
    this.stepperSteps = this.filterStepperSteps();

    const providerIdAndSessionCode =
      this.authService.getProviderIdAndSessionCode();
    this.providerId = providerIdAndSessionCode.providerId;

    this.candidateExamSessionService.getGlobalInformations();

    effect(() => {
      const examSession = this.examSession();
      this.userService.getUser(this.providerId).subscribe();
      if (examSession == null) return; // TODO: manage error case

      const pin = this.examSession()?.pin;
      if (pin !== null && pin !== undefined) {
        this.userExamSessionService
          .getUserExamSessionByPinAndProviderId(pin, this.providerId)
          .subscribe();
      }
    });

    // Listening CurrentStep
    effect(() => {
      const currentStep = this.currentStep();
      if (!currentStep) return; // TODO: manage error case
      this.formManager.goToStep(currentStep);
    });
  }

  filterStepperSteps = (): MenuItem[] => {
    const fullSteps = this.formManager.formatStepForStepper();
    return fullSteps.filter((step) => step.label);
  };

  isStepperDisplayed(): boolean {
    const currentStep = this.currentStep();
    if (!currentStep) return false; // TODO: manage error case
    return DISPLAYED_EXAM_STEPS.includes(currentStep);
  }

  goToTrainingWaitingExplanation = (): void => {
    const examSession = this.examSession();
    if (examSession == null) return; // TODO: manage error case

    // confirm session and clear session answers
    this.userExamSessionService.updateStatusConfirmed(
      examSession.id,
      this.providerId,
    );

    // first display waiting page
    this.candidateExamSessionService.updateCurrentStep(
      ExamStep.WAITING_TRAINING,
    );
  };

  getWaitingMessage = (): string | undefined => {
    const currentStep = this.steps[this.formManager.currentStep];
    return currentStep.waitingMessage;
  };

  goToTrainingStep(): void {
    const examSession = this.examSession();
    if (examSession == null) return; // TODO: manage error case
    // update DB first
    this.userExamSessionService
      .updateStatusFinishedExplanation(examSession.id, this.providerId)
      .subscribe({
        next: () => {
          this.candidateExamSessionService.updateCurrentStep(
            ExamStep.TRAINING_QUESTIONS,
          );
        },
      });
  }

  ngOnDestroy(): void {
    untracked(() => {
      this.examSession.set(null);
      this.candidateExamSessionService.updateCurrentStep(null);
    });
    this.candidateExamSessionService.ngOnDestroy();
  }
}
