import { CommonModule } from '@angular/common';
import { Component, computed, effect, input } from '@angular/core';

import { HttpClientModule } from '@angular/common/http';
import { SpinnerComponent } from '@app/components/common/spinner/spinner.component';
import { AnswerChoices } from '@app/models/question/answerChoices.model';
import { MediaService } from '@app/services/media.service';
import { UserExamSessionService } from '@app/services/userExamSession.service';
import { numberToString } from '@app/utils/stringHelper';
import { ButtonModule } from 'primeng/button';

@Component({
  selector: 'app-question-correction-answers',
  standalone: true,
  imports: [ButtonModule, CommonModule, HttpClientModule, SpinnerComponent],
  templateUrl: './question-correction-answers.component.html',
  styleUrl: './question-correction-answers.component.scss',
})
export class QuestionCorrectionAnswersComponent {
  userAnswerIds = computed(() =>
    this.userExamSessionService.signalCurrentAnswers()?.map((ac) => ac?.id),
  );

  questionAnswers = input<AnswerChoices[] | undefined>([]);

  candidateAnswersCorrection: { [key: number]: boolean | null } = {};
  numberToString = numberToString;

  loading = false;

  constructor(
    public mediaService: MediaService,
    public userExamSessionService: UserExamSessionService,
  ) {
    effect(
      () => {
        const candidateAnswerIds = this.userAnswerIds();
        const questionAnswers = this.questionAnswers();

        if (candidateAnswerIds === null || questionAnswers === undefined)
          return;

        this.resetAnswerCorrection();
        this.setAnswerCorrection(candidateAnswerIds, questionAnswers);
      },
      {
        allowSignalWrites: true,
      },
    );
  }

  resetAnswerCorrection(): void {
    this.candidateAnswersCorrection = {};
  }

  setAnswerCorrection(
    candidateAnswerIds: string[],
    questionAnswers: AnswerChoices[],
  ): void {
    questionAnswers.forEach((choice, index) => {
      const isCorrect = choice.correct ?? false;
      const isSelectedByCandidate = candidateAnswerIds.includes(choice.id);

      // Cas 1 : il a sélectionné : réponse incorrecte ==> isCORRECT = FALSE
      // Cas 2 : il a sélectionné : réponse correcte ==> ISCORRECT = TRUE
      // CAs 3 : il n'a pas sélectionné : réponse correcte ==> ISCORRECT = TRUE
      if (!isSelectedByCandidate && !isCorrect) {
        return;
      }
      this.candidateAnswersCorrection[index] = isCorrect;
    });
  }

  isCandidateAnswer(choice: AnswerChoices): boolean {
    return this.userAnswerIds().includes(choice.id) ?? false;
  }

  getAnswerCorrectionStyle(answerIndex: number): string {
    const isCorrect = this.candidateAnswersCorrection[answerIndex];

    if (isCorrect === true) return 'success';
    if (isCorrect === false) return 'error';
    return 'p-button-tertiary not-selected';
  }
}
