import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';

import { SpinnerComponent } from '@app/components/common/spinner/spinner.component';
import { ViewQuestionComponent } from '@app/components/dialogs/view-question/view-question.component';
import { QuestionDetail } from '@app/models/question/question-detail.model';
import { TruncatePipeModule } from '@app/pipes/truncate.pipe';
import { MediaService } from '@app/services/media.service';
import { QuestionService } from '@app/services/question.service';
import { AdminRoute, getQuestionIdRoute, RouteLabel } from '@app/utils/routes';
import { TranslateModule } from '@ngx-translate/core';
import { PfActionButtonComponent } from 'pf-ui';
import { ButtonModule } from 'primeng/button';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TableModule } from 'primeng/table';
import {
  BreadcrumbComponent,
  BreadcrumbItem,
} from '@components/common/breadcrumb/breadcrumb.component';
import { TooltipModule } from 'primeng/tooltip';
import { QuestionListComponent } from '@components/questions/question-list/question-list.component';
import { hasAuthority } from '@models/user/agent-detail.model';
import { AgentService } from '@services/agent.service';
import { DropdownModule } from 'primeng/dropdown';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { QuestionStatus } from '@models/question/questionStatus.model';
import {
  SpecificConfirmDialogComponent,
  SpecificConfirmDialogData,
} from '@components/dialogs/specific-confirm-dialog/specific-confirm-dialog.component';
import { Question } from '@models/question/question.model';
import { MessageService } from 'primeng/api';

export const MAX_QUESTION_DESCRIPTION_LENGTH = 75;

@Component({
  selector: 'app-questions',
  standalone: true,
  templateUrl: './questions.component.html',
  imports: [
    ButtonModule,
    CommonModule,
    PfActionButtonComponent,
    RouterLink,
    SpinnerComponent,
    TableModule,
    TranslateModule,
    TruncatePipeModule,
    BreadcrumbComponent,
    TooltipModule,
    QuestionListComponent,
    DropdownModule,
    FormsModule,
    ReactiveFormsModule,
  ],
})
export class QuestionsComponent {
  AdminRoute = AdminRoute;
  form: FormGroup | undefined;
  getQuestionIdRoute = getQuestionIdRoute;
  agent = this.agentService.signalCurrent;

  breadcrumbs: BreadcrumbItem[] = [
    { label: RouteLabel.Home, route: AdminRoute.Home },
    { label: RouteLabel.Questions },
  ];

  visualizationDialogRef: DynamicDialogRef = new DynamicDialogRef();
  suspendDialogRef: DynamicDialogRef = new DynamicDialogRef();

  constructor(
    public questionService: QuestionService,
    private mediaService: MediaService,
    public dialogService: DialogService,
    public agentService: AgentService,
    public messageService: MessageService,
    public fb: FormBuilder,
  ) {
    this.questionService.signalCurrent.set(new QuestionDetail());
    this.mediaService.signalCurrent.set(null);
  }

  get canShow(): boolean {
    return hasAuthority(this.agent(), 'question.view');
  }

  get canEdit(): boolean {
    return hasAuthority(this.agent(), 'question.edit');
  }

  canSuspend(question: Question): boolean {
    if (hasAuthority(this.agent(), 'question.validate')) {
      return question.questionStatus !== QuestionStatus.SUSPENDED;
    }
    return false;
  }

  get canCreate(): boolean {
    return hasAuthority(this.agent(), 'question.create');
  }

  openSuspendModal(question: Question): void {
    if (!this.canSuspend(question)) return;
    this.questionService.get(question.id).subscribe((question) => {
      this.suspendDialogRef = this.dialogService.open(
        SpecificConfirmDialogComponent,
        {
          data: {
            title: 'Suspendre la question',
            label: 'Voulez-vous suspendre la question ?',
            confirmLabel: 'Annuler',
            cancelLabel: 'Confirmer',
          } satisfies SpecificConfirmDialogData,
          styleClass: 'pf-two-column-form-modal',
          showHeader: false,
        },
      );

      this.suspendDialogRef.onClose.subscribe((cancel?: boolean) => {
        if (cancel === false) {
          this.questionService.suspend(question).subscribe({
            error: () => {
              this.messageService.add({
                severity: 'error',
                detail: 'Suspension de la question échouée',
                summary: 'Erreur',
              });
            },
            next: () => {
              this.questionService
                .listPaginated({
                  global_filter: [QuestionStatus.VALIDATED],
                  global_filter_fields: 'questionStatus',
                })
                .subscribe();
              this.messageService.add({
                severity: 'success',
                detail: 'Suspension de la question réussi',
                summary: 'Opération réussi',
              });
            },
          });
        }
      });
    });
  }

  openViewQuestionModal(questionId: string): void {
    this.questionService.get(questionId).subscribe((question) => {
      this.visualizationDialogRef = this.dialogService.open(
        ViewQuestionComponent,
        {
          data: {
            question,
          },
          styleClass: 'pf-two-column-form-modal',
          showHeader: false,
        },
      );

      this.visualizationDialogRef.onClose.subscribe(() => {
        this.questionService.signalCurrent.set(new QuestionDetail());
      });
    });
  }

  protected readonly QuestionStatus = QuestionStatus;
  protected readonly Object = Object;
}
