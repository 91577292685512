import { Component, input } from '@angular/core';
import { BreadcrumbModule } from 'primeng/breadcrumb'
import { MenuItem } from 'primeng/api'
import { NgIf } from '@angular/common'
import { PfActionButtonComponent } from 'pf-ui'
import { ButtonModule } from 'primeng/button'

export interface BreadcrumbItem extends MenuItem {
  label: string,
  route?: string,
  action?: () => void
}

@Component({
  selector: 'app-breadcrumb',
  standalone: true,
  imports: [
    BreadcrumbModule,
    NgIf,
    PfActionButtonComponent,
    ButtonModule
  ],
  styleUrl: './breadcrumb.component.scss',
  templateUrl: './breadcrumb.component.html',
})
export class BreadcrumbComponent {
  items = input<BreadcrumbItem[]>();
  disabled = input<boolean>(false);

  constructor() {}

  callAction(item: BreadcrumbItem): void {
    if (item.action && !this.disabled()) item.action()
  }
}
