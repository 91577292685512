<pf-form *ngIf="currentForm" [formGroup]="currentForm">
  <div class="grid">
    <div class="col-12 flex justify-content-start">
      <pf-action-button
        styleClass="p-button-tertiary"
        label="Question à double énoncés"
        type="custom"
        (buttonClicked)="addDoubleQuestion()"
      >
      </pf-action-button>
    </div>
    <pf-card class="col-12" title="Choisir le média">
      <div class="grid grid-nogutter">
        <pf-form-label-wrapper
          [showErrors]="showErrors(currentForm, 'picture')"
          class="col-12"
        >
          <ng-template #errors>
            <pf-form-error
              *ngIf="controls?.picture?.errors?.['required']"
              label="Le média est obliagoire. Veuillez le renseigner"
            ></pf-form-error>
          </ng-template>
        </pf-form-label-wrapper>
        <div class="col-12 flex justify-content-start">
          <pf-action-button
            [disabled]="mediaUrls() === undefined"
            styleClass="p-button-tertiary button-link"
            label="Sélectionner depuis la banque de médias"
            customIconClass="pi pi-plus"
            (buttonClicked)="openSelectMediaModal()"
          >
          </pf-action-button>
          <pf-action-button
            styleClass="p-button-tertiary button-link"
            label="Télécharger à partir de mon ordinateur"
            customIconClass="pi pi-upload"
            (buttonClicked)="openUploadMediaModal()"
          >
          </pf-action-button>
        </div>
        <div class="col-12 medium-image-container">
          <p-image
            *ngIf="imagePath"
            [src]="imagePath"
            alt="Image"
            [preview]="true"
          ></p-image>
          <p-skeleton *ngIf="!imagePath" animation="none"></p-skeleton>
        </div>
        <div class="col-12 flex justify-content-start">
          <pf-action-button
            styleClass="p-button-tertiary button-link"
            label="Supprimer le média"
            customIconClass="pi pi-trash"
            (buttonClicked)="emptyPicture()"
          >
          </pf-action-button>
        </div>
      </div>
    </pf-card>
    <pf-card
      [class.col-12]="!isDoubleQuestion()"
      [class.col-6]="isDoubleQuestion()"
      [title]="descriptionTitle"
    >
      <app-question-description-form
        [form]="currentForm"
        [control]="controls?.description"
        controlName="description"
      >
      </app-question-description-form>
    </pf-card>
    <pf-card
      [class.hidden]="!isDoubleQuestion()"
      [class.col-6]="isDoubleQuestion()"
      [title]="descriptionTitle"
    >
      <app-question-description-form
        [form]="currentForm"
        [control]="controls?.secondDescription"
        controlName="secondDescription"
      >
      </app-question-description-form>
    </pf-card>
    <pf-card
      [class.col-12]="!isDoubleQuestion()"
      [class.col-6]="isDoubleQuestion()"
      [title]="answerChoicesTitle"
    >
      <app-question-answer-form
        [form]="currentForm"
        [control]="controls?.answerChoices"
        controlName="answerChoices"
        [isDoubleQuestion]="isDoubleQuestion()"
      >
      </app-question-answer-form>
    </pf-card>
    <pf-card
      [class.hidden]="!isDoubleQuestion()"
      [class.col-6]="isDoubleQuestion()"
      [title]="answerChoicesTitle"
    >
      <app-question-answer-form
        [form]="currentForm"
        [control]="controls?.secondAnswerChoices"
        controlName="secondAnswerChoices"
        [isDoubleQuestion]="isDoubleQuestion()"
        [isInit]="isDoubleQuestion()"
      >
      </app-question-answer-form>
    </pf-card>
    <div class="col-12 flex justify-content-end gap-3">
      <pf-action-button
        label="Visualiser la question"
        (buttonClicked)="validate(false)"
      ></pf-action-button>
      <pf-action-button
        styleClass="p-button-tertiary"
        label="Valider"
        (buttonClicked)="validate(true)"
      ></pf-action-button>
    </div>
  </div>
</pf-form>
