import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { CommonModule } from '@angular/common';

@Pipe({
  name: 'pluralize'
})
export class PluralizePipe implements PipeTransform {

  transform(value: number, singular: string, plural?: string): string {
    if (!singular) {
      return '';
    }

    if (value <= 1) {
      return `${value} ${singular}`;
    }

    if (plural != null) {
      return `${value} ${plural}`;
    }

    // Simple pluralization by adding 's'
    return `${value} ${singular}s`;
  }
}

@NgModule({
  declarations: [PluralizePipe],
  imports: [CommonModule],
  exports: [PluralizePipe],
})
export class PluralizePipeModule {}
