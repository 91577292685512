import { CommonModule, NgForOf, NgOptimizedImage } from '@angular/common';
import { Component, effect, signal, untracked } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { Question } from '@app/models/question/question.model';
import { TruncatePipeModule } from '@app/pipes/truncate.pipe';
import {
  BreadcrumbComponent,
  BreadcrumbItem,
} from '@components/common/breadcrumb/breadcrumb.component';
import { ViewQuestionComponent } from '@components/dialogs/view-question/view-question.component';
import { Media } from '@models/media/media.model';
import { Category } from '@models/question/category.model';
import { hasAuthority } from '@models/user/agent-detail.model';
import { TranslateModule } from '@ngx-translate/core';
import { MAX_QUESTION_DESCRIPTION_LENGTH } from '@pages/questions/questions.component';
import { AgentService } from '@services/agent.service';
import { CategoryService } from '@services/category.service';
import { MediaService } from '@services/media.service';
import { QuestionService } from '@services/question.service';
import { SeriesService } from '@services/series.service';
import { getImageSafeUrl } from '@utils/media';
import { AdminRoute, RouteLabel, getQuestionIdRoute } from '@utils/routes';
import { PfActionButtonComponent, PfTableComponent, TableColumn } from 'pf-ui';
import { SharedModule } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TableModule } from 'primeng/table';

@Component({
  selector: 'app-category-series',
  standalone: true,
  imports: [
    TranslateModule,
    RouterLink,
    ButtonModule,
    NgForOf,
    PfActionButtonComponent,
    SharedModule,
    TableModule,
    CommonModule,
    TruncatePipeModule,
    NgOptimizedImage,
    PfTableComponent,
    BreadcrumbComponent,
  ],
  templateUrl: './category-series.component.html',
})
export class CategorySeriesComponent {
  getImageSafeUrl = getImageSafeUrl;
  getQuestionIdRoute = getQuestionIdRoute;
  id: string;

  category: Category | undefined;
  viewQuestionModalRef: DynamicDialogRef = new DynamicDialogRef();

  breadcrumbs: BreadcrumbItem[] = [
    { label: RouteLabel.Home, route: AdminRoute.Home },
    { label: RouteLabel.Business, route: AdminRoute.Business },
    { label: RouteLabel.Categories, route: AdminRoute.Categories },
    { label: RouteLabel.Series },
  ];

  columns: TableColumn[] = [
    { name: 'Question' },
    { name: 'Média' },
    { name: '' },
  ];

  values = this.seriesService.signalSeries;
  agent = this.agentService.signalCurrent;
  mediaUrls = signal<Record<string, SafeUrl | undefined> | undefined>(
    undefined,
  );

  rowsLength: number = this.values()?.length ?? 4;

  constructor(
    protected questionService: QuestionService,
    private route: ActivatedRoute,
    private seriesService: SeriesService,
    private categoryService: CategoryService,
    public dialogService: DialogService,
    public agentService: AgentService,
    public mediaService: MediaService,
  ) {
    this.id = this.route.snapshot.paramMap.get('id') ?? '';
    this.categoryService.get(this.id).subscribe((val) => (this.category = val));
    this.loadNewSeries();

    effect(
      () => {
        const questions = this.values();
        if (questions == null || questions.length <= 0) return;

        this.loadImagesForEachQuestion(questions);
      },
      { allowSignalWrites: true },
    );
  }

  loadImagesForEachQuestion(questions: Question[]): void {
    questions?.forEach((question) => {
      const media = question.picture as Media;
      const keyName = media?.keyName;
      if (keyName !== undefined) {
        this.mediaService.getImageThumbnail(keyName).subscribe((mediaUrl) => {
          this.updateMediaUrls(keyName, mediaUrl);
        });
      }
    });
  }

  updateMediaUrls(keyName: string, mediaUrl: SafeUrl | undefined): void {
    untracked(() => {
      this.mediaUrls.update((currentRecord) => ({
        ...currentRecord,
        [keyName ?? '']: mediaUrl,
      }));
    });
  }

  get canCreate(): boolean {
    return hasAuthority(this.agent(), 'business.manage');
  }

  loadNewSeries(): void {
    this.seriesService.getSeries(this.id).subscribe();
  }

  openViewQuestionModal(questionId: string): void {
    this.questionService.get(questionId).subscribe((question) => {
      this.viewQuestionModalRef = this.dialogService.open(
        ViewQuestionComponent,
        {
          data: {
            question,
          },
          styleClass: 'pf-two-column-form-modal',
          showHeader: false,
        },
      );
    });
  }

  protected readonly MAX_QUESTION_DESCRIPTION_LENGTH =
    MAX_QUESTION_DESCRIPTION_LENGTH;
}
