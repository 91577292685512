import { CommonModule } from '@angular/common';
import { Component, effect, untracked } from '@angular/core';

import { PersonalDataItemComponent } from '@app/components/exam/personal-data-item/personal-data-item.component';
import { QuestionPreviewComponent } from '@app/components/questions/question-preview/question-preview.component';
import { ExamStep } from '@app/models/exam/examSteps.model';
import { QuestionDetail } from '@app/models/question/question-detail.model';
import { AuthService } from '@app/services/auth.service';
import { ExamSessionService } from '@app/services/examSession.service';

import { UserExamSessionService } from '@app/services/userExamSession.service';

import { TranslateModule } from '@ngx-translate/core';

import { ExamSession } from '@models/exam/examSession.model';
import { CandidateExamSessionService } from '@services/candidateExamSession.service';
import { DEFAULT_WAITING_PAGE_TIMEOUT } from '@utils/constants';
import { PfFormComponent, PfFormSummaryEditionWrapperComponent } from 'pf-ui';
import { CheckboxModule } from 'primeng/checkbox';

@Component({
  selector: 'app-candidate-training-correction',
  standalone: true,
  imports: [
    CheckboxModule,
    CommonModule,
    TranslateModule,
    PfFormComponent,
    PersonalDataItemComponent,
    PfFormSummaryEditionWrapperComponent,
    QuestionPreviewComponent,
  ],
  templateUrl: './training-correction.component.html',
  styleUrl: '../questions/questions.component.scss',
})
export class TrainingCorrectionComponent {
  currentQuestion = this.userExamSessionService.signalCurrentQuestion;
  trainingQuestions = this.userExamSessionService.signalQuestions;
  examSession = this.examSessionService.signalExamSession;

  currentQuestionIndex = 0;
  providerId: string;

  constructor(
    private authService: AuthService,
    protected examSessionService: ExamSessionService,
    public userExamSessionService: UserExamSessionService,
    private candidateExamSessionService: CandidateExamSessionService,
  ) {
    this.providerId = this.authService.getProviderIdAndSessionCode().providerId;

    effect(() => {
      const examSession = this.examSession();
      if (examSession == null) return; // TODO: manage error case

      // Fetching training answers
      this.userExamSessionService
        .getTrainingAnswers(examSession.id, this.providerId)
        .subscribe();

      untracked(() => {
        const trainingQuestions = this.trainingQuestions();
        if (trainingQuestions) {
          this.initQuestion(trainingQuestions);
        } else if (examSession != null) {
          this.fetchTrainingQuestions(examSession);
        }
      });
    });
  }

  fetchTrainingQuestions(examSession: ExamSession): void {
    this.userExamSessionService
      .getTrainingQuestions(examSession.id, this.providerId)
      .subscribe((trainingQuestions) => {
        this.initQuestion(trainingQuestions);
      });
  }

  goToWaitingExam(): void {
    const examSession = this.examSession();
    if (examSession == null) return; // TODO: manage error case

    this.userExamSessionService.updateStatusFinishedTraining(
      examSession.id,
      this.providerId,
    );

    this.userExamSessionService.setCurrentQuestion(null);
    this.candidateExamSessionService.updateCurrentStep(ExamStep.WAITING_EXAM);
    // On bloque l'affichage de la page d'attente pendant au moins 5 sec
    this.candidateExamSessionService.updateIsWaitingExamScreenDisplayedWithDelay(
      true,
      DEFAULT_WAITING_PAGE_TIMEOUT,
    );
  }

  initQuestion(questions: QuestionDetail[] | null): void {
    if (!questions) {
      console.info('No question found');
      return;
    }

    if (questions.length > this.currentQuestionIndex) {
      const currentQuestionId = questions[this.currentQuestionIndex].id;
      this.userExamSessionService.getQuestion(currentQuestionId).subscribe();
    } else {
      this.goToWaitingExam();
    }
  }

  setNextQuestion(): void {
    this.currentQuestionIndex++;
    this.initQuestion(this.trainingQuestions());
  }
}
