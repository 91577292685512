<app-specific-closable-dialog
  title="Éditer le média"
  (closeClicked)="close()"
  [showCloseButton]="true"
>
  <pf-form *ngIf="form" [formGroup]="form">
    <div class="grid">
      <pf-step
        [classList]="'col-12 w-24rem'"
        summary="Sélectionner les étiquettes associées"
      ></pf-step>

      <div class="grid col-12 ml-2 pl-7">
        <div *ngFor="let tag of mediaTags; index as i" class="col-4">
          <p-checkbox
            class="lg:col-3 col-2"
            [className]="'white-space-normal'"
            [formControl]="$any(form).controls['tags']"
            [inputId]="'tags' + tag.id"
            [label]="tag.name"
            [value]="tag"
          >
          </p-checkbox>
        </div>
      </div>

      <div class="col-12 flex justify-content-end">
        <pf-action-button
          styleClass="p-button-tertiary"
          label="Valider"
          (buttonClicked)="close()"
        ></pf-action-button>
      </div>
    </div>
  </pf-form>
</app-specific-closable-dialog>
