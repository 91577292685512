<div class="explanation-page h-screen align-content-center">
  <div class="section text-xl relative">
    <h4>
      Instructions pour l'épreuve théorique générale du permis de conduire
    </h4>
    <p>
      Nous allons procéder à l'épreuve théorique générale du permis de conduire.
    </p>
    <p>
      L'examen comporte <b>20 questions</b> et débutera après la projection de
      <b>3 questions préliminaires</b>, qui ne seront pas prises en compte dans
      votre résultat final.
    </p>
    <ul>
      <li>
        Questions 1 à 17 : appliquez la réglementation en vigueur en Polynésie
        Française.
      </li>
      <li>
        Question 18 à 20 : appliquez la législation en vigueur en Métropole, car
        le permis de conduire délivré en Polynésie est valide au niveau
        national.
      </li>
    </ul>
    <p>
      Pour chaque question, sélectionnez <b>la</b> ou
      <b>les réponses correctes</b>. Certaines questions peuvent avoir une,
      deux, voire trois réponses exactes.
    </p>
    <p><u>À retenir :</u></p>
    <ul>
      <li>
        Vous êtes le conducteur du véhicule de tourisme d'où les photos ont été
        prises.
      </li>
      <li>
        Lors des situations de dépassement, votre réserve de vitesse vous permet
        de dépasser normalement.
      </li>
    </ul>
    <p>
      Pour assurer le bon déroulement de l'épreuve, nous vous demandons de ne
      pas communiquer entre vous par quelque moyen que ce soit. Toute
      communication entraînera votre exclusion de la salle et l'ajournement de
      l'examen.
    </p>
    <p>Merci de patienter avant le lancement de l'examen par le surveillant.</p>
    <div class="circle-countdown">
      <pf-circle-countdown
        #countdown
        [start]="startTimer"
        [countdownDuration]="DEFAULT_EXPLANATION"
        [countdownColors]="['#073ca5', '#5986e0', '#f78c01', '#cc0000']"
        [countdownColorTime]="countdownColorTimes()"
        (onCountDownCompleted)="loadTrainingStep()"
      >
      </pf-circle-countdown>
    </div>
  </div>
</div>
