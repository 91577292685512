import { Category } from '../question/category.model';
import { SessionExamSteps } from './examSteps.model';
import {
  LanguageEnum,
  UserExamSession,
} from '@models/exam/userExamSession.model';

export type ExamSession = {
  id: string;
  sessionDate?: string;
  pin?: string;
  activatedAt?: Date;
  information?: string;
  island?: Island;
  step?: SessionExamSteps;
  userExamSessionDTO?: UserExamSession[];
};

export type Island = {
  id: string;
  name: string;
};

export type ExamSessionStatusDTO = {
  id: string;
  sessionDate?: string;
  pin?: string | null;
  hasClickedOnStartTraining: boolean;
  hasClickedOnStartExam: boolean;
  hasClickedOnShowResult: boolean;
  hasClickedOnSessionEnd: boolean;
  hasValidatedCandidatesInformation: boolean;
  allAreConnected: boolean;
  allHaveFinishedTraining: boolean;
  allHaveFinishedExam: boolean;
  userExamSessionStatusDTOS: UserExamSessionStatusDTO[];
};

export type UserExamSessionStatusDTO = {
  providerId: string;

  category: Category;
  language: LanguageEnum;
  additionalTime: boolean;

  present: boolean;
  connected: boolean;
  hasFinishedTraining: boolean;
  hasFinishedExam: boolean;
  hasFinishedExplanation: boolean;
  hasFinishedTrainingExamples: boolean;
  hasSawResult: boolean;
  lastSavedQuestionId?: string | null;
  examSessionStatus?: ExamSessionStatusDTO;
};

export type ExamSessionByDate = {
  [date: string]: ExamSession[];
};

export const defaultExamSession: ExamSession = {
  id: '',
};
