<div class="section relative flex flex-column justify-content-between">
  <app-session-tooltip-helper/>
  <pf-form-summary-edition-wrapper
    class="personal-information-summary-edition-wrapper"
    [editionAvailable]="false"
    [isReadOnly]="true"
  >
    <ng-template #formContent>
      <pf-form-summary-edition-wrapper
        [editionAvailable]="false"
        [isReadOnly]="true"
        title="Mes informations candidat"
        class="w-6 inside-wrapper"
      >
        <ng-template #formContent>
          <app-personal-data-item
            [label]="'PF.CREATE_USER.FIELDS.ID.LABEL' | translate"
            [value]="providerId"
          ></app-personal-data-item>
        </ng-template>
      </pf-form-summary-edition-wrapper>

      <div class="my-6 flex">
        <pf-form-summary-edition-wrapper
          [editionAvailable]="false"
          [isReadOnly]="true"
          title="Ma session d'examen"
          class="w-6 inside-wrapper"
        >
          <ng-template #formContent>
            <app-personal-data-item
              label="Examen"
              [value]="categoryName()"
            ></app-personal-data-item>
            <app-personal-data-item
              label="Date"
              [value]="sessionDate()"
            ></app-personal-data-item>
            <app-personal-data-item
              label="Heure"
              [value]="sessionTime()"
            ></app-personal-data-item>
          </ng-template>
        </pf-form-summary-edition-wrapper>

        <pf-form-summary-edition-wrapper
          [editionAvailable]="false"
          [isReadOnly]="true"
          title="Détails"
          class="w-6 inside-wrapper"
        >
          <ng-template #formContent>
            <app-personal-data-item
              label="Nombre de questions"
              [value]="examQuestionsCount()"
            ></app-personal-data-item>
            <app-personal-data-item
              label="Langue"
              [value]="language()"
            ></app-personal-data-item>
            <app-personal-data-item
              label="Spécificité"
              [value]="specificity()"
            ></app-personal-data-item>
          </ng-template>
        </pf-form-summary-edition-wrapper>
      </div>
    </ng-template>
  </pf-form-summary-edition-wrapper>

  <div class="flex justify-content-end">
    <pf-action-button
      styleClass="p-button-tertiary p-button-validateInformation"
      iconPosition="right"
      customIconClass="pi pi-arrow-right"
      label="Valider mes informations"
      type="custom"
      (click)="validateInformation()"
    >
    </pf-action-button>
  </div>
</div>
