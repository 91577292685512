import { AgentDetail } from './agent-detail.model';

export type Agent = Omit<AgentDetail, 'agentRoles' | 'island'> & {
  island?: string;
  agentRoles?: string[];
};

export const getFullName = (agent: Agent): string => {
  return [agent?.firstName, agent?.lastName].filter(Boolean).join(' ');
};
