import { Routes } from '@angular/router';
import { CandidateComponent } from '@pages/candidate/candidate.component';
import { CandidateLoginComponent } from '@pages/candidate/login/login.component';
import { CreateSessionComponent } from '@pages/create-session/create-session.component';
import { AnalyzedQuestionsComponent } from '@pages/home/analyzed-question/analyzed-questions.component';
import { HomeComponent } from '@pages/home/home.component';
import { MediaComponent } from '@pages/media/media.component';
import { QuestionComponent } from '@pages/questions/question/question.component';
import { QuestionsComponent } from '@pages/questions/questions.component';
import { SessionComponent } from '@pages/sessions/session/session.component';
import { SessionsComponent } from '@pages/sessions/sessions.component';
import { BusinessComponent } from '@pages/settings/business/business.component';
import { CategoriesComponent } from '@pages/settings/business/categories/categories.component';
import { CategorySettingsComponent } from '@pages/settings/business/categories/category-settings/category-settings.component';
import { CategorySeriesComponent } from '@pages/settings/business/categories/series/category-series.component';

import { SeriesComponent } from '@pages/series/series/series.component';
import { TagsComponent } from '@pages/settings/business/tags/tags.component';
import { ThemesComponent } from '@pages/settings/business/themes/themes.component';
import { TrainingQuestionsComponent } from '@pages/settings/business/training-questions/training-questions.component';
import { UsersComponent } from '@pages/settings/users/users.component';
import { CategoriesComponent as CategoriesStatsComponent } from '@pages/statistics/categories/categories.component';
import { QuestionsComponent as QuestionsStatsComponent } from '@pages/statistics/questions/questions.component';
import { StatisticsComponent } from '@pages/statistics/statistics.component';
import { ThemesComponent as StatsThemeComponent } from '@pages/statistics/themes/themes.component';
import { AdminRoute, CandidateRoute, RouteLabel } from '@utils/routes';
import { MenuItem } from 'primeng/api';
import { AuthGuard } from './guard/auth.guard';
import { CandidateAuthGuard } from './guard/candidate-auth.guard';
import {
  AgentDetail,
  hasAnyAuthorities,
  hasAuthority,
} from '@models/user/agent-detail.model';
import { ArchivesSeriesComponent } from '@pages/series/archives/series/archives-series.component';
import { ArchivesComponent } from '@pages/series/archives/archives.component';
import { SerieComponent } from '@pages/series/archives/series/serie/serie.component';
import { LogsUsersComponent } from '@pages/series/archives/logs/logs-users.component';

const unprotectedAdminRoutes: Routes = [
  { path: AdminRoute.Home, component: HomeComponent },
  {
    path: AdminRoute.AnalyzedQuestionId,
    component: AnalyzedQuestionsComponent,
  },
  {
    path: AdminRoute.Questions,
    component: QuestionsComponent,
    data: {
      permissions: ['question.view'],
    },
  },
  {
    path: AdminRoute.Question,
    component: QuestionComponent,
    data: {
      permissions: ['question.view'],
    },
  },
  {
    path: AdminRoute.QuestionId,
    component: QuestionComponent,
    data: {
      permissions: ['question.view'],
    },
  },
  {
    path: AdminRoute.Media,
    component: MediaComponent,
    data: {
      permissions: ['media.view'],
    },
  },
  {
    path: AdminRoute.Series,
    component: SeriesComponent,
    data: {
      permissions: ['serie.view'],
    },
  },
  {
    path: AdminRoute.SeriesGenerate,
    component: SeriesComponent,
    data: {
      permissions: ['serie.view'],
    },
  },
  {
    path: AdminRoute.SeriesArchives,
    component: ArchivesComponent,
    data: {
      permissions: ['archive.view'],
    },
  },
  {
    path: AdminRoute.SeriesArchivesLogsUsers,
    component: LogsUsersComponent,
    data: {
      permissions: ['archive.view'],
    },
  },
  {
    path: AdminRoute.SeriesArchivesDownloadedSeries,
    component: ArchivesSeriesComponent,
    data: {
      permissions: ['archive.view'],
    },
  },
  {
    path: AdminRoute.SeriesArchivesDownloadedSeriesId,
    component: SerieComponent,
    data: {
      permissions: ['archive.view'],
    },
  },
  {
    path: AdminRoute.SeriesId,
    component: CategorySeriesComponent,
    data: {
      permissions: ['business.manage'],
    },
  },
  {
    path: AdminRoute.SessionId,
    component: SessionComponent,
    data: {
      permissions: ['session.view.all', 'session.view.external'],
    },
  },
  {
    path: AdminRoute.Sessions,
    component: SessionsComponent,
    data: {
      permissions: ['session.view.all', 'session.view.external'],
    },
  },
  {
    path: AdminRoute.Session,
    component: CreateSessionComponent,
    data: {
      permissions: ['session.create'],
    },
  },
  {
    path: AdminRoute.Statistics,
    component: StatisticsComponent,
    data: {
      permissions: ['stat.view'],
    },
  },
  {
    path: AdminRoute.Settings,
    component: BusinessComponent,
    data: {
      permissions: ['business.manage'],
    },
  },
  {
    path: AdminRoute.Users,
    component: UsersComponent,
    data: {
      permissions: ['agent.manage'],
    },
  },
  {
    path: AdminRoute.Business,
    component: BusinessComponent,
    data: {
      permissions: ['business.manage'],
    },
  },
  {
    path: AdminRoute.TrainingQuestions,
    component: TrainingQuestionsComponent,
    data: {
      permissions: ['business.manage'],
    },
  },
  {
    path: AdminRoute.Categories,
    component: CategoriesComponent,
    data: {
      permissions: ['category.view'],
    },
  },
  {
    path: AdminRoute.CategoryId,
    component: CategorySettingsComponent,
    data: {
      permissions: ['category.edit'],
    },
  },
  {
    path: AdminRoute.Themes,
    component: ThemesComponent,
    data: {
      permissions: ['theme.view'],
    },
  },
  {
    path: AdminRoute.StatsQuestions,
    component: QuestionsStatsComponent,
    data: {
      permissions: ['stat.view'],
    },
  },
  {
    path: AdminRoute.StatsThemes,
    component: StatsThemeComponent,
    data: {
      permissions: ['stat.view'],
    },
  },
  {
    path: AdminRoute.StatsCategories,
    component: CategoriesStatsComponent,
    data: {
      permissions: ['stat.view'],
    },
  },
  {
    path: AdminRoute.Tags,
    component: TagsComponent,
    data: {
      permissions: ['mediatag.view'],
    },
  },
];

const adminRoutes = unprotectedAdminRoutes.map((unprotectedRoute) => ({
  ...unprotectedRoute,
  canActivate: [AuthGuard],
}));

export const adminMenuItems: MenuItem[] = [
  {
    label: RouteLabel.Home,
    icon: 'pi pi-table',
    routerLink: [AdminRoute.Home],
    routerLinkActiveOptions: { exact: true },
  },
];

export const questionsMenuItems = (agent: AgentDetail | null): MenuItem => {
  return {
    label: 'Questions',
    icon: 'pi pi-list',
    routerLink: [AdminRoute.Questions],
    visible: hasAuthority(agent, 'question.view'),
    items: [
      {
        label: RouteLabel.Question,
        icon: 'pi pi-plus',
        routerLink: [AdminRoute.Question],
        routerLinkActiveOptions: { exact: true },
        visible: hasAnyAuthorities(agent, ['question.create', 'question.edit']),
      },
      {
        label: RouteLabel.Questions,
        icon: 'pi pi-list',
        routerLink: [AdminRoute.Questions],
        routerLinkActiveOptions: { exact: true },
        visible: hasAuthority(agent, 'question.view'),
      },
      {
        label: RouteLabel.Media,
        icon: 'pi pi-images',
        routerLink: [AdminRoute.Media],
        routerLinkActiveOptions: { exact: true },
        visible: hasAuthority(agent, 'media.view'),
      },
    ],
  };
};

export const seriesMenuItems = (agent: AgentDetail | null): MenuItem => {
  return {
    label: 'Séries',
    icon: 'pi pi-file',
    visible: hasAnyAuthorities(agent, ['serie.view', 'archive.view']),
    routerLink: [AdminRoute.Series],
    items: [
      {
        label: RouteLabel.SeriesGenerate,
        icon: 'pi pi-plus',
        routerLink: [AdminRoute.Series],
        routerLinkActiveOptions: { exact: true },
        visible: hasAuthority(agent, 'serie.view'),
      },
      {
        label: RouteLabel.SeriesArchives,
        icon: 'pi pi-inbox',
        routerLink: [AdminRoute.SeriesArchives],
        routerLinkActiveOptions: { exact: true },
        visible: hasAuthority(agent, 'archive.view'),
      },
    ],
  };
};

export const sessionsMenuItems = (agent: AgentDetail | null): MenuItem => {
  return {
    label: RouteLabel.Sessions,
    icon: 'pi pi-calendar',
    styleClass: 'p-menuitem-link-active',
    routerLink: [AdminRoute.Sessions],
    visible: hasAnyAuthorities(agent, [
      'session.view.all',
      'session.view.external',
    ]),
    items: [
      {
        label: 'Programme des sessions',
        icon: 'pi pi-clock',
        routerLink: [AdminRoute.Sessions],
        routerLinkActiveOptions: { exact: true },
        visible: hasAnyAuthorities(agent, [
          'session.view.all',
          'session.view.external',
        ]),
      },
      {
        label: RouteLabel.Session,
        icon: 'pi pi-calendar-plus',
        routerLink: [AdminRoute.Session],
        routerLinkActiveOptions: { exact: true },
        visible: hasAuthority(agent, 'session.create'),
      },
    ],
  };
};

export const statisticsMenuItems = (agent: AgentDetail | null): MenuItem => {
  return {
    label: RouteLabel.Statistics,
    icon: 'pi pi-chart-bar',
    routerLink: [AdminRoute.Statistics],
    visible: hasAuthority(agent, 'stat.view'),
  };
};

export const agentManageMenuItems = (agent: AgentDetail | null): MenuItem => {
  return {
    label: RouteLabel.Users,
    icon: 'pi pi-users',
    routerLink: [AdminRoute.Users],
    routerLinkActiveOptions: { exact: true },
    visible: hasAuthority(agent, 'agent.manage'),
  };
};

export const businessManageMenuItems = (
  agent: AgentDetail | null,
): MenuItem => {
  return {
    label: RouteLabel.Business,
    icon: 'pi pi-briefcase',
    routerLink: [AdminRoute.Business],
    routerLinkActiveOptions: { exact: true },
    visible: hasAuthority(agent, 'business.manage'),
  };
};

export const settingsMenuItems = (agent: AgentDetail | null): MenuItem => {
  return {
    label: RouteLabel.Settings,
    icon: 'pi pi-cog',
    routerLink: [AdminRoute.Settings],
    visible: hasAnyAuthorities(agent, ['business.manage', 'agent.manage']),
  };
};

export const routes: Routes = [
  ...adminRoutes,
  {
    path: CandidateRoute.Home,
    component: CandidateComponent,
    canActivate: [CandidateAuthGuard],
  },
  {
    path: CandidateRoute.Login,
    component: CandidateLoginComponent,
  },
  {
    path: 'admin',
    children: [{ path: '**', redirectTo: AdminRoute.Home, pathMatch: 'full' }],
  },
  { path: '**', redirectTo: CandidateRoute.Login, pathMatch: 'full' },
];
