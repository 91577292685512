<div
  *ngIf="loading"
  class="flex text-center align-items-center justify-content-center h-full"
>
  <app-spinner />
</div>
<div *ngIf="!loading" class="grid">
  <div
    *ngFor="let choice of questionAnswers(); let answerIndex = index"
    class="col-6"
  >
    <div>
      <p-button
        [disabled]="true"
        [label]="choice.description ?? ''"
        iconPos="right"
        [icon]="isCandidateAnswer(choice) ? 'pi pi-check' : ''"
        [styleClass]="
          'w-full flex gap-4 text-900 text-lg align-items-center ' +
          getAnswerCorrectionStyle(answerIndex)
        "
      >
        <div class="text-2xl">{{ numberToString(answerIndex) }}</div>
      </p-button>
    </div>
  </div>
</div>
