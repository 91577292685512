import { CommonModule } from '@angular/common';
import { Component, effect, input, untracked } from '@angular/core';

import { PersonalDataItemComponent } from '@app/components/exam/personal-data-item/personal-data-item.component';
import { QuestionPreviewComponent } from '@app/components/questions/question-preview/question-preview.component';
import { ExamSession } from '@app/models/exam/examSession.model';
import { ExamStep } from '@app/models/exam/examSteps.model';
import { QuestionDetail } from '@app/models/question/question-detail.model';
import { Question } from '@app/models/question/question.model';
import { AuthService } from '@app/services/auth.service';
import { ExamSessionService } from '@app/services/examSession.service';

import { UserExamSessionService } from '@app/services/userExamSession.service';

import { TranslateModule } from '@ngx-translate/core';

import { AppService } from '@services/app.service';
import { CandidateExamSessionService } from '@services/candidateExamSession.service';
import { MediaService } from '@services/media.service';
import { PfFormComponent, PfFormSummaryEditionWrapperComponent } from 'pf-ui';
import { CheckboxModule } from 'primeng/checkbox';

@Component({
  selector: 'app-candidate-questions',
  standalone: true,
  imports: [
    CheckboxModule,
    CommonModule,
    TranslateModule,
    PfFormComponent,
    PersonalDataItemComponent,
    PfFormSummaryEditionWrapperComponent,
    QuestionPreviewComponent,
  ],
  templateUrl: './questions.component.html',
  styleUrl: './questions.component.scss',
})
export class QuestionsComponent {
  trainingQuestions = input<boolean>(true);
  currentQuestion = this.userExamSessionService.signalCurrentQuestion;
  examSession = this.examSessionService.signalExamSession;
  userExamSession = this.userExamSessionService.signalUserExamSession;

  currentQuestionIndex = 0;
  totalQuestions = 0;
  providerId: string;

  constructor(
    private authService: AuthService,
    protected examSessionService: ExamSessionService,
    public userExamSessionService: UserExamSessionService,
    private candidateExamSessionService: CandidateExamSessionService,
    private mediaService: MediaService,
    private appService: AppService,
  ) {
    this.appService.updateHeaderDisplay(false);
    this.providerId = this.authService.getProviderIdAndSessionCode().providerId;
    this.mediaService.fetchStaticVoices().subscribe();

    effect(() => {
      const examSession = this.examSession();
      if (examSession == null) return; // TODO: manage error case

      untracked(() => {
        if (this.trainingQuestions()) {
          this.fetchTrainingQuestions(examSession);
        } else {
          this.fetchExamQuestions(examSession);
        }
      });
    });
  }

  fetchExamQuestions(examSession: ExamSession): void {
    this.userExamSessionService
      .getQuestions(examSession.id, this.providerId)
      .subscribe((examQuestions) => {
        this.currentQuestionIndex = this.getInitialQuestionIndex(examQuestions);
        this.initQuestion(examQuestions);
      });
  }

  fetchTrainingQuestions(examSession: ExamSession): void {
    this.userExamSessionService
      .getTrainingQuestions(examSession.id, this.providerId)
      .subscribe((trainingQuestions) => {
        this.currentQuestionIndex =
          this.getInitialQuestionIndex(trainingQuestions);
        this.initQuestion(trainingQuestions);
      });
  }

  get questionList(): (Question | QuestionDetail)[] | null {
    return this.userExamSessionService.signalQuestions();
  }

  goToSessionEnd(examSessionId: string): void {
    this.userExamSessionService
      .updateStatusFinishedExam(examSessionId, this.providerId)
      .subscribe({
        next: () => {
          this.candidateExamSessionService.updateCurrentStep(
            ExamStep.SESSION_END,
          );
        },
      });
  }

  endingQuestions(): void {
    const examSession = this.examSession();
    if (examSession == null) return; // TODO: manage error case
    if (this.trainingQuestions()) {
      this.userExamSessionService.setCurrentQuestion(null);
      // update DB first
      this.userExamSessionService
        .updateStatusFinishedTrainingExamples(examSession.id, this.providerId)
        .subscribe({
          next: () => {
            this.candidateExamSessionService.updateCurrentStep(
              ExamStep.TRAINING_CORRECTION,
            );
          },
        });
    } else {
      this.goToSessionEnd(examSession.id);
    }
  }

  getInitialQuestionIndex(questions: QuestionDetail[]): number {
    const lastSavedQuestionId = this.userExamSession()?.lastSavedQuestionId;
    let currentIdx = 0;
    if (lastSavedQuestionId != null) {
      const lastSavedQuestionIdx = questions.findIndex(
        (question) => question.id === lastSavedQuestionId,
      );
      if (lastSavedQuestionIdx === questions?.length - 1) {
        currentIdx = lastSavedQuestionIdx;
      } else {
        currentIdx = lastSavedQuestionIdx + 1;
      }
    }
    return currentIdx;
  }

  initQuestion(questions: (Question | QuestionDetail)[] | null): void {
    if (!questions) {
      console.warn('No question found');
      return;
    }

    // EXAMEN OU EN ENTRAINEMENT EN COURS
    this.totalQuestions = questions.length;
    const currentQuestionId = questions[this.currentQuestionIndex]?.id;
    this.userExamSessionService.getQuestion(currentQuestionId).subscribe();
  }

  setNextQuestion(): void {
    if (this.currentQuestionIndex < this.totalQuestions - 1) {
      this.currentQuestionIndex++;
      this.initQuestion(this.questionList);
    } else {
      this.endingQuestions();
    }
  }
}
