import { Component } from '@angular/core';
import { BreadcrumbComponent, BreadcrumbItem } from '@components/common/breadcrumb/breadcrumb.component';
import { AdminRoute, getCategoryIdRoute, RouteLabel } from '@utils/routes';
import { CategoryService } from '@services/category.service';
import { PfActionButtonComponent } from 'pf-ui';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-settings-business',
  standalone: true,
  imports: [
    BreadcrumbComponent,
    PfActionButtonComponent,
    RouterLink,
  ],
  templateUrl: './business.component.html',
})
export class BusinessComponent {
  breadcrumbs: BreadcrumbItem[] = [
    { label: RouteLabel .Home, route: AdminRoute.Home },
    { label: RouteLabel.Business }
  ];

  constructor(public categoryService: CategoryService) {
  }

  protected readonly AdminRoute = AdminRoute;
  protected readonly getCategoryIdRoute = getCategoryIdRoute;
}
