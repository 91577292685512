<div class="grid">
  <app-breadcrumb [items]="breadcrumbs"></app-breadcrumb>
</div>

<h1>Séries téléchargées</h1>

<pf-table
  [response]="series()"
  [isLazyMode]="true"
  [columns]="columns"
  noResultLabel="Aucune série n'a été téléchargée"
  (onParametersChanged)="onParametersChangedHandler($event)"
  [rows]="LIMIT_ROW_TABLE"
  [displaySkeleton]="true"
  [showCaption]="true"
  [showSearch]="false"
  title="Liste des séries téléchargées"
  dataKey="id"
  [rowsPerPageOptions]="[10, 20]"
  styleClass="mb-5"
>
  <ng-template #content let-serie>
    <tr>
      <td>
        {{ serie.id }}
      </td>
      <td class="w-10rem">{{ serie.category?.name }}</td>
      <td>
        {{ displayCreatorName(serie) }}
      </td>
      <td>Le {{ serie.createdDate | date: "dd/MM/yyyy à H:mm" }}</td>
      <td class="icon-button">
        <p-button
          icon="pi pi-eye"
          [rounded]="true"
          size="small"
          pTooltip="visualiser"
          ariaLabel="visualiser"
          tooltipPosition="top"
          [routerLink]="getSeriesArchivesDownloadedSeries(serie.id)"
        ></p-button>
      </td>
    </tr>
  </ng-template>
</pf-table>
