import { Component, effect } from '@angular/core';
import { AdminRoute, RouteLabel } from '@utils/routes';
import {
  BreadcrumbComponent,
  BreadcrumbItem,
} from '@components/common/breadcrumb/breadcrumb.component';
import { PfActionButtonComponent, TableColumn } from 'pf-ui';
import { ThemeService } from '@services/theme.service';
import { ThemesTreeComponent } from '@pages/settings/business/themes/treeNodeThemes/themesTree.component';
import { Theme } from '@models/question/theme.model';
import { ButtonModule } from 'primeng/button';
import { TooltipModule } from 'primeng/tooltip';
import { MessageService } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CreateThemeComponent } from '@pages/settings/business/themes/create/create-theme.component';
import { AgentService } from '@services/agent.service';
import { hasAuthority } from '@models/user/agent-detail.model';

export type ThemeEventType = {
  theme: Theme;
  parent: { level: number; theme: Theme };
};

@Component({
  selector: 'app-settings-business-themes',
  standalone: true,
  templateUrl: './themes.component.html',
  imports: [
    BreadcrumbComponent,
    ThemesTreeComponent,
    PfActionButtonComponent,
    ButtonModule,
    TooltipModule,
  ],
})
export class ThemesComponent {
  columns: TableColumn[] = [
    { name: 'Thèmes' },
    { name: 'Utilisation' },
    { name: 'Réussite' },
    { name: '' },
  ];

  agent = this.agentService.signalCurrent;

  breadcrumbs: BreadcrumbItem[] = [
    { label: RouteLabel.Home, route: AdminRoute.Home },
    { label: RouteLabel.Business, route: AdminRoute.Business },
    { label: RouteLabel.Themes },
  ];

  createThemeDialogRef: DynamicDialogRef = new DynamicDialogRef();

  themes = this.themeService.signalList;
  clonedThemes?: Theme[];

  rowsLength: number = this.themes?.length ?? 4;

  constructor(
    public themeService: ThemeService,
    public messageService: MessageService,
    public dialog: DialogService,
    private agentService: AgentService
  ) {
    this.load();
    effect(() => {
      this.clonedThemes = this.themes();
    });
  }

  load(): void {
    this.themeService.list();
  }

  openModalCreateTheme(): void {
    if(!this.canCreate) return;

    this.createThemeDialogRef = this.dialog.open(CreateThemeComponent, {
      styleClass: 'pf-two-column-form-modal',
      showHeader: false,
    });

    this.createThemeDialogRef.onClose.subscribe((name?: string) => {
      if (name != null) {
        this.createTheme(name);
      }
    });
  }

  get canCreate(): boolean {
    return hasAuthority(this.agent(), 'theme.create');
  }

  createTheme(name: string): void {
    const theme = new Theme();
    theme.name = name;
    this.themeService.create(theme).subscribe({
      error: () => {
        this.load();
        this.messageService.add({
          severity: 'error',
          detail: 'Impossible de créer le thème',
          summary: 'Erreur',
        });
      },
      complete: () => {
        this.load();
        this.messageService.add({
          severity: 'success',
          detail: 'Thème créé avec succès',
          summary: 'Succès',
        });
      },
    });
  }
}
