import { CommonModule } from '@angular/common';
import { Component, effect } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AgentDetail, hasAuthority } from '@app/models/user/agent-detail.model';
import { Agent } from '@app/models/user/agent.model';
import { IslandService } from '@app/services/island.service';
import { LIMIT_ROW_TABLE } from '@app/utils/constants';
import { AbstractEditableColumn } from '@components/common/abstract/table/editable/abstractEditableColumn';
import {
  BreadcrumbComponent,
  BreadcrumbItem,
} from '@components/common/breadcrumb/breadcrumb.component';
import { Role } from '@models/user/role.model';
import { AgentService } from '@services/agent.service';
import { RoleService } from '@services/role.service';
import { AdminRoute, RouteLabel } from '@utils/routes';
import { PageRequest, PfTableComponent, TableColumn } from 'pf-ui';
import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { DialogService } from 'primeng/dynamicdialog';
import { MultiSelectModule } from 'primeng/multiselect';
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';
import { Observable, of, switchMap } from 'rxjs';

@Component({
  selector: 'app-settings-users',
  standalone: true,
  imports: [
    ButtonModule,
    CommonModule,
    BreadcrumbComponent,
    PfTableComponent,
    TableModule,
    MultiSelectModule,
    FormsModule,
    TooltipModule,
    DropdownModule,
  ],
  templateUrl: './users.component.html',
})
export class UsersComponent extends AbstractEditableColumn<AgentDetail> {
  breadcrumbs: BreadcrumbItem[] = [
    { label: RouteLabel.Home, route: AdminRoute.Home },
    { label: RouteLabel.Settings, route: AdminRoute.Business },
    { label: RouteLabel.Users },
  ];

  currentAgent = this.agentService.signalCurrent;
  agents = this.agentService.signalList;
  roles = this.roleService.signalList;
  islands = this.islandService.signalList;

  editableItems: AgentDetail[] | undefined;

  columns: TableColumn[] = [
    { name: 'Matricule' },
    { name: 'Nom', sortName: 'firstName' },
    { name: 'Prénom', sortName: 'lastName' },
    { name: 'Lieu' },
    { name: 'Rôle(s)' },
    { name: '' },
  ];

  initialPageRequest: PageRequest = {
    global_filter: [],
  };

  currentPageRequest = this.initialPageRequest;

  loading: boolean = false;

  LIMIT_ROW_TABLE = LIMIT_ROW_TABLE;

  constructor(
    public agentService: AgentService,
    public roleService: RoleService,
    public islandService: IslandService,
    public override messageService: MessageService,
    public override dialog: DialogService,
  ) {
    super(messageService, dialog);

    effect(() => {
      this.editableItems = (this.agents()?.result as AgentDetail[]) ?? [];
    });
  }

  load(): void {
    of(undefined)
      .pipe(
        switchMap(() => {
          if (this.islands().length === 0) {
            return this.islandService.list();
          }
          return of(undefined);
        }),
        switchMap(() => {
          if (this.roles().length === 0) {
            return this.roleService.list();
          }
          return of(undefined);
        }),
        switchMap(() => this.agentService.list(this.currentPageRequest)),
      )
      .subscribe();
  }

  onParametersChangedHandler(event: PageRequest): void {
    this.currentPageRequest = {
      ...this.currentPageRequest,
      ...event,
      sort: 'firstName',
    };
    this.load();
  }

  renderRoles(values: Role[]): string {
    return values.map((x) => x.displayName).join(', ');
  }

  delete(item: AgentDetail): Observable<AgentDetail> {
    return of(item);
  }

  reloadItems(): void {
    this.onParametersChangedHandler(this.currentPageRequest);
  }

  save(agent: AgentDetail): Observable<AgentDetail> {
    const mappedAgent = {
      id: agent.id,
      sub: agent.sub,
      firstName: agent.firstName,
      lastName: agent.lastName,
      island: agent.island != null ? agent.island.id : undefined,
      agentRoles: agent.agentRoles?.map((role) => role.id),
    } satisfies Agent;

    return this.agentService.patch(mappedAgent);
  }

  canEditAgent(agent: Agent): boolean {
    return (
      (hasAuthority(this.currentAgent(), 'agent.manage') &&
        !this.isCurrentAgentSelf(agent)) ||
      this.currentAgentIsAdmin()
    );
  }

  isCurrentAgentSelf(agent: Agent): boolean {
    return (
      this.currentAgent()?.id != null && this.currentAgent()?.id == agent.id
    );
  }

  currentAgentIsAdmin(): boolean {
    return (
      this.currentAgent()?.agentRoles?.some(
        (role) => role.name === 'SUPER_ADMIN' || role.name === 'ADMIN_TENANT',
      ) ?? false
    );
  }
}
