import { CommonModule } from '@angular/common';
import { Component, input } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ReactiveFormsModule, } from '@angular/forms';
import { AbstractFormComponent } from '@components/common/abstract/form/abstract-form.component';

import { QuestionForm } from '@app/models/question/question-form.model';
import { PfFormErrorComponent, PfFormLabelWrapperComponent } from 'pf-ui';
import { InputTextModule } from 'primeng/inputtext';

@Component({
  selector: 'app-question-description-form',
  standalone: true,
  imports: [
    InputTextModule,
    CommonModule,
    ReactiveFormsModule,
    PfFormErrorComponent,
    PfFormLabelWrapperComponent,
  ],
  templateUrl: './question-description-form.component.html',
})
export class QuestionDescriptionFormComponent extends AbstractFormComponent {
  form = input<FormGroup<QuestionForm> | undefined>();
  control = input<
    | AbstractControl<string | null | undefined, string | null | undefined>
    | undefined
  >();
  controlName = input<string>();

  constructor(protected fb: FormBuilder) {
    super();
  }
}
