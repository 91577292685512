<div class="grid">
  <app-breadcrumb [items]="breadcrumbs"></app-breadcrumb>
</div>

<h1>Voir une série</h1>
<h5>Vérifier votre série d’examen</h5>

<div class="flex mb-4 justify-content-end">
  <div class="table-header flex justify-content-end">
    <pf-action-button
      *ngIf="canCreate"
      label="Charger une nouvelle série"
      (click)="loadNewSeries()"
    ></pf-action-button>
  </div>
</div>

<pf-table
  [data]="values()"
  [isLazyMode]="true"
  noResultLabel="Pas de série"
  [rows]="rowsLength"
  [columns]="columns"
  [displaySkeleton]="true"
  [showCaption]="true"
  [title]="'Série ' + category?.name"
  styleClass="mb-5"
>
  <ng-template pTemplate="caption">
    <div class="table-header flex justify-content-end">
      <pf-action-button
        label="Charger une nouvelle série"
        (click)="loadNewSeries()"
      ></pf-action-button>
    </div>
  </ng-template>

  <ng-template #header>
    <tr>
      <th
        *ngFor="let column of columns"
        [id]="'id_' + column"
        class="font-normal"
      >
        {{ column }}
      </th>
    </tr>
  </ng-template>

  <ng-template #content let-question>
    <tr>
      <td>
        {{ question.description | truncate: MAX_QUESTION_DESCRIPTION_LENGTH }}
      </td>
      <td>
        <div
          *ngIf="question.picture; else elseBlock"
          class="image-"
          style="width: 100px"
        >
          <img
            [src]="getImageSafeUrl(question.picture, mediaUrls())"
            alt="{{ question.picture.keyName }}"
            style="object-fit: contain; width: 100%"
          />
        </div>
        <ng-template #elseBlock>Pas de photo</ng-template>
      </td>
      <td class="icon-button">
        <div class="flex">
          <p-button
            icon="pi pi-eye"
            [rounded]="true"
            size="large"
            (click)="openViewQuestionModal(question.id)"
          ></p-button>
          <p-button
            icon="pi pi-file-edit"
            [rounded]="true"
            size="large"
            [routerLink]="[getQuestionIdRoute(question.id)]"
          ></p-button>
        </div>
      </td>
    </tr>
  </ng-template>
</pf-table>
