export const QuestionStep = {
  CREATE_QUESTION: 'CREATE_QUESTION',
  CHARACTERIZE_QUESTION: 'CHARACTERIZE_QUESTION',
  SUMMARY_QUESTION: 'SUMMARY_QUESTION',
} as const;

export const questionStepTranslation: Record<QuestionSteps, string> = {
  [QuestionStep.CREATE_QUESTION]: 'Créer une question',
  [QuestionStep.CHARACTERIZE_QUESTION]: 'Caractériser une question',
  [QuestionStep.SUMMARY_QUESTION]: 'Synthèse de la question',
};

export type QuestionSteps = (typeof QuestionStep)[keyof typeof QuestionStep];
