<pf-table
  [data]="editableItems"
  noResultLabel="Pas de thème"
  dataKey="id"
  class="tree-node-theme-table"
  [showCaption]="false"
>
  @if (level() === 0) {
    <ng-template #header>
      <tr>
        <th style=" width: 5rem" scope="col"></th>
        <th scope="col">Nom</th>
        <th scope="col"></th>
      </tr>
    </ng-template>
  }
  <ng-template #content let-theme let-expanded="expanded">
    <tr>
      <td class="icon-button">
        <div [style]="'margin-left: ' + getMarginSize(theme)">
          <p-button *ngIf="hasChildren(theme)"
            [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
            [rounded]="true"
            size="small"
            [pRowToggler]="theme"
            [ariaLabel]="expanded ? 'Replier le thème': 'Déplier le thème'"
            [pTooltip]="expanded ? 'Replier le thème': 'Déplier le thème'"
            tooltipPosition="top"
          ></p-button>
        </div>
      </td>
      <td>
        @if (isEditing(theme)) {
          <input
            class="w-full"
            pInputText
            type="text"
            [(ngModel)]="theme.name"/>
        } @else {
          <div class="flex w-full align-items-center gap-2">
            {{ theme.name }}
          </div>
        }
      </td>
      <td class="icon-button">
        <div class="flex justify-content-end">
          @if(!isEditing(theme) && level() <= 1){
            <p-button *ngIf="!isAsserting(theme)"
              ariaLabel="Ajouter un sous-thème"
              pTooltip="Ajouter un sous-thème"
              tooltipPosition="top"
              icon="pi pi-plus"
              [rounded]="true"
              [disabled]="!canCreate"
              (onClick)="addNew(theme)"
            ></p-button>
          }
          <p-button *ngIf="isAsserting(theme)"
            ariaLabel="Annuler"
            pTooltip="Annuler"
            tooltipPosition="top"
            icon="pi pi-times"
            [rounded]="true"
            (onClick)="cancelAssertion(theme)"
          ></p-button>
          <p-button *ngIf="isEditing(theme)"
            ariaLabel="Annuler"
            pTooltip="Annuler"
            tooltipPosition="top"
            icon="pi pi-times"
            [rounded]="true"
            (onClick)="onRowEditCancel(theme)"
          ></p-button>
          <p-button *ngIf="isEditing(theme)"
            ariaLabel="Enregistrer"
            pTooltip="Enregistrer"
            tooltipPosition="top"
            icon="pi pi-check"
            [rounded]="true"
            [disabled]="!canEdit"
            (onClick)="onRowEditSave(theme)"
          ></p-button>
          <p-button *ngIf="!isEditing(theme)"
            ariaLabel="Modifier le nom"
            pTooltip="Modifier le nom"
            tooltipPosition="top"
            icon="pi pi-pencil"
            [rounded]="true"
            [disabled]="!canEdit || isAsserting(theme)"
            (onClick)="onRowEditInit(theme)"
          ></p-button>
          <p-button *ngIf="!isEditing(theme)"
            ariaLabel="Supprimer le thème"
            pTooltip="Supprimer le thème"
            tooltipPosition="top"
            icon="pi pi-trash"
            [rounded]="true"
            [disabled]="!canDelete || isAsserting(theme)"
            (onClick)="openModalConfirmDelete(theme)"
          ></p-button>
        </div>
      </td>
    </tr>
    <tr *ngIf="isAsserting(theme)">
      <td>  </td>
      <td>
        <input
          class="w-full"
          pInputText
          type="text"
          [(ngModel)]="newTheme.name"/>
      </td>
      <td class="icon-button">
        <div class="flex justify-content-end">
          <p-button ariaLabel="Annuler"
                    pTooltip="Annuler"
                    tooltipPosition="top"
                    icon="pi pi-times"
                    [rounded]="true"
                    (onClick)="cancelAssertion(theme)"
          ></p-button>
          <p-button ariaLabel="Enregistrer"
                    [disabled]="!canEdit"
                    pTooltip="Enregistrer"
                    tooltipPosition="top"
                    icon="pi pi-check"
                    [rounded]="true"
                    (onClick)="onAssertion(theme)"
          ></p-button>
        </div>
      </td>
    </tr>
  </ng-template>

  <ng-template #expansion let-theme>
    <tr>
      <td colspan="7" class="justify-content-center p-0">
        <div>
          <app-settings-business-themes-tree-node
            [themes]="theme.children"
            [level]="level()+1"
            [agent]="agent()"
            (onAddNewThemeAtPosition)="handleAddNewThemeAtPositionClick($event, theme)"
            (onThemeChange)="reloadItems()"
          >
          </app-settings-business-themes-tree-node>
        </div>
      </td>
    </tr>
  </ng-template>
</pf-table>
