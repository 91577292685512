import { Component } from '@angular/core';
import { AdminRoute, RouteLabel } from '@app/utils/routes';
import {
  BreadcrumbComponent,
  BreadcrumbItem,
} from '@components/common/breadcrumb/breadcrumb.component';
import { TableColumn } from 'pf-ui';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { Category, CategoryForm } from '@models/question/category.model';
import { NgIf } from '@angular/common';
import { CategoryService } from '@services/category.service';
import { AbstractFormComponent } from '@components/common/abstract/form/abstract-form.component';
import { QuestionsStatsComponent } from '@components/statistics/questionsStats/questions-stats.component';

@Component({
  selector: 'app-statistics-questions',
  standalone: true,
  imports: [
    BreadcrumbComponent,
    ReactiveFormsModule,
    DropdownModule,
    NgIf,
    QuestionsStatsComponent,
  ],
  templateUrl: './questions.component.html',
})
export class QuestionsComponent extends AbstractFormComponent {
  protected readonly AdminRoute = AdminRoute;

  form: FormGroup<CategoryForm> | undefined;

  categoriesList: Category[] = [];

  columns: TableColumn[] = [
    { name: '' },
    { name: 'Questions' },
    { name: 'Thèmes' },
    { name: 'Utilisation' },
    { name: 'Réussite' },
  ];

  selectedCategory: Category | null = null;

  breadcrumbs: BreadcrumbItem[] = [
    { label: RouteLabel.Home, route: AdminRoute.Home },
    { label: RouteLabel.Statistics, route: AdminRoute.Statistics },
    { label: RouteLabel.StatsQuestions },
  ];

  constructor(
    public categoryService: CategoryService,
    public fb: FormBuilder,
  ) {
    super();
    this.categoryService
      .list()
      .subscribe(
        (val) => (this.categoriesList = val._embedded?.category ?? []),
      );

    this.form = this.initForm(this.fb);
  }

  initForm(fb?: FormBuilder): FormGroup<CategoryForm> | undefined {
    if (!fb) return;

    return fb.group<CategoryForm>({
      selectedCategory: fb.control(this.selectedCategory),
    });
  }

  handleChangeCategory(): void {
    this.selectedCategory = this.form?.controls.selectedCategory.value ?? null;
  }
}
