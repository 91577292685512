<div class="grid">
  <app-breadcrumb [items]="breadcrumbs"></app-breadcrumb>
</div>

<h1>{{ RouteLabel.AnalyzedQuestion }}</h1>

<app-validate-question
  [disabled]="!hasPermissionToValidate"
  [loading]="loading"
  class="col-12"
  [form]="form"
>
  <ng-template #customActions let-form>
    <div class="col-12 flex justify-content-end gap-3">
      @if (canBeValidated) {
        <pf-action-button
          label="Tester"
          tooltipPosition="top"
          pTooltip="Tester la question"
          (click)="onTestQuestionModal()"
        ></pf-action-button>
        <pf-action-button
          [disabled]="!hasPermissionToValidate"
          label="Rejeter la question"
          tooltipPosition="top"
          [pTooltip]="rejectTooltipMessage"
          (click)="openRejectQuestionModal()"
        ></pf-action-button>
        <pf-action-button
          [disabled]="!hasPermissionToValidate"
          styleClass="p-button-tertiary"
          label="Valider la question"
          tooltipPosition="top"
          [pTooltip]="validateTooltipMessage"
          (click)="openValidateQuestionModal()"
        ></pf-action-button>
      } @else {
        <p-button
          label="Editer la question"
          [routerLink]="getQuestionIdRoute(questionId)"
        ></p-button>
      }
    </div>
  </ng-template>
</app-validate-question>
