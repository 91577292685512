import {
  AbstractControl,
  FormBuilder,
  FormGroup,
} from '@angular/forms';
import { Candidate } from './candidate.model';

export type CandidateForm = {
  [key in keyof Candidate]: AbstractControl<Candidate[key] | null>;
};

export const initCandidateForm = (
  fb: FormBuilder,
  candidate: Candidate
): FormGroup<CandidateForm> => {
  return fb.group<CandidateForm>({
    id: fb.control(candidate.id),
    providerId: fb.control(candidate.providerId),
    category: fb.control(candidate.category),
    language: fb.control(candidate.language),
    additionalTime: fb.control(candidate.additionalTime),
  });
};
