<div class="grid">
  <app-breadcrumb [items]="breadcrumbs"></app-breadcrumb>
</div>

<h1>Question d'entraînement</h1>
<h5>Questions d’entraînement présentées aux candidats lors des sessions d’examen</h5>

<div class="flex mb-4 justify-content-end">
  <pf-action-button *ngIf="canEdit"
    label="Ajouter une question d'entraînement"
    customIconClass="pi pi-plus"
    (buttonClicked)="addTrainingQuestion()"
  ></pf-action-button>
</div>

<pf-table
  [data]="editableItems"
  [response]="undefined"
  [isLazyMode]="true"
  noResultLabel="Pas de question d'entraînement"
  [rows]="rowsLength"
  [columns]="columns"
  [displaySkeleton]="true"
  [showCaption]="true"
  title="Questions d'entraînement"
  styleClass="mb-5"
>
  <ng-template #header>
    <tr>
      <th *ngFor="let column of columns" [id]="'id_'+column" class="font-normal">
        {{ column.name }}
      </th>
    </tr>
  </ng-template>

  <ng-template #content let-question let-index>
    <tr>
      <td tooltipPosition="top"
          [pTooltip]="getTooltipDescription(question)">{{ question.description | truncate : MAX_QUESTION_DESCRIPTION_LENGTH }}
      </td>
      <td>
        <app-select-media-thumbnail [media]="question.picture">

        </app-select-media-thumbnail>
      </td>
      <td>
        @if(isEditing(question)) {
          <textarea
            class="w-full"
            pInputTextarea
            placeholder="Veuillez renseigner une description !"
            rows="5"
            type="text"
            [(ngModel)]="question.correction"></textarea>
        } @else {
          @if (question.correction === null) {
            <p class="font-bold font-italic p-error">Veuillez renseigner une description !</p>
          } @else {
            {{ question.correction }}
          }
        }
      </td>
      <td class="icon-button">
        <div class="flex align-items-center justify-content-center gap-2">
          <p-button
            *ngIf="!isEditing(question)"
            icon="pi pi-eye"
            [disabled]="!canShow"
            [rounded]="true"
            size="large"
            ariaLabel="Voir la question"
            pTooltip="Voir la question"
            tooltipPosition="top"
            (click)="openViewQuestionModal(question)"
          ></p-button>
          <p-button
            *ngIf="!isEditing(question)"
            [disabled]="!canEdit || loading"
            icon="pi pi-pencil"
            [rounded]="true"
            size="small"
            tooltipPosition="top"
            pTooltip="Modifier la correction"
            ariaLabel="Modifier la correction"
            (click)="onRowEditInit(question)"
          ></p-button>
          <p-button
            *ngIf="!isEditing(question)"
            [disabled]="!canEdit || loading"
            icon="pi pi-trash"
            [rounded]="true"
            size="small"
            tooltipPosition="top"
            pTooltip="Supprimer la correction"
            ariaLabel="Supprimer la correction"
            (click)="openModalConfirmDelete(question)"
          ></p-button>

          <p-button
            *ngIf="isEditing(question)"
            [disabled]="loading"
            [rounded]="true"
            pTooltip="Annuler la modification"
            tooltipPosition="top"
            ariaLabel="Supprimer la correction"
            icon="pi pi-times"
            (click)="onRowEditCancel(question)"
            class="p-button-rounded p-button-text p-button-danger">
          </p-button>
          <p-button
            *ngIf="isEditing(question)"
            [disabled]="loading"
            [rounded]="true"
            pTooltip="Enregistrer la correction"
            tooltipPosition="top"
            ariaLabel="Enregistrer la correction"
            icon="pi pi-check"
            (click)="onRowEditSave(question)"
            class="p-button-rounded p-button-text p-button-success mr-2">
          </p-button>
        </div>
      </td>
    </tr>
  </ng-template>
</pf-table>
