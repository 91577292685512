<div class="section relative flex flex-column justify-content-between mb-6">
  <div class="flex">
    <pf-form-summary-edition-wrapper
      [editionAvailable]="false"
      [isReadOnly]="true"
      class="w-6 inside-wrapper"
    >
      <ng-template #formContent>
        <div class="mb-3">
          <p class="font-bold text-xl mb-1">Examen</p>
          <p class="font-normal">{{ serie()?.category?.name }}</p>
        </div>
      </ng-template>
    </pf-form-summary-edition-wrapper>
  </div>

  <div class="flex">
    <pf-form-summary-edition-wrapper
      [editionAvailable]="false"
      [isReadOnly]="true"
      class="w-6 inside-wrapper"
    >
      <ng-template #formContent>
        <div class="mb-3">
          <p class="font-bold text-xl mb-1">Agent</p>
          <p class="font-normal">{{ creator }}</p>
        </div>
      </ng-template>
    </pf-form-summary-edition-wrapper>
    <pf-form-summary-edition-wrapper
      [editionAvailable]="false"
      [isReadOnly]="true"
      class="w-6 inside-wrapper"
    >
      <ng-template #formContent>
        <div class="mb-3">
          <p class="font-bold text-xl mb-1">Date de téléchargement</p>
          <p class="font-normal">
            {{ serie()?.createdDate | date: "dd/MM/yyyy à H:mm" }}
          </p>
        </div>
      </ng-template>
    </pf-form-summary-edition-wrapper>
    <pf-form-summary-edition-wrapper
      [editionAvailable]="false"
      [isReadOnly]="true"
      class="w-6 inside-wrapper"
    >
      <ng-template #formContent>
        <div class="mb-3">
          <p class="font-bold text-xl mb-1">Nombre de questions</p>
          <p class="font-normal">
            {{ serie()?.questions?.length ?? 0 | pluralize: "question" }}
          </p>
        </div>
      </ng-template>
    </pf-form-summary-edition-wrapper>
  </div>
</div>
