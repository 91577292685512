import { Component } from '@angular/core';
import {
  BreadcrumbComponent,
  BreadcrumbItem,
} from '@components/common/breadcrumb/breadcrumb.component';
import { AdminRoute, RouteLabel } from '@utils/routes';
import { DatePipe, NgIf } from '@angular/common';
import { SeriesService } from '@services/series.service';
import {
  PageRequest,
  PfFormSummaryEditionWrapperComponent,
  PfTableComponent,
  TableColumn,
} from 'pf-ui';
import { ThumbnailComponent } from '@components/medias/thumbnail/thumbnail.component';
import { ButtonModule } from 'primeng/button';
import { TooltipModule } from 'primeng/tooltip';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { QuestionListComponent } from '@components/questions/question-list/question-list.component';
import { Theme } from '@models/question/theme.model';
import { MAX_QUESTION_DESCRIPTION_LENGTH } from '@pages/questions/questions.component';
import { TruncatePipeModule } from '@app/pipes/truncate.pipe';
import { PersonalDataItemComponent } from '@components/exam/personal-data-item/personal-data-item.component';
import { SessionTooltipHelperComponent } from '@components/tooltips/session-tooltip-helper/session-tooltip-helper.component';
import { getFullName } from '@models/user/agent.model';
import { PluralizePipeModule } from '@app/pipes/pluralize.pipe';
import { SerieInformationsComponent } from '@components/series/serie-informations.component';
import { LIMIT_ROW_TABLE } from '@app/utils/constants';

@Component({
  selector: 'app-archives-series-serie',
  standalone: true,
  imports: [
    BreadcrumbComponent,
    NgIf,
    PfTableComponent,
    ThumbnailComponent,
    DatePipe,
    ButtonModule,
    TooltipModule,
    RouterLink,
    QuestionListComponent,
    TruncatePipeModule,
    PersonalDataItemComponent,
    PfFormSummaryEditionWrapperComponent,
    SessionTooltipHelperComponent,
    PluralizePipeModule,
    SerieInformationsComponent,
  ],
  templateUrl: './serie.component.html',
})
export class SerieComponent {
  serieQuestions = this.seriesService.signalSeriesPaginated;
  serie = this.seriesService.signalCurrent;

  serieId?: string;

  breadcrumbs: BreadcrumbItem[] = [
    { label: RouteLabel.Home, route: AdminRoute.Home },
    { label: RouteLabel.SeriesArchives, route: AdminRoute.SeriesArchives },
    {
      label: RouteLabel.SeriesArchivesDownloadedSeries,
      route: AdminRoute.SeriesArchivesDownloadedSeries,
    },
  ];

  columns: TableColumn[] = [
    { name: 'Description', sortName: 'description' },
    { name: 'Média' },
    { name: 'Thème(s)' },
  ];

  currentPageRequest: PageRequest = {
    page_size: 10,
    sort: 'description',
  };

  LIMIT_ROW_TABLE = LIMIT_ROW_TABLE;

  constructor(
    private seriesService: SeriesService,
    private route: ActivatedRoute,
  ) {
    this.serieId = this.route.snapshot.paramMap.get('id') ?? '';
    this.breadcrumbs.push({ label: 'Série ' + this.serieId });

    this.seriesService.getSerie(this.serieId).subscribe();
  }

  onParametersChangedHandler(event: PageRequest): void {
    this.currentPageRequest = {
      ...this.currentPageRequest,
      ...event,
    };
    if (this.serieId !== undefined) {
      this.seriesService
        .getSerieQuestions(this.serieId, this.currentPageRequest)
        .subscribe();
    }
  }

  renderList(values: Theme[]): string {
    return values?.map((x) => x.name).join(', ');
  }

  protected readonly MAX_QUESTION_DESCRIPTION_LENGTH =
    MAX_QUESTION_DESCRIPTION_LENGTH;
  protected readonly getFullName = getFullName;
}
