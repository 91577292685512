@if (shouldDisplayTrainingAlert()) {
  <pf-alert [label]="alertTrainingMessage()" severity="info"></pf-alert>
}
@if (shouldDisplayExamAlert()) {
  <pf-alert [label]="alertExamMessage()" severity="info"></pf-alert>
}
<pf-action-button
  type="previous"
  styleClass="flex w-min p-button-tertiary border-none justify-content-start text-sm"
  label="Retour"
  (buttonClicked)="goBack()"
></pf-action-button>
<h1>Bienvenue,</h1>

<div *ngIf="sessionStatus()?.sessionDate !== undefined" class="section p-3">
  <h2 class="m-0">{{ getFrenchSessionDate() }}</h2>
  <div class="flex justify-content-between align-items-center">
    <div>
      <p class="ml-3">
        <i class="pi pi-lock pr-2"></i>PIN de la session : {{ sessionPin }}
      </p>
      <p class="ml-3 mb-0">
        <i class="pi pi-users pr-2"></i>{{ numberOfRegisteredCandidates }}
      </p>
    </div>
    <pf-download-button
      *ngIf="sessionStatus().hasClickedOnSessionEnd"
      label="Télécharger les résultats"
      extension="csv"
      (buttonClicked)="downloadResultsCsv()"
    >
    </pf-download-button>
  </div>
</div>
<pf-form *ngIf="form" [formGroup]="form">
  <div class="flex flex-wrap gap-6 my-5">
    <pf-action-button
      label="Générer le code PIN"
      styleClass="p-session-button"
      class="w-full flex-1"
      (buttonClicked)="generatePin()"
      [disabled]="isPinButtonDisabled()"
      [class.button-disabled]="isPinButtonDisabled()"
    >
    </pf-action-button>
    <pf-action-button
      label="Démarrer l'entraînement"
      styleClass="p-session-button"
      class="w-full flex-1"
      [disabled]="isStartTrainingButtonDisabled()"
      (buttonClicked)="onStartStep(SessionExamStep.TRAINING_QUESTIONS)"
      [class.button-disabled]="this.sessionStatus().hasClickedOnStartTraining"
    >
    </pf-action-button>
    <pf-action-button
      label="Démarrer l'examen"
      styleClass="p-session-button"
      class="w-full flex-1"
      [disabled]="isStartExamButtonDisabledSignal()"
      (buttonClicked)="onStartStep(SessionExamStep.OFFICIAL_TEST)"
      [class.button-disabled]="this.sessionStatus().hasClickedOnStartExam"
    >
    </pf-action-button>
    <pf-action-button
      label="Afficher les résultats"
      styleClass="p-session-button"
      class="w-full flex-1"
      (buttonClicked)="onStartStep(SessionExamStep.DISPLAY_RESULTS)"
      [disabled]="isDisplayResultsButtonDisabledSignal()"
      [class.button-disabled]="this.sessionStatus().hasClickedOnShowResult"
    >
    </pf-action-button>
    <pf-action-button
      label="Clôturer la session"
      styleClass="p-session-button"
      class="w-full flex-1"
      (buttonClicked)="onStartStep(SessionExamStep.SESSION_END)"
      [disabled]="isEndedSessionButtonDisabledSignal()"
      [class.button-disabled]="this.sessionStatus().hasClickedOnSessionEnd"
    >
    </pf-action-button>
  </div>

  <pf-table
    [data]="controls.candidates.controls"
    [isLazyMode]="false"
    [noResultLabel]="'Pas de candidat inscrit pour cette session'"
    [rows]="controls.candidates.controls.length"
    title="Candidats"
    [showCaption]="true"
    styleClass="mb-5"
  >
    <ng-template #header>
      <tr>
        <th
          *ngFor="let column of columns"
          class="font-normal"
          [id]="'session_table_column_name_' + column"
          [class.text-center]="centeredColumns.includes(column)"
        >
          {{ column }}
        </th>
      </tr>
    </ng-template>

    <ng-template #content let-candidate>
      <tr>
        <td class="text-center">
          <div [formGroup]="candidate">
            <p-checkbox
              [inputId]="candidate.controls.user.value.providerId"
              [binary]="true"
              (onChange)="
                updateCandidatePresence(
                  candidate.controls.user.value.providerId
                )
              "
              formControlName="present"
            ></p-checkbox>
          </div>
        </td>
        <td>{{ candidate.controls.user.value.providerId }}</td>
        <td>{{ candidate.controls.category?.value?.name }}</td>
        <td>{{ getLanguage(candidate.controls.language.value) }}</td>
        <td>{{ getSpec(candidate.controls.additionalTime.value) }}</td>
        <td>
          <div class="candidate-connected">
            <div class="flex gap-2 align-items-center">
              <i
                tooltipPosition="top"
                [pTooltip]="
                  isCandidateConnectedMessage(
                    candidate.controls.connected.value
                  )
                "
                class="pi {{
                  candidatePassedStepClass(candidate.controls.connected.value)
                }} "
              ></i>
              <i
                tooltipPosition="top"
                [pTooltip]="
                  isCandidateFinishTrainingMessage(
                    candidate.controls.hasFinishedTraining.value
                  )
                "
                class="pi {{
                  candidatePassedStepClass(
                    candidate.controls.hasFinishedTraining.value
                  )
                }} "
              ></i>
              <i
                tooltipPosition="top"
                [pTooltip]="
                  isCandidateFinishedExamMessage(
                    candidate.controls.hasFinishedExam.value
                  )
                "
                class="pi {{
                  candidatePassedStepClass(
                    candidate.controls.hasFinishedExam.value
                  )
                }} "
              ></i>
              <i
                tooltipPosition="top"
                [pTooltip]="
                  isCandidateSawResultMessage(
                    candidate.controls.hasSawResult.value
                  )
                "
                class="pi {{
                  candidatePassedStepClass(
                    candidate.controls.hasSawResult.value
                  )
                }} "
              ></i>
            </div>
          </div>
        </td>
      </tr>
    </ng-template>
  </pf-table>

  <div *ngIf="controls.candidates.length > 0" class="section p-4">
    <p-checkbox
      ariaLabel="Je certifie que les informations renseignées sont exactes"
      label="Je certifie que les informations renseignées sont exactes"
      formControlName="informationValidated"
      [binary]="true"
      (onChange)="openConfirmCandidatesInformationValidated()"
    ></p-checkbox>
  </div>
</pf-form>
