<div class="grid">
  <app-breadcrumb [items]="breadcrumbs"></app-breadcrumb>
</div>

<h1>Générer une serie</h1>
<h5>Définissez la série que vous souhaitez créer</h5>

<pf-form *ngIf="form" [formGroup]="form">
  <div class="grid form-container">
    <pf-card class="col-12">
      <div class="grid">
        <pf-form-label-wrapper
          [showErrors]="showErrors(form, 'selectedCategory')"
          class="col-12"
        >
          <ng-template #errors>
            <pf-form-error
              *ngIf="form.controls?.selectedCategory?.errors?.['required']"
              label="Catégorie obligatoire."
            ></pf-form-error>
          </ng-template>
        </pf-form-label-wrapper>
        <div class="flex w-full justify-content-between align-items-end">
          <p-dropdown
            class="col-4"
            styleClass="w-full"
            formControlName="selectedCategory"
            [options]="categories()?._embedded?.category ?? []"
            optionLabel="name"
            placeholder="Catégorie"
          >
          </p-dropdown>
          <pf-action-button
            type="custom"
            label="Télécharger"
            [disabled]="loading() || !isCategorySelected"
            [customIconClass]="loading() ? 'pi pi-spinner spin-animation' : ''"
            (buttonClicked)="download()"
          ></pf-action-button>
        </div>
      </div>
    </pf-card>
  </div>
</pf-form>
