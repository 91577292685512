import { CommonModule } from '@angular/common';
import { Component, effect } from '@angular/core';
import { DttLogoComponent } from '@app/components/common/dtt-logo/dtt-logo.component';
import { SpinnerComponent } from '@app/components/common/spinner/spinner.component';
import { AuthService } from '@app/services/auth.service';
import { ExamSessionService } from '@app/services/examSession.service';
import { UserExamSessionService } from '@app/services/userExamSession.service';
import { AppService } from '@services/app.service';

@Component({
  selector: 'app-candidate-results',
  standalone: true,
  imports: [CommonModule, DttLogoComponent, SpinnerComponent],
  templateUrl: './results.component.html',
  styleUrl: './results.component.scss',
})
export class ResultsComponent {
  userExamResults = this.userExamSessionService.signalResults;
  userExamSession = this.userExamSessionService.signalUserExamSession;
  examSession = this.examSessionService.signalExamSession;

  providerId: string;
  correctionLoading = true;
  hasSuccessfullyPassed?: boolean;

  constructor(
    protected examSessionService: ExamSessionService,
    protected userExamSessionService: UserExamSessionService,
    private authService: AuthService,
    private appService: AppService,
  ) {
    this.appService.updateHeaderDisplay(false);
    this.providerId = this.authService.getProviderIdAndSessionCode().providerId;

    effect(() => {
      if (
        this.userExamResults() !== undefined &&
        this.userExamResults() !== null
      ) {
        this.correctionLoading = false;
        this.hasSuccessfullyPassed =
          this.userExamResults()?.hasSuccessfullyPassed;
      }
    });

    effect(() => {
      const examSession = this.examSession();
      if (examSession == null) return; // TODO: manage error case

      this.userExamSessionService
        .getCorrection(examSession.id, this.providerId)
        .subscribe();
    });
  }

  get numberOfErrors(): number {
    const numberOfCorrectQuestions = this.userExamResults()?.correctQuestions;
    const numberOfQuestions = this.userExamSession()?.category.numberOfQuestions ?? 0;

    if (numberOfCorrectQuestions === undefined) {
      return numberOfQuestions;
    }

    return numberOfQuestions - numberOfCorrectQuestions;
  }

  get errorLine(): string {
    const category = this.userExamSession()?.category;

    if (!category) {
      return '';
    }

    const numberOfErrors = this.numberOfErrors;
    const maxErrors =
      category.numberOfQuestions - category.requiredCorrectQuestions;

    const numberOfErrorsLine = `<div>Vous avez fait ${numberOfErrors} fautes sur ${category.numberOfQuestions}.</div>`;
    const maxErrorsLine = `<div>Pour réussir l'${category.name}, vous devez en faire ${maxErrors} ou moins.</div>`;

    // EXAM FAILED
    if (numberOfErrors > maxErrors) {
      return numberOfErrorsLine + maxErrorsLine;
    }

    // EXAM PASSED
    if (numberOfErrors === 1) {
      return `Vous avez fait ${numberOfErrors} faute sur ${category.numberOfQuestions} à l'${category.name}.`;
    }

    if (numberOfErrors === 0) {
      return `Vous n'avez fait aucune faute sur ${category.numberOfQuestions} à l'${category.name}.`;
    }

    return `Vous avez fait ${numberOfErrors} fautes sur ${category.numberOfQuestions} à l'${category.name}.`;
  }
}
