import {
  ApplicationConfig,
  ErrorHandler,
  importProvidersFrom,
} from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import {
  PreloadAllModules,
  Router,
  provideRouter,
  withComponentInputBinding,
  withPreloading,
} from '@angular/router';

import {
  HTTP_INTERCEPTORS,
  HttpClient,
  provideHttpClient,
  withInterceptors,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { APP_INITIALIZER } from '@angular/core';
import {
  TranslateCompiler,
  TranslateLoader,
  TranslateModule,
  TranslateModuleConfig,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import * as Sentry from '@sentry/angular-ivy';
import {
  KeycloakAngularModule,
  KeycloakBearerInterceptor,
  KeycloakService,
} from 'keycloak-angular';
import { CookieService } from 'ngx-cookie-service';
import { TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';
import { PfUiTranslateLoader } from 'pf-ui';
import { MessageService } from 'primeng/api';
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from 'primeng/dynamicdialog';
import { routes } from './app.routes';
import { initializeKeycloak } from './init/keycloak-init.factory';
import { AdminInterceptor } from './interceptors/admin.interceptor';
import { tenantInterceptor } from './interceptors/tenant.interceptor';
import { xAuthTokenInterceptor } from './interceptors/x-auth-token.interceptor';

const createTranslateLoader = (http: HttpClient): PfUiTranslateLoader =>
  PfUiTranslateLoader.mergeWith(
    new TranslateHttpLoader(http, './assets/i18n/', '.json'),
  );

export const translateConfig: TranslateModuleConfig = {
  loader: {
    provide: TranslateLoader,
    useFactory: createTranslateLoader,
    deps: [HttpClient, Router],
  },
  compiler: {
    provide: TranslateCompiler,
    useClass: TranslateMessageFormatCompiler,
  },
  defaultLanguage: 'fr',
};

export const appConfig: ApplicationConfig = {
  providers: [
    MessageService,
    CookieService,
    DialogService,
    DynamicDialogConfig,
    DynamicDialogRef,
    provideRouter(
      routes,
      withComponentInputBinding(),
      withPreloading(PreloadAllModules),
    ),
    provideAnimations(),
    importProvidersFrom(TranslateModule.forRoot(translateConfig)),
    provideHttpClient(
      withInterceptorsFromDi(),
      withInterceptors([
        xAuthTokenInterceptor,
        tenantInterceptor,
        AdminInterceptor,
      ]),
    ),
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler(),
    },
  ],
};

export const keycloakProviders: ApplicationConfig['providers'] = [
  {
    provide: APP_INITIALIZER,
    useFactory: initializeKeycloak,
    multi: true,
    deps: [KeycloakService],
  },
  KeycloakAngularModule,
  KeycloakService,
  KeycloakBearerInterceptor,
  {
    provide: HTTP_INTERCEPTORS,
    useClass: KeycloakBearerInterceptor,
    multi: true,
    deps: [KeycloakService],
  },
];
