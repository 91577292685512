import { CommonModule, NgOptimizedImage } from '@angular/common';
import { Component, CUSTOM_ELEMENTS_SCHEMA, signal } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { Media } from '@app/models/media/media.model';
import { MediaService } from '@app/services/media.service';
import { TranslateModule } from '@ngx-translate/core';
import { PageRequest, PfActionButtonComponent, PfTableComponent } from 'pf-ui';
import { ButtonModule } from 'primeng/button';
import { DialogService } from 'primeng/dynamicdialog';
import { TableModule } from 'primeng/table';
import {
  BreadcrumbComponent,
  BreadcrumbItem,
} from '@components/common/breadcrumb/breadcrumb.component';
import { UploadMediaComponent } from '@components/medias/upload-media/upload-media.component';
import { EditMediaComponent } from '@pages/media/edit-media/edit-media.component';
import { MediaTag } from '@models/media/mediaTag.model';
import { MediaTypes } from '@models/media/media-types.models';
import { MediaTagService } from '@services/mediaTag.service';
import { catchError, throwError } from 'rxjs';
import { MessageService } from 'primeng/api';
import { SpinnerComponent } from '@components/common/spinner/spinner.component';
import { TagModule } from 'primeng/tag';
import { AdminRoute, RouteLabel } from '@utils/routes';
import { MultiSelectModule } from 'primeng/multiselect';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule } from '@angular/forms';
import { TooltipModule } from 'primeng/tooltip';
import { ThumbnailComponent } from '@components/medias/thumbnail/thumbnail.component';
import { AgentService } from '@services/agent.service';
import { hasAuthority } from '@models/user/agent-detail.model';
import { LIMIT_ROW_TABLE } from '@app/utils/constants';

@Component({
  selector: 'app-media',
  standalone: true,
  imports: [
    CommonModule,
    DropdownModule,
    PfActionButtonComponent,
    TranslateModule,
    RouterLink,
    ButtonModule,
    BreadcrumbComponent,
    FormsModule,
    MultiSelectModule,
    NgOptimizedImage,
    SpinnerComponent,
    TableModule,
    TagModule,
    TooltipModule,
    ThumbnailComponent,
    PfTableComponent,
  ],
  templateUrl: './media.component.html',
  styleUrl: './media.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class MediaComponent {
  mediaTags = this.mediaTagsService.signalList;
  medias = this.mediaService.signalListPaginated;
  agent = this.agentService.signalCurrent;

  selectedTags: MediaTag[] = [];

  breadcrumbs: BreadcrumbItem[] = [
    { label: RouteLabel.Home, route: AdminRoute.Home },
    { label: RouteLabel.Media },
  ];

  currentPage = 0;

  loading = signal<boolean>(false);

  initialPageRequest: PageRequest = {
    global_filter_fields: 'tagIds',
    global_filter: [],
  };

  currentPageRequest = this.initialPageRequest;

  LIMIT_ROW_TABLE = LIMIT_ROW_TABLE;

  constructor(
    private dialog: DialogService,
    private mediaTagsService: MediaTagService,
    private messageService: MessageService,
    public mediaService: MediaService,
    private router: Router,
    private agentService: AgentService,
  ) {
    this.mediaService.signalCurrent.set(null);
    this.mediaTagsService.getList().subscribe();
  }

  onParametersChangedHandler(event: PageRequest): void {
    this.currentPageRequest = {
      ...this.currentPageRequest,
      ...event,
    };
    this.mediaService.findByTags(this.currentPageRequest).subscribe();
  }

  get canEdit(): boolean {
    return hasAuthority(this.agent(), 'media.view');
  }

  get icon(): string {
    if (this.currentPageRequest?.order !== undefined) {
      if (this.currentPageRequest.order === 'ASC') {
        return 'pi-sort-amount-up-alt';
      }
      return 'pi-sort-amount-down';
    }
    return 'pi-sort-alt';
  }

  get canCreateQuestionFromMedia(): boolean {
    return hasAuthority(this.agent(), 'question.create');
  }

  get canCreateMedia(): boolean {
    return hasAuthority(this.agent(), 'media.create');
  }

  openAddNewMediaModal(): void {
    const dialog = this.dialog.open(UploadMediaComponent, {
      data: {
        tagList: this.mediaTags(),
      },
      styleClass: 'pf-two-column-form-modal',
      showHeader: false,
    });

    dialog.onClose.subscribe((media?: Media) => {
      if (media === undefined) return;

      this.saveMedia(media);
    });
  }

  saveMedia(media: Media): void {
    this.loading.set(true);
    this.mediaService
      .post(media, MediaTypes.PICTURE)
      .pipe(
        catchError((err) => {
          this.showErrorToast(this.messageService, err.error);
          return throwError(() => err);
        }),
      )
      .subscribe(() => {
        this.mediaService.findByTags(this.currentPageRequest).subscribe();
        this.loading.set(false);
      });
  }

  updateMedia(media: Media): void {
    this.mediaService
      .put(media)
      .subscribe(() =>
        this.mediaService.findByTags(this.currentPageRequest).subscribe(),
      );
  }

  showErrorToast(messageService: MessageService, message: string): void {
    messageService.add({
      severity: 'error',
      summary: 'Erreur',
      detail: message,
    });
  }

  createQuestionFromMediaModel(media: Media): void {
    this.mediaService.setCurrent(media);
    this.router.navigate([AdminRoute.Question]);
  }

  openEditMediaModal(media: Media): void {
    const dialog = this.dialog.open(EditMediaComponent, {
      data: {
        mediaId: media.id,
        mediaTags: media.mediaTags,
      },
      styleClass: 'pf-two-column-form-modal',
      showHeader: false,
    });

    dialog.onClose.subscribe(
      async (data: { mediaTags?: MediaTag[]; mediaType?: MediaTypes }) => {
        if (data !== undefined) {
          const { mediaTags } = data;
          this.updateMedia({ id: media.id, mediaTags });
        }
      },
    );
  }

  filterByMediaTag(selectedTags: MediaTag[]): void {
    if (selectedTags.length === 0) {
      this.clearMediaTagFilter();
      return;
    }

    this.currentPageRequest = {
      ...this.currentPageRequest,
      global_filter_fields: 'tagIds',
      global_filter: selectedTags.map((tag) => tag.id),
    };

    this.mediaService.findByTags(this.currentPageRequest).subscribe();
  }

  clearMediaTagFilter(): void {
    this.selectedTags = [];
    this.mediaService.findByTags(this.initialPageRequest).subscribe();
  }
}
