import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, signal } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { Media } from '@app/models/media/media.model';
import { SafePipe } from '@app/pipes/safe.pipe';
import { formatMediaTags } from '@app/utils/media';
import { environement } from '@environments/environment';
import { ApiResponse } from '@models/common/api-response.model';
import { PageableResponse } from '@models/common/pageable-response.model';
import { MediaTypes } from '@models/media/media-types.models';
import { VoiceSound } from '@models/voice-sound/voice-sound.model';
import { BaseService } from '@services/base.service';
import { ErrorService } from '@services/error.service';
import { PageRequest, PageResponse } from 'pf-ui';
import { MessageService } from 'primeng/api';
import { EMPTY, Observable, catchError, from, map } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class MediaService extends BaseService {
  signalList = signal<Media[] | undefined>(undefined);

  signalListPaginated = signal<PageResponse<Media> | undefined>(undefined);
  signalCurrent = signal<Media | null>(null);
  signalStaticVoices = signal<Array<VoiceSound>>([]);
  correctionIntroStaticVoices = signal<Array<VoiceSound>>([]);

  constructor(
    protected override http: HttpClient,
    protected override messageService: MessageService,
    private errorService: ErrorService,
    private safePipe: SafePipe,
  ) {
    super(http, messageService);
  }

  setCurrent(media: Media): void {
    this.signalCurrent.set(media);
  }

  resetCurrent(): void {
    this.signalCurrent.set(null);
  }

  list(): Observable<Media[]> {
    return this.executeRequest(
      this.http.get<Media[]>(`${environement.BACKEND_URL}/medias`),
    ).pipe(
      tap((val) => {
        this.signalList.set(val);
      }),
    );
  }

  listPaginated(event?: PageRequest): Observable<PageResponse<Media>> {
    return this.executePaginatedRequest(
      this.http.get<ApiResponse<{ [key: string]: Media[] }>>(
        `${environement.BACKEND_URL}/media`,
        {
          params: this.getHttpParams({ ...event, projection: 'mediaList' }),
        },
      ),
    ).pipe(
      tap((pageResponse) => {
        this.signalListPaginated.set(pageResponse);
      }),
    );
  }

  getImage(keyName?: string): Observable<SafeUrl | undefined> {
    return this.http
      .get<Blob>(`${environement.BACKEND_URL}/medias/image/${keyName ?? ''}`, {
        responseType: 'blob' as 'json',
      })
      .pipe(
        map((blob: Blob) => this.mapBlobToTrustedUrl(blob)),
        catchError((err) => {
          return this.errorService.handleErrors(err);
        }),
      );
  }

  getImageThumbnail(keyName?: string): Observable<SafeUrl | undefined> {
    return this.http
      .get<Blob>(
        `${environement.BACKEND_URL}/medias/image/thumbnail/${keyName ?? ''}`,
        {
          responseType: 'blob' as 'json',
        },
      )
      .pipe(
        map((blob: Blob) => this.mapBlobToTrustedUrl(blob)),
        catchError((err) => {
          return this.errorService.handleErrors(err);
        }),
      );
  }

  getAudio(keyName?: string): Observable<string> {
    return this.http
      .get<Blob>(`${environement.BACKEND_URL}/medias/audio/${keyName ?? ''}`, {
        responseType: 'blob' as 'json',
      })
      .pipe(
        map((blob: Blob) => this.mapBlobToAudioUrl(blob)),
        catchError((err) => {
          return this.errorService.handleErrors(err);
        }),
      );
  }

  public mapBlobToTrustedUrl = (blob: Blob): SafeUrl | undefined => {
    const objectURL = URL.createObjectURL(blob);
    return this.safePipe.transform(objectURL);
  };

  public mapBlobToAudioUrl = (blob: Blob): string => {
    return URL.createObjectURL(blob);
  };

  get(id: string): void {
    this.executeRequest(
      this.http.get<Media>(`${environement.BACKEND_URL}/media/${id}`),
    ).subscribe((val) => {
      this.signalCurrent.set(val);
    });
  }

  post(media: Media, type: MediaTypes): Observable<Media> {
    if (media.base64_file === undefined) return EMPTY;

    return from(this.mapBody(media, type)).pipe(
      switchMap((data) => {
        const headers = new HttpHeaders({ enctype: 'multipart/form-data' });

        return this.executeRequest(
          this.http.post<Media>(
            `${environement.BACKEND_URL}/medias/upload?projection=mediaDetail`,
            data,
            {
              headers,
            },
          ),
        ).pipe(tap((val) => this.signalCurrent.set(val)));
      }),
    );
  }

  put(media: Media): Observable<Media> {
    return this.executeRequest(
      this.http.patch<Media>(`${environement.BACKEND_URL}/media/${media.id}`, {
        ...media,
        mediaTags: formatMediaTags(media.mediaTags),
      }),
    ).pipe(tap((val) => this.signalCurrent.set(val)));
  }

  findByTags(event?: PageRequest): Observable<PageResponse<Media>> {
    const params = this.getHttpParams({ ...event });

    return this.http
      .get<PageableResponse<Media>>(`${environement.BACKEND_URL}/medias/tags`, {
        params,
      })
      .pipe(
        this.mapPageableResponseToPageResponse<Media>(),
        tap((val) => {
          this.signalListPaginated.set(val);
          this.signalList.set(val.result as Media[] | undefined);
        }),
      );
  }

  async mapBody(media: Media, type: MediaTypes): Promise<FormData> {
    const mimeType =
      media.name?.endsWith('.png') === true ? 'image/png' : 'image/jpg';
    const totalBase64 = `data:${mimeType};base64,${media.base64_file}`;
    const base64res = await fetch(totalBase64 || '');
    const blob = await base64res.blob();
    const file = new File([blob], media.name === undefined ? '' : media.name);
    const formData = new FormData();

    media.base64_file = undefined;
    media.type = type;

    formData.append('file', file, file.name);
    formData.append(
      'media',
      new Blob([JSON.stringify(media)], { type: 'application/json' }),
    );

    return formData;
  }

  fetchStaticVoices(): Observable<{
    answerIntroVoices: VoiceSound[];
    correctionIntroVoices: VoiceSound[];
  }> {
    return this.executeRequest(
      this.http.get<{
        answerIntroVoices: VoiceSound[];
        correctionIntroVoices: VoiceSound[];
      }>(`${environement.BACKEND_URL}/custom-voice-sound/static-voices`),
    ).pipe(
      tap((voiceSounds) => {
        this.signalStaticVoices.set(voiceSounds.answerIntroVoices);
        this.correctionIntroStaticVoices.set(voiceSounds.correctionIntroVoices);
      }),
    );
  }

  getCorrectionIntroStaticVoices(
    questionNumber: number,
  ): VoiceSound | undefined {
    return this.correctionIntroStaticVoices().find(
      (voiceSound) =>
        voiceSound.text === 'Question numéro ' + questionNumber + ' ',
    );
  }
}
