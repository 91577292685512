<div
  *ngIf="loading"
  class="flex text-center align-items-center justify-content-center h-full"
>
  <app-spinner />
</div>
<div *ngIf="!loading" class="grid">
  <div
    *ngFor="let choice of randomChoices(); let answerIndex = index"
    class="col-6 answer"
  >
    <p-button
      [disabled]="!displayDetails()"
      [label]="choice.description ?? ''"
      (click)="
        toggleAnswerSelection()(answerSelection(), answerIndex, choice.id)
      "
      [styleClass]="
        'w-full p-3 justify-content-center gap-4 text-xl border-round-sm ' +
        getSelectedAnswerStyle(answerIndex)
      "
    >
      <div class="text-2xl">{{ numberToString(answerIndex) }}</div>
    </p-button>
  </div>
</div>
