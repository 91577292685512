<pf-table
  [response]="questionsStats()"
  [isLazyMode]="true"
  [columns]="columns"
  (onParametersChanged)="onParametersChangedHandler($event)"
  [rows]="LIMIT_ROW_TABLE"
  [displaySkeleton]="true"
  [showCaption]="true"
  [showSearch]="false"
  [title]="tableTitle"
  [rowsPerPageOptions]="[10]"
  noResultLabel="Pas de statistique sur les questions"
  dataKey="id"
  styleClass="mb-5"
>
  <ng-template #content let-question>
    <tr>
      <td class="icon-button">
        <p-button
          [icon]="question.flagged ? 'pi pi-flag-fill' : 'pi pi-flag'"
          [rounded]="true"
          size="small"
          [ariaLabel]="
            question.flagged
              ? 'Arrêter de suivre la question'
              : 'Suivre la question'
          "
          (click)="toggleFlag(question)"
        ></p-button>
      </td>
      <td class="">
        {{
          question.questionDescription
            | truncate: MAX_QUESTION_DESCRIPTION_LENGTH
        }}
      </td>
      <td [innerHTML]="getThemesName(question)"></td>
      <td>{{ question.usageRate | percent }}</td>
      <td>{{ question.successRate | percent }}</td>
    </tr>
  </ng-template>
</pf-table>
