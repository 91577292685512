import { HttpClient } from '@angular/common/http';
import { Injectable, signal } from '@angular/core';
import { MediaTag } from '@app/models/media/mediaTag.model';
import { environement } from '@environments/environment';
import { ApiResponse } from '@models/common/api-response.model';
import { MediaTagList } from '@models/media/mediaTag-list.model';
import { BaseService } from '@services/base.service';
import { MessageService } from 'primeng/api';
import { Observable, tap } from 'rxjs';
import { PageRequest, PageResponse } from 'pf-ui';

@Injectable({
  providedIn: 'root',
})
export class MediaTagService extends BaseService {
  signalList = signal<MediaTag[]>([]);
  signalListPaginated = signal<PageResponse<MediaTag> | undefined>(undefined);

  signalLists = signal<ApiResponse<MediaTagList> | null>(null);

  constructor(protected override http: HttpClient, protected override messageService: MessageService) {
    super(http, messageService);
  }

  put(mediaTag: MediaTag): Observable<MediaTag> {
    return this.executeRequest(
      this.http.put<MediaTag>(
        `${ environement.BACKEND_URL }/media_tag/${ mediaTag.id }`, mediaTag
      )
      , { retryCount: 0, catchError: false });
  }

  list(): Observable<ApiResponse<MediaTagList>> {
    return this.executeRequest(
      this.http.get<ApiResponse<MediaTagList>>(`${ environement.BACKEND_URL }/media_tag?sort=name`)
    ).pipe(
      tap((mediaTag) => {
        this.signalLists.set(mediaTag);
      })
    );
  }

  getListPaginated(event?: PageRequest): Observable<PageResponse<MediaTag>> {
    return this.executePaginatedRequest(this.http
      .get<ApiResponse<{ [key: string]: MediaTag[]; }>>(`${ environement.BACKEND_URL }/media_tag`, {
        params: this.getHttpParams({ ...event, projection: 'mediaTagList' })
      }))
      .pipe(
        tap((pageResponse) => {
          this.signalListPaginated.set(pageResponse);
        })
      );
  }

  create(name: string): Observable<string> {
    return this.executeRequest(
      this.http.post<string>(`${ environement.BACKEND_URL }/media_tag/create`, { name: name })
    );
  }

  delete(mediaTag: MediaTag): Observable<MediaTag> {
    return this.executeRequest(
      this.http.delete<MediaTag>(`${ environement.BACKEND_URL }/media_tag/${ mediaTag.id }`, { params: mediaTag })
    );
  }

  getList(): Observable<MediaTag[]> {
    return this.executeRequest(this.http
      .get<MediaTag[]>(`${ environement.BACKEND_URL }/media_tag/`)
    )
      .pipe(
        tap((val) => {
          this.signalList.set(val);
        }));
  }
}
