<app-specific-closable-dialog title="Changer de tenant" (closeClicked)="close()">

  <div class="card flex justify-content-center">
    <p-selectButton
      styleClass="flex"
      [options]="tenants"
      [(ngModel)]="tenantSelected"
      (onChange)="changeTenant($event.value)"
    >

    </p-selectButton>
  </div>

  <div class="flex align-items-stretch flex-column">
    <pf-form-action-bar class="mt-5" styleClass="gap-5 justify-content-center">
      <pf-action-button
        (buttonClicked)="validate()"
        styleClass="text-xl"
        label="Recharger"
        type="custom"
      >
      </pf-action-button>
      <pf-action-button
        (buttonClicked)="close(false)"
        styleClass="text-xl p-button-tertiary"
        [label]="'PF.GLOBAL.NO' | translate"
      >
      </pf-action-button>
    </pf-form-action-bar>
  </div>
</app-specific-closable-dialog>
