import { CommonModule } from '@angular/common';
import {
  Component,
  computed,
  effect,
  EventEmitter,
  input,
  Output,
  signal,
  untracked,
} from '@angular/core';
import { FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { PersonalDataItemComponent } from '@app/components/exam/personal-data-item/personal-data-item.component';
import {
  ExamSession,
  UserExamSessionStatusDTO,
} from '@app/models/exam/examSession.model';
import { AuthService } from '@app/services/auth.service';
import { UserService } from '@app/services/user.service';
import { UserExamSessionService } from '@app/services/userExamSession.service';
import {
  formatLocaleDateDDmonthYYYY,
  formatLocaleSessionTime,
} from '@app/utils/examSessions';

import { TranslateModule } from '@ngx-translate/core';

import {
  PfActionButtonComponent,
  PfFormComponent,
  PfFormSummaryEditionWrapperComponent,
} from 'pf-ui';
import { CheckboxModule } from 'primeng/checkbox';
import { ButtonModule } from 'primeng/button';
import { SessionTooltipHelperComponent } from '@components/tooltips/session-tooltip-helper/session-tooltip-helper.component';
import { DateTranslateService } from '@services/dateTranslate.service';
import { AppService } from '@services/app.service';
import { LanguageEnum } from '@models/exam/userExamSession.model';
import { ExamSessionService } from '@services/examSession.service';

@Component({
  selector: 'app-personal-information',
  standalone: true,
  imports: [
    CheckboxModule,
    CommonModule,
    TranslateModule,
    PfFormComponent,
    PersonalDataItemComponent,
    PfFormSummaryEditionWrapperComponent,
    ReactiveFormsModule,
    PfActionButtonComponent,
    ButtonModule,
    SessionTooltipHelperComponent,
  ],
  templateUrl: './personal-information.component.html',
})
export class PersonalInformationComponent {
  userExamSession = input<UserExamSessionStatusDTO | undefined>(undefined);
  examSession = input<ExamSession | null>(null);
  goToNextStep = input<() => void>();

  @Output() validateClicked = new EventEmitter();

  language = computed(() => this.setLanguage());
  specificity = computed(() => this.setSpecificity());
  sessionDate = computed(() => this.setSessionDate());
  sessionTime = computed(() => this.setSessionTime());
  categoryName = computed(() => this.setCategoryName());

  examQuestionsCount = signal<string>('0');

  providerId: string;
  timeout: ReturnType<typeof setTimeout> | undefined;

  constructor(
    protected authService: AuthService,
    protected formBuilder: FormBuilder,
    protected userExamSessionService: UserExamSessionService,
    protected userService: UserService,
    private examSessionService: ExamSessionService,
    private dateTranslateService: DateTranslateService,
    private appService: AppService,
  ) {
    this.providerId = this.authService.getProviderIdAndSessionCode().providerId;
    this.userService.getUser(this.providerId).subscribe();
    this.appService.updateHeaderDisplay(true);

    effect(
      () => {
        const examSession = this.examSession();
        if (examSession == null) return; 

        untracked(() => {
          this.fetchExamQuestions(examSession);
        });
      },
      { allowSignalWrites: true },
    );
  }

  setLanguage(): string {
    const lang: string | undefined = this.userExamSession()?.language;
    return lang !== undefined
      ? LanguageEnum[lang as keyof typeof LanguageEnum]
      : '';
  }

  setSpecificity(): string {
    return (this.userExamSession()?.additionalTime ?? false)
      ? 'Tiers-temps'
      : 'Aucune';
  }

  validateInformation(): void {
    const goToNextStep = this.goToNextStep();
    if (goToNextStep) {
      this.timeout = setTimeout(() => {
        goToNextStep();
      }, 1000);
    } else {
      clearTimeout(this.timeout);
    }
  }

  fetchExamQuestions(examSession: ExamSession): void {
    this.userExamSessionService
      .getQuestions(examSession.id, this.providerId)
      .subscribe((examQuestions) => {
        this.examQuestionsCount.set(examQuestions?.length?.toString() || '0');
      });
  }

  setSessionDate(): string {
    return formatLocaleDateDDmonthYYYY(
      this.dateTranslateService.getMonthLabels(),
      this.examSession()?.sessionDate,
    );
  }

  setSessionTime(): string {
    return formatLocaleSessionTime(
      this.dateTranslateService.getMonthLabels(),
      this.examSession()?.sessionDate,
    );
  }

  setCategoryName(): string {
    return this.userExamSession()?.category?.name ?? '';
  }
}
